<template>
  <div class="flex-row outer_data align-between">
    <div class="flex-row">
      <div style="width: 58px; display: flex;align-items: center;"
           v-bind:class="{icon_true :source.ischeck ,icon:!source.ischeck}">
        <el-checkbox v-model="source.ischeck" @change="seletDevice(source)"></el-checkbox>
      </div>
      <div style="width: 285px;  height: 52px;display: flex;">
        <p :title="source.fileName" class="span_name_tile_name"> {{ source.fileName }}</p>
      </div>
      <div style="width: 285px;margin-left: 16px;display: flex;">
        <span class="span_name">{{ source.fileType === '0' ? "Video" : 'Image' }}</span>
      </div>
      <div style="width: 285px;margin-left: 16px;display: flex;">
        <span class="span_name">{{ source.createTime }}</span>
      </div>
      <div style="width: 285px;margin-left: 16px;display: flex;">
        <span class="span_name">{{ this.renderSize(source.fileSize) }}</span>
      </div>
      <div class="icon" style="width: 285px;margin-left: 16px;display: flex;align-items: center;">
        <img style="width: 18px;height: 18px;cursor: pointer;" src="@/assets/icon/icondelete.svg" @click="deleFile"/>
      </div>

    </div>
  </div>
</template>

<script>
import {renderSize} from "../../utils/ruoyi";

export default {
  name: 'itemView',
  props: {
    index: { // 每一行的索引
      type: Number
    },
    source: { // 每一行的内容
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    renderSize,
    seletDevice() {
      $bus.emit('mylibrary', {code: 0, data: this.source})
    },
    deleFile(){
      $bus.emit('mylibrary', {code: 1, data: this.source})
    }
  },

}
</script>

<style scoped lang="scss">
.justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

::v-deep .el-checkbox {
  margin-left: 16px;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #303030;

  .el-checkbox__inner {
    width: 26px;
    height: 26px;
    border-radius: 50%;

    border: 1px solid rgba(149, 149, 149, 1);
    //修改选中框中的对勾的大小和位置
    &::after {
      top: 5px;
      height: 12px;
      left: 10px;
    }
  }

  .el-checkbox__label {
    display: inline-grid;
    color: #FFFFFF;
    width: 200px;

  }

  //修改点击文字颜色不变
  .el-checkbox__input.is-checked + .el-checkbox__label {
    border: 1px solid rgba(0, 145, 255, 1);
    background: rgba(0, 145, 255, 1);
    color: #303030;

  }


}

.align-between {
  display: flex;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.outer_data {
  width: 100%;
  height: 52px;
  margin-bottom: 10px;
  border-radius: 12px;
  border: 1px solid #FFFFFF;
  background-color: #FFFFFF;
  transition: 0.2s ease;

  & .icon {
    opacity: 0;
  }


  &:not(.selecting):hover {
    transform: translateY(-1px);
    filter: drop-shadow(0px 4px 4px rgba(48, 48, 48, 0.15));

    & .icon {
      opacity: 1;

    }

  }


}

.span_name_tile_name {
  margin: auto 0;
  cursor: pointer;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #404756;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.span_name {
  margin: auto 0;
  cursor: pointer;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #404756;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


</style>