<template>
  <div class="flex-col">
    <el-row class="top">
      <div class="flex-row justify-between">
        <div style=" display: flex; flex-direction: row;">
          <div style="background: rgba(64, 71, 86, 1);width: 54px;height: 54px;display: flex;cursor: pointer; "
               @click="goBack()">
            <img style="margin: auto;" :src="require('@/assets/icon/600.svg')"/>
          </div>
          <div style="width: 100px;height: 54px;display: flex;">
            <span class="span_tile">Go Back</span>
          </div>
        </div>
        <div class="flex-row" style="overflow-y: hidden;" v-if="seveItme === 0">
          <div class="flex-row" style="width: 150px;height: 54px;cursor: pointer;" @click="preview">
            <div style="display: flex;align-content: center;margin: auto;">
              <img style="width: 18px;height: 18px;" :src="require('@/assets/icon/preview.svg')">
              <span class="span_preview">Preview</span>
            </div>
          </div>
          <div class="flex-row" style="width: 150px;background: #0FBF19;height: 54px;cursor: pointer;">
            <div style="display: flex;align-content: center;margin: auto;" @click="jsonHtml()">
              <img style="width: 18px;height: 18px;" :src="require('@/assets/icon/save.svg')">
              <span class="span_seve">Save Board</span>
            </div>
          </div>
        </div>

      </div>
    </el-row>

    <div v-loading="loading" element-loading-text="Saving....">
      <div class="flex-row" style="overflow-y: hidden;" v-if="seveItme === 0">
        <div style="float: left;height: 100%;overflow: hidden;min-width: 360px;" :style="{width:cptBarWidth+'px'}"
        >
          <!--       -->
          <Componentleft ref="configBar" :currentCpt.sync="cacheComponents" :designData.sync="designData"
                         @refreshCptData="refreshCptData" @setWidthX="refesWsData" :height="windowHeight"/>
        </div>
        <div style="float: left;position: relative;overflow: hidden;height: 100%;background: #F2F2F2"
             :style="{width:(windowWidth-cptBarWidth-configBarWidth)+'px'}" @click.self="outBlur">

          <div style="height: 10px;margin-left: 20px" :style="{width:1920*containerScale+'px'}">
            <!--<ScaleMarkX/>顶部刻度线-->
          </div>
          <div style="position: absolute;width: 10px;"
               :style="{height:1920*containerScale / designData.scaleX * designData.scaleY+'px'}">
            <!--<ScaleMarkY/>左侧刻度线-->
          </div>

          <div class="webContainer" :style="{width:designData.scaleX+'px',height: designData.scaleY+'px', backgroundColor: designData.bgColor,
             backgroundImage: designData.bgImg ? 'url('+fileUrl+designData.bgImg+')':'none',transform: 'scale('+containerScale+')' }"
               id="myElement" @dragover="allowDrop" ref="webContainer" @click.self="outBlur">
            <div v-for="(item,index) in cacheComponents" :key="item.id"
                 class="cptDiv" :style="{width:Math.round(item.cptWidth)+'px', height:Math.round(item.cptHeight)+'px',
                  top:Math.round(item.cptY)+'px',left:Math.round(item.cptX)+'px',
                  zIndex: currentCptIndex === index ? 1800 : item.cptZ}" :ref="'div'+item.cptKey+index"
                 @mousedown="showConfigBar($event,item,index)" tabindex="0">


              <!-- 2021-12-28新增iframe组件，防止焦点聚焦在iframe内部，添加此蒙版 -->

              <div class="activeMask"
                   :style="cacheChoices[item.id] ? {border:'1px solid #9931F8'}:{border:'1px dotted #9931F8'}">
                <div class="flex-row top_div_tile" v-if="cacheChoices[item.id] ">
                  <div class="flex-row" style="margin: auto">
                    <img style="width: 16px;height: 16px;margin-right: 6px;" :src="require('@/assets/icon/empty.svg')">
                    <span class="span_tile_name">{{ tilename }}</span>
                  </div>

                </div>
              </div>
              <div style="width: 100%;height: 100%;">
                <component :is="item.cptKey" :ref="item.cptKey+index" :width="Math.round(item.cptWidth)"
                           :height="Math.round(item.cptHeight)" :option="item"/>
              </div>
              <!--           <div class="delTag">
                            <i class="el-icon-copy-document" @click.stop="copyCpt(item)"/>
                            <i style="margin-left: 4px" class="el-icon-delete" @click.stop="delCpt(item,index)"/>
                          </div>-->
              <!--            <div v-show="currentCptIndex === index"
                               style="top: 0;left: 0;cursor: se-resize;transform: translate(-50%, -50%)"
                               class="resizeTag" v-resize="'lt'"/>
                          <div v-show="currentCptIndex === index"
                               style="top: 0;left: 50%;cursor: s-resize;transform: translate(-50%, -50%)"
                               class="resizeTag" v-resize="'t'"/>
                          <div v-show="currentCptIndex === index"
                               style="top: 0;right: 0;cursor: ne-resize;transform: translate(50%, -50%)"
                               class="resizeTag" v-resize="'rt'"/>
                          <div v-show="currentCptIndex === index"
                               style="top: 50%;right: 0;cursor: w-resize;transform: translate(50%, -50%)"
                               class="resizeTag" v-resize="'r'"/>
                          <div v-show="currentCptIndex === index"
                               style="bottom: 0;right: 0;cursor: se-resize;transform: translate(50%, 50%)"
                               class="resizeTag" v-resize="'rb'"/>
                          <div v-show="currentCptIndex === index"
                               style="bottom: 0;left: 50%;cursor: s-resize;transform: translate(-50%, 50%)"
                               class="resizeTag" v-resize="'b'"/>
                          <div v-show="currentCptIndex === index"
                               style="bottom: 0;left: 0;cursor: ne-resize;transform: translate(-50%, 50%)"
                               class="resizeTag" v-resize="'lb'"/>
                          <div v-show="currentCptIndex === index"
                               style="top: 50%;left: 0;cursor: w-resize;transform: translate(-50%, -50%)"
                               class="resizeTag" v-resize="'l'"/>-->
            </div>
          </div>

        </div>
        <div style="width: 20px;margin-top: 200px;position: absolute;right: 20px;">
          <el-slider v-model="containerScale" :min="0.3" :max="1" :step="0.01" :vertical="true" height="200px"/>
        </div>
      </div>

      <div style="overflow-y: hidden; display: flex;position: relative;" v-if="seveItme === 1">
        <div class="flex-col" style="margin: auto;">
          <span class="span_tile_board" style="margin-top: 195px;">Board Saved</span>
          <span class="span_boadrd">{{ designData.title }}</span>
          <div style="width: 1px;height: 88px;background-color: #E0E1DD;margin: 64px auto ;"></div>
          <span class="span_tile_board">Next Step</span>
          <div class="flex-row">
            <div class="flex-row" style="cursor: pointer;" @click="isSelect = true">
              <div
                  style="width: 50px;height: 54px;border-radius: 50%;border: 2px solid #9931F8;background-color: #FFFFFF;display: flex;">
                <img style="margin: auto;" :src="require('@/assets/icon/iconpushdevice.svg')">
              </div>
              <span class="span_iles">Push To Device</span>
            </div>
            <div class="flex-row" style="margin-left: 120px;cursor: pointer;" @click="handleGenTable()">
              <div
                  style="width: 50px;height: 54px;border-radius: 50%;border: 2px   solid #9931F8;background-color: #FFFFFF;display: flex;">
                <img style="margin: auto;" :src="require('@/assets/icon/iconcompress.svg')">
              </div>
              <span class="span_iles">Export As .zip</span>
            </div>
          </div>

        </div>

      </div>

    </div>

    <FromLibrary v-if="isFile" :projec-data="Library" v-on:chickBack="chickBack"/>
    <select-device v-if="isSelect" :projec-data="downloadID" v-on:chickBack="chickBack"/>

  </div>
</template>
<script>
import portrait from '@/components/portrait'
import options from "@/components/landscape"
import Componentleft from "@/views/template/Component-left.vue";
import ComponentBar from "@/views/designer/componentBar.vue";
import ConfigBar from "@/views/designer/configBar.vue";
import ScaleMarkY from "@/views/designer/scaleMark/ScaleMarkY.vue";
import ScaleMarkX from "@/views/designer/scaleMark/ScaleMarkX.vue";
import env, {fileUrl} from "../../../env";
import FromLibrary from "@/popover/FromLibrary.vue";
import SelectDevice from "@/popover/SelectDevice.vue";
import {addmuseaBoard, EditMuseaBoard} from "@/api/login";
import {setDateTem} from "@/utils";
import html2canvas from "html2canvas";
import axios from "axios";
import {getToken} from "@/utils/auth";
import {getViewDataHeight, getViewDataWidth, splitIntoEqualShares} from "@/utils/ruoyi";
export default {
  name: 'template',
  components: {SelectDevice, FromLibrary, ScaleMarkX, ScaleMarkY, ConfigBar, ComponentBar, Componentleft},
  data() {
    return {
      currentCptIndex: -1,
      windowWidth: 0,
      windowHeight: 0,
      configBarWidth: 0,
      fileUrl: env.fileUrl,
      portrait,
      options,
      cacheComponents: [],
      cptBarWidth: 360,
      designData: {
        id: '', title: 'New Board', scaleX: 1280, scaleY: 800, version: '',
        bgColor: '#FFF', simpleDesc: '', bgImg: '', viewCode: 0, components: []
      },
      currentCpt: {cptOption: undefined},
      containerScale: 0.75,
      cacheChoices: {},
      cacheChoicesFixed: {},//记录移动前选中组件的位置 自定义事件内部无法处理，放在了外面。
      tilename: 'Section 1 (Empty)',
      seveItme: 0,
      designCache: {},
      windowWidths: 0,
      windowHeights: 0,
      conHeight: 0,
      downloadID: {},
      EditorID: 0,
      loading: false,
      isFile: false,
      Imagelist: [],
      VidoList: [],
      Library: {},
      isSelect: false

    }
  },
  created() {
    this.initContainerSize();
    $bus.on('freeboard', data => {
      console.log(data)
      switch (data.id) {
        case 1:
          this.Library = data
          this.isFile = true
          break
        case 2:
          this.isFile = false
          break
        case 3:
          this.setDataListWidth(data.data)
          break
        case 4:
          this.setDataListHeight(data.data)
          break
      }
    })
  },
  mounted() {
    /*    window.addEventListener("keydown", (event) => {
          if (that.currentCptIndex !== -1) {
            let key = event.key
            switch (key) {//方向键移动当前组件
              case 'ArrowDown':
                that.currentCpt.cptY += 1;
                break;
              case 'ArrowUp':
                that.currentCpt.cptY -= 1;
                break;
              case 'ArrowLeft':
                that.currentCpt.cptX -= 1
                break;
              case 'ArrowRight':
                that.currentCpt.cptX += 1
                break;
            }
          }
        })
        window.onresize = () => {
          return (() => {
            that.initContainerSize()
          })();
        };*/
    let datalists = this.$route.query.data
    const datalist = JSON.parse(datalists)
    this.designData.title = datalist.name
    if (datalist.overall !== undefined) {
      if (datalist.overall === 'vertical') {
        this.designData.scaleX = 800
        this.designData.scaleY = 1280
        this.containerScale = 0.59
        datalist.html.forEach(it => {
          this.drop(it)
        })

      } else if (datalist.overall === 'horizontal') {
        this.designData.scaleX = 1280
        this.designData.scaleY = 800
        this.containerScale = 0.95
        datalist.html.forEach(it => {
          this.drop(it)
        })
      }
    } else {
      const design = datalist
      this.EditorID = 1
      this.designData.id = design.boardId
      const screenSize = design.screenSize.split('X')
      this.designData.scaleX = screenSize[0]
      this.designData.scaleY = screenSize[1]
      this.designData.title = design.boardName
      this.designData.bgColor = design.boardBackground
      JSON.parse(design.sourceInfo).sources.forEach(it => {
        this.cacheComponents.push(it);
        this.showConfigBar({}, it, this.cacheComponents.length - 1)//丢下组件后刷新组件属性栏
      })
      this.$refs['configBar'].showCptConfig(0);
      this.containerScale = 0.95
    }
    window.addEventListener("keydown", this.handleKeyDown)
    window.addEventListener("keyup", this.handlekeyUp)
    this.moveNodeDown()
    //  this.drop({name: 'Text', icon: 'text', cptKey: 'empty-sig', width: 150, height: 40, option: cpt_text_option} )
  },
  methods: {
    setDataListWidth(e) {
      this.cacheComponents.forEach((itme, index) => {
        if (itme.prolin.id !== -1) {
          itme.cptWidth = e * itme.prolin.w
          itme.cptX = e * itme.prolin.x
        } else {
          getViewDataWidth(splitIntoEqualShares(e, 3), {
            width: e,
            height: itme.cptHeight,
            cpt_text_option: itme.cptOption
          }).forEach((its, indexs) => {
            if (index === indexs) {
              itme.cptWidth = its.width
              itme.cptX = its.offsetX
            }
          })

        }
      })
      $bus.emit('temvido', 1)
    },
    setDataListHeight(e) {
      this.cacheComponents.forEach((itme, index) => {
        if (itme.prolin.id !== -2) {
          itme.cptHeight = e * itme.prolin.h
          itme.cptY = e * itme.prolin.y
        } else {
          getViewDataHeight(splitIntoEqualShares(e, 3), {
            width: itme.cptWidth,
            height: e,
            cpt_text_option: itme.cptOption
          }).forEach((its, indexs) => {
            if (index === indexs) {
              itme.cptHeight = its.height

              itme.cptY = its.offsetY
            }

          })

        }

      })
      $bus.emit('temvido', 1)
    },
    initContainerSize() {
      this.windowWidth = document.documentElement.clientWidth
      this.windowHeight = document.documentElement.clientHeight
      let tempWidth = this.windowWidth - this.cptBarWidth - this.configBarWidth;
      this.containerScale = 0.59
    },
    goBack() {
      if (this.seveItme === 1) {
        this.$showInfoGo({
          callback: (obj) => {
            if (obj === 'yes') {
              this.$router.push({name: 'home'})

            } else {
              this.$router.go(-1); // 返回上一个页面
            }
          }
        })

      } else {
        this.$router.go(-1); // 返回上一个页面
      }

    },
    refreshCptData() {
      const refName = this.currentCpt.cptKey + this.currentCptIndex;
      if (!this.$refs[refName][0].refreshCptData) {
        this.$message.warning('当前图层还未实现refreshCptData方法')
      } else {
        this.$refs[refName][0].refreshCptData();//刷新子组件数据，refs为组建名加index
      }
    },
    refesWsData(e) {

    },
    outBlur() {//取消聚焦组件
      this.currentCptIndex = -1;
      this.currentCpt = {};
      this.cacheChoices = {}
    },
    showConfigBar(e, item, index) {//刷新属性栏数据，页面上拖动的组件执行click事件来更新组件的属性栏
      this.currentCpt = item;
      this.currentCptIndex = index;
      if (this.$refs['div' + item.cptKey + index]) {
     //   this.$refs['div' + item.cptKey + index][0].focus();//聚焦 用于多选移动
      }
      if (!e.ctrlKey) {//未按住ctrl键
        this.cacheChoices = {}
      }
      this.$refs['configBar'].showCptConfig(item);
      this.cacheChoices[item.id] = item
      //  this.cacheChoicesFixed[item.id] = JSON.parse(JSON.stringify(item))
    },
    allowDrop(e) {
      e.preventDefault()
    },
    drop(config) {//从组件栏丢下组件
      let cpt = {
        cptTitle: config.name,
        icon: config.icon,
        cptKey: config.cptKey,
        Contvalue: '',
        cptOptionKey: 'contenttype',//config.cptOptionKey ? config.cptOptionKey : config.cptKey + '-option',
        cptOption: config.option,
        cptX: Math.round(config.offsetX),
        cptY: Math.round(config.offsetY),
        cptZ: 100,
        cptWidth: config.width,
        cptHeight: config.height,
        id: require('uuid').v1(),
        prolin: config.prolin
      }
      this.cacheComponents.push(cpt);
      this.cacheChoices = {}//多选清空
      // this.showConfigBar({}, cpt, this.cacheComponents.length - 1)//丢下组件后刷新组件属性栏

    },
    copyCpt(item) {
      let copyItem = JSON.parse(JSON.stringify(item))
      copyItem.cptX = item.cptX + 30//复制的组件向右下偏移
      copyItem.cptY = item.cptY + 30
      copyItem.id = require('uuid').v1();
      this.cacheComponents.push(copyItem);
      this.currentCptIndex = this.cacheComponents.length - 1//聚焦到复制的组件
    },
    delCpt(cpt, index) {
      this.$confirm('delete ' + cpt.cptTitle + ' assembly?', 'prompt', {
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel',
        type: 'warning'
      }).then(() => {
        //记录一个bug，v-for key值重复导致页面渲染数据错乱。在丢下组件时实用uuid作为key解决。
        this.currentCpt = {};
        this.cacheComponents.splice(index, 1);
        // const childId = this.$refs[cpt.cptKey+index][0].uuid
        //  clearCptInterval(childId);
      }).catch(() => {
      });
    },
    preview() {//预览按钮
      this.designData.components = this.cacheComponents;
      localStorage.setItem('designCache', JSON.stringify(this.designData));
      console.info(this.designData)
      let routeUrl = this.$router.resolve({
        path: "/temindex"
      });
      window.open(routeUrl.href, '_blank');
    },
    chickBack() {
      this.isFile = false
      this.isSelect = false
    },
    setView(data) {
      console.log(this.cacheComponents)
      let screenSize = this.designData.scaleX.toString() + 'X' + this.designData.scaleY.toString()
      if (this.EditorID === 0) {
        let das = {
          htmlPath: data.htmlUrl,
          boardName: this.designData.title,
          aspectRadio: this.designData.viewCode.toString(),
          screenSize: screenSize,
          boardType: "0",
          boardBackground: this.designData.bgColor,
          boardThumbnail: data.thumbnailUrl,
          sourceInfo: this.cacheComponents

        }
        addmuseaBoard(das).then(postMessage => {
          if (postMessage.code === 200) {
            console.log(postMessage)
            this.downloadID =  {code: 0, id: postMessage.id}
            this.seveItme = 1
            this.loading = false
          }
        }).catch(reason => {
        })
      } else {
        this.downloadID = {code: 0,id: this.designData.id}
        let das = {
          boardId: this.designData.id,
          htmlPath: data.htmlUrl,
          boardName: this.designData.title,
          aspectRadio: this.designData.viewCode.toString(),
          screenSize: screenSize,
          boardType: "0",
          boardBackground: this.designData.bgColor,
          boardThumbnail: data.thumbnailUrl,
          sourceInfo: this.cacheComponents
        }
        EditMuseaBoard(das).then(postMessage => {
          if (postMessage.code === 200) {
            console.log(postMessage)
            this.seveItme = 1
            this.loading = false
          }
        }).catch(reason => {
        })
      }

    },
    handleGenTable() {
      let licenes = JSON.parse(sessionStorage.getItem('Licenselist'))
      let omse = licenes.filter(it => it.dataleft > 0 && it.licenseType === '4')
      if (omse.length < 1) {
        this.$showMsgBox({
          caption: 'License expired',
          icon: 'failed',
          msg: ['Your license for MUSEA has been expired.', 'Please consider to renew your license to','enjoy MUSEA features.'],
          button: 'Go Back',
          callback: (data) => {

          }
        })
      }else {

        this.$download.zip("/prod-api/system/museaBoard/exportBoard/" + this.downloadID.id, "MuSeaBoard.zip");
      }


    },
    async jsonHtml() {
      this.loading = true
      this.outBlur()
      var template = "";
      const that = this
      this.cacheComponents.forEach(it => {
        console.log(setDateTem(it.cptKey, it))
        template += `${setDateTem(it.cptKey, it)}`;
      })
      const bgtile1 = '<div style="width:' + that.designData.scaleX + 'px;height:' + that.designData.scaleY + 'px;background-color: ' + that.designData.bgColor + ';max-width:' + that.designData.scaleX + 'px;max-height: ' + that.designData.scaleY + 'px;overflow: hidden;">'
      const tile2 = '<div style="position: relative;width: ' + that.designData.scaleX + 'px;height: ' + that.designData.scaleY + 'px;">'
      const tile3 = '</div></div>'
      const jsv0 = '<script src="'
      const jsv1 = './vue.min.js">'
      const jsv2 = "</scr"
      const jsv3 = "ipt>"
      let html = `<!DOCTYPE html>
                <html>
                <head>
                <meta charset="utf-8">
                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                <meta name="viewport" content="width=device-width,initial-scale=1.0,user-scalable=no">
                 ${jsv0 + jsv1 + jsv2 + jsv3}
                <meta name="referrer" content="never"><style> *{ margin: 0;  padding: 0;} body{}</style>
                <link rel="stylesheet" href="./stylesheet.css">
                <title></title>
                </head>
                <body>${bgtile1 + tile2 + template + tile3}</body>
                </html>`
      const options = {
        logging: true,
        useCORS: true,
        allowTaint: false,
      }
      html2canvas(document.getElementById('myElement'), options).then(async canvas => {
        var base64 = canvas.toDataURL("image/jpeg")
        let fn = new FormData()
        fn.append('htmlData', html)
        fn.append('thumbnailData', base64.split('data:image/jpeg;base64,')[1])
        await axios({
          method: 'POST',
          url: `${fileUrl}/prod-api/common/genHtmlAndThumbnail`,
          data: fn,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
            "Authorization": getToken()
          },
        }).then(postMessage => {
          this.setView(postMessage.data)
        })
      })
    },

    handleKeyDown(event) {
      const {key, ctrlKey} = event
      if (key === 'z' && ctrlKey) {
        this.handleUndo()
      }
      if (ctrlKey) {
        document.body.style.cursor = 'move';
      }
      if (this.currentCptIndex !== -1) {
        let key = event.key
        switch (key) {//方向键移动当前组件
          case 'ArrowDown':
            this.currentCpt.cptY += 1;
            break;
          case 'ArrowUp':
            this.currentCpt.cptY -= 1;
            break;
          case 'ArrowLeft':
            this.currentCpt.cptX -= 1
            break;
          case 'ArrowRight':
            this.currentCpt.cptX += 1
            break;

        }
      }
    },
    handlekeyUp(event) {
      const {key, ctrlKey} = event
      document.body.style.cursor = 'default';
    },
    moveNodeDown() {
      const child = document.getElementById('myElement')
      let isDragging = false
      let prevX = 0
      let prevY = 0
      child.addEventListener('mousedown', function (e) {
        isDragging = true
        prevX = e.clientX
        prevY = e.clientY
      })
      child.addEventListener('mouseup', function () {
        isDragging = false
      })
      child.addEventListener('mousemove', function (e) {
        const {key, ctrlKey} = e
        if (isDragging && ctrlKey) {
          const diffX = e.clientX - prevX
          const left = parseInt(window.getComputedStyle(child).left) || 0
          child.style.left = `${left + diffX}px`
          prevX = e.clientX
          const diffY = e.clientY - prevY
          const top = parseInt(window.getComputedStyle(child).top) || 0
          child.style.top = `${top + diffY}px`
          prevY = e.clientY
        }
      })
    },

  }
}

</script>
<style scoped>
.top {
  height: 54px;
  color: #fff;
  overflow: hidden;
  margin: 0;
  font-size: 18px;
  line-height: 45px;
  background: rgba(255, 255, 255, 1);
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.span_seve {
  margin-left: 12px;
  font-size: 14px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 14px;
}

.span_preview {
  margin-left: 12px;
  font-size: 14px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #404756;
  line-height: 14px;
}

.span_tile {
  margin: auto;
  font-size: 16px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #404756;
  line-height: 16px;
}


.webContainer {
  position: relative;
  margin: 50px;
  background-size: 100% 100%;
  transform-origin: 0 0
}

.delTag {
  width: 45px;
  height: 22px;
  background: rgba(43, 51, 64, 0.8);
  border-radius: 2px;
  color: #ccc;
  z-index: 2000;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  display: none;
  cursor: pointer
}

.activeMask {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1801
}


.cptDiv {

  position: absolute;
  outline: none
}

.cptDiv:hover .delTag {
  display: block
}

.resizeTag {
  width: 8px;
  height: 8px;
  position: absolute;
  background-color: #B6BFCE;
  z-index: 2000;
  border-radius: 50%;
}

.configBtn:hover {
  cursor: pointer;
  color: #B6BFCE
}

.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}

.top_div_tile {
  padding: 7px 8px;
  display: flex;
  position: absolute;
  top: -30px;
  background: #9931F8;
  width: fit-content;
  height: fit-content;
  margin-left: 12px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.span_tile_name {
  white-space: nowrap;
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 14px;
}

.span_tile_board {
  margin: 40px auto;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #8F8F8F;
  line-height: 14px;
}


.span_boadrd {
  margin: auto;
  font-size: 48px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #1E1E1E;
  line-height: 48px;
}

.span_iles {
  margin: auto 20px;
  font-size: 16px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #9931F8;
  line-height: 16px;
}

</style>