<template>
  <div v-if="show" class="mask">
    <div class="dlg-msg-box flex flex-col">
      <div class="flex-col div_forgot" style="width: 328px;height : 191px;margin: auto;">
        <span class="title_span">Log out</span>
        <span class="span_tile_log">Confirm to log out?</span>
        <i style="margin-top: 40px;"></i>
        <div class="flex-row" style="margin: auto;">
          <button class="emailAddPassword" @click="confirmClick()">Log out</button>
          <button class="button_cancel" style="margin-left: 16px" @click="cancelClick()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'logout',
  props: {
    caption: {},
    show: {},
    msg: {},
    callback: {}
  },
  methods: {
    init() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = true;
    },
    close() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("close")
    },
    confirmClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("yes")
      //this.$emit('confirm');
    },
    cancelClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("no")
    }
  }
}
</script>


<style scoped>
@import url(dialogcss.css);
</style>