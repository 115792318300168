<template>
  <div class="login-container">
    <el-form v-if="itme ===0" ref="loginForm" :model="loginForm" size="small" :rules="loginRules"
             class="login-form" auto-complete="on" label-position="left">
      <div class="flex-col">
        <img style="width:96px;height: 96px;margin: auto;" :src="require('@/assets/logo.svg')">
        <span class="title0" style="margin-top: 14px">MUSEA</span>
        <span class="title1" style="margin-top: 6px">By GloryStar</span>
      </div>
      <el-form-item prop="username" style="margin-top: 64px">
        <div class="formDiv" style="height: 46px;">
          <input class="ipt" v-model="loginForm.username" placeholder="Email address"/>
        </div>
      </el-form-item>
      <el-form-item prop="password">
        <div class="formDiv" style="height: 46px;">
          <input class="ipt" v-model="loginForm.password" autocomplete="new-password"
                 :type="passwordType" placeholder="Password" @keyup.enter="handleLogin"/>
          <span class="show-pwd" @click="showPwd">
          <i :class="passwordType === 'password' ? 'el-icon-view' : 'el-icon-more'"/>
          </span>
        </div>
      </el-form-item>
      <el-row :gutter="12">
        <el-col :span="12">
          <el-form-item prop="username">
            <div class="formDiv" style="height: 56px;">
              <input class="ipt" v-model="loginForm.code" placeholder="CAPTCHA" @keyup.enter="handleLogin"/>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <img :src="baseUrl" @click="getCode()" style="width: 100%;height:56px;"/>
          <!-- time刷新作用-->
        </el-col>
      </el-row>
      <el-row :gutter="12" style="margin-top: 51px;">
        <el-col :span="12">
          <div>
            <el-checkbox v-model="checked">Remember Me</el-checkbox>
          </div>
        </el-col>
        <el-col :span="12">
          <div style="display: flex;justify-items: right">
            <span class="span_tile" style="text-align: right" @click="seveRegister">Forgot Password</span>
          </div>
        </el-col>
      </el-row>
      <el-button :loading="loading" type="primary"
                 class="button_logo"
                 @click.native.prevent="handleLogin">Sign In
      </el-button>


      <div style="height: 46px;display: flex;justify-items: center">
        <!--     BtnShow     -->
        <span class="span_tile1" style="text-align: center" @click="BtnShow">I don’t have an account</span>
      </div>

    </el-form>
    <el-form v-if="itme ===1" ref="register" :model="registerForm" size="small" :rules="registerRules"
             class="login-form" auto-complete="on" label-position="left">
      <div class="flex-col">
        <img style="width:96px;height: 96px;margin: auto;" :src="require('@/assets/logo.svg')">
        <span class="title0" style="margin-top: 14px">MUSEA</span>
        <span class="title1" style="margin-top: 6px">By GloryStar</span>
      </div>
      <el-form-item prop="username" style="margin-top: 36px">
        <div class="formDiv" style="height: 46px;">
          <input class="ipt" v-model="signUpForm.companyName" placeholder="Company Name"/>
        </div>
      </el-form-item>
      <el-form-item prop="licenseLanguage">
        <el-popover
            placement="bottom"
            title=""
            v-model="licenseLanguage"
        >
          <div class="popover_view flex-col">
            <div v-for="(item, index) in tileData" :key="index">
              <span class="popover_view_span_langua" @click="clickLanguag(item)">{{ item.label }}</span>
            </div>
          </div>
          <div class="formDiv" style="height: 46px;" slot="reference">
            <input class="ipt" v-model="signUpForm.licenseLanguage" placeholder="Title" readonly="readonly"/>
          </div>
        </el-popover>
      </el-form-item>
      <el-form-item prop="password">
        <div class="formDiv" style="height: 46px;">
          <input class="ipt" v-model="signUpForm.contactName" placeholder="Name Of Contact Person"/>
        </div>
      </el-form-item>
      <el-form-item prop="password">
        <div class="formDiv" style="height: 46px;">
          <input class="ipt" v-model="signUpForm.email" placeholder="Contact email"/>
        </div>
      </el-form-item>
      <el-row :gutter="12">
        <el-col :span="12">
          <el-form-item prop="username">
            <div class="formDiv" style="height: 46px;">
              <input class="ipt" v-model="signUpForm.phonenumber1" placeholder="Area Code"/>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="username">
            <div class="formDiv" style="height: 46px;">
              <input class="ipt" v-model="signUpForm.phonenumber2" placeholder="Contact Phone"/>
            </div>
          </el-form-item>
          <!-- time刷新作用-->
        </el-col>
      </el-row>
      <el-row :gutter="12">
        <el-col :span="12">
          <el-form-item prop="username">
            <div class="formDiv" style="height: 46px;">
              <input class="ipt" v-model="signUpForm.code" placeholder="CAPTCHA"/>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <img :src="baseUrl" @click="getCode()" style="width: 100%;height:46px;"/>
          <!-- time刷新作用-->
        </el-col>
      </el-row>
      <el-button  :loading="loading" type="primary"
                  class="button_logo"
                 @click.native.prevent="signUpBtn">Sign up
      </el-button>

      <div style="height: 46px;display: flex;justify-items: center;">
        <span class="span_tile" style="text-align: center" @click="goLogin">I already have an account</span>
      </div>
    </el-form>

    <span class="text_box_masn" style="margin-top: 64px">© 2024 Glory Star. All rights reserved </span>
  </div>
</template>
<script>
import {captchaImage, checkEmailAdd, checkSendEmail, getInfo, getkeyid, register, setLogin, signUp} from "@/api/login";
import {setToken} from "@/utils/auth";
export default {
  data() {
    const validateUsername = (rule, value, callback) => {
      callback()
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 0) {
        callback(new Error('Password illegal'))
      } else {
        callback()
      }
    }
    return {
      loginForm: {username: '', password: '', code: '', uuid: ''},
      loginRules: {
        username: [{required: true, trigger: 'blur', validator: validateUsername}],
        password: [{required: true, trigger: 'blur', validator: validatePassword}]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined,
      baseUrl: '',
      time: '',
      checked: false,
      uuid: '',
      itme: 0,
      registerForm: {
        CompanyName: '',
        password: '',
        Title: '',
        ContactPerson: '',
        Contactemail: '',
        AreaCode: '',
        ContactPhone: '',
        code: '',
        uuid: ''
      },
      registerRules: {
        username: [{required: true, trigger: 'blur', validator: validateUsername}],
        password: [{required: true, trigger: 'blur', validator: validatePassword}]
      },
      resetEmail: '',
      tileData: [
        {value: 'Mr', label: 'Mr'},
        {value: 'Ms', label: 'Ms'}

      ],
      licenseLanguage: '',
      signUpForm: {
        nickName: '',
        companyName: '',
        licenseLanguage: '',
        email: '',
        phonenumber: '',
        phonenumber1: '',
        phonenumber2: '',
        code: '',
        uuid: '',
        contactName: '',
        licenses: []
      }
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  created() {
    this.getCode()
    this.getCookie()
  },
  methods: {
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        // this.$refs.password.focus()
      })
    },
    handleLogin() {
      if (this.checked) {
        localStorage.setItem('username', this.loginForm.username)
        localStorage.setItem('password', this.loginForm.password)
        localStorage.setItem('rememberMe', this.loginForm.rememberMe)
      } else {
        localStorage.removeItem('username')
        localStorage.removeItem('password')
        localStorage.removeItem('rememberMe')
      }
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          let loginParam = JSON.parse(JSON.stringify(this.loginForm))
          setLogin(loginParam).then(postMessage => {
            this.loading = false;
            if (postMessage.code === 200) {
              setToken(postMessage.token)
              this.checkBtnHide()
            } else {
              this.getCode()
              if (postMessage.msg === '验证码错误') {
                this.$showMsgBox({
                  caption: 'Login Failed',
                  icon: 'failed',
                  msg: ['Captcha validation failed.', 'Please try again.'],
                  button: 'Try Again',
                  callback: (data) => {
                    this.getCode()
                  }
                })
              } else if( postMessage.msg === 'License expired'){
                this.$showMsgBox({
                  caption: 'Login Failed',
                  icon: 'failed',
                  msg: ['Login failed due to license expired.', 'Please contact our customer support  ' , 'to renew your license.'],
                  button: 'Try Again',
                  callback: (data) => {
                    this.getCode()
                  }
                })

              } else {
                this.$showMsgBox({
                  caption: 'Login Failed',
                  icon: 'failed',
                  msg: ['Login failed due to wrong user name or', 'password. Please check and try again'],
                  button: 'Try Again',
                  callback: (data) => {
                    this.getCode()
                  }
                })
              }
            }
          })

        } else {
          return false
        }
      })
    },
    handleRegister() {
      this.$refs.register.validate(valid => {
        if (valid) {
          this.loading = true;
          let loginParam = JSON.parse(JSON.stringify(this.registerForm))
          register(loginParam).then(postMessage => {
            this.loading = false;
            if (postMessage.code === 200) {
              this.itme = 0
              this.$message.success('Registration successful')
            } else {
              this.$message.error(postMessage.msg)
              this.getCode()
            }
          })

        } else {
          return false
        }
      })

    },
    BtnShow() {
      this.itme = 1
      this.loading = false
      this.getCode()
    },
    goLogin() {
      this.itme = 0
      this.loading = false
      this.getCode()
    },
    getCookie() {
      const username = localStorage.getItem('username')
      const password = localStorage.getItem('password')
      const rememberMe = localStorage.getItem('rememberMe')
      this.loginForm.username = undefined ? this.loginForm.username : username
      this.loginForm.password = undefined ? this.loginForm.password : password
      this.checked = rememberMe === undefined ? false : Boolean(rememberMe)
    },
    getCode() {
      captchaImage().then(item => {
        this.baseUrl = 'data:image/jpg;base64,' + item.img
        this.loginForm.uuid = item.uuid
        this.registerForm.uuid = item.uuid
        this.signUpForm.uuid = item.uuid

      })
    },
    checkBtnHide() {
      getInfo().then(itme => {
        if (itme.code === 200) {
          sessionStorage.removeItem('homeItme')
          sessionStorage.setItem('userInfo', JSON.stringify(itme.user))
          this.$router.replace('/home').catch(err => err)

        }
      })
    },
    seveRegister() {
      this.itme = -1
      this.showPassFollow({
        caption: '',
        msg: [
          'A link will be sent to your registered email address. Please',
          'follow the instructions in the email to reset your password.',
          'Your current password will be invalid.'
        ],
        button: {
          isinput: true,
          isconfirm: true,
          iscancel: true,
          confirm: 'Send reset link',
          cancel: 'Go Back Login',
        },
        callback: (data) => {
          console.log(data)
          if (data.code === 0) {
            this.goLogin()
          } else if (data.code === 1) {
            this.goLogin()
          }
        }
      })
    },
    clickLanguag(data) {
      this.licenseLanguage = false
      this.signUpForm.licenseLanguage = data.label

    },
    /**
     * 注册
     */
    async signUpBtn() {
      //  this.signUpForm.email = this.signUpForm.userName
      let entryinfo = false
      this.loading = true;
      this.signUpForm.nickName = 'SUPER USER'
      this.signUpForm.phonenumber = this.signUpForm.phonenumber1 + '-' + this.signUpForm.phonenumber2
      this.signUpForm.licenses.splice(0, this.signUpForm.licenses.length)
      for (var i = 0; i < 2; i++) {
        this.signUpForm.licenses.push({
          licenseKey: getkeyid()
        })
      }
      console.log(this.signUpForm)
      switch ('') {
        case this.signUpForm.email:
          entryinfo = true
          break
        case this.signUpForm.contactName:
          entryinfo = true
          break
        case this.signUpForm.userName:
          entryinfo = true
          break
        case this.signUpForm.phonenumber1:
          entryinfo = true
          break
        case this.signUpForm.phonenumber2:
          entryinfo = true
          break
        case this.signUpForm.code:
          entryinfo = true
          break
      }
      if (entryinfo) {
        this.loading = false;
        this.itme = -1
        this.$showRegisterSu(
            {
              msg: ['Please fill in the required fields'],
              button: {
                isconfirm: true,
                iscancel: false,
                confirm: 'confirm'
              },
              callback: (e) => {
                this.itme = 1
              }
            }
        )
        return false
      }
      if (this.IsEmail(this.signUpForm.email)) {
        let respons = await checkEmailAdd(this.signUpForm.email)
        console.log(respons)
        if (respons.code === 201) {
          this.itme = -1
          this.$showRegisterSu(
              {
                msg: ['The mailbox has been bound and registered!'],
                button: {
                  isconfirm: true,
                  iscancel: false,
                  confirm: 'confirm'
                },
                callback: (e) => {
                  this.loading = false;
                  this.itme = 1
                }
              }
          )
        } else {
          console.log(this.signUpForm)
          let data = {
            nickName: "SUPER USER",
            companyName: this.signUpForm.companyName,
            userName: this.signUpForm.email,
            phonenumber: this.signUpForm.phonenumber1 + '-' + this.signUpForm.phonenumber2,
            code: this.signUpForm.code,
            uuid: this.signUpForm.uuid,
            contactName: this.signUpForm.licenseLanguage + '-' + this.signUpForm.contactName,
            licenses: this.signUpForm.licenses,
            email: this.signUpForm.email
          }
          let respons = await signUp(data)
          if (respons.code === 200) {
            await this.checkEmailState(respons.data.redisStr)
          } else if (respons.code === -2) {
            this.itme = -1
            this.$showRegisterSu(
                {
                  msg: [respons.msg],
                  button: {
                    isconfirm: false,
                    iscancel: false,
                    confirm: 'confirm'
                  },
                  callback: (e) => {
                    this.itme = 1
                  }
                }
            )

          } else {
            this.itme = -1
            this.$showRegisterSu(
                {
                  msg: [respons.msg, 'Server error, please contact administrator to deal with'],
                  button: {
                    isconfirm: true,
                    iscancel: false,
                    confirm: 'confirm'
                  },
                  callback: (e) => {
                    this.itme = 1
                  }
                }
            )

          }
        }
      } else {
        this.loading = false;
        this.itme = -1
        this.$showRegisterSu(
            {
              msg: ['Email format error'],
              button: {
                isconfirm: true,
                iscancel: false,
                confirm: 'confirm'
              },
              callback: (e) => {
                this.itme = 1
              }
            }
        )
      }
    },

    /**
     * 查看邮件发送状态
     */
    async checkEmailState(str) {

      let time = setInterval(async () => {
        let respons = await checkSendEmail(str)
        if (respons.data.isSned === 1) {
          clearInterval(time)
          this.loading = false;
          this.itme = -1
          this.$showRegisterSu(
              {
                msg: ['The email has been sent successfully'],
                button: {
                  isconfirm: true,
                  iscancel: false,
                  confirm: 'confirm'
                },
                callback: (obj) => {
                  if (obj === 'yes') {
                    this.goLogin()
                  }
                }


              }
          )
        }
      }, 1000)
    },
  }

}

</script>


<style scoped>


.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.login-container {
  display: flex;
  background: url("@/assets/Loginbg.svg");
  min-height: 100%;
  width: 100%;
  overflow: hidden;
}

.login-form {
  border-radius: 24px;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.08);
  background: #FFFFFF;
  position: relative;
  width: 460px;
  max-width: 100%;
  padding: 36px 35px 0 35px;
  margin: auto;
  overflow: hidden;
}

.title0 {
  font-family: Inter-SemiBold, Inter;
  font-size: 26px;
  color: #9931F8;
  margin: auto;
  text-align: center;
  font-weight: bold;
}

.title1 {
  font-size: 12px;
  font-family: Inter, Inter;
  margin: auto;
  font-weight: 400;
  color: #969DAC;
  line-height: 12px;
}

.show-pwd {
  margin: auto;
  position: absolute;
  right: 30px;
  top: 10px;
  font-size: 16px;
  color: #889aa4;
  cursor: pointer;
  user-select: none;
}

.formDiv {
  margin: auto;
  text-align: center;
  background: #E0E1DD;
  border-radius: 36px 36px 36px 36px;
  opacity: 1;
  border: 1px solid #E0E1DD;

}

.formLabel {
  display: inline-block;
  width: 30px;
  font-size: 18px;
  color: #889aac;
  text-align: right
}

.ipt {
  text-align: center;
  display: inline-block;
  width: 80%;
  height: 100%;
  font-size: 15px;
  color: #404756;
  padding-left: 10px;
  border: none;
  background: #E0E1DD;
  outline: none
}

.ipt::-ms-reveal {
  display: none
}

.span_tile {
  cursor: pointer;
  width: 100%;
  height: 14px;
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #AFAFAF;
  line-height: 14px;
}


.span_tile1 {
  cursor: pointer;
  width: 100%;
  height: 14px;
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: rgba(153, 49, 248, 1);
  line-height: 14px;
}

.button_logo {
  width: 100%;
  margin-bottom: 16px;
  background: #9931F8;
  border-radius: 999px;
  margin-top: 23px;
  border: #9931F8;
  height: 42px;
  font-family: Inter-SemiBold, Inter;
  font-size: 14px;
}

.go_login {
  cursor: pointer;
  text-align: right;
  width: 100%;
  height: 42px;
  color: #aaaaaa;
  font-family: Inter-SemiBold, Inter;
  font-size: 14px;
}

.text_box_masn {
  position: fixed;
  bottom: 24px;
  width: 100%;
  text-align: center;
  color: #8F8F8F;
  font-size: 12px;
  font-family: Inter-Regular, Inter;
}

.popover_view {
  width: 200px;
  height: auto;
}

.popover_view_span_langua {
  cursor: pointer;
  margin-top: 15px;
  font-size: 14px;
  font-family: Inter-Regular, Inter, serif;
  color: #303030;
  font-weight: 400;
}

</style>