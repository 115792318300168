import GoToBoard from "@/erredpop/GoToBoard.vue";
let ConfirmConstructor, instance

const  showGoToBoard  ={
   install(Vue){
       ConfirmConstructor = Vue.extend(GoToBoard)
       instance = new ConfirmConstructor().$mount()
       document.body.appendChild(instance.$el)
       Vue.prototype.$showGoToBoard = options => {
           Object.assign(instance, options)
           instance.init()
       }
   }

}
export default showGoToBoard