import request from '@/utils/request'

export function captchaImage() {
    return request({
        url: '/prod-api/captchaImage',
        method: 'get',
    })
}

//登录账号
export function setLogin(data) {
    return request({
        url: '/prod-api/login',
        method: 'post',
        data: data
    })
}

//注册账号
export function register(data) {
    return request({
        url: '/prod-api/register',
        method: 'post',
        data: data
    })
}

//获取用户资料
export function getInfo() {
    return request({
        url: '/prod-api/getInfo',
        method: 'get',
    })
}

export function loginSso(data) {
    return request({
        url: '/prod-api/loginSso',
        method: 'post',
        from: data
    })
}

export function getRouters() {
    return request({
        url: '/prod-api/getRouters',
        method: 'get',
    })
}

//添加文件信息
export function museaFile(data) {
    return request({
        url: '/prod-api/system/museaFile',
        method: 'post',
        data: data
    })
}

//获取文件列表
export function museaFileList(data) {
    return request({
        url: '/prod-api/system/museaFile/list',
        method: 'get',
        params: data

    })
}

//删除文件
export function deletemuseaFile(data) {
    return request({
        url: '/prod-api/system/museaFile/' + data,
        method: 'delete',
    })
}

//添加模板
export function addmuseaBoard(data) {
    return request({
        url: '/prod-api/system/museaBoard',
        method: 'post',
        data: data
    })
}

//获取模板列表
export function museaBoardlist(data) {
    return request({
        url: '/prod-api/system/museaBoard/list',
        method: 'get',
        params: data
    })
}

//删除模板
export function deleteMusea(data) {
    return request({
        url: '/prod-api/system/museaBoard/' + data,
        method: 'delete',
    })
}

//下载模板
export function exportBoard() {
    return request({
        url: '/prod-api/tool/gen/batchGenCode?tables=musea_file',
        method: 'get',
    })
}

//生成html和图片
export function genHtml(data) {
    return request({
        url: '/prod-api/common/genHtmlAndThumbnail',
        method: 'post',
        from: data
    })
}

//编辑
export function EditMuseaBoard(data) {
    return request({
        url: '/prod-api/system/museaBoard',
        method: 'put',
        data: data
    })
}

// 获取starcontrol设备列表
export function museaDevicelist(data) {
    return request({
        url: '/prod-api/system/museaDevice/list',
        method: 'get',
        params: data
    })
}

//推送模板到设备
export function pushMuseaBoard(data) {
    return request({
        url: '/prod-api/system/museaDevice/pushMuseaFile',
        method: 'post',
        data: data
    })

}

//新增Stack  /prod-api/system/stack
export function addstack(data) {
    return request({
        url: '/prod-api/system/stack',
        method: 'post',
        data: data
    })
}

export function putstack(data) {
    return request({
        url: '/prod-api/system/stack',
        method: 'put',
        data: data
    })
}

//获取Stack列表
export function getStack(data) {
    return request({
        url: '/prod-api/system/stack/list',
        method: 'get',
        params: data
    })
}

//批量删除Stack
export function deleteStack(data) {
    return request({
        url: '/prod-api/system/stack/' + data,
        method: 'delete',

    })
}

//获取licnese列表
export function Licenselist(data) {
    return request({
        url: '/prod-api/system/license/queryAllLicense',
        method: 'get',
        params: data
    })

}


// 设置密码查询当前用户邮箱
export function querSetNewPassWordUser(parms) {
    return request({
        url: `/prod-api/userIdQueryEmail/${parms.id}/${parms.str}`,
        method: "GET"
    });
}

// 发送邮箱重置密码
export function resetPassWordBtn(email) {
    return request({
        url: "/prod-api/resetPasswordSendMail",
        method: "POST",
        data: email
    });
}

export function getkeyid() {
    return 'xxxx-xxxx-xxxx-xxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16).toUpperCase()
    })
}

// 校验邮箱是否重复
export function checkEmailAdd(email) {
    return request({
        url: "/prod-api/checkEmail?email=" + email,
        method: "GET"
    });
}


// 注册
export function signUp(data) {
    return request({
        url: "/prod-api/registerUser",
        method: "POST",
        data: data
    });
}


// 设置密码
export function setNewPassword(data) {
    return request({
        url: "/prod-api/setUserPassword",
        method: "POST",
        data: data
    });
}

// 查看邮件发送状态
export function checkSendEmail(str) {
    return request({
        url: "/prod-api/checkRedisStr?redisStr=" + str,
        method: "GET"
    });
}


// 重置密码
export function resetPassWord(data) {
    return request({
        url: "/prod-api/resetPassword",
        method: "POST",
        data: data
    });
}


// 编辑账号
export function submitEdit(data) {
    return request({
        url: '/prod-api/system/user/update',
        method: 'post',
        data: data
    })
}


//向Zoho提交关闭公司账号请求
export function accountClosureRequest(data) {
    return request({
        url: '/prod-api/zoho/api/accountClosureRequest',
        method: 'POST',
        params: data
    })
}

//获取分组
export function getGroups() {
    return request({
        url: '/prod-api/system/museaDevice/getGroups',
        method: 'get'
    })
}