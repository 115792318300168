<template>
  <div v-if="show" class="mask">
    <div class="dlg-msg-box flex flex-col">
      <div class="flex-col div_forgot" style="width: 328px;height: auto;margin: auto; padding-bottom: 24px">
        <span class="title_span">{{ caption }}</span>
        <img style="width: 164px;height: 142px;margin-top: 32px;margin-left: 82px;margin-bottom: 32px;"
             :src="require('@/assets/icon/'+icon+'.svg')"/>

        <div v-for="(item,index) in msg" :key="index" style="width: 328px;display: flex;">
          <span style="margin: auto;font-family: Inter-Regular; color: #1E1F21;font-size: 14px;">
            {{ item }}</span>
        </div>

        <button class="butt_del" style="width: 120px; height: 40px;margin-left: 103px;margin-top: 32px;"
                @click="confirmClick()">
          {{ button }}
        </button>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'MsgBox',
  props: {
    caption: {},
    show: {},
    button: {},
    msg: {},
    icon: {},
    callback: {}
  },
  methods: {
    init() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = true;
    },
    close() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("close")
    },
    confirmClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("yes")
      //this.$emit('confirm');
    },
    cancelClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("no")
    }
  }
}
</script>


<style scoped>
@import url(dialogcss.css);
</style>