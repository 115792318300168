import DeleteTouchArea from "@/erredpop/DeleteTouchArea.vue";


let ConfirmConstructor, instance
const showDeleteTouchArea = {
    install(Vue){
        ConfirmConstructor = Vue.extend(DeleteTouchArea)
        instance = new ConfirmConstructor().$mount()
        document.body.appendChild(instance.$el)
        Vue.prototype.$showDeleteTouchArea= options => {
            Object.assign(instance, options)
            instance.init()
        }
    }
}
export default showDeleteTouchArea