<template>
  <div v-bind:class="{pata_div:option.attribute.typeView !==1,pata_div_false:option.attribute.typeView===1}"
       @click="Oncliksview()">
  </div>
</template>

<script>
export default {
  name: 'touch-sig',
  props: {
    option: Object
  },
  created() {
    console.log('---------touch-sig-------->')
    console.log(this.option)
    console.log('---------touch-sig-------->')
  },
  methods: {
    Oncliksview(e) {
      $bus.emit('touchsig', this.option)
    }
  }

}

</script>

<style scoped>
.pata_div {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(229, 196, 255, 0.7);
  border-radius: 8px 8px 8px 8px;
  border: 2px solid #9931F8;
}

.pata_div_false {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 8px 8px;
}


</style>