<template>
  <div>
    <!-- <img v-bind:src="options.img" title="点击上传头像" class="img-circle img-lg" /> -->
    <!--   <div @click="editCropper()" class="upload_active_btn">Upload profile image</div>-->
    <div class="flex-row button_fil" @click="editCropper()">
      <img style="width: 15px;height: 15px;margin-right: 8px; " src="@/assets/icon/icon_upload.svg" alt=""/>
      <p class="p-tile">Upload new</p>
    </div>
    <!--    <el-button class="el-button_fil" @click="editCropper()">Upload new</el-button>-->

    <el-dialog
        :title="title"
        :visible.sync="open"
        width="800px"
        append-to-body
        @opened="modalOpened"
    >
      <el-row>
        <el-col :xs="24" :md="12" :style="{ height: '350px' }">
          <vue-cropper
              ref="cropper"
              :img="options.img"
              :info="true"
              :autoCrop="options.autoCrop"
              :autoCropWidth="options.autoCropWidth"
              :autoCropHeight="options.autoCropHeight"
              :fixedBox="options.fixedBox"
              @realTime="realTime"
              v-if="visible"
          />
        </el-col>
        <el-col :xs="24" :md="12" :style="{ height: '350px' }">
          <div class="avatar-upload-preview">
            <img :src="previews.url" :style="previews.img"/>
          </div>
        </el-col>
      </el-row>
      <br/>
      <el-row>
        <el-col :lg="2" :md="2">
          <el-upload
              action="#"
              :http-request="requestUpload"
              :show-file-list="false"
              :before-upload="beforeUpload"
          >
            <el-button size="small">
              Select Files
              <!-- <i class="el-icon-upload el-icon--right"></i> -->
            </el-button>
          </el-upload>
        </el-col>
        <el-col :lg="{ span: 1, offset: 2 }" :md="2">
          <el-button
              icon="el-icon-plus"
              size="small"
              @click="changeScale(1)"
          ></el-button>
        </el-col>
        <el-col :lg="{ span: 1, offset: 1 }" :md="2">
          <el-button
              icon="el-icon-minus"
              size="small"
              @click="changeScale(-1)"
          ></el-button>
        </el-col>
        <el-col :lg="{ span: 1, offset: 1 }" :md="2">
          <el-button
              icon="el-icon-refresh-left"
              size="small"
              @click="rotateLeft()"
          ></el-button>
        </el-col>
        <el-col :lg="{ span: 1, offset: 1 }" :md="2">
          <el-button
              icon="el-icon-refresh-right"
              size="small"
              @click="rotateRight()"
          ></el-button>
        </el-col>
        <el-col :lg="{ span: 2, offset: 6 }" :md="2">
          <el-button type="primary" size="small" @click="uploadImg()"
          >Upload
          </el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>

import axios from 'axios'
import {VueCropper} from 'vue-cropper'
import {fileUrl} from "../../../env";
import {getToken} from "@/utils/auth";

export default {
  components: {VueCropper},
  props: {
    user: {
      type: Object
    },
    image: {
      type: String
    },
    tilename: {
      type: String
    }
  },
  data() {
    return {
      // 是否显示弹出层
      open: false,
      // 是否显示cropper
      visible: false,
      // 弹出层标题
      title: 'Modify the picture',
      options: {
        img: '', //裁剪图片的地址
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        name: ''
      },
      previews: {}
    }
  },
  created() {
    this.options.img = ''
    let that = this
    if (this.image) {
      let url =
          process.env.NODE_ENV === 'development'
              ? process.env.VUE_APP_IP
              : window.location.origin
      this.main(url + this.image, function (base64) {
        that.options.img = base64
      })
      console.log(url)
    } else {
      this.options.img = ''
    }
  },
  methods: {
    // 编辑头像
    editCropper() {
      this.open = true
    },
    // 打开弹出层结束时的回调
    modalOpened() {
      this.visible = true
    },
    // 覆盖默认的上传行为
    requestUpload() {
    },
    // 向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft()
    },
    // 向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight()
    },
    // 图片缩放
    changeScale(num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    // 上传预处理
    beforeUpload(file) {
      if (file.type.indexOf('image/') == -1) {
      } else {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          this.options.img = reader.result
        }
        this.options.name = file.name
      }
    },
    // 上传图片
    uploadImg() {
      if (!this.options.img) {


        return false
      }

      this.$refs.cropper.getCropBlob(async data => {
        let file = new window.File([data], this.options.name, {
          type: 'image/jpeg'
        })

        let formData = new FormData()
        formData.append('avatarfile', file)
        await axios({
          method: 'POST',
          url: `${fileUrl}/prod-api/system/user/profile/avatar`,
          data: formData,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
            "Authorization": getToken()
          }
        })
            .then(respons => {
              if (respons.data.code == '200') {
                // 上传成功
                this.$emit('uploadImg', respons.data)
                this.open = false

              } else {
                // 上传失败
                /*       this.$store.dispatch('app/setPopupClobal', {
                         isShow: true,
                         text: {
                           headerText: 'System error',
                           mainText: 'Upload failed'
                         }
                       })*/

                return false
              }
            })
            .catch(error => {
              /*   this.$store.dispatch('app/setPopupClobal', {
                   isShow: true,
                   text: {
                     headerText: 'Warning',
                     mainText: 'Network error'
                   }
                 })*/
            })
        // uploadAvatar(formData).then((response) => {
        //   if (response.code === 200) {
        //     this.open = false;
        //     this.options.img = process.env.VUE_APP_BASE_API + response.imgUrl;
        //     store.commit("SET_AVATAR", this.options.img);
        //   }
        //   this.visible = false;
        // });
      })
    },
    // 实时预览
    realTime(data) {
      this.previews = data
    },

    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return this.blobToFile(blob, filename)
    },

    getBase64Image(img) {
      var canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      var ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0, img.width, img.height)
      var dataURL = canvas.toDataURL('image/png') // 可选其他值 image/jpeg
      return dataURL
    },

    main(src, cb) {
      let that = this
      var image = new Image()
      image.src = src + '?v=' + Math.random() // 处理缓存
      image.crossOrigin = '*' // 支持跨域图片
      image.onload = function () {
        var base64 = that.getBase64Image(image)
        cb && cb(base64)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-row {
  display: flex;
  flex-direction: row;
}

.upload_active_btn {
  width: auto;
  height: 42px;
  border-radius: 2px;
  border: 1px solid #0091ff;

  font-size: 14px;
  font-family: OpenSans-Semibold, OpenSans;
  font-weight: 600;
  color: #0091ff;
  text-align: center;
  line-height: 42px;
  margin-top: 10px;
  cursor: pointer;
}

.el-button_fil {
  margin-left: 20px;
  margin-top: 30px;
  width: auto;
  height: 37px;
  font-size: 14px;
  background: #FFFFFF;
  font-family: Inter-Bold, Inter, serif;
  font-weight: bold;
  border: 1px solid #0091FF;
  color: #0091FF;

}

.button_fil {
  cursor: pointer;
}

.p-tile {
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  font-size: 13px;
  color: #9931F8;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.avatar-upload-preview {
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
  width: 180px;
  height: 180px;
  border-radius: 50%;
  box-shadow: 0 0 4px #ccc;
  overflow: hidden;
}

</style>
