<template>
  <div class="bar flex-row">
    <div v-for="group in options[0].children" :key="group.name" style="margin-left: 28px;cursor: move;">

      <div >
        <img :draggable="true" :config="JSON.stringify(group)"  @dragstart="dragStart"  :title="group.name"
             style="width: 30px;height: 30px; cursor: move;" :src="require('@/assets/icon/'+group.icon+'.svg')"
             :type="'image/svg+xml'">

<!--                <embed  @dragstart="dragStart" :config="JSON.stringify(group)" :draggable="true" style="width: 30px;height: 30px; " :src="require('@/assets/icon/'+group.icon+'.svg')"-->
<!--                       type="image/svg+xml"/>-->

      </div>
    </div>
  </div>
</template>

<script>
import options from "@/components/options";

export default {
  name: 'leftcombar',
  data() {
    return {
      options
    }
  },
  props: {
    selectedComponents: Array,
    currentCptIndex: Number
  },
  mounted() {
    console.log('=======this.options======>')
    console.log(this.options)
  },
  methods: {
    dragStart(e) {
      let copyDom = e.currentTarget.cloneNode(true);
      console.log('====leftcombar======')
      console.log(copyDom)
      console.log('==========')
      this.$emit('dragStart', copyDom);
    },
    showConfigBar(e, item, index) {
      this.$emit('showConfigBar', e, item, index);
    },
    copyCpt(item) {
      console.log('----componentBar---copyCpt--->')
      console.log(item)
      this.$emit('copyCpt', item);
    },
    delCpt(item, index) {
      this.$emit('delCpt', item, index);
    },
    handleClick(){

    }
  }

}

</script>

<style scoped>
.bar {
  align-items: center;
  padding-right: 24px;
  display: flex;
  width: fit-content;
  height: 62px;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  background-color: #FFFFFF;

}

.flex-row {
  display: flex;
  flex-direction: row;
}

.img_icon {

}

</style>