<template>
  <div class="flex-col">
    <el-row class="top">
      <div style=" display: flex; flex-direction: row;">
        <div style="background: rgba(64, 71, 86, 1);width: 54px;height: 54px;display: flex;cursor: pointer; "
             @click="goBack()">
          <img style="margin: auto;" :src="require('@/assets/icon/600.svg')"/>
        </div>
        <div style="width: 100px;height: 54px;display: flex;">
          <span class="span_tile">Go Back</span>
        </div>

      </div>

    </el-row>
    <div class="flex-col">
      <div class="flex-row"
           style="padding-left: 88px;padding-right: 88px;  justify-content: space-between;padding-top: 64px;">
        <span
            style="font-family: Inter-Medium, Inter;color: #404756;font-size: 48px;cursor: default;">Choose A Template</span>
        <div class="flex-row div_free" @click="onClick()">
          <div style="margin: auto;align-items: center;display: flex;">
            <img style="width: 16px;height: 16px;margin: auto" :src="require('@/assets/icon/freeboard.svg')">
            <span
                style="font-family: Inter-SemiBold, Inter;color: #9931F8;font-size: 14px;margin-left: 8px;">Free Board</span>
          </div>

        </div>

      </div>
      <div class="flex-row" style="padding-left: 88px;padding-right: 88px; margin-top: 32px;">
        <div :class="item === 0 ?'div_button_true' :'div_button_false'" @click="onClickPor(0)">
          <span :class="item === 0 ? 'span_item_true':'span_item_false'">Landscape</span>
        </div>
        <div :class="item === 1 ?'div_button_true' :'div_button_false'" @click="onClickPor(1)">
          <span :class="item === 1 ? 'span_item_true':'span_item_false'">Portrait</span>
        </div>
      </div>
      <landscape v-if="item === 0"/>
      <portrait v-if="item === 1"/>
    </div>

  </div>
</template>


<script>
import Landscape from "@/views/choosetemplate/LandScape.vue";
import Portrait from "@/views/choosetemplate/Portrait.vue";
import {museaFileList} from "@/api/login";

export default {
  components: {Portrait, Landscape},
  data() {
    return {
      item: 0
    }
  },
  created() {
   this.getViewList()
  },
  methods: {
    onClickPor(data) {
      this.item = data
    },
    goBack() {
      this.$router.go(-1); // 返回上一个页面
    },
    onClick() {
      this.$router.push({path: '/freeboard'}).catch()
    },
    getViewList() {
      let dataInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      museaFileList({userId: dataInfo.userId, fileName: this.username}).then(postMessage => {
        if (postMessage.code === 200) {
          let vidoList = []
          let ImageList = []
          postMessage.rows.forEach(itme => {
            if (itme.fileType === '0') {
              vidoList.push(itme)
            } else {
              ImageList.push(itme)
            }
          })
          sessionStorage.setItem('museaFileVide', JSON.stringify(vidoList))
          sessionStorage.setItem('museaFileImage', JSON.stringify(ImageList))

        }
      })
    },
  }

}

</script>


<style scoped>
.top {
  height: 54px;
  color: #fff;
  overflow: hidden;
  margin: 0;
  font-size: 18px;
  line-height: 45px;
  background: rgba(255, 255, 255, 1);
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.div_free {
  cursor: pointer;
  display: flex;
  width: 130px;
  height: 40px;
  border-radius: 999px;
  opacity: 1;
  border: 2px solid #9931F8;
}

.div_button_true {
  cursor: pointer;
  display: flex;
  width: 144px;
  height: 30px;
  padding-bottom: 12px;
  border-bottom: 2px solid #404756;
}

.div_button_false {
  cursor: pointer;
  display: flex;
  width: 144px;
  height: 30px;
  padding-bottom: 12px;
}

.span_item_true {
  margin: auto;
  text-align: center;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #404756;
  line-height: 14px;
}

.span_item_false {
  margin: auto;
  font-size: 14px;
  text-align: center;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #AFAFAF;
  line-height: 14px;
}

.el-col {
  height: 100%;
  border-radius: 4px;
}

.bg-purple-dark {
  height: 100%;
  background: #FFFFFF;
}

.bg-purple {
  height: 100%;
  background: #FFFFFF;
}

.bg-purple-light {
  height: 100%;
  background: #FFFFFF;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.span_tile {
  margin: auto;
  font-size: 16px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #404756;
  line-height: 16px;
}

</style>