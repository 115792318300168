<template>
  <div class="flex-col" v-loading="loading">
    <div class="flex-row"
         style="padding-left: 88px;padding-top: 64px;padding-right: 88px; display: flex;justify-content: space-between;">
      <span class="span_tile_names"> My Licenses </span>
      <el-popover
          placement="bottom"
          width="456"
          v-model="visible"
      >
        <div class="layer10 flex-col">
          <el-row class="el_table__row">
            <el-col :span="12">
              <el-input style="width:204px" placeholder="License key" prefix-icon="el-icon-search" type="text"
                        v-model="licenseke"
              />
            </el-col>
            <el-col :span="12" >
              <el-select style="width:204px" v-model="managersvalue" placeholder="license type">
                <el-option
                    v-for="item in project_managersoptions_All"
                    :key="item.value"
                    :label="item.label"
                    :value="item.isTrial"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row class="el_table__row">
            <el-col :span="12">
              <el-date-picker
                  style="width:204px"
                  v-model="Expirydate"
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="Expiry date">
              </el-date-picker>

            </el-col>
            <el-col :span="12">
              <el-date-picker
                  style="width:204px;"
                  value-format="yyyy-MM-dd"
                  v-model="Lastrenewdate"
                  type="date"
                  placeholder="Last renew date">
              </el-date-picker>
            </el-col>
          </el-row>
          <el-row class="el_table__row">
            <el-col :span="12">
              <el-select style="width:204px" v-model="distributorsvalue" placeholder="Validity">
                <el-option
                    v-for="item in project_Validity_All"
                    :key="item.value"
                    :label="item.label"
                    :value="item.isTrial"
                >
                </el-option>
              </el-select>

            </el-col>
            <el-col :span="12" >
            </el-col>
          </el-row>
          <el-row class="el_table__row">
            <el-col :span="12">
              <div class="flex-row">
                <div class="flex-row"
                     style="display: flex;align-content: center;align-items: center;cursor: pointer;"
                     @click="filtersiwn">
                  <img :width="15" :height="15" :src="require('@/assets/icon/ic_tick.svg')">
                  <span
                      style="margin-left: 9px;font-family: Inter-SemiBold;font-size: 13px;color: #9931F8; ">Apply</span>
                </div>
                <div class="flex-row"
                     style="margin-left: 17px;display: flex;align-content: center;align-items: center;cursor: pointer;"
                     @click="deletetag()">
                  <img :width="15" :height="15" :src="require('@/assets/icon/icon_reset.svg')">
                  <span
                      style="margin-left: 9px;font-family: Inter-SemiBold;font-size: 13px;color: #8F8F8F;">Reset</span>
                </div>
              </div>

            </el-col>
            <el-col :span="12">

            </el-col>
          </el-row>
        </div>
        <div class="flex-row div_filter" slot="reference">
          <img :src="require('@/assets/icon/ic_filter.svg')">
          <span class="span_filter">Filter</span>
        </div>
      </el-popover>
    </div>

    <div style="padding-left: 88px;padding-right: 88px;">
      <div class="bd5_tile flex-row">
        <input
            v-if="Selectall===1"
            type="checkbox"
            id="checkboxcaption"
            @click="UpcaptionCb"
            :value="false"
            v-model="place"

        />
        <div class="div_key flex-row">
          <p class="p">License&nbsp;key</p>
          <div class="sort-fn">
            <span :class="['positive',sortVal==='1'?'select':'']" @click="toggleSort('1')"></span>
            <span :class="['reverse',sortVal==='2'?'select':'']" @click="toggleSort('2')"></span>
          </div>
        </div>
        <div class="div_type">
          <p class="p">License&nbsp;type</p>
        </div>
        <div class="div_content">
          <p class="p">Expiry&nbsp;date</p>
          <div class="sort-fn">
            <span :class="['positive',sortVal==='3'?'select':'']" @click="toggleSort('3')"></span>
            <span :class="['reverse',sortVal==='4'?'select':'']" @click="toggleSort('4')"></span>
          </div>
        </div>
        <div class="div_content">
          <p class="p">Last&nbsp;renew&nbsp;date</p>
          <div class="sort-fn">
            <span :class="['positive',sortVal==='9'?'select':'']" @click="toggleSort('9')"></span>
            <span :class="['reverse',sortVal==='10'?'select':'']" @click="toggleSort('10')"></span>
          </div>
        </div>
        <div class="div_content">
          <p class="p">Days&nbsp;left</p>
          <div class="sort-fn">
            <span :class="['positive',sortVal==='5'?'select':'']" @click="toggleSort('5')"></span>
            <span :class="['reverse',sortVal==='6'?'select':'']" @click="toggleSort('6')"></span>
          </div>
        </div>
        <div class="div_content">
          <p class="p">Validity</p>
          <div class="sort-fn">
            <span :class="['positive',sortVal==='7'?'select':'']" @click="toggleSort('7')"></span>
            <span :class="['reverse',sortVal==='8'?'select':'']" @click="toggleSort('8')"></span>
          </div>
        </div>
      </div>
      <virtual-list class="list-keep"
                    :style="{height:700+'px'}"
                    :estimate-size="60"
                    :data-key="'id'"
                    :data-sources="UserList"
                    v-if="isRouterAlive"
                    :data-component="itemContactsitem"/>

    </div>

  </div>
</template>


<script>
import VirtualList from "vue-virtual-scroll-list";
import Contactsitem from "@/views/licenses/LicensesItme.vue";
import {Licenselist} from "@/api/login";

export default {
  name: "Licenses",
  data() {
    return {
      loading: false,
      Selectall: 0,
      place: false,
      sortVal: '',
      visible: false,
      filteedata: {
        name: '',
        email: '',
        Duration: 0
      },
      distributorsvalue: '',
      project_managersoptions_All: [
        {value: 'Cloud storage', label: 'Cloud storage', isTrial: '2'},
        {value: 'Musea', label: 'Musea', isTrial: '4'}
      ],
      project_Validity_All: [
        {value: 'Valid', label: 'Valid', isTrial: '1'},
        {value: 'Expired', label: 'Expired', isTrial: '0'}

      ],
      GroupDatalist: [
        {ID: '1'},
        {ID: '2'},
        {ID: '3'},
        {ID: '4'},
      ],
      itemContactsitem: Contactsitem,
      UserList: [],
      licenseke: '',
      devicename: '',
      Connectednumber: '',
      managersvalue: '',
      Validity: '',
      Expirydate: '',
      Lastrenewdate: '',
      tags: [],
      FilertDataNum: 0,
      FilerNum: 0,
      Filter_applied: 'Filter applied',
      isRouterAlive: true
    }
  },
  components: {
    'virtual-list': VirtualList
  },
  mounted() {
    this.getViewData()
  },

  methods: {
    UpcaptionCb() {

    },
    toggleSort(data) {
      this.sortVal = data
      this.isRouterAlive = false
      switch (data) {
        case '1':
          this.UserList = this.UserList.sort((a, b) =>
              parseInt(b.licenseKey.replace(new RegExp('[a-z]', 'ig'), '')) -
              parseInt(a.licenseKey.replace(new RegExp('[a-z]', 'ig'), ''))
          )
          break
        case '2':
          this.UserList = this.UserList.sort((a, b) =>
              parseInt(a.licenseKey.replace(new RegExp('[a-z]', 'ig'), '')) -
              parseInt(b.licenseKey.replace(new RegExp('[a-z]', 'ig'), ''))
          )
          break
        case '3':
          this.UserList = this.UserList.sort((a, b) =>
              parseInt(b.licenseContent) - parseInt(a.licenseContent)
          )
          break
        case '4':
          this.UserList = this.UserList.sort((a, b) =>
              parseInt(a.licenseContent) - parseInt(b.licenseContent)
          )
          break
        case '5':
          this.UserList = this.UserList.sort((a, b) =>
              parseInt(b.dataleft) - parseInt(a.dataleft)
          )
          break
        case '6':
          this.UserList = this.UserList.sort((a, b) =>
              parseInt(a.dataleft) - parseInt(b.dataleft)
          )
          break


      }
      this.$nextTick(function () {
            this.isRouterAlive = true
          }
      )

    },
    getViewData() {
      let dataInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      let data = {
        userId: dataInfo.userId,
        licenseKey: this.licenseke,
        licenseType: this.managersvalue,
        expiryTime: this.Expirydate,
        extendExpiryTime: this.Lastrenewdate,
        validity: this.distributorsvalue
      }
      Licenselist(data).then(reset => {
        if (reset.code === 200) {
          this.UserList.splice(0, this.UserList.length)
          this.FilerNum = reset.rows.length
          if (this.FilertDataNum === 0) {
            this.FilertDataNum = reset.rows.length
          }
          reset.rows.forEach(item => {
            switch (item.licenseType) {
              case  '1':
                item.Type = 'Device connection'
                item.Content = item.licenseContent + '  devices'
                break
              case  '2':
                item.Type = 'Cloud storage'
                item.Content = item.licenseContent + 'GB'
                break
              case  '3':
                item.Type = 'Extensions'
                item.Content = 'Tauri Cloud'
                break
              case '4':
                item.Type = 'Musea'
                item.Content = item.licenseContent + 'GB'
                break
            }
            if (item.extendExpiryTime && item.extendExpiryTime !== '') {
              item.dataleft = this.getDiffDay(item.extendExpiryTime, this.getCurrentTime()) > 0 ? this.getDiffDay(item.extendExpiryTime, this.getCurrentTime()) : 0
              item.expiryTimesdsquna = item.extendExpiryTime
            } else {
              item.dataleft = this.getDiffDay(item.expiryTime, this.getCurrentTime()) > 0 ? this.getDiffDay(item.expiryTime, this.getCurrentTime()) : 0
              item.expiryTimesdsquna = item.expiryTime
            }
            if (item.extendExpiryTime && item.extendExpiryTime !== '') {
              const sedataleftime = this.getDiffDay(item.expiryTime, this.getCurrentTime()) > 0 ? this.getDiffDay(item.expiryTime, this.getCurrentTime()) : 0
              if (sedataleftime > 0) {
                item.renewTime = '-'
              } else {
                item.renewTime = item.expiryTime
              }
            } else {
              item.renewTime = '-'
            }
            if (item.dataleft > 0) {

              item.validityta = 'Valid'
            } else {

              item.validityta = 'Expired'
            }
            this.UserList.push(item)

          })
        }
      })
    },
    // 计算时间差
    getDiffDay(date_1, date_2) {
      // 计算两个日期之间的差值
      let totalDays
      var date = new Date(date_1.replace(/-/g, '/')).getTime() - new Date(date_2.replace(/-/g, '/')).getTime()
      totalDays = Math.floor(date / (1000 * 3600 * 24)) // 向下取整
      totalDays = Math.floor(date / (1000 * 3600 * 24))
      return totalDays
    },
    //获取当前时间
    getCurrentTime() {
      //获取当前时间
      const _this = this
      let yy = new Date().getFullYear()
      let mm = new Date().getMonth() + 1
      let dd = new Date().getDate()
      let hh = new Date().getHours()
      let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
      let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
      _this.gettime = yy + '-' + mm + '-' + dd
      return _this.gettime
    },
    filtersiwn() {
      this.visible = false
      if (this.licenseke !== '') {
        if (this.tags.findIndex(item => item.field === 'licenseke')) {
          this.tags.push({name: this.licenseke, type: 'info', field: 'licenseke'})
        }
      }
      if (this.devicename !== '') {
        if (this.tags.findIndex(item => item.field === 'devicename')) {
          this.tags.push({name: this.devicename, type: 'info', field: 'devicename'})
        }
      }
      if (this.Connectednumber !== '') {
        if (this.tags.findIndex(item => item.field === 'Connectednumber')) {
          this.tags.push({name: this.Connectednumber, type: 'info', field: 'Connectednumber'})
        }
      }
      if (this.managersvalue !== '') {
        if (this.tags.findIndex(item => item.field === 'managersvalue')) {
          let managersvalue = this.project_managersoptions_All[this.project_managersoptions_All.findIndex(item => item.isTrial === this.managersvalue)].value
          this.tags.push({name: managersvalue, type: 'info', field: 'managersvalue'})

        }
      }
      if (this.Expirydate !== '') {
        if (this.tags.findIndex(item => item.field === 'Expirydate')) {
          this.tags.push({name: this.Expirydate, type: 'info', field: 'Expirydate'})
        }
      }
      if (this.Lastrenewdate !== '') {
        if (this.tags.findIndex(item => item.field === 'Lastrenewdate')) {
          this.tags.push({name: this.Lastrenewdate, type: 'info', field: 'Lastrenewdate'})
        }
      }
      if (this.distributorsvalue !== '') {
        console.log('--------->', this.distributorsvalue)
        if (this.tags.findIndex(item => item.field === 'distributorsvalue') === -1) {
          let projectname = this.project_Validity_All[this.project_Validity_All.findIndex(item => item.isTrial === this.distributorsvalue)]
          this.tags.push({name: projectname.value, type: 'info', field: 'distributorsvalue'})
        }
      }
      this.getViewData()
    },
    deletetag() {
      this.visible = false
      this.tags.splice(0, this.tags.length)
      this.licenseke = ''
      this.devicename = ''
      this.Connectednumber = ''
      this.managersvalue = ''
      this.Expirydate = ''
      this.Lastrenewdate = ''
      this.distributorsvalue = ''
      this.getViewData()
    }

  }


}
</script>

<style scoped lang="scss">

::v-deep .el-popper {
  padding-top: 12px;
  width: 99px !important;
}


::v-deep .el-checkbox {
  margin-left: 16px;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #303030;

  .el-checkbox__inner {
    width: 26px;
    height: 26px;
    border-radius: 50%;

    border: 1px solid rgba(149, 149, 149, 1);
    //修改选中框中的对勾的大小和位置
    &::after {
      top: 5px;
      height: 12px;
      left: 10px;
    }
  }

  .el-checkbox__label {
    display: inline-grid;
    color: #FFFFFF;
    width: 200px;

  }

  //修改点击文字颜色不变
  .el-checkbox__input.is-checked + .el-checkbox__label {
    border: 1px solid rgba(0, 145, 255, 1);
    background: rgba(0, 145, 255, 1);
    color: #303030;

  }


}

::v-deep .el-input__inner {
  border: 1px solid #C0BFC0;
  height: 44px;
  background-color: #FFFFFF;
  font-family: Inter-Medium;
  font-size: 13px;
  color: #3D2A56;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.span_tile_names {
  white-space: nowrap;
  font-size: 48px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #404756;
  line-height: 48px;
}

.div_filter {
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-content: center;
  align-items: center;
  width: 89px;
  height: 40px;
  border-radius: 999px;
  border: 1px solid #8F8F8F;
}


.span_filter {
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  font-size: 13px;
  color: #8F8F8F;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.bd5_tile {
  margin-top: 24px;
  height: 30px;

  .div_key {
    padding-left: 20px;
    display: flex;
    align-content: center;
    width: 340px;
    height: 26px;
  }

  .div_type {
    display: flex;
    align-content: center;
    width: 320px;
    height: 26px;
  }

  .div_content {
    display: flex;
    align-content: center;
    width: 160px;
    height: 26px;
  }


  .p {
    width: auto;
    height: 16px;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    font-size: 12px;
    color: #8F8F8F;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

}

.sort-fn {
  position: relative;
  top: -5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 20px;

  & > * {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }

  .positive {
    border-bottom-color: #aaaaaa;
    margin-bottom: 2px;

    &.select {
      border-bottom-color: #9931F8;
    }
  }

  .reverse {
    border-top-color: #aaaaaa;

    &.select {
      border-top-color: #9931F8;
    }
  }
}

.layer10 {
  width: auto;
  height: auto;
  background: #FFFFFF;
  box-shadow: rgba(48, 48, 48, 0.1);
  border-radius: 5px;
}

.el_table__row {
  width: 100%;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 20px;
}

.el-button_inpr {
  font-size: 12px;
}

</style>