<template>
  <div class="palays_div">
    <div style="width: 100%;height: 100%;display: flex;" v-if="itmeDiv === 0">
      <div style="width: 120px;margin: auto;" class="flex-col">
        <img :draggable="true" :config="JSON.stringify(group)" @dragstart="dragStart"
             style="width: 30px;height: 30px;margin: auto"
             :src="require('@/assets/icon/iconinteractiontouch.svg')" :type="'image/svg+xml'"/>
        <p class="tile_touch">Add Touch Area</p>
      </div>

    </div>
    <div class="flex-row justify-between" v-if="itmeDiv === 1">
      <div class="flex-row ">
        <div class="itme_div" @click="GoBack()">
          <div class="svg">
            <img v-bind:class="{img_svg0:Ids.cptOption.attribute.type!==0 ,img_svg1:Ids.cptOption.attribute.type===0}"
                 :width="30" :height="30" :src="require('@/assets/icon/iconinteractiongoback.svg')">
          </div>
          <p v-bind:class="{p_tile:Ids.cptOption.attribute.type!==0 ,p_tile2:Ids.cptOption.attribute.type===0}"
             class="p_tile">Go Back</p>
        </div>
        <div class="itme_div" @click="ToBoard">
          <div class="svg">
            <img v-bind:class="{img_svg0:Ids.cptOption.attribute.type!==1 ,img_svg1:Ids.cptOption.attribute.type===1}"
                 :width="30" :height="30" :src="require('@/assets/icon/icon_interactiongoo_board.svg')">
          </div>
          <p v-bind:class="{p_tile:Ids.cptOption.attribute.type!==1 ,p_tile2:Ids.cptOption.attribute.type===1}"
             class="p_tile">Go To Board</p>
        </div>
<!--        <div class="itme_div" @click="Browser" >
          <div class="svg">
            <img
                v-bind:class="{img_svg0:Ids.cptOption.attribute.type!==2 ,img_svg1:Ids.cptOption.attribute.type===2}"
                :width="30" :height="30" :src="require('@/assets/icon/interactionopenbrowser.svg')">
          </div>

          <p v-bind:class="{p_tile:Ids.cptOption.attribute.type!==2 ,p_tile2:Ids.cptOption.attribute.type===2}">Open
            Browser</p>
        </div>-->
      </div>
      <div class="itme_div1" @click="DeleteArea">
        <img :width="30" :height="30" :src="require('@/assets/icon/iconclose402.svg')">
        <p class="p_tile1">Delete Area</p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    currentCpt: [],
    selectedComponents: {},
    currentCptIndex: Number
  },

  data() {
    return {
      itmeDiv: 0,
      group: {
        name: 'touch',
        icon: 'videoleft',
        cptKey: 'touch-sig',
        icons: 'video',
        width: 200,
        height: 200,
        type: 0,
        option: {attribute: {url: '', fontWeight: 200, textLineHeight: 200, type: -1, Transition: ''}},
      },
      Ids: {}
    }
  },
  created() {

  },
  methods: {
    OnClinseItme(data) {
      this.itmeDiv = data
    },
    dragStart(e) {
      let copyDom = e.currentTarget.cloneNode(true);
      this.$emit('dragStart', copyDom);
    },
    showConfigBar(e, item, index) {
      this.$emit('showConfigBar', e, item, index);
    },
    copyCpt(item) {
      this.$emit('copyCpt', item);
    },
    delCpt(item, index) {
      this.$emit('delCpt', item, index);
    },
    modifyCpy(e) {
      this.$emit('modifyCpy', e);
    },

    showCptConfig(e) {
      this.Ids = e

    },
    GoBack() {
      console.log(this.Ids.cptOption.attribute)
      this.$showPreViousBoard({
        caption: this.Ids.cptOption.attribute.url,
        button: this.Ids.cptOption.attribute.Transition,
        callback: (data) => {
          const jsx = JSON.parse(data)
          if (jsx.coed === 0) {
            this.Ids.cptOption.attribute = {
              url: '',
              fontWeight: 200,
              textLineHeight: 200,
              type: 0,
              Transition: jsx.Transition
            }
            this.$emit('modifyCpy', this.Ids);
          }
        }
      })
    },
    ToBoard() {
      this.$showGoToBoard({
        msg: this.currentCpt,
        caption: this.Ids.cptOption.attribute.url,
        button: this.Ids.cptOption.attribute.Transition,
        callback: (data) => {
          const jsx = JSON.parse(data)
          if (jsx.coed === 0) {
            this.Ids.cptOption.attribute = {
              url: jsx.Board,
              fontWeight: 200,
              textLineHeight: 200,
              type: 1,
              Transition: jsx.Transition
            }
            this.$emit('modifyCpy', this.Ids);
          }
        }
      })
    },
    Browser() {
      this.$showOpenBrowser({
        caption: this.Ids.cptOption.attribute.url,
        button: this.Ids.cptOption.attribute.Transition,
        callback: (data) => {
          const jsx = JSON.parse(data)
          if (jsx.coed === 0) {
            this.Ids.cptOption.attribute = {
              url: jsx.Board,
              fontWeight: 200,
              textLineHeight: 200,
              type: 2,
              Transition: ''
            }
            this.$emit('modifyCpy', this.Ids);
          }
        }
      })

    },
    DeleteArea() {
      this.$showDeleteTouchArea({
        callback: (data) => {
          if (data === 'yes') {
            this.$emit('delCpt', this.Ids, 0);
          }

        }
      })
    }


  }

}

</script>

<style lang="scss" scoped>
.palays_div {
  width: 740px;
  height: 82px;
  background: #FFFFFF;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
}

.tile_touch {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #3D2A56;
  line-height: 12px;
}


.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.itme_div {
  cursor: pointer;
  border-right: 1px solid #ECECEC;
  width: 120px;
  height: 82px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.itme_div1 {
  cursor: pointer;
  border-left: 1px solid #ECECEC;
  width: 120px;
  height: 82px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.p_tile {
  margin-top: 10px;
  width: 120px;
  height: fit-content;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  font-size: 12px;
  color: #3D2A56;
  line-height: 12px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}

.p_tile2 {
  margin-top: 10px;
  width: 120px;
  height: fit-content;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  font-size: 12px;
  color: #9931F8;
  line-height: 12px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}

.p_tile1 {
  margin-top: 10px;
  width: 120px;
  height: fit-content;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  font-size: 12px;
  color: #E44356;
  line-height: 12px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}


.svg {
  width: 30px;
  height: 30px;
  overflow: hidden;

  .img_svg0 {
    position: relative;
    transform: translateX(-80px);
    filter: drop-shadow(80px 0 #3D2A56);
    border-left: 0px solid transparent; //防止drop-shadow主体超出视线隐藏后阴影消失
    border-right: 0px solid transparent;
  }

  .img_svg1 {
    position: relative;
    transform: translateX(-80px);
    filter: drop-shadow(80px 0 #9931F8);
    border-left: 0px solid transparent; //防止drop-shadow主体超出视线隐藏后阴影消失
    border-right: 0px solid transparent;
  }

}


</style>