import cpt_text_option from '@/element/attr/cpt-text-option'
import cpt_video_option from '@/element/attr/cpt-vido-option'
import cpt_image_option from '@/element/attr/cpt-img-option'
import cpt_subtitles from '@/element/attr/cpt-subtitles'
import cpt_plat_option from '@/element/attr/cpt-plat-option'


const cptOptions = [{
    name: 'assembly', icon: '', opened: true, children: [{
        name: 'Picture',
        icon: 'imageins',
        icons: 'image',
        cptKey: 'sig-img',
        option: {
            attribute: {
                url: require('@/assets/imageins.svg'),
                fit: 'fill',
                preview: false,
                type: 0,
                TouchUrl: '',
                ImageValue:'',
                Touchvalue: 0   ,
                ischeck: false
            }
        }
    }, {
        name: 'Video',
        icon: 'videoleft',
        cptKey: 'sig-video',
        icons: 'video',
        width: 200,
        height: 200,
        option: {attribute: {url: '', fontWeight: 200, textLineHeight: 200, type: 0}}
    },
        {
            name: 'Text',
            icon: 'textleft',
            icons: 'text',
            cptKey: 'sip-text',
            width: 100,
            height: 100,
            option: cpt_text_option,

        },
        {
            name: 'RSS Feed',
            icon: 'iconrssfeed',
            icons: 'iconrssfeed1',
            cptKey: 'sigsubtitles',
            width: 150,
            height: 40,
            option: cpt_subtitles
        },
        {
            name: 'playlist',
            icon: 'playlist',
            icons: 'playlist1',
            cptKey: 'sigPlaylist',
            width: 200,
            height: 200,
            option:
                {
                    attribute: {
                        url: '',
                        urlist: [], fontWeight: 200,
                        fontHeight: 200,
                        type: 1,
                        Interval: 10,
                        TouchUrl: '',
                        Touchvalue: -1,
                        ischeck: false
                    }
                }
        }

        //   a. 文字    a. 图片  a. 视频    a. 网页     a. 触摸位置
    ]
}]
export default cptOptions