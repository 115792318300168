<template>
  <div class="scrolling-text" :key="GeneralViod"
       :style="{backgroundColor:option.cptOption.attribute.bgColor,height:option.cptOption.attribute.textLineHeight}">
    <span id="mySubtitles"
          :class=" {spanseve_right :option.cptOption.attribute.direction !=='marquee',spanseve_left: option.cptOption.attribute.direction ==='marquee' }"
          :style="{textAlign:option.cptOption.attribute.textAlign,color:option.cptOption.attribute.textColor,fontSize: option.cptOption.attribute.textSize+'px',
    fontStyle:option.cptOption.attribute.fontStyle, fontWeight:option.cptOption.attribute.fontWeight,lineHeight:option.cptOption.attribute.textLineHeight+'px',
    fontFamily:option.cptOption.attribute.textFamily, textDecoration:option.cptOption.attribute.textDecoration,zIndex:option.cptOption.attribute.zIndex

    }">
      {{ cptData.value }}</span>
  </div>
</template>

<script>
import {pollingRefresh} from "@/utils/refreshCptData";
//
export default {
  name: "temsubtitles",
  props: {
    option: Object
  },
  data() {
    return {
      cptData: {},
      uuid: null,
      GeneralViod: Date.now(),
    }
  },
  computed: {

  },
  created() {
    console.log(this.option)
    this.uuid = require('uuid').v1();
    this.refreshCptData();
    $bus.on('sigvideo', data => {
      if (data.id === 0) {
        this.refreshCptData()
      } else if (data.id === 9) {
        this.setCssse(1)
      } else if (data.id === 10) {
        this.setCssse(2)
      }
    })
  },
  methods: {
    refreshCptData() {
      console.log('-----sigsubtitles---sip--text---->')
      pollingRefresh(this.uuid, this.option.cptOption.cptDataForm, this.loadData)
    },
    loadData() {
      this.cptData = JSON.parse(this.option.cptOption.cptDataForm.dataText)
    },
    redirect() {
      if (this.option.attribute.cptOption.attribute.url) {
        if (this.option.attribute.url.startsWith("view")) {
          this.$router.push(this.option.cptOption.attribute.url)
          this.$emit("reload")
        } else {
          window.open(this.option.cptOption.attribute.url)
        }
      }
    },
    setCssse(obj) {
      // var subtitles = document.getElementById('mySubtitles')
      // //   this.GeneralViod = Date.now()
      // switch (obj) {
      //   case 1:
      //     subtitles.style.cssText = "animation: marquee 20s linear infinite;"
      //     break
      //   case 2:
      //     subtitles.style.cssText = "animation: marquees 20s linear infinite;"
      //     break
      // }
    }
  },
  watch: {
    option: {
      handler(o, v) {
        console.log('======option ==========>')
        direction.direction
        this.animation = 'marquee 20s linear infinite'
        console.log(o)
      }, deep: true
    }
  }

}
</script>

<style scoped lang='scss'>
.scrolling-text {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  height: 100%;
}

.spanseve_left {
  display: inline-block;
  padding-left: 100%;
  /* 调整动画时长和效果 */
  animation: marquee 20s linear infinite;
}

.spanseve_right {
  display: inline-block;
  padding-left: 100%;
  /* 调整动画时长和效果 */
  animation: marquees 20s linear infinite;
}


@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquees {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

</style>