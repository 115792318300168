<template>
  <div style="width: 100%;height: 100%;display: flex;">
    <div  :style="{width:windowWidth+'px',height:windowHeight+'px',backgroundColor: designCache.bgColor,
  backgroundImage: designCache.bgImg ? 'url('+fileUrl+designCache.bgImg+')':'none',margin:'auto'}"
          style="background-size:100% 100%;background-color: #2b3340;overflow: hidden" ref="webContainer">
      <div style="position:relative;transform-origin:0 0;"
           :style="{width:windowWidth+'px',height:conHeight+'px',transform: 'scale('+containerScale+')'}">
        <div v-for="item in designCache.components" :key="item.id"
             style="position: absolute;"
             :style="{width:Math.round(item.cptWidth)+'px',
                  height:Math.round(item.cptHeight)+'px',
                  top:Math.round(item.cptY)+'px',
                  left:Math.round(item.cptX)+'px',
                  zIndex:item.cptZ}">

          <component :is="item.cptKey" :width="Math.round(item.cptWidth)"
                     :height="Math.round(item.cptHeight)" @reload="loadCacheData"
                     :option="item"/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import {fileUrl} from "/env";
import {genHtml} from "@/api/login";

export default {
  name: "preview_index",
  data() {
    return {
      fileUrl: fileUrl,
      designCache: {},
      windowWidth: 0,
      windowHeight: 0,
      conHeight: 0,
      containerScale: 1,
      authCodeDialogVisible: false,
      viewCode: ''
    }
  },
  created() {

  },
  mounted() {
    const that = this;
    that.loadCacheData();
  },
  methods: {
    loadCacheData() {
      let designCache = JSON.parse(localStorage.getItem('designCache'));
      console.log(designCache)
      this.loadDesign(designCache, false);
    },
    loadDesign(design, componentPares) {
      if (componentPares) {
        design.components = JSON.parse(design.components);
      }
      document.title = design.title;
      this.designCache = design;
      this.loadSize();


    },
    loadSize() {
      let designCache = JSON.parse(localStorage.getItem('designCache'));
      this.windowWidth = designCache.scaleX;
      this.windowHeight = designCache.scaleY;
      this.containerScale = Math.round(this.windowWidth / this.designCache.scaleX * 100) / 100
      this.conHeight = this.designCache.scaleY
    },


  }
}
</script>

<style scoped>
.bounce-enter-active {
  transition: all 1s;
  /*animation: bounce-in 1s;*/
}

.bounce-enter {
  opacity: 0;
  transform: scale(.5);
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
