<template>
  <div style="width: 100%;height:90vh;background: #FFFFFF;overflow: hidden">
    <el-row style="background: #FFFFFF;">
      <div style="margin-top: 10px;">
        <div class="flex-row" style="height: 48px;width: 100%">
          <div :class="itmeDiv===0 ? 'tile_span_true':'tile_span_false'" @click="OnClinseItme(0)">
            <span :class="itmeDiv ===0 ?'span_tile_true':'span_tile_false'">Basic Settings</span>
          </div>
          <div :class="itmeDiv===1 ? 'tile_span_true':'tile_span_false'" @click="OnClinseItme(1)">
            <span :class="itmeDiv ===1 ?'span_tile_true':'span_tile_false'">Boards</span>
          </div>
        </div>
      </div>
      <div v-if="itmeDiv===0" class="flex-col" style="width: 326px;">
        <div style="padding-left: 18px;padding-top: 20px;">
          <span class="span_name_tile">Name of the board</span>
          <div style="margin-bottom: 20px;">
            <el-input v-model="designData.title" maxlength="20" autocomplete="off"
                      style="width: 100%;margin-top: 8px;"></el-input>
          </div>
          <span class="span_name_tile">Screen Size</span>
          <div class="flex-row" style="margin-top: 12px;">
            <div class="flex-col">
              <span class="span_width">Width</span>
              <el-input v-model="designData.scaleX" autocomplete="off" style="width: 100%;margin-top: 8px;"
                        @input="setWidthX()"></el-input>
            </div>
            <div class="flex-col" style="margin-left: 12px">
              <span class="span_width">Height</span>
              <el-input v-model="designData.scaleY" autocomplete="off" style="width: 100%;margin-top: 8px;"
                        @input="setHeightY()"></el-input>
            </div>
          </div>
        </div>
        <div class="uploadItemDiv">
          <p class="uploadItemDivp">Play Settings</p>
        </div>
        <div class="flex-row justify-between">
          <p class="autoplay_p">Autoplay</p>
          <el-checkbox style="width: 24px;height: 24px;" v-model="designData.checkAll"
                       @change="handleCheckAllChange"></el-checkbox>
        </div>
        <div v-if="designData.checkAll" style="margin-top: 24px;">
          <p class="autoplay_p">Play interval (in seconds)</p>
          <el-input v-model="designData.Playinterval" autocomplete="off"
                    style="width: 100%;margin-top: 8px;margin-left: 18px"></el-input>
        </div>
      </div>
      <div v-if="itmeDiv===1" class="flex-col">
        <div class="addlibray flex-row" @click="refreshCptData">
          <img style="width: 16px;height: 16px;" :src="require('@/assets/icon/iconplus500.svg')">
          <p class="p_tile_text">Add From Library</p>
        </div>
        <TransitionGroup name="list" tag="div" class="container">
          <div v-for="(item ,i) in  currentCpt"
               style="overflow-y: auto"
               :key="i"
               :draggable="true"
               @dragstart="dragstart($event, i)"
               @dragenter="dragenter($event, i)"
               @dragend="dragend"
               @dragover="dragover"
          >
            <div class="flex-row align-between justify-between "
                 v-bind:class="{div_board_true:!item.ischeck&& item.isexistence ,div_board_felse:item.ischeck}"
                 style="cursor: pointer;" v-on:click.self="seveItem(item)">
              <div class="flex-row" style="display: flex;align-items: center;" @click="seveItem(item,i)">
                <img style="width: 20px;height: 20px; cursor: pointer;" :src="require('@/assets/icon/iconarrange.svg')"
                     v-if="item.ischeck">
                <div>

                  <img v-if="item.isexistence" :width="84" :height="64" :src="item.boardThumbnail" @error="handleError">
                  <div v-else
                       style="width: 84px;height: 64px;background-color: rgba(255, 255, 255, 0.9);display: flex;">
                    <img style="margin: auto" :src="require('@/assets/icon/ic_error.svg')">
                  </div>

                </div>

                <div class="flex-col" style="margin-left: 12px;">
                  <p class="boardname">{{ item.boardName }}</p>

                  <div class="flex-row" style="margin-top: 10px;" v-if="item.isexistence">
                    <div style="width: 43px;" class="screendiv">{{ Aspectratio[parseInt(item.aspectRadio)].label }}
                    </div>
                    <div style="width: 83px;margin-left: 8px;" class="screendiv">{{ item.screenSize }}</div>
                  </div>
                  <div class="div_missing" v-else>
                    <img :width="11" :height="11" :src="require('@/assets/icon/ic_exclamationmark.svg')"/>
                    Board missing
                  </div>
                </div>
              </div>
              <img style="width: 14px;height: 14px; cursor: pointer;margin-right: 13px;" @click="DeleItme(i)"
                   :src="require('@/assets/icon/code601.svg')"
                   v-if="item.ischeck|| !item.isexistence ">
              <div style="width: 30px;display: flex;align-items: center;background: #9931F8;height: 90px;"
                   v-if="!item.ischeck&& item.isexistence">
                <img style="width: 14px;height: 14px; cursor: pointer;margin: auto;"
                     :src="require('@/assets/icon/iconarrowright.svg')"/>
              </div>

            </div>
          </div>
        </TransitionGroup>

      </div>
    </el-row>
  </div>

</template>
<script>
let clickVal;
let moveVal;
let endVal;
let dragIndex = 0;
export default {
  name: 'Componentleft',
  props: {
    designData: Object,
    height: Number
  },
  data() {
    return {
      itmeDiv: 0,
      Aspectratio: [
        {value: '9:16', label: '9:16', id: 0},
        {value: '3:4', label: ' 3:4', id: 1},
        {value: '2:3', label: ' 2:3', id: 2}
      ],
      isBoard: false,
      checkAll: false,
      Playinterval: 10,
      currentCpt: [],
    }
  },
  created() {
    $bus.on('componentleft', data => {
      if (data.code === 0) {
        let index = this.currentCpt.findIndex(it => it.boardId === data.data.boardId)
        this.seveItem(this.currentCpt[index])
      }
    })

    /*
        $bus.on('Componentleft', data => {
          switch (data.code) {
            case 0:
              console.log('----->',data.data)
              if (this.currentCpt.length > 0) {
                this.currentCpt.forEach(it => {
                  data.data.forEach((itme, index) => {
                    if (itme.boardId === it.boardId) {
                      data.data.splice(index, 1)
                    }
                  })
                })
              }
              this.currentCpt.push(...data.data)
              break
          }
        })
    */

  },
  methods: {
    handleError(event) {
      event.target.src = require('@/assets/icon/failed.svg')
    },
    OnClinseItme(data) {
      this.itmeDiv = data
    },
    refreshCptData() {
      $bus.emit("createstack", {code: 1, data: this.currentCpt})

    },
    dragstart(e, index) {
      e.stopPropagation();
      dragIndex = index;
      setTimeout(() => {
        e.target.classList.add('moveing');
      }, 0);
    },
    dragenter(e, index) {
      e.preventDefault();
      // 拖拽到原位置时不触发
      if (dragIndex !== index) {
        const source = this.currentCpt[dragIndex];
        this.currentCpt.splice(dragIndex, 1);
        this.currentCpt.splice(index, 0, source);
        // 更新节点位置
        dragIndex = index;
      }
    },
    dragover(e) {
      e.preventDefault();
      e.dataTransfer.dropEffect = 'move';
    },
    dragend(e) {
      e.target.classList.remove('moveing');
    },
    seveItem(e, i) {
      if (e.isexistence) {
        this.currentCpt.forEach((it, index) => {
          if (e.boardId !== it.boardId || index !== i) {
            it.ischeck = true
          }
        })
        e.ischeck = !e.ischeck
        this.$emit('setView', e);
      }
    },
    DeleItme(e) {
      this.currentCpt.splice(e, 1)
    },
    setWidthX() {

    },
    setHeightY() {
    },
    handleCheckAllChange() {

    },
    addComponents(e) {

    },
    modifyData(data) {
      // this.currentCpt = e
      let cptdata = []
      console.log(data)
      if (this.currentCpt.length > 0) {
        this.currentCpt.forEach(it => {
          data.forEach((itme, index) => {
            if (itme.boardId === it.boardId) {
              data.splice(index, 1)
            }
          })
        })
      }
      this.currentCpt.push(...data)
      this.currentCpt.forEach(itme => {
        itme.isexistence = false
      })
      JSON.parse(sessionStorage.getItem('myboards')).forEach(it => {
        this.currentCpt.forEach(itme => {
          if (itme.boardId === it.boardId) {
            itme.isexistence = true
            itme.sourceInfo = it.sourceInfo
            itme.boardThumbnail = it.boardThumbnail
            itme.boardBackground = it.boardBackground
            itme.Radio = it.Radio
            itme.boardName = it.boardName
            itme.screenSize = it.screenSize

          }
        })

      })

    },
    DeleteData(e) {
    }

  },

}
</script>

<style scoped>

/deep/ .el-form-item__label {
  padding-left: 16px;
  white-space: nowrap;
  text-align: left;
  font-size: 18px;
}


.cptTitle {
  line-height: 35px;
  text-align: center;
  background: #3f4b5f;
  color: #fff;
}

.closeItem:hover {
  cursor: pointer;
  background: #2b3340;
}

.customForm {
  overflow-y: hidden;
}

.uploadItemDiv {
  padding-left: 18px;
  margin-top: 24px;
  display: flex;
  align-items: center;;
  justify-content: center;
  width: 360px;
  height: 54px;
  border-top: 1px solid #ECECEC;

}

.uploadItemDivp {
  width: 100%;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  font-size: 14px;
  color: #3D2A56;
  line-height: 14px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.autoplay_p {
  margin-left: 18px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  font-size: 14px;
  color: #1E1E1E;
  line-height: 14px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.tile_span_true {
  cursor: pointer;
  align-content: center;
  align-items: center;
  display: flex;
  width: 180px;
  height: 48px;
  background: #FFFFFF;
  border-bottom: 2px solid #1E1F21;
}

.tile_span_false {
  cursor: pointer;
  align-content: center;
  display: flex;
  width: 180px;
  height: 48px;
  background: #FFFFFF;
  border-bottom: 2px solid #AFAFAF;
}

.span_tile_true {
  margin: auto;
  height: 14px;
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #1E1F21;
}

.span_tile_false {
  margin: auto;
  height: 14px;
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 400;
  color: #AFAFAF;
}

.div_tope {
  margin-top: 8px;
  display: flex;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  height: 38px;
}

.span_name_tile {
  font-size: 13px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #1E1F21;
  line-height: 13px;
}

.span_width {
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #AFAFAF;
  line-height: 12px;
}

.addlibray {
  margin-left: 18px;
  align-items: center;
  justify-content: center;
  align-content: center;
  display: flex;
  cursor: pointer;
  margin-top: 20px;
  width: 324px;
  height: 40px;
  margin-bottom: 12px;
  border-radius: 999px;
  border: 1px solid #9931F8;
}

.p_tile_text {
  margin-left: 8px;
  font-size: 13px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #9931F8;
  line-height: 16px;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.moveing {
  opacity: 0;
}

.align-between {
  display: flex;
  align-items: center;
}

.justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.screendiv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 999px;
  border: 1px solid #E0E1DD;
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  color: #C0BFC0;
}

.boardname {
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #1E1E1E;
  line-height: 14px;
}

.div_board_true {
  padding-left: 22px;
  border: 2px solid #9931F8;
}

.div_board_felse {
  padding-top: 7px;
  padding-bottom: 7px;

}

.container {

  height: 72vh;
  overflow-y: auto;
}

.list-move, /* 对移动中的元素应用的过渡 */
.list-enter-active,
.list-leave-active {
  transition: all 0.2s ease;
}

</style>
<style scoped lang="scss">

::v-deep .el-checkbox {
  margin-left: 16px;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #303030;

  .el-checkbox__inner {
    width: 26px;
    height: 26px;
    border-radius: 2px;


    border: 1px solid rgba(149, 149, 149, 1);
    //修改选中框中的对勾的大小和位置
    &::after {
      top: 5px;
      height: 12px;
      left: 10px;
    }
  }


  .el-checkbox__label {
    display: inline-grid;
    color: #FFFFFF;
    width: 200px;
    background-color: rgba(153, 49, 248, 1);
  }

  .el-checkbox__inner {

  }

  .el-checkbox__label::after {
    background-color: rgba(153, 49, 248, 1);
  }

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-color: rgba(153, 49, 248, 1) !important;
    background-color: rgba(153, 49, 248, 1) !important;
  }

  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: rgba(153, 49, 248, 1) !important;
  }

  //修改点击文字颜色不变
  .el-checkbox__input.is-checked + .el-checkbox__label {
    border: 1px solid rgba(153, 49, 248, 1);
    background: rgba(153, 49, 248, 1);
    color: #303030;
  }


}

.div_missing {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  width: 116px;
  height: 24px;
  background: #F9D92B;
  border-radius: 999px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  font-size: 12px;
  color: #383838;
  line-height: 12px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

</style>