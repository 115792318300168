import GoPreViousBoard from "@/erredpop/GoPreViousBoard.vue";
let ConfirmConstructor, instance

const showPreViousBoard = {
    install(Vue) {
        ConfirmConstructor = Vue.extend(GoPreViousBoard)
        instance = new ConfirmConstructor().$mount()
        document.body.appendChild(instance.$el)
        Vue.prototype.$showPreViousBoard = options => {
            Object.assign(instance, options)
            instance.init()
        }
    }

}
export default showPreViousBoard