<template>
  <div :style="{color:option.cptOption.attribute.textColor,fontSize: option.cptOption.attribute.textSize+'px',
    fontStyle:option.cptOption.attribute.fontStyle, fontWeight:option.cptOption.attribute.fontWeight,lineHeight:option.cptOption.attribute.textLineHeight+'px',
    backgroundColor:option.cptOption.attribute.bgColor, zIndex:option.cptOption.attribute.zIndex,
    fontFamily:option.cptOption.attribute.textFamily, textDecoration:option.cptOption.attribute.textDecoration,
        width:'100%',height:'100%',whiteSpace:'pre-wrap',display:'grid',alignItems:'center',
        justifyItems:option.cptOption.attribute.textAlign,overflow:'hidden'
     }"
       @click="redirect">
    {{ cptData.value }}
  </div>
</template>

<script>
import {pollingRefresh} from "@/utils/refreshCptData";

export default {
  name: "tem-text",
  props: {
    option: Object
  },
  data() {
    return {
      cptData: {},
      uuid: null
    }
  },
  created() {
    this.uuid = require('uuid').v1();
    this.refreshCptData();
    $bus.on('siptext', data => {
      this.refreshCptData()
    })
  },
  methods: {
    refreshCptData() {
      pollingRefresh(this.uuid, this.option.cptOption.cptDataForm, this.loadData)
    },
    loadData() {
      this.cptData = JSON.parse(this.option.cptOption.cptDataForm.dataText)
    },
    redirect() {
      if (this.option.cptOption.attribute.url) {
        if (this.option.cptOption.attribute.url.startsWith("view")) {
          this.$router.push(this.option.cptOption.attribute.url)
          this.$emit("reload")
        } else {
          window.open(this.option.cptOption.attribute.url)
        }
      }
    }
  },

}
</script>


<style scoped>

</style>