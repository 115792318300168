<template>
  <div class="flex-col" v-loading="loading">
    <div class="flex-row"
         style="padding-left: 88px;padding-top: 64px;padding-right: 88px; display: flex;justify-content: space-between;">
      <span class="span_tile_names">My Stacks </span>
      <div class="flex-row">
        <div>
          <input class="elinput_inners" v-model="username" :placeholder="'Search by name'"
                 @clear="clearInpt" @change="InputString"/>
        </div>
        <div class="formDiv9" @click="OnlicLisb()">
          <div class="flex-row">
            <img class="img_icon" style="margin-right: 8px" :src="isIocn">
            <span class="spi_list">{{ isname }}</span>
          </div>

        </div>
        <div class="formDiv2">
          <div class="flex-row" @click="onclickDesigner()">
            <img class="img_icon" :src="require('@/assets/icon/add.svg')">
            <span class="spi_list1" style="margin-left: 8px;">Create Stack</span>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 28px;">
      <div class="box_div" style="overflow-y: auto" :style="{height:fullHeight+'px'}" v-if="isList">
        <div class="flex-row">
          <div class="row">
            <div v-for="(item,index) in listData" :key="index" class="ymdiv">
              <div class="image_div_tuer">
                <div class="div_img_sio">
                  <div class="flex-row">
                    <div class="div_img_sio_div">
                      <img v-if="item.sourceInfo.length >0" :src="item.sourceInfo[0].boardThumbnail"
                           @error="handleError">
                    </div>
                    <div class="div_img_sio_div">
                      <img v-if="item.sourceInfo.length >1" :src="item.sourceInfo[1].boardThumbnail">
                    </div>
                  </div>
                  <div class="flex-row">
                    <div class="div_img_sio_div">

                      <img v-if="item.sourceInfo.length >2" :src="item.sourceInfo[2].boardThumbnail">
                    </div>
                    <div class="div_img_sio_div">
                      <img v-if="item.sourceInfo.length >3" :src="item.sourceInfo[3].boardThumbnail">
                    </div>
                  </div>

                </div>
                <div class="flex-col"
                     style="position: absolute; bottom: 0;height: fit-content;width: 100%;">
                  <P style="text-align: center;width: 100%;font-size: 16px;font-family: Inter-Regular;color: #8F8F8F;margin-top: 12px;">
                    {{ item.stackName }}</P>
                </div>
              </div>
              <div class="icon"
                   style="width: 100%;height: 234px; z-index: 997; border-radius: 12px; transform: translateY(-1px);filter: drop-shadow(0px 4px 4px rgba(48, 48, 48, 0.15));">
              </div>
              <div style="width: 58px;margin-top: 12px;z-index: 999;"
                   v-bind:class="{icon_true :item.ischeck ,icon_felse:!item.ischeck}">
                <el-checkbox style="margin-left: 16px;" v-model="item.ischeck" @change="seletDevice(item)"></el-checkbox>
              </div>
              <div class="flex-row icon_felse" style="z-index: 999;margin-top: 79px;margin-left: 42px">
                <div class="div_board" style="border-right: 2px solid #C0BFC0;" @click="preview(item)">
                  <img :src="require('@/assets/icon/play1.svg')"></div>
                <div class="div_board" style="border-right: 2px solid #C0BFC0;" @click="setOnClisEdit(item)">
                  <img :src="require('@/assets/icon/edit2.svg')"></div>
                <el-dropdown :hide-on-click="false" trigger="hover">
                  <div class="div_board">
                    <img :src="require('@/assets/icon/more1.svg')">
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item style="border-bottom:1px  solid  #F2F2F2">
                      <div class="flex-col">
                        <div class="flex-row"
                             style="display: flex;cursor: pointer;padding-top: 10px;padding-bottom: 10px;"
                             @click="getDown(item) ">
                          <img style="width: 14px;height: 14px;" :src="require('@/assets/icon/iconcompress.svg')">
                          <p class="span_export">Export as .zip</p>
                        </div>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item style="border-bottom:1px  solid  #F2F2F2">
                      <div class="flex-col">
                        <div class="flex-row"
                             style="display: flex;cursor: pointer;padding-top: 10px;padding-bottom: 10px;"
                             @click="Pushdevice(item)">
                          <img style="width: 14px;height: 14px;" :src="require('@/assets/icon/iconpushdevice.svg')">
                          <p class="span_export">Push to device</p>
                        </div>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <div class="flex-row"
                           style="cursor: pointer;padding-top: 10px;padding-bottom: 10px;"
                           @click="oneDelete(item.stackId)">
                        <img style="width: 14px;height: 14px;" :src="require('@/assets/icon/icondelete.svg')">
                        <p class="span_delete">Delete</p>
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col" style="overflow-y: auto;" :style="{height:fullHeight+'px'}" v-else>
        <div class="flex-row" style="margin-left: 88px;margin-right: 88px;margin-bottom: 12px">
          <div style="width: 424px;padding-left: 58px;">
            <span class="span_tile_name">Title</span>
          </div>
          <div style="width: 386px;">
            <span class="span_tile_name">Boards</span>
          </div>
        </div>
        <div class="flex-col">
          <div class="outer_data flex-row" v-for="(item,index) in listData" :key="index">
            <div style="width: 58px; display: flex;align-items: center;"
                 v-bind:class="{icon_true :item.ischeck ,icon:!item.ischeck}">
              <el-checkbox style="margin-left: 16px;" v-model="item.ischeck" @change="seletDevice(item)"></el-checkbox>
            </div>
            <div style="width: 424px;display: flex;align-items: center;">
              <span class="span_tile_name1">{{ item.stackName }}</span>
            </div>
            <div style="width: 386px;display: flex;align-items: center;">
              <span class="span_tile_name1">{{ item.sourceInfo.length }}</span>
            </div>
            <div style="width: 386px;display: flex;align-items: center;" class="flex-row icon">
              <img style="width: 18px;height: 18px;cursor: pointer;" :src="require('@/assets/icon/play.svg')"
                   @click="preview(item)"
              />
              <img style="width: 18px;height: 18px;cursor: pointer;margin-left: 14px;" @click="setOnClisEdit(item)"
                   :src="require('@/assets/icon/iconedit.svg')"/>
              <el-dropdown :hide-on-click="false" trigger="hover">
                <img style="width: 18px;height: 18px;cursor: pointer;margin-left: 14px;" class="el-dropdown-link"
                     :src="require('@/assets/icon/more.svg')"/>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item style="border-bottom:1px  solid  #F2F2F2">
                    <div class="flex-col">
                      <div class="flex-row"
                           style="display: flex; cursor: pointer;padding-top: 10px;padding-bottom: 10px;"
                           @click="getDown(item)">
                        <img style="width: 14px;height: 14px;" :src="require('@/assets/icon/iconcompress.svg')">
                        <p class="span_export">Export as .zip</p>
                      </div>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item style="border-bottom:1px  solid  #F2F2F2">
                    <div class="flex-col">
                      <div class="flex-row"
                           style="display: flex;cursor: pointer;padding-top: 10px;padding-bottom: 10px;"
                           @click="Pushdevice(item)">
                        <img style="width: 14px;height: 14px;" :src="require('@/assets/icon/iconpushdevice.svg')">
                        <p class="span_export">Push to device</p>
                      </div>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <div class="flex-row" style="margin:  auto;cursor: pointer;padding-top: 10px;padding-bottom: 10px;"
                         @click="oneDelete(item.stackId)">
                      <img style="width: 14px;height: 14px;" :src="require('@/assets/icon/icondelete.svg')">
                      <p class="span_delete">Delete</p>
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <select-device v-if="isSelect" :projec-data="downloadID" v-on:chickBack="chickBack"/>
    <div class="action-bar justify-between" id="actionbar">
      <div class="flex-row">
        <div class="div_action" style="width: 124px; height: 41px;">
          <span class="span_action">{{ selected }}</span>
        </div>
        <div class="flex-row  iiem_popover" @click="CancelOperation">
          <img src="@/assets/icon/delete.svg" style="width: 15px;height: 15px;margin-right: 8px;margin-left: 16px;">
          <span class="span_item_popover_delete">Delete</span>
        </div>
      </div>
      <div style="width: 153px; height: 41px;justify-content: center;align-items: center;display: flex;cursor: pointer"
           @click="Cancel">
        <span class="span_tile_cancel">Cancel</span>
        <img style="width: 10px;height: 10px;margin-left: 8px;" :src="require('@/assets/icon/600.svg')">
      </div>
    </div>

  </div>
</template>
<script>
import {deleteStack, getStack} from "@/api/login";
import SelectDevice from "@/popover/SelectDevice.vue";

export default {
  name: 'My-stacks',
  components: {SelectDevice},
  data() {
    return {
      username: '',
      isname: 'Grid View',
      isList: true,
      selected: '',
      isactionbar: false,
      isIocn: require('@/assets/icon/grid.svg'),
      listData: [],
      seveList: [],
      Aspectratio: [
        {value: '9:16', label: '9:16', id: 0},
        {value: '3:4', label: ' 3:4', id: 1},
        {value: '2:3', label: ' 2:3', id: 2}
      ],
      fullHeight: document.documentElement.clientHeight - 200,
      loading: false,
      isSelect: false,
      downloadID: {}
    }
  },
  created() {
    this.getHtml()
  },
  methods: {
    handleError(event) {
      event.target.src = require('@/assets/icon/missing.png')

    },
    clearInpt() {
      this.getHtml()
    },
    InputString() {
      this.getHtml()
    },
    OnlicLisb() {
      this.isList = !this.isList
      if (this.isList) {
        this.isname = 'Grid View'
        this.isIocn = require('@/assets/icon/grid.svg')
      } else {
        this.isname = 'List View'
        this.isIocn = require('@/assets/icon/list.svg')

      }
    },
    onclickDesigner() {
      this.$router.push('/createstack').catch(err => err)

    },
    CancelOperation() {
      let listid = this.seveList.map(it => it.stackId)
      console.log()
      this.oneDelete(listid)


    },
    Cancel() {
      this.listData.forEach(it => {
        it.ischeck = false
      })
      this.seveList.splice(0, this.seveList.length)
      document.getElementById('actionbar').classList.remove('show')
    },
    getHtml() {
      this.loading = true
      this.listData.splice(0, this.listData.length)
      let dataInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      getStack({userId: dataInfo.userId, stackName: this.username}).then(postMessage => {
        this.loading = false
        if (postMessage.code === 200) {
          postMessage.rows.forEach(it => {
            it.ischeck = false
            it.sourceInfo = JSON.parse(JSON.parse(it.sourceInfo).sources)
          })
          let arr = postMessage.rows.sort(this.sortDownDate)
          this.listData = arr
          console.log(this.listData[0].sourceInfo[0].boardThumbnail)

        }

      }).catch(escape => {

      })
    },
    chickBack() {
      this.isSelect = false
    },

    seletDevice(data) {
      const actionBar = document.getElementById('actionbar')
      if (data.ischeck) {
        this.seveList.push(data)
        actionBar.classList.add('show')
      } else {
        let Index = this.seveList.findIndex(itme => itme.stackId === data.stackId)
        this.seveList.splice(Index, 1)
      }
      if (this.seveList.length === 0) {
        actionBar.classList.remove('show')
      }
      this.selected = this.seveList.length + ' selected'
    },
    preview(e) {
      //  this.designData.components = this.cacheComponents;
      console.log(e)

      localStorage.setItem('designCache', JSON.stringify({
        autoPlay: e.autoPlay,
        playInterval: e.playInterval,
        currentCpt: e.sourceInfo
      }));

      let routeUrl = this.$router.resolve({
        path: "/stacksPreview"
      });
      console.info(e)
      window.open(routeUrl.href, '_blank');
    },
    setOnClisEdit(obj) {
      this.$router.push({name: 'createstack', query: {data: JSON.stringify(obj)}})
    },
    Pushdevice(data) {
      this.downloadID = {code: 1, id: data.stackId}
      this.isSelect = true
    },
    getDown(e) {
      let licenes = JSON.parse(sessionStorage.getItem('Licenselist'))
      let omse = licenes.filter(it => it.dataleft > 0 && it.licenseType === '4')
      if (omse.length < 1) {
        this.$showMsgBox({
          caption: 'License expired',
          icon: 'failed',
          msg: ['Your license for MUSEA has been expired.', 'Please consider to renew your license to','enjoy MUSEA features.'],
          button: 'Go Back',
          callback: (data) => {

          }
        })
      }else {
        this.$download.zip("/prod-api/system/stack/exportStack/" + e.stackId, "MuSeaBoard.zip");
      }

    },
    oneDelete(obj) {
      this.$showDeleteFiles({
        caption: '',
        msg: '',
        callback: (data) => {
          if (data === 'yes') {
            deleteStack(obj).then(postMessage => {
              if (postMessage.code === 200) {
                this.seveList.splice(0, this.seveList.length)
                this.getHtml()
                document.getElementById('actionbar').classList.remove('show')
              }
            })
          }
        }
      })
    },

  }

}
</script>
<style scoped lang="scss">

::v-deep .el-popper {
  padding-top: 12px;
  width: 99px !important;
}


::v-deep .el-checkbox {

  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #303030;

  .el-checkbox__inner {
    width: 26px;
    height: 26px;
    border-radius: 50%;

    border: 1px solid rgba(149, 149, 149, 1);
    //修改选中框中的对勾的大小和位置
    &::after {
      top: 5px;
      height: 12px;
      left: 10px;
    }
  }

  .el-checkbox__label {
    display: inline-grid;
    color: #FFFFFF;
    width: 200px;

  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    border: 1px solid #9931F8;
    background-color: #9931F8;
  }

  .el-checkbox__label {
    display: inline-grid;
    color: #FFFFFF;
    width: 200px;
    background-color: #9931F8;

  }

  //修改点击文字颜色不变
  .el-checkbox__input.is-checked + .el-checkbox__label {
    border: 1px solid rgba(0, 145, 255, 1);
    background: rgba(0, 145, 255, 1);
    color: #303030;

  }


}

.elinput_inners {
  padding-left: 36px;
  margin-left: 16px;
  width: 246px;
  background: url('../../assets/icon/search.svg') no-repeat center center;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: 16px 13px;
  border: 1px solid #C0BFC0;
  border-radius: 999px;
  height: 40px;
  font-family: Inter-Regular;
  outline-color: #C0BFC0;
  font-size: 13px;
  color: #3D2A56;
}


.box_div {
  height: 500px;
  overflow: hidden;
  white-space: nowrap;
  overflow-y: scroll; // 设置滚动条

  /*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
  // 滚动条整体部分
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
  &::-webkit-scrollbar-button {
    display: none;
  }

  // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
  &::-webkit-scrollbar-thumb {
    background: rgba(144, 147, 153, 0.3);
    cursor: pointer;
    border-radius: 4px;
  }

  // 边角，即两个滚动条的交汇处
  &::-webkit-scrollbar-corner {
    display: none;
  }

  // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
  &::-webkit-resizer {
    display: none;
  }

}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}


.img_icon {
  width: 16px;
  height: 16px;
  margin: auto;
}

.span_tile_names {
  white-space: nowrap;
  font-size: 48px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #404756;
  line-height: 48px;
}

.formDiv0 {
  cursor: pointer;
  width: 246px;
  height: 40px;
  text-align: center;
  border-radius: 36px 36px 36px 36px;
  opacity: 1;
  border: 1px solid #383838;
}

.formDiv9 {
  cursor: pointer;
  margin-left: 12px;
  width: 113px;
  height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  border-radius: 999px;
  opacity: 1;
  border: 1px solid #3D2A56;
}

.formDiv2 {
  cursor: pointer;
  margin-left: 12px;
  width: 139px;
  height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  background-color: #9931F8;
  border-radius: 36px 36px 36px 36px;
  opacity: 1;
  border: 1px solid #9931F8;
}

.formLabel {
  display: inline-block;
  width: 30px;
  font-size: 18px;
  color: #889aac;
  text-align: right
}


.spi_list {
  margin: auto;
  font-size: 13px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #3D2A56;
  line-height: 13px;
}

.spi_list1 {
  margin: auto;
  font-size: 13px;
  font-family: Inter-Regular, Inter;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 13px;
}

.row {
  padding-left: 88px;
  padding-right: 88px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ymdiv {
  width: 304px;
  height: 262px;
  margin: 24px 12px;
  cursor: pointer;
  position: relative;


  & .icon {
    position: absolute;
    opacity: 0;
    pointer-events: auto;
  }

  & .icon_true {
    position: absolute;
    opacity: 1;
  }

  & .icon_felse {
    position: absolute;
    opacity: 0;
    pointer-events: auto;
  }

  &:not(.selecting):hover {
    /*  transform: translateY(-1px);
      filter: drop-shadow(0px 4px 4px rgba(48, 48, 48, 0.15));*/

    & .check-box,
    & .icon {
      width: 223px;
      height: fit-content;
      background-color: #333333;
      opacity: 0.7;
      pointer-events: auto;
    }

    & .icon_felse {
      transform: translateY(-1px);
      filter: drop-shadow(0px 4px 4px rgba(48, 48, 48, 0.15));
      width: 223px;
      height: fit-content;
      opacity: 1;
      pointer-events: auto;
    }


  }

}


.outer_data {
  margin-left: 88px;
  margin-right: 88px;
  height: 52px;
  margin-bottom: 10px;
  background-color: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #FFFFFF;
  transition: 0.2s ease;

  & .icon {
    opacity: 0;
  }

  & .icon_true {
    opacity: 1;
  }


  &:not(.selecting):hover {
    transform: translateY(-1px);
    filter: drop-shadow(0px 4px 4px rgba(48, 48, 48, 0.15));

    & .icon {
      opacity: 1;

    }

  }
}

.image_div_tuer {
  position: absolute;
  width: 304px;
  height: 262px;
  background: #F2F2F2;
  border-radius: 12px;
  opacity: 1;
}

.imeg_tile {
  font-size: 16px;
  font-family: Inter-Regular, Inter;
  font-weight: 500;
  color: #404756;
  line-height: 16px;
}

.imeg_tile_div {
  width: fit-content;
  padding: 8px;
  color: #AFAFAF;
  background: #F2F2F2;
  border-radius: 999px;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
}

.span_tile_name {
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #989898;
  line-height: 12px;
}

.span_tile_cancel {
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 16px;
}

.span_tile_name1 {
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #404756;
  line-height: 14px;
}

.action-bar {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  width: 100vw;
  height: 44px;
  background-color: #3D2A56;
  z-index: 99;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.2s ease;
  overflow-y: hidden;

  & p {
    color: white;
    font-weight: 700;
  }

  & .button {
    border: 1px solid white;
    margin-left: 20px;
    padding: 10px 20px;
    cursor: pointer;
  }

  &.show {
    transform: translateX(-50%) translateY(0%);
  }
}

.div_action {
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
}

.span_action {
  width: auto;
  height: auto;
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 16px;
}

.iiem_popover {
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: rgba(228, 93, 109, 1);
  height: 41px;
  width: fit-content;
  padding-right: 16px;
}

.span_item_popover_delete {
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 16px;
}

.my_boards_imgs {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.span_export {
  margin-left: 8px;
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #9931F8;
  line-height: 14px;
}

.span_delete {
  margin-left: 8px;
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #E44356;
  line-height: 14px;
}

.div_img_sio {
  position: relative;
  height: 234px;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 12px;

  img {
    height: 100%;
    width: 100%;
    border-radius: 12px;
    object-fit: cover;
    background-repeat: no-repeat;
    position: relative;
  }

  img::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 12px;

  }
}

.div_img_sio_div {
  margin: 8px 0 0 8px;
  width: 140px;
  height: 105px;
  background: #F4F4F4;
  border-radius: 8px;
}


.div_board {
  display: flex;
  width: 66px;
  height: 66px;
}

.div_board img {
  margin: auto;
  width: 24px;
  height: 24px;
}


</style>
