<template>
  <el-dialog
      :modal-append-to-body="true"
      custom-class="classdois"
      title=""
      :show-close="false"
      width="328px"
      :visible.sync="exist"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
  >
    <div class="flex-col div_forgot">
      <div class="flex-row title_div justify-between">
        <span class="title_span">File Uploading...</span>
      </div>
      <div class="flex-col" style="padding: 16px">
        <img
            alt=""
            class="icon4"
            referrerpolicy="no-referrer"
            src="@/assets/icon/ic_loading.svg"
        />
        <el-progress style="margin:32px auto; width: 124px;" :percentage="percentage" :color="'#9931F8'"
                     :define-back-color="'#ECECEC'" status="exception" :show-text="false"></el-progress>
        <span class="soan_file">Please wait until it completes.</span>
      </div>
      <button class="button_gotit" @click="cancel()">Cancel</button>
    </div>
  </el-dialog>
</template>

<script>
import {getToken} from "@/utils/auth";
import {baseUrl, fileUrl} from '/env'
import axios from "axios";
import {museaFile} from "@/api/login";

export default {
  name: 'uploading',
  props: ['projecData'],
  data() {
    return {
      exist: true,
      percentage: 0
    }
  },
  created() {
    this.selectFile()
    $bus.on('progress', data => {
      this.percentage = data
    })
  },
  methods: {
    Close() {
      this.$emit('chickBack')
    },

    async selectFile() {
      this.isupfile = 1
      this.tilename = "Importing data"
      let that = this
      let inputFile = this.projecData
      // let baseUrl = 'http://172.16.32.166:8080';
      let fn = new FormData()
      fn.append('file', inputFile)
      let cancelToken = axios.CancelToken;//Axios使我修改axios原型链了。
      that.source = cancelToken.source();

      await axios({
        method: 'POST',
        url: `${fileUrl}/prod-api/common/upload`,
        data: fn,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
          "Authorization": getToken()
        },
        onUploadProgress: function (progressEvent) {
          let pos = parseInt(((progressEvent.loaded / progressEvent.total) * 100) | 0, 10)
          $bus.emit('progress', parseInt(pos.toFixed(0).toString()))
        }

      }).then(respons => {
        console.log(respons.data.code)
        if (respons.data.code === 200) {
          this.uploadMuseFile(respons.data)
        } else {
          this.Close()
        }
      }).catch(reason => {
      })

    },
    uploadMuseFile(obj) {
      let typeList = ['avi', 'm4v', 'mov', 'mp4','MP4', 'MOV', 'AVI', 'M4V']
      let typeList1 = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG', 'gif', 'GIF', 'BMP','bmp']
      let Filename = obj.originalFilename.split('.')
      let fliterType = this.flitersFilesType(Filename[Filename.length - 1], typeList)
      let fliterType1 = this.flitersFilesType(Filename[Filename.length - 1], typeList1)
      let fileType = ''
      if (fliterType) {
        fileType = '0'
      } else {
        if (fliterType1) {
          fileType = '1'
        } else {
        }
      }
      if (fileType !== '') {
        let dataInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        let data = {
          "userId": dataInfo.userId,
          "fileName": obj.originalFilename,
          "fileType": fileType,
          "fileSize": this.projecData.size,
          "filePath": obj.url
        }
        museaFile(data).then(postMessage => {
          this.Close()
          if (postMessage.code === 200) {
            this.$showfileUplade({})
          } else {
            this.$showErrorOccured({msg:[
                'Please make sure you have enough cloud',
                'storage in your account for these files.']})
          //  this.$message.error('Unsupported upload file format')
          }
        })
      }


    },

    cancel() {
      let that = this;
      if (that.source) {//先判断soucre是否存在，因为如果我打开弹框不作任何操作，点击取消按钮没有这一层判断的话，that.source.cancel(‘取消上传’);会报错。
        this.exist = true
        this.isDelet = false
        this.$emit('chickBack')
        that.source.cancel('取消上传');//”取消上传”这几个字，会在上面catch()的error中输出的，可以console看一下。
      }
    },
  }
}

</script>

<style scoped>
/deep/ .el-dialog {
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 0 0 rgba(255, 255, 255, 0);
}

/deep/ .el-dialog__wrapper {
  background-color: rgba(242, 242, 242, 1);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  transition: all 0.2s ease-out;

}

.content /deep/ .el-dialog__header {
  display: none;
}

.content /deep/ .el-dialog__body {
  padding: 0;
}

/deep/ .el-dialog.classdois {
  border-radius: 5px;
}

</style>
<style lang="scss" scoped>


.title_div {
  padding-left: 16px;
  border-bottom: 1px solid #FFFFFF;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding-right: 16px;
  background-color: #FFFFFF;
  width: auto;
  height: 44px;
}


.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title_span {
  width: 100%;
  height: auto;
  font-size: 16px;
  font-family: Inter-SemiBold, Inter;
  color: #3D2A56;
  line-height: 20px;
}

.button_gotit {
  margin: auto;
  cursor: pointer;
  width: 120px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 5px;
  color: #C0BFC0;
  font-family: Inter-SemiBold, Radikal;
  font-size: 13px;
  border: 1px solid #C0BFC0;
  border-radius: 999px;
}


.div_forgot {
  width: 328px;
  height: 361px;
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.1);

}

.icon2 {
  cursor: pointer;
  width: 12px;
  height: 12px;
}

.span_up_add {
  text-align: center;
  width: 100%;
  font-size: 14px;
  font-family: Radikal-Regular, Radikal;
  font-weight: 400;
  color: #959595;
  line-height: 18px;
}

.span_for_mag {
  display: flex;
  margin: auto;
  font-size: 13px;
  text-align: center;
  font-weight: 400;
  color: #959595;
  line-height: 17px;
  font-family: Radikal-Regular, Radikal;
}


//旋转动画
@-webkit-keyframes mymove {
  from {
    transform: rotate(10deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes mymove {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icon4 {
  width: 130px;
  height: 130px;
  animation: mymove 1ms infinite;
  -webkit-animation: mymove 500ms infinite;
}

.soan_file {
  margin: auto;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #1E1E1E;
  line-height: 19px;
}

.icon5 {
  width: 130px;
  height: 130px;
}

</style>