import MsgBox from '@/erredpop/index.vue'
let ConfirmConstructor, instance

const showMsgBox = {
    install(Vue) {
        ConfirmConstructor = Vue.extend(MsgBox)
        instance = new ConfirmConstructor().$mount()
        document.body.appendChild(instance.$el)

        Vue.prototype.$showMsgBox = options => {
            Object.assign(instance, options)
            instance.init()
        }
    }
}

export default showMsgBox