import fileUplade from "@/erredpop/FileUplade.vue";

let ConfirmConstructor, instance
const showfileUplade = {
    install(Vue) {
        ConfirmConstructor = Vue.extend(fileUplade)
        instance = new ConfirmConstructor().$mount()
        document.body.appendChild(instance.$el)
        Vue.prototype.$showfileUplade = options => {
            Object.assign(instance, options)
            instance.init()
        }
    }
}
export default showfileUplade
