
import {Message} from "element-ui";

let cptTimer = {}


export function pollingRefresh(uuid, cptDataForm, loadData){
    if(uuid) {
        clearInterval(cptTimer[uuid]);//清除旧的定时器
    }
    if (!cptDataForm){
      //  Message.warning("cptDataForm==>null");
        return;
    }
    if (!loadData){
    //    Message.warning("子组件未实现数据解析方法");
        return;
    }
    loadData();
    if (cptDataForm.pollTime && cptDataForm.pollTime !== 0) {//轮询
        cptTimer[uuid] = setInterval(function () {
            loadData();
        }, cptDataForm.pollTime * 1000)
    }
}

