<template>
  <div :key="GeneralViod" :style="{zIndex:option.cptOption.attribute.zIndex}">
    <div v-if="isVideo" style="width: 100%; height: 100%">
      <img :width="option.cptWidth"
           :height="option.cptHeight"
           style="position: relative; display: inline-block;overflow: hidden;"
           :id="option.cptOption.id" crossOrigin="anonymous" alt="img"

           :style="{zIndex:option.cptOption.attribute.zIndex}"
           :src="url? url: require('@/assets/imageins.svg')"
           :fit="option.cptOption.attribute.fit"/>
    </div>
    <video v-else id="example_video_1" controls preload="auto" ref="videoPlayer" crossorigin="anonymous" style="object-fit:fill;"
           @ended="onVideoEnded"
           :width="option.cptWidth"
           :height="option.cptHeight" autoplay="autoplay" muted
           :poster="videoImg" :src="url">
    </video>
  </div>
</template>

<script>
import {pollingRefresh} from "@/utils/refreshCptData";
export default {
  name: 'templaylist',
  props: {
    option: Object
  },
  data() {
    return {
      url: '',
      GeneralViod: Date.now(),
      videoImg: require('@/assets/icon/video.svg'),
      uuid: null,
      isVideo: true,
      curr: 0
    }
  },
  created() {
    // this.setOneOf()
    this.uuid = require('uuid').v1();
    console.log('=======templaylist========>')
    console.log(this.option)
    console.log('===============>')
    $bus.on('sigPlaylist', data => {

      if (data === 1) {
        this.curr = 0
        this.isVideo = this.option.cptOption.attribute.urlist[this.curr].fileType === '1'
        this.url = this.option.cptOption.attribute.urlist[this.curr].filePath
        if (this.isVideo) {
          this.setViewImag()
        } else {
          this.GeneralViod = Date.now()
        }
      }
    })
  },
  mounted() {
    this.setOneOf()
  },
  methods: {
    refreshCptData() {
      pollingRefresh(this.uuid, this.option, this.loadData)
    },
    loadData() {
      this.cptData = JSON.parse(this.option.cptOption.attribute)
    },
    onVideoEnded() {
      // 在这里执行你想要的操作
      if (this.curr < this.option.cptOption.attribute.urlist.length ) {

        this.isVideo = this.option.cptOption.attribute.urlist[this.curr].fileType === '1'
        if (this.isVideo) {
          this.url = this.option.cptOption.attribute.urlist[this.curr].filePath
          ++this.curr
          this.setViewImag()
        } else {
          this.url = this.option.cptOption.attribute.urlist[this.curr].filePath
          ++this.curr
          this.GeneralViod = Date.now()

        }
      } else {
        this.curr = 0
        this.url = this.option.cptOption.attribute.urlist[this.curr].filePath
        this.isVideo = this.option.cptOption.attribute.urlist[this.curr].fileType === '1'
        this.setViewImag()
      }
    },
    setOneOf() {
      if (this.curr < this.option.cptOption.attribute.urlist.length) {

        this.isVideo = this.option.cptOption.attribute.urlist[this.curr].fileType === '1'
        if (this.isVideo) {
          this.url = this.option.cptOption.attribute.urlist[this.curr].filePath
          this.GeneralViod = Date.now()
          ++this.curr

          this.setViewImag()
        } else {
          this.url = this.option.cptOption.attribute.urlist[this.curr].filePath
          this.GeneralViod = Date.now()
          ++this.curr

        }
      } else {
        this.curr = 0
        this.url = this.option.cptOption.attribute.urlist[this.curr].filePath
        this.isVideo = this.option.cptOption.attribute.urlist[this.curr].fileType === '1'
        this.setViewImag()
      }
    },
    setViewImag() {
      setTimeout(this.setOneOf, this.option.cptOption.attribute.Interval * 1000)
    }
  },
  watch: {
    option: {
      handler(o, v) {

      },
      deep: true
    }
  },

}
</script>

<style scoped>

</style>