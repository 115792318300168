<template>
  <div class="flex-row outer_data align-between">
    <div class="flex-row align-between  ">
      <div style="width: 52px;">
        <el-checkbox v-model="source.ischeck" @change="selestDevice(source)"></el-checkbox>
      </div>
      <div style="width: 84px;">
        <img :width="84" :height="64" :src="source.boardThumbnail"></div>
      <div class="flex-col" style="margin-left: 12px;">
        <p class="boardname">{{ source.boardName }}</p>

        <div class="flex-row" style="margin-top: 10px;">
          <div style="width: 43px;" class="screendiv">{{ Aspectratio[parseInt(source.aspectRadio)].label }}</div>
          <div style="width: 83px;margin-left: 8px;" class="screendiv">{{ source.screenSize }}</div>
        </div>


      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    index: { // 每一行的索引
      type: Number
    },
    source: { // 每一行的内容
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      Aspectratio: [
        {value: '9:16', label: '9:16', id: 0},
        {value: '3:4', label: ' 3:4', id: 1},
        {value: '2:3', label: ' 2:3', id: 2}
      ],
    }
  },

  methods: {
    selestDevice(obj) {
      $bus.emit('AddBoardDia', {code: 1, data: obj})
    }
  }

}
</script>

<style scoped lang="scss">
.justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

::v-deep .el-checkbox {
  margin-left: 16px;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #303030;

  .el-checkbox__inner {
    width: 22px;
    height: 22px;
    border-radius: 50%;

    border: 1px solid rgba(149, 149, 149, 1);
    //修改选中框中的对勾的大小和位置
    &::after {

      top: 3px;
      height: 12px;
      left: 8px;
    }
  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    border: 1px solid #9931F8;
    background-color: #9931F8;
  }

  .el-checkbox__label {
    display: inline-grid;
    color: #FFFFFF;
    width: 200px;
    background-color: #9931F8;

  }

  //修改点击文字颜色不变
  .el-checkbox__input.is-checked + .el-checkbox__label {
    border: 1px solid #9931F8;
    background: #9931F8;
    color: #303030;

  }


}

.align-between {
  display: flex;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.outer_data {
  width: 390px;
  height: 64px;
  margin-bottom: 16px;
  border: 1px solid #FFFFFF;
  background-color: #FFFFFF;
  transition: 0.2s ease;

  &:not(.selecting):hover {
    transform: translateY(-1px);
    filter: drop-shadow(0px 4px 4px rgba(48, 48, 48, 0.15));

    & .check-box,
    & .icon {
      width: 223px;
      height: fit-content;
      background-color: #333333;
      opacity: 0.7;
      pointer-events: auto;
    }

    & .icon_felse {
      width: 223px;
      height: fit-content;
      opacity: 1;
      pointer-events: auto;
    }


  }

}

.span_name_tile_name {
  cursor: pointer;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #404756;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.span_name {
  margin: auto 0;
  cursor: pointer;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #404756;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.span_name_tile_name {
  margin: auto 0;
  cursor: pointer;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #404756;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.screendiv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 999px;
  border: 1px solid #E0E1DD;
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  color: #C0BFC0;
}

.boardname {
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #1E1E1E;
  line-height: 14px;
}

</style>