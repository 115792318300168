<template>
  <div class="flex-col">
    <el-row class="top">
      <div class="flex-row justify-between">
        <div style=" display: flex; flex-direction: row;">
          <div style="background: rgba(64, 71, 86, 1);width: 54px;height: 54px;display: flex;cursor: pointer; "
               @click="goBack()">
            <img style="margin: auto;" :src="require('@/assets/icon/600.svg')"/>
          </div>
          <div style="width: 100px;height: 54px;display: flex;">
            <span class="span_tile">Go Back</span>
          </div>
        </div>
        <div class="flex-row" v-if="false" >
          <div class="flex-row div_tile_r" @click="handleUndo()">
            <div class="svgicon">
              <img v-bind:class="{img_svg1:historyData.length > 0 ,img_svg0:!historyData.length > 0}"
                   :src="require('@/assets/icon/Undo.svg')">
            </div>
            <p v-bind:class="{tile_span_r:historyData.length > 0,tile_span_r_false:historyData.length<1}" >Undo</p>
          </div>
          <div style="height: 17px;width: 1px;background-color: #C0BFC0;margin-left: 8px;margin-right: 8px;"></div>
          <div class="flex-row div_tile_r" @click="handleRedo()">
            <div class="svgicon">
              <img v-bind:class="{img_svg1:RedoData.length > 0 ,img_svg0:!RedoData.length > 0}"
                   :src="require('@/assets/icon/Redo.svg')">
            </div>
            <p v-bind:class="{tile_span_r:RedoData.length > 0,tile_span_r_false:RedoData.length<1}" >Redo</p>
          </div>
        </div>
        <div class="flex-row" v-if="seveItme === 0">
          <div class="flex-row" style="width: 50px;height: 54px;cursor: pointer;" @click="setdrawer">
            <div style="display: flex;align-content: center;margin: auto;">
              <img style="width: 18px;height: 18px;" :src="require('@/assets/icon/Relationaldiagram.svg')">
            </div>
          </div>
          <div class="flex-row" style="width: 50px;height: 54px;cursor: pointer;" @click="preview">
            <div style="display: flex;align-content: center;margin: auto;">
              <img style="width: 18px;height: 18px;" :src="require('@/assets/icon/iconpreview.svg')">
            </div>
          </div>
          <div class="flex-row" style="width: 150px;background: #0FBF19;height: 54px;cursor: pointer;"
               @click="seveStack()">
            <div style="display: flex;align-content: center;margin: auto;">
              <img style="width: 18px;height: 18px;" :src="require('@/assets/icon/save.svg')">
              <span class="span_seve">Save Stack</span>
            </div>
          </div>
        </div>

      </div>
    </el-row>
    <div v-loading="loading" element-loading-text="Saving....">
      <div class="flex-row " style="overflow-y: hidden;" v-if="seveItme === 0">
        <div style="float: left;height: 100%;overflow: hidden;min-width: 360px;z-index: 998;">
          <Componentleft :designData.sync="StackData" @setView="setView" ref="compleft"/>
        </div>
        <div class="flex-col" style="display: flex;align-items: center;" v-if="isTouch">
          <div :style="{width:windowWidth+'px',height:windowHeight+'px'}" ref="webContainer"
               style="position: relative;transform:scale(0.85)">
            <div style="position:absolute;z-index: 999;"
                 :style="{width:windowWidth+'px',height:conHeight+'px'}">
              <div class="webContainer" style="border-radius: 8px ;position:absolute;"
                   :style="{width:windowWidth+'px',height: conHeight+'px'}"
                   id="myElement" ref="webContainer"
                   @dragover="allowDrop" @drop="drop" @click.self="outBlur">
                <div v-for="(item,index) in cacheComponents" :key="item.id"
                     class="cptDiv" :style="{width:Math.round(item.cptWidth)+'px', height:Math.round(item.cptHeight)+'px',
                  top:Math.round(item.cptY)+'px',left:Math.round(item.cptX)+'px',
                  zIndex: currentCptIndex === index ? 1800 : item.cptZ }" :ref="'div'+item.cptKey+index"
                     @mousedown="showConfigBar($event,item,index)" tabindex="0">
                  <div v-show="currentCptIndex === index"
                       style="position: fixed;border-top: 1px dashed #8A8A8A;width: 100%;left:0"/><!--顶部辅助线-->
                  <div v-show="currentCptIndex === index"
                       style="position: fixed;border-right: 1px dashed #8A8A8A;height:100%;top:0"/><!--左侧辅助线-->
                  <!-- 2021-12-28新增iframe组件，防止焦点聚焦在iframe内部，添加此蒙版 -->
                  <div v-resize="'move'" class="activeMask"
                       :style="cacheChoices[item.id] ? {border:'3px solid #9931F8'}:{}">
                    <div class="flex-row top_div_tile" v-if="cacheChoices[item.id] ">
                      <div class="flex-row" style="margin: auto">
                        <img style="width: 16px;height: 16px;margin-right: 6px;"
                             :src="require('@/assets/icon/interatouchfff.svg')">
                        <span class="span_tile_name">Touch</span>
                      </div>
                    </div>
                  </div>
                  <div style="width: 100%;height: 100%;">
                    <component :is="item.cptKey" :ref="item.cptKey+index" :width="Math.round(item.cptWidth)"
                               :height="Math.round(item.cptHeight)" :option="item.cptOption"/>
                  </div>

                  <div v-show="currentCptIndex === index"
                       style="top: 0;left: 0;cursor: se-resize;transform: translate(-50%, -50%)"
                       class="resizeTag" v-resize="'lt'"/>
                  <div v-show="currentCptIndex === index"
                       style="top: 0;left: 50%;cursor: s-resize;transform: translate(-50%, -50%)"
                       class="resizeTag" v-resize="'t'"/>
                  <div v-show="currentCptIndex === index"
                       style="top: 0;right: 0;cursor: ne-resize;transform: translate(50%, -50%)"
                       class="resizeTag" v-resize="'rt'"/>
                  <div v-show="currentCptIndex === index"
                       style="top: 50%;right: 0;cursor: w-resize;transform: translate(50%, -50%)"
                       class="resizeTag" v-resize="'r'"/>
                  <div v-show="currentCptIndex === index"
                       style="bottom: 0;right: 0;cursor: se-resize;transform: translate(50%, 50%)"
                       class="resizeTag" v-resize="'rb'"/>
                  <div v-show="currentCptIndex === index"
                       style="bottom: 0;left: 50%;cursor: s-resize;transform: translate(-50%, 50%)"
                       class="resizeTag" v-resize="'b'"/>
                  <div v-show="currentCptIndex === index"
                       style="bottom: 0;left: 0;cursor: ne-resize;transform: translate(-50%, 50%)"
                       class="resizeTag" v-resize="'lb'"/>
                  <div v-show="currentCptIndex === index"
                       style="top: 50%;left: 0;cursor: w-resize;transform: translate(-50%, -50%)"
                       class="resizeTag" v-resize="'l'"/>
                </div>
              </div>
            </div>
            <div style="position:absolute;z-index: 998;"
                 :style="{width:windowWidth+'px',height:conHeight+'px',background:designCache.bgColor}">
              <div v-for="item in designCache.components" :key="item.id"
                   style="position: absolute;"
                   :style="{width:Math.round(item.cptWidth)+'px',
                  height:Math.round(item.cptHeight)+'px',
                  top:Math.round(item.cptY)+'px',
                  left:Math.round(item.cptX)+'px',
                  zIndex: item.cptZ }">
                <component :is="item.cptKey" :width="Math.round(item.cptWidth)"
                           :height="Math.round(item.cptHeight)" @reload="loadCacheData"
                           :option="designCache.viewCode ==='0' ?   item :item.cptOption"/>
              </div>

            </div>
          </div>
        </div>
        <button-comp-view v-if="isTouch" ref="configBar" @dragStart="dragStart" :selectedComponents="designCache"
                          :currentCpt.sync="BoardDiaData"
                          style="z-index: 1999;position: absolute;bottom: 16px;left: 30%;"
                          @copyCpt="copyCpt" @delCpt="delCpt" @modifyCpy="modifyCpy"/>
      </div>
      <div style="overflow-y: hidden; display: flex;position: relative;" v-if="seveItme === 1">
        <div class="flex-col" style="margin: auto;">
          <span class="span_tile_board" style="margin-top: 195px;">Stacks  Saved</span>
          <span class="span_boadrd">{{ StackData.title }}</span>
          <div style="width: 1px;height: 88px;background-color: #E0E1DD;margin: 64px auto ;"></div>
          <span class="span_tile_board">Next Step</span>
          <div class="flex-row">
            <div class="flex-row" style="cursor: pointer;" @click="isSelectdata = true">
              <div
                  style="width: 50px;height: 54px;border-radius: 50%;border: 2px solid #9931F8;background-color: #FFFFFF;display: flex;">
                <img style="margin: auto;" :src="require('@/assets/icon/iconpushdevice.svg')">
              </div>
              <span class="span_iles">Push To Device</span>
            </div>
            <div class="flex-row" style="margin-left: 120px;cursor: pointer;" @click="handleGenTable()">
              <div
                  style="width: 50px;height: 54px;border-radius: 50%;border: 2px   solid #9931F8;background-color: #FFFFFF;display: flex;">
                <img style="margin: auto;" :src="require('@/assets/icon/iconcompress.svg')">
              </div>
              <span class="span_iles">Export As .zip</span>
            </div>
          </div>

        </div>

      </div>
    </div>
    <AddBoardDia v-if="isSelect" :options.sync="BoardDiaData" v-on:chickBack="chickBack"/>

    <select-device v-if="isSelectdata" :projec-data="{code:1,id: StackData.id}" v-on:chickBack="chickBack"/>
    <el-drawer
        :title="'Touch Area'"
        :visible.sync="drawer"
        :direction="direction"
        :before-close="handleClose">
      <div style="padding-top: 24px;">
        <div v-for="(itme,index) in rentCptData">
          <div class="flex-row outer_divitme" @click="handledComopent(itme)">

            <div class="flex-row" v-if="itme.type === 1">
              <div class="flex-row div_button">
                <img :width="84" :height="64" :src="itme.oneData.boardThumbnail">
                <div class="flex-col">
                  <p>{{ itme.oneData.boardName }}</p>
                </div>
              </div>
              <img style="margin-left: 20px;margin-right: 20px;"
                   :src="require('@/assets/icon/icon_interactiongoo_board.svg')">
              <div class="flex-row div_button">
                <img :width="84" :height="64" :src="itme.Jump.boardThumbnail">
                <div class="flex-col">
                  <p>{{ itme.Jump.boardName }}</p>
                </div>
              </div>
            </div>
            <div class="flex-row" v-if="itme.type ===0">
              <div class="div_button"></div>
              <img style="margin-left: 20px;margin-right: 20px;"
                   :src="require('@/assets/icon/iconinteractiongoback.svg')">
              <div class="flex-row div_button">
                <img :width="84" :height="64" :src="itme.oneData.boardThumbnail">
                <div class="flex-col">
                  <p>{{ itme.oneData.boardName }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </el-drawer>

  </div>
</template>

<script>
import Componentleft from "@/views/createstack/Componentleft.vue";
import AddBoardDia from "@/popover/AddBoardDia.vue";
import ButtonCompView from "@/views/createstack/ButtonCompView.vue";
import Leftcombar from "@/views/freeboard/LeftComBar.vue";
import env, {fileUrl} from "../../../env";
import {getHtml, getVUeData} from "@/utils";
import axios from "axios";
import {getToken} from "@/utils/auth";
import {addstack, putstack} from "@/api/login";
import SelectDevice from "@/popover/SelectDevice.vue";

export default {
  components: {SelectDevice, Leftcombar, ButtonCompView, AddBoardDia, Componentleft},
  data() {
    return {
      isSelect: false,
      seveItme: 0,
      loading: false,
      cptBarWidth: 360,
      configBarWidth: 0,
      fileUrl: env.fileUrl,
      StackData: {
        id: '', title: 'New Stacks', scaleX: 1280, scaleY: 800, version: '', checkAll: false, Playinterval: 10
      },
      designData: {
        id: '', title: 'New Board', scaleX: 1280, scaleY: 800, version: '', components: []
      },
      cacheComponents: [],
      CompData: [],
      designCache: {},
      windowWidth: 1280,
      windowHeight: 800,
      conHeight: 0,
      containerScale: 0.75,
      authCodeDialogVisible: false,
      viewCode: '',
      TouchData: {},
      isTouch: false,
      cacheChoices: {},
      currentCptIndex: -1,
      BoardDiaData: [],
      cacheChoicesFixed: {},//记录移动前选中组件的位置 自定义事件内部无法处理，放在了外面。
      EditorID: 0,
      isSelectdata: false,
      historyData: [],
      RedoData: [],
      drawer: false,
      direction: 'rtl',
      rentCptData: []

    }
  },
  created() {
    var ther = this
    $bus.on('createstack', data => {
      switch (data.code) {
        case 0:
          ther.BoardDiaData = data.data
          ther.$refs.compleft.modifyData(data.data)
          ther.isSelect = false
          break
        case 1:
          this.BoardDiaData = data.data
          this.isSelect = true
          break
      }
    })

  },
  mounted() {
    let datalist = this.$route.query.data
    if (datalist) {
      const design = JSON.parse(datalist)
      this.EditorID = 1
      this.StackData.id = design.stackId
      const screenSize = design.screenSize.split('')
      this.designData.scaleX = screenSize[0]
      this.designData.scaleY = screenSize[1]
      this.StackData.title = design.stackName
      const autoPlay = design.autoPlay === '1'
      const playInterval = parseInt(design.playInterval)
      this.StackData.checkAll = autoPlay

      this.StackData.Playinterval = playInterval
      //  $bus.emit("Componentleft", {code: 0, data: design.sourceInfo})
      design.sourceInfo.forEach(it => {
        it.ischeck = true
      })
      this.BoardDiaData = design.sourceInfo
      this.$refs['compleft'].modifyData(design.sourceInfo)

    }

  },
  methods: {
    preview() {
      //  this.designData.components = this.cacheComponents;
      console.log('-------------------------')
      console.log(this.StackData)
      console.log('-------------------------')
      localStorage.setItem('designCache', JSON.stringify({
        autoPlay: this.StackData.checkAll,
        playInterval: this.StackData.Playinterval,
        currentCpt: this.$refs.compleft.$data.currentCpt
      }));

      let routeUrl = this.$router.resolve({
        path: "/stacksPreview"
      });
      console.info(this.$refs.compleft.$data)
      window.open(routeUrl.href, '_blank');
    },
    goBack() {
      this.$router.go(-1); // 返回上一个页面

    },

    seveStack() {
      this.BoardDiaData = this.$refs.compleft.$data.currentCpt
      this.jsonHtml()
    },

    async jsonHtml() {
      this.loading = true
      console.log(this.StackData)
      let data = {
        autoPlay: this.StackData.checkAll,
        playInterval: this.StackData.Playinterval,
        scaleX: this.StackData.scaleX,
        scaleY: this.StackData.scaleY,
        currentCpt: this.$refs.compleft.$data.currentCpt
      }
      const StringVueData = getVUeData(data)
      const htmldata = getHtml(this.designData, StringVueData)
      let fn = new FormData()
      fn.append('htmlData', htmldata)
      fn.append('thumbnailData', '')
      await axios({
        method: 'POST',
        url: `${fileUrl}/prod-api/common/genHtmlAndThumbnail`,
        data: fn,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
          "Authorization": getToken()
        },
      }).then(postMessage => {
        this.seveHtml(postMessage.data)
        //  console.log(postMessage)
      })

    },
    seveHtml(data) {
      if (this.EditorID === 0) {
        addstack({
          stackName: this.StackData.title,
          screenSize: this.StackData.scaleX + 'X' + this.StackData.scaleY,
          autoPlay: this.StackData.checkAll ? '1' : '0',
          playInterval: this.StackData.Playinterval.toString(),
          htmlPath: data.htmlUrl,
          stackThumbnail: data.thumbnailUrl,
          sourceInfo: JSON.stringify(this.$refs.compleft.$data.currentCpt)
        }).then(postMessage => {
          if (postMessage.code === 200) {
            this.StackData.id = postMessage.id
            this.loading = false
            this.seveItme = 1
          }
        })

      } else {
        putstack({
          stackId: this.StackData.id,
          stackName: this.StackData.title,
          screenSize: this.StackData.scaleX + 'X' + this.StackData.scaleY,
          autoPlay: this.StackData.checkAll ? '1' : '0',
          playInterval: this.StackData.Playinterval.toString(),
          htmlPath: data.htmlUrl,
          stackThumbnail: data.thumbnailUrl,
          sourceInfo: JSON.stringify(this.$refs.compleft.$data.currentCpt)
        }).then(postMessage => {

          if (postMessage.code === 200) {
            this.loading = false
            this.seveItme = 1
          }
        })
      }
    },

    chickBack() {
      this.isSelect = false
      this.isSelectdata = false
    },
    OnClisk(e) {

    },
    loadCacheData() {
      let designCache = JSON.parse(localStorage.getItem('designCache'));
      this.loadDesign(designCache, false);
    },
    loadDesign(design, componentPares) {
      if (componentPares) {
        design.components = JSON.parse(design.components);
      }
      document.title = design.title;
      this.designCache = design;
      this.loadSize(design);
    },
    loadSize(designCache) {

      // let designCache = JSON.parse(localStorage.getItem('designCache'));
      this.windowWidth = designCache.scaleX;
      this.windowHeight = designCache.scaleY;
      this.containerScale = Math.round(this.windowWidth / this.designCache.scaleX * 100) / 100
      this.conHeight = this.designCache.scaleY
    },
    setView(data) {

      this.loadDesign({}, false);
      this.cacheComponents = []
      this.isTouch = false

      if (data.ischeck) {
        // this.TouchData = {}
        this.loadDesign({}, false);
        this.cacheComponents = []
        this.isTouch = false
      } else {
        let scale = data.screenSize.split('X')
        let designData = {
          id: '',
          title: data.boardName,
          scaleX: scale[0],
          scaleY: scale[1],
          version: '',
          bgColor: data.boardBackground,
          simpleDesc: '',
          bgImg: '',
          viewCode: data.boardType,
          components: JSON.parse(data.sourceInfo).sources
        }
        this.cacheComponents = data.cacheComponents
        this.loadDesign(designData, false);
        //  this.TouchData = data
        this.isTouch = true
        this.BoardDiaData = this.$refs.compleft.$data.currentCpt

      }
      this.$refs['configBar'].OnClinseItme(0)
    },
    setToteView(data) {
      this.drawer = false
      this.loadDesign({}, false);
      this.cacheComponents = []
      this.isTouch = false

      let scale = data.screenSize.split('X')
      let designData = {
        id: '',
        title: data.boardName,
        scaleX: scale[0],
        scaleY: scale[1],
        version: '',
        bgColor: data.boardBackground,
        simpleDesc: '',
        bgImg: '',
        viewCode: data.boardType,
        components: JSON.parse(data.sourceInfo).sources
      }
      this.cacheComponents = data.cacheComponents
      this.loadDesign(designData, false);
      //  this.TouchData = data
      this.isTouch = true
      this.BoardDiaData = this.$refs.compleft.$data.currentCpt
      this.$refs['configBar'].OnClinseItme(0)
    },
    dragStart(copyDom) {//从组件栏拿起组件
      this.copyDom = copyDom;
      copyDom.draggable = false;
    },
    delCpt(cpt, index) {//删除组件
      let indexs = this.cacheComponents.findIndex(it => it.id === cpt.id)
      this.currentCpt = {};
      this.cacheComponents.splice(indexs, 1);
      this.$refs['configBar'].OnClinseItme(0)
      this.$refs['compleft'].DeleteData(cpt)

    },
    copyCpt(item) {
      let copyItem = JSON.parse(JSON.stringify(item))
      if (item.cptKey !== 'sigPlaylist') {
        copyItem.cptX = item.cptX + 30//复制的组件向右下偏移
        copyItem.cptY = item.cptY + 30
        copyItem.id = require('uuid').v1();
        this.cacheComponents.push(copyItem);
        this.currentCptIndex = this.cacheComponents.length - 1//聚焦到复制的组件
      } else {
        let Index = this.cacheComponents.findIndex(itme => itme.cptKey === 'sigPlaylist')
        if (Index === -1) {
          copyItem.cptX = item.cptX + 30//复制的组件向右下偏移
          copyItem.cptY = item.cptY + 30
          copyItem.id = require('uuid').v1();
          this.cacheComponents.push(copyItem);
          this.currentCptIndex = this.cacheComponents.length - 1//聚焦到复制的组件
        } else {
          this.$message.warning('Only one can be created PlayList')
        }
      }
    },
    drop(e) { //添加组件
      let config = JSON.parse(this.copyDom.getAttribute('config'));
      if (config.cptKey !== 'sigPlaylist') {
        let cpt = {
          cptTitle: config.name,
          icon: config.icon,
          icons: config.icons,
          cptKey: config.cptKey,
          cptOptionKey: config.cptKey + '-option',
          cptOption: config.option,
          cptX: Math.round(e.offsetX),
          cptY: Math.round(e.offsetY),
          cptZ: 100,
          cptWidth: config.width ? config.width : 400,
          cptHeight: config.height ? config.height : 300,
          id: require('uuid').v1(),
        }
        this.cacheComponents.push(cpt);
        this.cacheChoices = {}//多选清空
        this.showConfigBar({}, cpt, this.cacheComponents.length - 1)//丢下组件后刷新组件属性栏
        //   this.$refs['configBar'].showCptConfig();
        this.$refs['compleft'].addComponents(cpt)

      } else {
        let Index = this.cacheComponents.findIndex(itme => itme.cptKey === 'sigPlaylist')
        if (Index === -1) {
          let cpt = {
            cptTitle: config.name,
            icon: config.icon,
            cptKey: config.cptKey,
            icons: config.icons,
            cptOptionKey: config.cptKey + '-option',
            cptOption: config.option,
            cptX: Math.round(e.offsetX),
            cptY: Math.round(e.offsetY),
            cptZ: 100,
            cptWidth: config.width ? config.width : 400,
            cptHeight: config.height ? config.height : 300,
            id: require('uuid').v1(),
          }
          this.cacheComponents.push(cpt);
          this.cacheChoices = {}//多选清空
          this.showConfigBar({}, cpt, this.cacheComponents.length - 1)//丢下组件后刷新组件属性栏
          this.$refs['configBar'].showCptConfig();
        } else {
          this.$message.warning('Only one can be created PlayList')
        }
      }

    },
    //修改组件
    modifyCpy(e) {
      let indexs = this.cacheComponents.findIndex(it => it.id === e.id)
      //this.cacheComponents[indexs] =e
      this.$set(this.cacheComponents, indexs, e)
      this.$refs['configBar'].OnClinseItme(0)
    },
    allowDrop(e) {
      e.preventDefault()
    },
    showConfigBar(e, item, index) {//刷新属性栏数据，页面上拖动的组件执行click事件来更新组件的属性栏
      this.currentCpt = item;
      this.currentCptIndex = index;
      this.$refs['configBar'].OnClinseItme(1)
      /*    if (this.$refs['div' + item.cptKey + index]) {
            this.$refs['div' + item.cptKey + index][0].focus();//聚焦 用于多选移动
          }*/
      if (!e.ctrlKey) {//未按住ctrl键
        this.cacheChoices = {}
      }
      this.$refs['configBar'].showCptConfig(item);
      this.cacheChoices[item.id] = item
      this.cacheChoicesFixed[item.id] = JSON.parse(JSON.stringify(item))

      // $bus.emit('sigvideo', {id: 1})
    },
    outBlur() {//取消聚焦组件
      this.currentCptIndex = -1;
      this.currentCpt = {};
      this.cacheChoices = {}
      this.$refs['configBar'].OnClinseItme(0)
    },
    refreshCptData() {
      const refName = this.currentCpt.cptKey + this.currentCptIndex;
      if (!this.$refs[refName][0].refreshCptData) {
        //this.$message.warning('当前图层还未实现refreshCptData方法')
      } else {
        this.$refs[refName][0].refreshCptData();//刷新子组件数据，refs为组建名加index
      }
    },
    handleGenTable() {
      var curr = this.BoardDiaData
      let isexistence = true
      curr.forEach(item => {
        if (!item.isexistence) {
          isexistence = false
        }
      })
      console.log(this.StackData)
      let licenes = JSON.parse(sessionStorage.getItem('Licenselist'))
      let omse = licenes.filter(it => it.dataleft > 0 && it.licenseType === '4')
      if (omse.length < 1) {
        this.$showMsgBox({
          caption: 'License expired',
          icon: 'failed',
          msg: ['Your license for MUSEA has been expired.', 'Please consider to renew your license to','enjoy MUSEA features.'],
          button: 'Go Back',
          callback: (data) => {

          }
        })
      }else {
        if (isexistence) {
          this.$download.zip("/prod-api/system/stack/exportStack/" + this.StackData.id, "MuSeaBoard.zip");
        } else {
          this.$showExportError({
            callback: (data) => {
              if (data === 'yes') {

                this.$download.zip("/prod-api/system/stack/exportStack/" + this.StackData.id, "MuSeaBoard.zip");
              }
            }
          })
        }
      }



    },
    handleRedo() {
    },
    handleUndo() {
    },
    setdrawer() {
      this.rentCptData.splice(0, this.rentCptData.length)
      let currentCpt = this.$refs.compleft.$data.currentCpt
      let rentCpt = []
      currentCpt.forEach(it => {
        it.cacheComponents.forEach(itmes => {
          rentCpt.push({
            boardId: it.boardId,
            boardName: it.boardName,
            touchID: itmes.id,
            type: itmes.cptOption.attribute.type,
            oneData: it,
            Jump: currentCpt.findIndex(its => its.htmlPath === itmes.cptOption.attribute.url) === -1 ? '' : currentCpt[currentCpt.findIndex(itme => itme.htmlPath === itmes.cptOption.attribute.url)]
          })
        })
      })
      this.rentCptData = rentCpt
      this.drawer = !this.drawer
      console.log(this.rentCptData)
    },
    handleClose(done) {
      done();
    },
    handledComopent(e) {
      this.setToteView(e.oneData)
      $bus.emit('componentleft', {code: 0, data: e.oneData})
    }
  },
  directives: {
    resize(el, binding, vNode) {//组件拉伸，移动位置
      el.onmousedown = function (e) {
        const that = vNode.context;
        const scaleClientX = e.clientX / that.containerScale;
        const scaleClientY = e.clientY / that.containerScale;
        const rbX = scaleClientX - el.parentNode.offsetWidth;
        const rbY = scaleClientY - el.parentNode.offsetHeight;
        const ltX = scaleClientX + el.parentNode.offsetWidth;
        const ltY = scaleClientY + el.parentNode.offsetHeight;
        const disX = scaleClientX - el.parentNode.offsetLeft;
        const disY = scaleClientY - el.parentNode.offsetTop;
        let cptWidth, cptHeight, cptX, cptY;

        document.onmousemove = function (me) {
          const meScaleClientX = me.clientX / that.containerScale
          const meScaleClientY = me.clientY / that.containerScale
          if (binding.value === 'move') {
            cptX = meScaleClientX - disX;
            cptY = meScaleClientY - disY;
            Object.keys(that.cacheChoices).forEach((key) => {
              that.cacheChoices[key].cptX = that.cacheChoicesFixed[key].cptX + Math.round(meScaleClientX - scaleClientX)
              that.cacheChoices[key].cptY = that.cacheChoicesFixed[key].cptY + Math.round(meScaleClientY - scaleClientY)
            })
          } else {
            switch (binding.value) {
              case 'lt':
                cptWidth = ltX - meScaleClientX;
                cptHeight = ltY - meScaleClientY;
                cptX = meScaleClientX - disX;
                cptY = meScaleClientY - disY;
                that.currentCpt.cptX = Math.round(cptX);
                that.currentCpt.cptY = Math.round(cptY);
                break;
              case 't':
                cptHeight = ltY - meScaleClientY;
                cptY = meScaleClientY - disY;
                that.currentCpt.cptY = Math.round(cptY);
                break;
              case 'rt':
                cptWidth = meScaleClientX - rbX;
                cptHeight = ltY - meScaleClientY;
                cptY = meScaleClientY - disY;
                that.currentCpt.cptY = Math.round(cptY);
                break;
              case 'r':
                cptWidth = meScaleClientX - rbX;
                break;
              case 'rb':
                cptWidth = meScaleClientX - rbX;
                cptHeight = meScaleClientY - rbY;
                break;
              case 'b':
                cptHeight = meScaleClientY - rbY;
                break;
              case 'lb':
                cptWidth = ltX - meScaleClientX;
                cptHeight = meScaleClientY - rbY;
                cptX = meScaleClientX - disX;
                that.currentCpt.cptX = Math.round(cptX);
                break;
              case 'l':
                cptWidth = ltX - meScaleClientX;
                cptX = meScaleClientX - disX;
                that.currentCpt.cptX = Math.round(cptX);
                break;
            }
            cptWidth = cptWidth < 40 ? 40 : cptWidth;//限制最小缩放
            cptHeight = cptHeight < 20 ? 20 : cptHeight;
            if (cptWidth) that.currentCpt.cptWidth = Math.round(cptWidth);
            if (cptHeight) that.currentCpt.cptHeight = Math.round(cptHeight);
          }
        }
        document.onmouseup = function () {
          document.onmousemove = document.onmouseup = null;
          that.cacheChoicesFixed = JSON.parse(JSON.stringify(that.cacheChoices));//解决多选移动未松开ctrl键第二次以后拖动定位还原
        }
        return false;
      }
    }
  },
}
</script>

<style scoped lang="scss">
.top {
  height: 54px;
  color: #fff;
  overflow: hidden;
  margin: 0;
  font-size: 18px;
  line-height: 45px;
  background: rgba(255, 255, 255, 1);
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.span_seve {
  margin-left: 12px;
  font-size: 14px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 14px;
}

.span_preview {
  margin-left: 12px;
  font-size: 14px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #404756;
  line-height: 14px;
}

.span_tile {
  margin: auto;
  font-size: 16px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #404756;
  line-height: 16px;
}


.webContainer {
  position: relative;
  background-size: 100% 100%;
  transform-origin: 0 0
}

.delTag {
  width: 45px;
  height: 22px;
  background: rgba(43, 51, 64, 0.8);
  border-radius: 2px;
  color: #ccc;
  z-index: 2000;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  display: none;
  cursor: pointer
}

.activeMask {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1801
}


.cptDiv {

  position: absolute;
  outline: none
}

.cptDiv:hover .delTag {
  display: block
}

.resizeTag {
  width: 8px;
  height: 8px;
  position: absolute;
  background-color: #B6BFCE;
  z-index: 2000;
  border-radius: 50%;
}

.configBtn:hover {
  cursor: pointer;
  color: #B6BFCE
}

.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}

.top_div_tile {
  padding: 7px 8px;
  display: flex;
  position: absolute;
  top: -30px;
  background: #9931F8;
  width: fit-content;
  height: fit-content;
  margin-left: 12px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.span_tile_name {
  white-space: nowrap;
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 14px;
}

.span_tile_board {
  margin: 40px auto;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #8F8F8F;
  line-height: 14px;
}


.span_boadrd {
  margin: auto;
  font-size: 48px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #1E1E1E;
  line-height: 48px;
}

.span_iles {
  margin: auto 20px;
  font-size: 16px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #9931F8;
  line-height: 16px;
}



.tile_span_r {
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  font-size: 13px;
  color: #1E1E1E;
  line-height: 13px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.tile_span_r_false {
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  font-size: 13px;
  color: #C0BFC0;
  line-height: 13px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}


.div_tile_r {
  width: 90px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.svgicon {
  margin-top: -10px;
  width: 30px;
  height: 30px;
  overflow: hidden;

  .img_svg0 {
    position: relative;
    transform: translateX(-80px);
    filter: drop-shadow(80px 0 #C0BFC0);
    border-left: 0 solid transparent; //防止drop-shadow主体超出视线隐藏后阴影消失
    border-right: 0 solid transparent;
    opacity: 0.5;
  }

  .img_svg1 {
    position: relative;
    transform: translateX(-80px);
    filter: drop-shadow(80px 0 #1E1E1E);
    border-left: 0 solid transparent; //防止drop-shadow主体超出视线隐藏后阴影消失
    border-right: 0 solid transparent;
  }

}

.div_button {
  width: 200px;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 0 2px #CCC;
}

.outer_divitme {
  display: flex;
  align-content: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
  cursor: pointer;
  border: 1px solid #FFFFFF;
  transition: 0.2s ease;

  &:not(.selecting):hover {
    border: 1px solid #9931F8;
    transform: translateY(-1px);
    filter: drop-shadow(0px 4px 4px rgba(48, 48, 48, 0.15));
  }
}

</style>