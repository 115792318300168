import ForgotPassword from "@/erredpop/ForgotPassword.vue";
let ConfirmConstructor, instance

const showForgotPassword = {
    install(Vue) {
        ConfirmConstructor = Vue.extend(ForgotPassword)
        instance = new ConfirmConstructor().$mount()
        document.body.appendChild(instance.$el)

        Vue.prototype.showForgotPassword = options => {
            Object.assign(instance, options)
            instance.init()
        }
    }
}
export default showForgotPassword