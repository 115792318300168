<template>
  <div class="flex-col" style="overflow-y: hidden;">
    <div class="flex-row" style="margin-top: 12px;">
      <div class="flex-col">
        <span class="span_tile">X</span>
        <el-input v-model="attribute.cptX" autocomplete="off" style="width:156px;margin-top: 8px;"
                  @input="OInpi"></el-input>
      </div>
      <div class="flex-col" style="margin-left: 12px">
        <span class="span_tile">Y</span>
        <el-input v-model="attribute.cptY" autocomplete="off" style="width:156px;margin-top: 8px;"
                  @input="OInpi"></el-input>
      </div>
    </div>
    <div class="flex-row" style="margin-top: 12px;">
      <div class="flex-col">
        <span class="span_tile">Width</span>
        <el-input v-model="attribute.cptWidth" autocomplete="off" style="width:156px;margin-top: 8px;"
                  @input="OInpi"></el-input>
      </div>
      <div class="flex-col" style="margin-left: 12px">
        <span class="span_tile">Height</span>
        <el-input v-model="attribute.cptHeight" autocomplete="off"
                  style="width:156px;margin-top: 8px;"
                  @input="OInpi"></el-input>
      </div>

    </div>
    <span class="span_tile_name">Text content</span>
    <el-input
        style="margin-top: 8px;width: 324px;"
        type="text"
        @input="OnInpTot"
        v-model="textarea">
    </el-input>
    <span class="span_tile_name">Font</span>
    <el-select v-model="Fontvalue" placeholder="Select a content type" style="width: 324px;margin-top: 8px;"
               @change="onChangeView">
      <el-option
          v-for="item in sourType"
          :key="item.id"
          :label="item.label"
          :value="item.value">
      </el-option>
    </el-select>
    <div class="flex-row" style="margin-top: 12px;overflow: hidden;">
      <el-select v-model="attribute.cptOption.attribute.fontStyle" placeholder="Select" style="width: 156px;"
                 @change="onChangeView">
        <el-option
            v-for="item in textFamilyList"
            :key="item"
            :label="item"
            :value="item">
        </el-option>
      </el-select>
      <div style="width: 156px;margin-left: 12px;">
        <el-input-number :min="14" :max="600" v-model="attribute.cptOption.attribute.textSize" style="width: 100%"
                         @input="OInpiTextSize"/>
      </div>
      <!--      <el-select v-model="value" placeholder="Select" style="width: 156px;margin-left: 12px;"
                       @change="onChangeView">
              <el-option
                  v-for="item in sourType"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>-->
    </div>
    <span class="span_tile_name">Text align</span>
    <div class="flex-row" style="margin-top: 8px;">
      <div :class="itmeText===0 ? 'text_align_div_true':'text_align_div_false'" @click="onclickText(0)">
        <img style="width: 18px;height: 15px;margin: auto;" :src="require('@/assets/icon/Align-left.svg')">
      </div>
      <div :class="itmeText===2 ? 'text_align_div_true':'text_align_div_false'" @click="onclickText(2)">
        <img style="width: 18px;height: 15px;margin: auto;" :src="require('@/assets/icon/Align-right.svg')">
      </div>

    </div>
    <span class="span_tile_name">Text color</span>
    <div class="flex-row" style="margin-top: 9px;">
      <el-color-picker v-model="attribute.cptOption.attribute.textColor"></el-color-picker>
      <div
          style="border-radius: 8px;border: 1px solid #D9D9D9;width: 284px;height: 38px;margin-left: 8px;display: flex;align-items: center;padding-left: 12px;">
        <span class="span_tile_y">{{ attribute.cptOption.attribute.textColor }}</span></div>
    </div>
    <span class="span_tile_name">Background fill</span>
    <div class="flex-row" style="margin-top: 9px;">
      <el-color-picker v-model="attribute.cptOption.attribute.bgColor"></el-color-picker>
      <div
          style="border-radius: 8px;border: 1px solid #D9D9D9;width: 284px;height: 38px;margin-left: 8px;display: flex;align-items: center;padding-left: 12px;">
        <span class="span_tile_y">{{ attribute.cptOption.attribute.bgColor }}</span>
      </div>
    </div>
<!--
    <div style="height: 54px;display: flex;margin-top: 24px;border-top: 1px solid #F2F2F2;">
      <span class="span_tile_name">Touch Interaction</span>
    </div>
    <div class="flex-row justify-between" style="width: 300px;margin-top: 8px;">
      <span class="span_tile_y">Enable touch</span>
      <el-checkbox v-model="ischeck" @change="handleCheckAllChange"></el-checkbox>
    </div>
-->

  </div>
</template>

<script>
export default {
  name: 'FreeSubtitles',
  props: {
    attribute: Object
  },
  data() {
    return {
      value: '',
      textarea: '',
      Fontvalue: '',
      sourType: [
        {id: 0, label: 'Inter-Black', value: 'Inter-Black'},
        {id: 1, label: 'Inter-Bold', value: 'Inter-Bold'},
        {id: 2, label: 'Inter-ExtraBold', value: 'Inter-ExtraBold'},
        {id: 3, label: 'Inter-ExtraLight', value: 'Inter-ExtraLight'},
        {id: 4, label: 'Inter-Light', value: 'Inter-Light'},
        {id: 5, label: 'Inter-Medium', value: 'Inter-Medium'},
        {id: 6, label: 'Inter-Regular', value: 'Inter-Regular'},
        {id: 7, label: 'Inter-SemiBold', value: 'Inter-SemiBold'},
        {id: 8, label: 'Inter-Thin', value: 'Inter-Thin'},
        {id: 9, label: 'RobotoMono-Bold', value: 'RobotoMono-Bold'}],
      textFamilyList: ['normal', 'italic', 'oblique'],
      itmeText: 0,
      textColor: '',
      ischeck: false,
      backgrou: ''
    }
  },
  created() {
    this.textarea = JSON.parse(this.attribute.cptOption.cptDataForm.dataText).value
    console.log('=======FreeSubtitles=======created========>')
    console.log(this.attribute.cptOption.attribute.textFamily)
    this.Fontvalue = this.attribute.cptOption.attribute.textFamily
    switch (this.attribute.cptOption.attribute.direction) {
      case 'marquee':
        this.itmeText = 0
        break
      case 'marquees':
        this.itmeText = 2
        break
    }

  },
  methods: {
    onChangeView(e) {
      this.attribute.cptOption.attribute.textFamily = e
    },
    onclickText(e) {
      this.itmeText = e
      switch (e) {
        case  0:
          this.attribute.cptOption.attribute.direction = 'marquee'
          $bus.emit('sigvideo', {id: 9})
          break
        case 2:
          this.attribute.cptOption.attribute.direction = 'marquees'
          $bus.emit('sigvideo', {id: 10})
          break
      }
      console.log(this.attribute.cptOption.attribute)

    },
    handleCheckAllChange() {

    },
    OnInpTot() {
      //  this.currentCpt.cptOption.cptDataForm.dataText = JSON.stringify(newValue); {"value":"Plain text"}
      this.attribute.cptOption.cptDataForm.dataText = JSON.stringify({value: this.textarea});
      $bus.emit('sigvideo', {id: 0})
      console.log(this.attribute.cptOption.cptDataForm.dataText)
    },
    OInpi() {

    },
    OInpiTextSize(e) {
      this.attribute.cptHeight = e / 0.8
    }

  },

}
</script>

<style scoped lang="scss">

::v-deep .el-checkbox {
  margin-left: 16px;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #303030;

  .el-checkbox__inner {
    width: 24px;
    height: 24px;
    border: 1px solid rgba(149, 149, 149, 1);
    //修改选中框中的对勾的大小和位置
    &::after {
      top: 5px;
      height: 12px;
      left: 10px;
    }
  }

  .el-checkbox__label {
    display: inline-grid;
    color: #FFFFFF;
    width: 200px;

  }

  //修改点击文字颜色不变
  .el-checkbox__input.is-checked + .el-checkbox__label {
    border: 1px solid rgba(0, 145, 255, 1);
    background: rgba(0, 145, 255, 1);
    color: #303030;
  }

}

.span_tile {
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #C0BFC0;
  line-height: 12px;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.span_tile_type {
  height: 13px;
  font-size: 13px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #1E1F21;
  line-height: 13px;
}

.span_tile_name {

  margin-top: 20px;
  height: 14px;
  font-size: 14px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #404756;
  line-height: 14px;
}

.span_tile_y {
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #1E1F21;
  line-height: 14px;
}

.text_align_div_true {
  cursor: pointer;
  width: 81px;
  height: 32px;
  display: flex;
  background-color: #F2F2F2;
}

.text_align_div_false {
  cursor: pointer;
  width: 81px;
  height: 32px;
  display: flex;
  background-color: #FFFFFF;
}


</style>