import axios from "axios";
import {getToken} from "@/utils/auth";
import qs from "qs";
import router from "@/router";
import {fileUrl} from '/env'

//process.env.NODE_ENV用于区分是生产环境还是开发环境
//var isPro = 'http://'+window.location.host;
var isPro = fileUrl;

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: isPro,
    // 超时
    timeout: 900000,

});
// request拦截器
service.interceptors.request.use(
    config => {
        if (config.method === "get") {
            //如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
            config.paramsSerializer = function (params) {
                return qs.stringify(params, {arrayFormat: "repeat"});
            };
        }
        // 是否需要设置 token
        const isToken = (config.headers || {}).isToken === false;
        if (getToken() && !isToken) {
            config.headers["Authorization"] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改

        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    res => {
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200;
        // 获取错误信息
        if (code === 401) {
            if (router.currentRoute.name !== 'login' && router.currentRoute.name !== 'setPassWord' ) {  //这里必须限制为非login页面
                window.location.href = '/login'
                return res.data;
            }
        } else if (code === 400) {
            return res.data;

        } else {
            return res.data;
        }
    },
    error => {
        return Promise.reject(error);
    }
);

export default service;
