import Vue from 'vue'
import cpt_text from '@/element/sig-text'
import cpt_image from '@/element/sig-img'
import empty_sig from '@/element/empty-sig.vue'
import sigvideo from '@/element/sig-video.vue'
import sigsubtitles from '@/element/sig-Subtitles.vue'
import sigPlaylist from '@/element/sig-Playlist.vue'
import temVido from "@/element/templView/tem-vido.vue";
import temImg from "@/element/templView/tem-img.vue";
import temText from "@/element/templView/tem-text.vue";
import temSubtitles from "@/element/templView/tem-subtitles.vue";
import temPlaylist from "@/element/templView/tem-playlist.vue";
import touchSig from "@/element/Touch-sig.vue";



let cptList = [
    cpt_text, cpt_image, empty_sig, sigvideo, sigsubtitles, sigPlaylist, temVido, temImg, temText, temSubtitles, temPlaylist ,touchSig
]

cptList.forEach(ele => {
    Vue.component(ele.name, ele);
});
