<template>

    <div class="flex-row"
         style="height: 50px;display: flex;align-content: center;align-items: center;">
      <div style="width: 52px;">
        <el-checkbox v-model="source.isSelected" @change="seletDevice(source)"></el-checkbox>
      </div>
      <div style="width: 300px;padding-left: 16px;">
        <p class="span_name">{{ source.deviceName }}</p>
      </div>
      <div style="width: 320px;">
        <p class="span_name">{{ source.serialNumber }}</p>
      </div>
      <div style="width: 180px;">
        <div style="width: fit-content;">
          <p class="span_name" :style="activation(source.status)">{{ source.statusMessage }}</p>
        </div>
      </div>
    </div>

<!--    <div class="flex-row align-between  ">
      <div style="width: 52px;">
        <el-checkbox v-model="source.isSelected" @change="seletDevice(source)"></el-checkbox>
      </div>
      <div style="width: 180px;background-color: #0FBF19">
        <p class="span_name">{{ source.deviceName }}</p>
      </div>
      <div style="width: 120px;background-color: #1e9fff">
        <p class="span_name">{{ source.serialNumber }}</p>
      </div>
      <div style="width: 180px;background-color: #1E1E1E">
        <div style="width: fit-content;">
          <p class="span_name" :style="activation(source.status)">{{ source.statusMessage }}</p>
        </div>
      </div>
    </div>-->

</template>

<script>
export default {
  name: 'DevIcleItme',
  props: {
    index: { // 每一行的索引
      type: Number
    },
    source: { // 每一行的内容
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    activation() {
      return (severity) => {
        if (severity === '1') {
          return {
            'color': '#18C622',
            'border': '1px solid #18C622',
            'border-radius': ' 4px',
            'padding': '5px 12px'

          }

        } else {
          return {
            'color': '#A4ABBD',
            'border': '1px solid #A4ABBD',
            'border-radius': ' 4px',
            'padding': '5px 12px'
          }
        }
      }
    }

  },

  methods: {
    seletDevice(obj) {
      if (obj.status === '1') {
        $bus.emit('DevIcleItme', {code: 1, data: obj})
      } else {
        this.source.isSelected = false
       this.$message.error("Please select an online device")
      }
    }
  }


}
</script>


<style scoped lang="scss">
.justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

::v-deep .el-checkbox {
  margin-left: 16px;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #303030;

  .el-checkbox__inner {
    width: 22px;
    height: 22px;
    border-radius: 50%;

    border: 1px solid rgba(149, 149, 149, 1);
    //修改选中框中的对勾的大小和位置
    &::after {

      top: 3px;
      height: 12px;
      left: 8px;
    }
  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    border: 1px solid #9931F8;
    background-color: #9931F8;
  }

  .el-checkbox__label {
    display: inline-grid;
    color: #FFFFFF;
    width: 200px;
    background-color: #9931F8;

  }

  //修改点击文字颜色不变
  .el-checkbox__input.is-checked + .el-checkbox__label {
    border: 1px solid #9931F8;
    background: #9931F8;
    color: #303030;

  }


}

.align-between {
  display: flex;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.outer_data {
  width: 100%;
  height: 52px;
  margin-bottom: 10px;
  border: 1px solid #FFFFFF;
  transition: 0.2s ease;

}

.span_name_tile_name {
  cursor: pointer;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #404756;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.span_name {
  margin: auto 0;
  cursor: pointer;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #404756;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.span_name_tile_name {
  margin: auto 0;
  cursor: pointer;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #404756;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

</style>