<template>
  <div class="row">
    <div class="ymdiv flex-col" v-for="(item,index) in options" :key="item.id" @click="OnlicLisb(item)">
      <el-container :direction="item.direction" class="selecting_not">
        <el-main v-for="(ite ,ind) in item.View"
                 :style="{width:ite.width, height:ite.height,borderRadius:'8px',marginLeft:ite.marginleft,marginTop:ite.marginTop}"
                 :key="ind"
        >
          <el-container :direction="ite.direction" :style="{height:ite.height}">
            <el-main v-for="(ites ,indexs) in ite.list"
                     :style="{width:ites.width, height:ites.height,background:'#ffffff',borderRadius:'8px',marginLeft:ites.marginleft,marginTop:ites.marginTop}"
                     :key="indexs"
            >
            </el-main>
          </el-container>
        </el-main>
      </el-container>
      <span class="span_name">{{ item.name }} </span>
    </div>
  </div>
</template>


<script>
import options from "@/components/landscape"

export default {
  name: 'landscape',
  data() {
    return {
      options
    }
  },
  methods: {
    OnlicLisb(obj) {
      this.$router.push({path: '/template', query: {data: JSON.stringify(obj)}})
    },

  }
}
</script>

<style scoped>
.row {
  overflow-y: auto;
  height: 650px;
  padding-left: 88px;
  padding-right: 88px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.ymdiv {

  width: 304px;
  height: 234px;
  margin: 24px 12px;
  cursor: pointer;
  position: relative;
  background: #F2F2F2;


}

.selecting_not {
  &:not(.selecting):hover {
    transform: translateY(-1px);
    filter: drop-shadow(0px 4px 4px rgba(48, 48, 48, 0.15));
  }
}

.span_name {
  margin-top: 16px;
  text-align: center;
  background: #F2F2F2;
  font-family: Inter-Regular, Inter;
  font-size: 14px;
  color: #AFAFAF;
}

.el-card__body.el-main {
  padding: 0px;
}

.el-card ::v-deep .el-card__body {
  padding: 0px;
  background-color: powderblue;
}

/deep/ .el-main {
  padding: 0;
}
</style>