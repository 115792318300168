export default {
    cptDataForm: {dataText: '{"value":"Plain text"}', dataSource: 1, pollTime: 0},
    attribute: {
        url: '', textColor: '#000000', textSize: 25,
        fontWeight: 'normal', textLineHeight: 'normal',
        textFamily: 'Microsoft YaHei', textAlign: 'left',
        fontStyle: 'normal', textDecoration: 'none', bgColor: 'rgba(255, 255, 255, 0)',
        zIndex: 100,
        TouchUrl: '',
        Touchvalue: 0,
        ischeck: false

    }
}
