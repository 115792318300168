<template>
  <div v-if="show" class="mask">
    <div class="dlg-msg-box flex flex-col">
      <div class="flex-col" style="width: 496px;height: auto;margin: auto; background: #FFFFFF;border-radius: 24px;padding-bottom: 32px;">
        <div style="margin: auto">
          <img style="width: 96px;height: 96px;margin-top: 36px;margin-bottom: 12px;"
               :src="require('@/assets/logo.svg')">
          <p class="tile_pa" style="margin: auto">MUSEA</p>
          <p class="tile_name_p" style="margin: 6px auto">By GloryStar</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
export default {
  props: {
    caption: {},
    show: {},
    msg: [],
    button: {},
    callback: {}
  },
  methods: {
    init() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = true;
    },
    close() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("close")
    },
    confirmClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("yes")
      //this.$emit('confirm');
    },
    cancelClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("no")
    }
  }
}
</script>

<style>

.formDiv {
  height: 46px;
  margin-left: 48px;
  margin-right: 48px;
  text-align: center;
  background: #E0E1DD;
  border-radius: 36px 36px 36px 36px;
  opacity: 1;
  border: 1px solid #E0E1DD;

}

.formDiv1 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 24px;
  margin-left: 48px;
  margin-right: 48px;
  height: 42px;
  background: #9931F8;
  border-radius: 999px 999px 999px 999px;
  opacity: 1;
  font-size: 14px;
  font-family: Inter-SemiBold;
  color: #ECECEC;
}

.span_go {
  cursor: pointer;
  margin-top: 48px;
  text-align: center;
  height: 14px;
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #C0BFC0;
  line-height: 14px;
}

.ipt {
  text-align: center;
  display: inline-block;
  width: 80%;
  height: 100%;
  color: #404756;
  padding-left: 10px;
  border: none;
  background: #E0E1DD;
  outline: none;
  font-family: Inter-Regular;
  font-size: 14px;
}

.ipt::-ms-reveal {
  display: none
}
</style>
<style scoped>
@import url(dialogcss.css);
</style>