//横屏
import cpt_text_option from "@/element/attr/cpt-text-option";
import {getViewDataWidth, splitIntoEqualShares} from "@/utils/ruoyi";

const LandscapeOns = [
    {
        id: 0,
        name: 'Full screen',
        direction: 'horizontal',
        overall: 'horizontal',
        View: [{
            width: '304px', height: "202px", marginleft: "0px", direction: 'horizontal',
            list: [{width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"}]
        }],
        html: [{
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280,
            height: 800,
            offsetX: 0,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 0, w: 1, h: 1, x: 0, y: 0}
        }]

    },
    {
        id: 1,
        name: '2-Rows Horizontal',
        direction: 'vertical',
        overall: 'horizontal',
        View: [{
            width: '304px',
            height: "99px",
            marginleft: "0px",
            marginTop: "0px",
            direction: 'horizontal',
            list: [{width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"}]
        }, {
            width: '304px',
            height: "99px",
            marginleft: "0px",
            marginTop: "4px",
            direction: 'horizontal',
            list: [{width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"}]
        }],
        html: [{
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280,
            height: 800 * 0.5,
            offsetX: 0,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 1, w: 1, h: 0.5, x: 0, y: 0}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280,
            height: 800 * 0.5,
            offsetX: 0,
            offsetY: 800 * 0.5,
            option: cpt_text_option,
            prolin: {id: 1, w: 1, h: 0.5, x: 0, y: 0.5}
        }]

    },
    {
        id: 2, name: 'Text-Image', direction: 'vertical', overall: 'horizontal', View: [{
            width: '304px',
            height: "66px",
            marginleft: "0px",
            marginTop: "0px",
            direction: 'horizontal',
            list: [{width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"},]
        }, {
            width: '304px',
            height: "132px",
            marginleft: "0px",
            marginTop: "4px",
            direction: 'horizontal',
            list: [{width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"},]
        }],
        html: [{
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280,
            height: 800 * 0.2,
            offsetX: 0,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 2, w: 1, h: 0.2, x: 0, y: 0}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280,
            height: 800 * 0.8,
            offsetX: 0,
            offsetY: 800 * 0.2,
            option: cpt_text_option,
            prolin: {id: 2, w: 1, h: 0.8, x: 1, y: 0.2}
        }]
    },
    {
        id: 3, name: 'Image-Text', direction: 'vertical', overall: 'horizontal', View: [{
            width: '304px',
            height: "132px",
            marginleft: "0px",
            marginTop: "0px",
            direction: 'horizontal',
            list: [{width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"}]
        }, {
            width: '304px',
            height: "66px",
            marginleft: "0px",
            marginTop: "4px",
            direction: 'horizontal',
            list: [{width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"}]
        }],
        html: [{
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280,
            height: 800 * 0.8,
            offsetX: 0,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 3, w: 1, h: 0.8, x: 0, y: 0}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280,
            height: 800 * 0.2,
            offsetX: 0,
            offsetY: 800 * 0.8,
            option: cpt_text_option,
            prolin: {id: 3, w: 1, h: 0.2, x: 0, y: 0.8}
        }]
    },
    {
        id: 4, name: '2-Columns', direction: 'horizontal', overall: 'horizontal', View: [{
            width: '150px',
            height: "202px",
            marginleft: "0px",
            marginTop: "0px",
            direction: 'horizontal',
            list: [{width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"}]
        }, {
            width: '150px',
            height: "202px",
            marginleft: "4px",
            marginTop: "0px",
            direction: 'horizontal',
            list: [{width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"}]
        }],
        html: [{
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280 * 0.5,
            height: 800,
            offsetX: 0,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 4, w: 0.5, h: 1, x: 0, y: 0}
        },
            {
                name: 'Text',
                icon: 'text',
                cptKey: 'empty-sig',
                width: 1280 * 0.5,
                height: 800,
                offsetX: 1280 * 0.5,
                offsetY: 0,
                option: cpt_text_option,
                prolin: {id: 4, w: 0.5, h: 1, x: 0.5, y: 0}
            }]
    },
    {
        id: 5, name: 'One-Third', direction: 'horizontal', overall: 'horizontal', View: [{
            width: '100px',
            height: "202px",
            marginleft: "0px",
            marginTop: "0px",
            direction: 'horizontal',
            list: [{width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"}]
        }, {
            width: '200px', height: "202px", marginleft: "4px", marginTop: "0px", direction: 'horizontal', list: [
                {width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"},
            ]
        }],
        html: [{
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280 * 0.2,
            height: 800,
            offsetX: 0,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 5, w: 0.2, h: 1, x: 0, y: 0}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280 * 0.8,
            height: 800,
            offsetX: 1280 * 0.2,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 5, w: 0.8, h: 1, x: 0.2, y: 0}
        }]
    },
    {
        id: 6, name: 'One-Third (Reversed)', direction: 'horizontal', overall: 'horizontal', View: [{
            width: '200px',
            height: "202px",
            marginleft: "0px",
            marginTop: "0px",
            direction: 'horizontal',
            list: [{width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"}]
        }, {
            width: '100px',
            height: "202px",
            marginleft: "4px",
            marginTop: "0px",
            direction: 'horizontal',
            list: [{width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"}]
        }],
        html: [{
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280 * 0.8,
            height: 800,
            offsetX: 0,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 6, w: 0.8, h: 1, x: 0, y: 0}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280 * 0.2,
            height: 800,
            offsetX: 1280 * 0.8,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 6, w: 0.2, h: 1, x: 0.8, y: 0}
        }]
    },
    {
        id: 7, name: 'One-Third (Reversed)', direction: 'horizontal', overall: 'horizontal', View: [{
            width: '100px',
            height: "202px",
            marginleft: "0px",
            marginTop: "0px",
            direction: 'horizontal',
            list: [{width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"}]
        }, {
            width: '100px',
            height: "202px",
            marginleft: "4px",
            marginTop: "0px",
            direction: 'horizontal',
            list: [{width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"}]
        }, {
            width: '100px',
            height: "202px",
            marginleft: "4px",
            marginTop: "0px",
            direction: 'horizontal',
            list: [{width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"}]

        }],
        html: getViewDataWidth(splitIntoEqualShares(1280, 3),
            {width: 1280, height: 800, cpt_text_option: cpt_text_option})
    },
    {
        id: 8,
        name: '3-Sections A',
        direction: 'horizontal',
        overall: 'horizontal',
        View: [{
            width: '200px',
            height: "202px",
            marginleft: "0px",
            marginTop: "0px",
            direction: 'horizontal',
            list: [{width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"}]
        }, {
            width: '100px',
            height: "202px",
            marginleft: "4px",
            marginTop: "0px",
            direction: 'vertical',
            list: [
                {width: '100px', height: "100%", marginleft: "0px", marginTop: "0px"},
                {
                    width: '100px',
                    height: "100%",
                    marginleft: "0px",
                    marginTop: "4px"
                }]
        }],
        html: [{
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280 * 0.8,
            height: 800,
            offsetX: 0,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 8, w: 0.8, h: 1, x: 0, y: 0}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280 * 0.2,
            height: 800 * 0.5,
            offsetX: 1280 * 0.8,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 8, w: 0.2, h: 0.5, x: 0.8, y: 0}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280 * 0.2,
            height: 800 * 0.5,
            offsetX: 1280 * 0.8,
            offsetY: 800 * 0.5,
            option: cpt_text_option,
            prolin: {id: 8, w: 0.2, h: 0.5, x: 0.8, y: 0.5}
        }
        ]
    },
    {
        id: 9, name: '3-Sections A', direction: 'horizontal', overall: 'horizontal', View: [
            {
                width: '100px',
                height: "202px",
                marginleft: "0px",
                marginTop: "0px",
                direction: 'vertical',
                list: [
                    {width: '100px', height: "100%", marginleft: "0px", marginTop: "0px"},
                    {
                        width: '100px',
                        height: "100%",
                        marginleft: "0px",
                        marginTop: "4px"
                    }]
            },
            {
                width: '200px',
                height: "202px",
                marginleft: "4px",
                marginTop: "0px",
                direction: 'horizontal',
                list: [{width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"}]
            }],
        html: [{
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280 * 0.2,
            height: 800 * 0.5,
            offsetX: 0,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 9, w: 0.2, h: 0.5, x: 0, y: 0}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280 * 0.2,
            height: 800 * 0.5,
            offsetX: 0,
            offsetY: 800 * 0.5,
            option: cpt_text_option,
            prolin: {id: 9, w: 0.2, h: 0.5, x: 0, y: 0.5}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280 * 0.8,
            height: 800,
            offsetX: 1280 * 0.2,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 9, w: 0.8, h: 1, x: 0.2, y: 0}
        }]
    },
    {
        id: 10, name: '4-Sections', direction: 'vertical', overall: 'horizontal',
        View: [{
            width: '304px', height: "66px", marginleft: "0px", marginTop: "0px", direction: 'horizontal',
            list: [
                {width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"},
                {width: '150px', height: "100%", marginleft: "4px", marginTop: "0px"}
            ]
        }, {
            width: '304px', height: "132px", marginleft: "0px", marginTop: "4px", direction: 'horizontal',
            list: [
                {width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"},
            ]
        },],
        html: [{
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280 * 0.5,
            height: 800 * 0.2,
            offsetX: 0,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 10, w: 0.5, h: 0.2, x: 0, y: 0}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280 * 0.5,
            height: 800 * 0.2,
            offsetX: 1280 * 0.5,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 10, w: 0.5, h: 0.2, x: 0.5, y: 0}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280,
            height: 800 * 0.8,
            offsetX: 0,
            offsetY: 800 * 0.2,
            option: cpt_text_option,
            prolin: {id: 10, w: 1, h: 0.8, x: 0, y: 0.2}
        }]
    },
    {
        id: 11, name: '4-Sections', direction: 'vertical', overall: 'horizontal',
        View: [{
            width: '304px', height: "132px", marginleft: "0px", marginTop: "0px", direction: 'horizontal',
            list: [
                {width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"}
            ]
        }, {
            width: '304px', height: "66px", marginleft: "0px", marginTop: "4px", direction: 'horizontal',
            list: [
                {width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"},
                {width: '150px', height: "100%", marginleft: "4px", marginTop: "0px"}
            ]
        },], html: [{
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280,
            height: 800 * 0.8,
            offsetX: 0,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 11, w: 1, h: 0.8, x: 0, y: 0}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280 * 0.5,
            height: 800 * 0.2,
            offsetX: 0,
            offsetY: 800 * 0.8,
            option: cpt_text_option,
            prolin: {id: 11, w: 0.5, h: 0.2, x: 0, y: 0.8}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280 * 0.5,
            height: 800 * 0.2,
            offsetX: 1280 * 0.5,
            offsetY: 800 * 0.8,
            option: cpt_text_option,
            prolin: {id: 11, w: 0.5, h: 0.2, x: 0.5, y: 0.8}
        }]
    },
    {
        id: 12, name: '4-Sections', direction: 'vertical', overall: 'horizontal',
        View: [{
            width: '304px', height: "99px", marginleft: "0px", marginTop: "0px", direction: 'horizontal',
            list: [
                {width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"},
                {width: '150px', height: "100%", marginleft: "4px", marginTop: "0px"}
            ]
        }, {
            width: '304px', height: "99px", marginleft: "0px", marginTop: "4px", direction: 'horizontal',
            list: [
                {width: '150px', height: "100%", marginleft: "0px", marginTop: "0px"},
                {width: '150px', height: "100%", marginleft: "4px", marginTop: "0px"}
            ]
        },], html: [{
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280 * 0.5,
            height: 800 * 0.5,
            offsetX: 0,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 12, w: 0.5, h: 0.5, x: 0, y: 0}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280 * 0.5,
            height: 800 * 0.5,
            offsetX: 1280 * 0.5,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 12, w: 0.5, h: 0.5, x: 0.5, y: 0}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280 * 0.5,
            height: 800 * 0.5,
            offsetX: 1280 * 0.5,
            offsetY: 800 * 0.5,
            option: cpt_text_option,
            prolin: {id: 12, w: 0.5, h: 0.5, x: 0.5, y: 0.5}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 1280 * 0.5,
            height: 800 * 0.5,
            offsetX: 0,
            offsetY: 800 * 0.5,
            option: cpt_text_option,
            prolin: {id: 12, w: 0.5, h: 0.5, x: 0, y: 0.5}
        }]
    },
]
export default LandscapeOns