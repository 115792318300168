//竖屏

import cpt_text_option from "@/element/attr/cpt-text-option";

import {getViewDataHeight, splitIntoEqualShares} from "@/utils/ruoyi";

const PortraitOns = [
    {
        id: 0, name: 'Full screen', direction: 'vertical', overall: 'vertical', View: [{
            width: '160px', height: "202px", marginleft: "0px", direction: 'vertical',
            list: [{width: '160px', height: "100%", marginleft: "0px", marginTop: "0px"}]
        }],
        html: [{
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 800,
            height: 1280,
            offsetX: 0,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 0, w: 1, h: 1, x: 0, y: 0}
        }]
    },
    {
        id: 1, name: '2-Sections', direction: 'vertical', overall: 'vertical',
        View: [
            {
                width: '160px', height: "99px", marginleft: "0px", direction: 'vertical',
                list: [{width: '160px', height: "100%", marginleft: "0px", marginTop: "0px"}]
            },
            {
                width: '160px', height: "99px", marginleft: "0px", direction: 'vertical',
                list: [{width: '160px', height: "100%", marginleft: "0px", marginTop: "4px"}]
            }
        ],
        html: [{
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 800,
            height: 1280 * 0.5,
            offsetX: 0,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 1, w: 1, h: 0.5, x: 0, y: 0}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 800,
            height: 1280 * 0.5,
            offsetX: 0,
            offsetY: 1280 * 0.5,
            option: cpt_text_option,
            prolin: {id: 1, w: 1, h: 0.5, x: 0, y: 0.5}
        }]
    },
    {
        id: 2, name: 'One-Third', direction: 'vertical', overall: 'vertical', View: [
            {
                width: '160px', height: "66px", marginleft: "0px", direction: 'vertical',
                list: [{width: '160px', height: "100%", marginleft: "0px", marginTop: "0px"}]
            },
            {
                width: '160px', height: "132px", marginleft: "0px", direction: 'vertical',
                list: [{width: '160px', height: "100%", marginleft: "0px", marginTop: "4px"}]
            }
        ],
        html: [{
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 800,
            height: 1280 * 0.2,
            offsetX: 0,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 0, w: 1, h: 0.2, x: 0, y: 0}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 800,
            height: 1280 * 0.8,
            offsetX: 0,
            offsetY: 1280 * 0.2,
            option: cpt_text_option,
            prolin: {id: 2, w: 1, h: 0.8, x: 0, y: 0.2}
        }]
    },
    {
        id: 3, name: '3-Rows', direction: 'vertical', overall: 'vertical', View: [
            {
                width: '160px', height: "132px", marginleft: "0px", direction: 'vertical',
                list: [{width: '160px', height: "100%", marginleft: "0px", marginTop: "0px"}]
            },
            {
                width: '160px', height: "66px", marginleft: "0px", direction: 'vertical',
                list: [{width: '160px', height: "100%", marginleft: "0px", marginTop: "4px"}]
            }
        ],
        html: [{
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 800,
            height: 1280 * 0.8,
            offsetX: 0,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 3, w: 1, h: 0.8, x: 0, y: 0}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 800,
            height: 1280 * 0.2,
            offsetX: 0,
            offsetY: 1280 * 0.8,
            option: cpt_text_option,
            prolin: {id: 3, w: 1, h: 0.2, x: 0, y: 0.8}
        }]
    },
    {
        id: 4, name: '3-Sections', direction: 'vertical', overall: 'vertical', View: [
            {
                width: '160px', height: "66px", marginleft: "0px", direction: 'vertical',
                list: [{width: '160px', height: "100%", marginleft: "0px", marginTop: "0px"}]
            },
            {
                width: '160px', height: "66px", marginleft: "0px", direction: 'vertical',
                list: [{width: '160px', height: "100%", marginleft: "0px", marginTop: "4px"}]
            },
            {
                width: '160px', height: "66px", marginleft: "0px", direction: 'vertical',
                list: [{width: '160px', height: "100%", marginleft: "0px", marginTop: "4px"}]
            }
        ],
        html: getViewDataHeight(splitIntoEqualShares(1280, 3),
            {width: 800, height: 1280, cpt_text_option: cpt_text_option})
    },
    {
        id: 5, name: '3-Sections', direction: 'vertical', overall: 'vertical', View: [
            {
                width: '160px', height: "66px", marginleft: "0px", direction: 'horizontal',
                list: [{width: '160px', height: "100%", marginleft: "0px", marginTop: "0px"}, {
                    width: '160px',
                    height: "100%",
                    marginleft: "4px",
                    marginTop: "0px"
                }]
            },
            {
                width: '160px', height: "132px", marginleft: "0px", direction: 'vertical',
                list: [{width: '160px', height: "100%", marginleft: "0px", marginTop: "4px"}]
            }

        ],
        html: [{
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 800 * 0.5,
            height: 1280 * 0.2,
            offsetX: 0,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 5, w: 0.5, h: 0.2, x: 0, y: 0}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 800 * 0.5,
            height: 1280 * 0.2,
            offsetX: 800 * 0.5,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 5, w: 0.5, h: 0.2, x: 0.5, y: 0}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 800,
            height: 1280 * 0.8,
            offsetX: 0,
            offsetY: 1280 * 0.2,
            option: cpt_text_option,
            prolin: {id: 5, w: 1, h: 0.8, x: 0, y: 0.2}
        }]
    },
    {
        id: 6, name: '3-Sections (Reversed)', direction: 'vertical', overall: 'vertical', View: [
            {
                width: '160px', height: "132px", marginleft: "0px", direction: 'horizontal',
                list: [{width: '160px', height: "100%", marginleft: "0px", marginTop: "0px"}]
            },
            {
                width: '160px', height: "66px", marginleft: "0px", direction: 'horizontal', marginTop: "4px",
                list: [{width: '160px', height: "100%", marginleft: "0px", marginTop: "0px"}, {
                    width: '160px',
                    height: "100%",
                    marginleft: "4px",
                    marginTop: "0px"
                }]
            }

        ],
        html: [{
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 800 * 0.5,
            height: 1280 * 0.2,
            offsetX: 0,
            offsetY: 1280 * 0.8,
            option: cpt_text_option,
            prolin: {id: 6, w: 0.5, h: 0.2, x: 0, y: 0.8}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 800 * 0.5,
            height: 1280 * 0.2,
            offsetX: 800 * 0.5,
            offsetY: 1280 * 0.8,
            option: cpt_text_option,
            prolin: {id: 6, w: 0.5, h: 0.2, x: 0.5, y: 0.8}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 800,
            height: 1280 * 0.8,
            offsetX: 0,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 6, w: 1, h: 0.8, x: 0, y: 0}
        }]
    },
    {
        id: 7, name: '4-Sections', direction: 'vertical', overall: 'vertical', View: [
            {
                width: '160px', height: "99px", marginleft: "0px", direction: 'horizontal',
                list: [{width: '160px', height: "100%", marginleft: "0px", marginTop: "0px"}, {
                    width: '160px',
                    height: "100%",
                    marginleft: "4px",
                    marginTop: "0px"
                }]
            },
            {
                width: '160px', height: "99px", marginleft: "0px", direction: 'horizontal', marginTop: "4px",
                list: [{width: '160px', height: "100%", marginleft: "0px", marginTop: "0px"}, {
                    width: '160px',
                    height: "100%",
                    marginleft: "4px",
                    marginTop: "0px"
                }]
            }

        ], html: [{
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 800 * 0.5,
            height: 1280 * 0.5,
            offsetX: 0,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 6, w: 0.5, h: 0.5, x: 0, y: 0}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 800 * 0.5,
            height: 1280 * 0.5,
            offsetX: 800 * 0.5,
            offsetY: 0,
            option: cpt_text_option,
            prolin: {id: 6, w: 0.5, h: 0.5, x: 0.5, y: 0}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 800 * 0.5,
            height: 1280 * 0.5,
            offsetX: 800 * 0.5,
            offsetY: 1280 * 0.5,
            option: cpt_text_option,
            prolin: {id: 6, w: 0.5, h: 0.5, x: 0.5, y: 0.5}
        }, {
            name: 'Text',
            icon: 'text',
            cptKey: 'empty-sig',
            width: 800 * 0.5,
            height: 1280 * 0.5,
            offsetX: 0,
            offsetY: 1280 * 0.5,
            option: cpt_text_option,
            prolin: {id: 6, w: 0.5, h: 0.5, x: 0, y: 0.5}
        }]
    }
]
export default PortraitOns