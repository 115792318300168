const env = {
    active: 'dev',//preview,dev
    baseUrl: 'http://musea.thestarcontrol.com',
    fileUrl: 'http://musea.thestarcontrol.com',
    version: '0.9.0',
}
//http://172.16.32.85:8014
// http://musea.thestarcontrol.com
export const baseUrl = env.baseUrl
export const fileUrl = env.fileUrl
export default env
