var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({color:_vm.option.attribute.textColor,fontSize: _vm.option.attribute.textSize+'px',
  fontStyle:_vm.option.attribute.fontStyle, fontWeight:_vm.option.attribute.fontWeight,lineHeight:_vm.option.attribute.textLineHeight+'px',
  backgroundColor:_vm.option.attribute.bgColor, zIndex:_vm.option.attribute.zIndex,
  fontFamily:_vm.option.attribute.textFamily, textDecoration:_vm.option.attribute.textDecoration,
  width:'100%',height:'100%',whiteSpace:'pre-wrap',display:'grid',alignItems:'center',justifyItems:_vm.option.attribute.textAlign,
  overflow:'hidden',
  }),on:{"click":_vm.redirect}},[_vm._v(" "+_vm._s(_vm.cptData.value)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }