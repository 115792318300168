import PassFollow from "@/erredpop/PassFollow.vue";
let ConfirmConstructor, instance

const showPassFollow = {
    install(Vue) {
        ConfirmConstructor = Vue.extend(PassFollow)
        instance = new ConfirmConstructor().$mount()
        document.body.appendChild(instance.$el)

        Vue.prototype.showPassFollow = options => {
            Object.assign(instance, options)
            instance.init()
        }
    }
}
export default showPassFollow