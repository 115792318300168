import OpenBrowser from "@/erredpop/OpenBrowser.vue";

let ConfirmConstructor, instance

const showOpenBrowser = {
    install(Vue){
        ConfirmConstructor = Vue.extend(OpenBrowser)
        instance = new ConfirmConstructor().$mount()
        document.body.appendChild(instance.$el)
        Vue.prototype.$showOpenBrowser= options => {
            Object.assign(instance, options)
            instance.init()
        }
    }
}
export default showOpenBrowser