<template>
  <div v-if="show" class="mask">
    <div class="dlg-msg-box flex flex-col">
      <div class="flex-col div_forgot" style="width: 460px;height: auto;margin: auto;">
        <span class="title_span">Close My Account</span>
        <div class="flex-col" style="width: 460px;margin-top: 32px;">
          <span class="span_up_add">Do you confirm to permanently delete this account? We will</span>
          <span class="span_up_add">need sometime to review your request and get back to you as </span>
          <span class="span_up_add">soon as possible. In the meantime, you will still be able to</span>
          <span class="span_up_add">login as usual. You are welcome to send us an email if you</span>
          <span class="span_up_add"> want to cancel your request.</span>
        </div>
        <div class="flex-row" style="margin-top: 36px;margin-left: 24px;margin-bottom: 24px;">
          <div class="emailAddPassword" style="width: 198px;" @click="Uptext()">Send request</div>
          <div class="button_cancel" style="width: 198px;margin-left: 16px;" @click="cancelClick()">Go back</div>
        </div>


      </div>
    </div>
  </div>

</template>
<script>
import {accountClosureRequest} from "@/api/login";

export default {
  name: "closeAccount",
  props: {
    caption: {},
    show: {},
    msg: {},
    callback: {},
    feature: {
      rating: 5,
      experience: 'Very good design, very user-friendly',
      reason: 'I don t want to use it anymore'
    },
  },
  methods: {
    init() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = true;
    },
    close() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("close")
    },
    confirmClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("yes")
      //this.$emit('confirm');
    },
    cancelClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("no")
    },
    Uptext() {
      console.log({
        rating: 5,
        experience: 'Very good design, very user-friendly',
        reason: 'I don t want to use it anymore'
      })
      accountClosureRequest({
        rating: 5,
        experience: 'Very good design, very user-friendly',
        reason: 'I don t want to use it anymore'
      }).then(rops => {
        if (rops.code === 200) {
          this.show = false;
          this.callback("yes")
          this.$message('success')
        }
      }).catch()

    }
  }
}
</script>

<style scoped>
@import url(dialogcss.css);
</style>