<template>
  <div v-if="show" class="mask">
    <div class="dlg-msg-box flex flex-col">
      <div class="flex-col div_forgot" style="width: 344px;height: 210px;margin: auto;">
        <span class="title_span">Delete Selected Files</span>
        <div class="flex-col" style="width: 344px;margin-top: 32px;">
          <span class="span_up_add">Selected file(s) will be removed from cloud.</span>
          <span class="span_up_add">This action is irreversible.</span>
        </div>
        <div class="flex-row" style="margin-left: 24px;margin-top: 40px;">
          <button class="emailAddPassword" @click="confirmClick()">Delete Now</button>
          <button class="button_cancel" style="margin-left: 16px" @click="cancelClick()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeleteFiles',
  props: {
    caption: {},
    show: {},
    msg: {},
    callback: {}
  },
  methods: {
    init() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = true;
    },
    close() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("close")
    },
    confirmClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("yes")
      //this.$emit('confirm');
    },
    cancelClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("no")
    }
  }
}
</script>


<style scoped>
@import url(dialogcss.css);
</style>