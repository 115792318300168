<template>
  <div style="width: 100%;height: 100%;display: flex;">
    <div class="flex-col" style="margin: auto;">
      <span class="span_tile">Empty</span>
      <span class="span_edit">Click to edit</span>
    </div>
  </div>
</template>


<script>
export default {
  name: 'empty-sig',
  props: {option: Object},
  mounted() {
  },
  methods: {}

}
</script>


<style scoped>

.flex-col {
  display: flex;
  flex-direction: column;
}

.span_tile {
  font-size: 36px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #AFAFAF;
  line-height: 36px;
}

.span_edit {
  font-size: 16px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #AFAFAF;
  line-height: 16px;

}


</style>