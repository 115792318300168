<template>
  <div class="scrolling-text" :key="GeneralViod"
       :style="{backgroundColor:option.attribute.bgColor}">
      <span style="text-align: center"
            :class=" {spanseve_right :option.attribute.direction !=='marquee',spanseve_left: option.attribute.direction ==='marquee' }"
            :style="{textAlign:option.attribute.textAlign,color:option.attribute.textColor,fontSize: option.attribute.textSize+'px',
    fontStyle:option.attribute.fontStyle, fontWeight:option.attribute.fontWeight,lineHeight:option.attribute.textLineHeight+'px',
    fontFamily:option.attribute.textFamily, textDecoration:option.attribute.textDecoration,zIndex:option.attribute.zIndex}">
         {{ cptData.value }}
      </span>
  </div>
</template>



<script>
import {pollingRefresh} from "@/utils/refreshCptData";
// :class=" {spanseve_right :option.attribute.direction !=='marquee',spanseve_left: option.attribute.direction ==='marquee' }"
/**
 *     :style="{textAlign:option.attribute.textAlign,color:option.attribute.textColor,fontSize: option.attribute.textSize+'px',
 *     fontStyle:option.attribute.fontStyle, fontWeight:option.attribute.fontWeight,lineHeight:option.attribute.textLineHeight+'px',
 *     fontFamily:option.attribute.textFamily, textDecoration:option.attribute.textDecoration,zIndex:option.attribute.zIndex}">
 *
 * */
export default {
  name: "sigsubtitles",
  props: {
    option: Object
  },
  data() {
    return {
      cptData: {},
      uuid: null,
      GeneralViod: Date.now(),
      animation: 'marquee 20s linear infinite'
    }
  },
  computed: {},
  created() {
    console.log('option=sigsubtitles=>', this.option)
    this.uuid = require('uuid').v1();
    this.refreshCptData();
    $bus.on('sigvideo', data => {
      if (data.id === 0) {
        this.refreshCptData()
      } else if (data.id === 9) {
        this.setCssse(1)
      } else if (data.id === 10) {
        this.setCssse(2)
      }
    })
  },
  methods: {
    refreshCptData() {

      pollingRefresh(this.uuid, this.option.cptDataForm, this.loadData)
    },
    loadData() {
      this.cptData = JSON.parse(this.option.cptDataForm.dataText)
    },
    redirect() {
      if (this.option.attribute.url) {
        if (this.option.attribute.url.startsWith("view")) {
          this.$router.push(this.option.attribute.url)
          this.$emit("reload")
        } else {
          window.open(this.option.attribute.url)
        }
      }
    },
    setCssse(obj) {
    }
  },
  watch: {
    option: {
      handler(o, v) {
        console.log('======option ==========>', this.option)
        direction.direction
        this.animation = 'marquee 20s linear infinite'
        console.log(o)
      }, deep: true
    }
  }

}
</script>

<style scoped lang='scss'>
.scrolling-text {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  height: 100%;
  width: 100%;
}

.spanseve_left {
  display: inline-block;
  padding-left: 100%;
  /* 调整动画时长和效果 */
  animation: marquee 20s linear infinite;
}

.spanseve_right {
  display: inline-block;
  padding-left: 100%;
  /* 调整动画时长和效果 */
  animation: marquees 20s linear infinite;
}


@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquees {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

</style>