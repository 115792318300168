import ErrorOccured from "@/erredpop/ErrorOccured.vue";

let ConfirmConstructor, instance
const showErrorOccured = {
    install(Vue) {
        ConfirmConstructor = Vue.extend(ErrorOccured)
        instance = new ConfirmConstructor().$mount()
        document.body.appendChild(instance.$el)
        Vue.prototype.$showErrorOccured = options => {
            Object.assign(instance, options)
            instance.init()
        }

    }

}
export default showErrorOccured