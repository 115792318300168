<template>
  <div class="flex-col">
    <div class="flex-row"
         style="padding-left: 88px;padding-top: 64px;padding-right: 88px; display: flex;justify-content: space-between;">
      <div class="flex-row" style="display: flex;align-content: center;height: 48px;">
        <span class="span_tile_name">Media Library</span>
        <div class="flex-col" style="width: 100px;margin-left: 16px;margin-top: 10px;">
          <span class="span_y_tile">{{ renderSize(minGB) }} / {{ renderSize(maxGb) }}</span>
          <el-progress :text-inside="true" :show-text="false" :stroke-width="9" :percentage="progress"
                       :color="'#18C622'"
                       :define-back-color="'#fff'"
                       style="margin-top: 8px;"></el-progress>
        </div>
      </div>
      <div class="flex-row">
        <el-input prefix-icon="el-icon-search" clearable v-model="username" placeholder="Search by name"
                  @clear="clearInpt" @change="InputString"/>
        <!--        <div class="formDiv0">
                  <div class="formLabel"><i class="el-icon-search"></i></div>
                  <input class="ipt" v-model="username" placeholder="Search by name"/>
                </div>-->

        <div class="formDiv2" @click="Onclik()">
          <div class="flex-row justify-between">
            <img class="img_icon1" :src="require('@/assets/icon/upload.svg')">
            <span class="spi_list11">Upload File</span>
          </div>

        </div>
      </div>
    </div>
    <div class="flex-col">
      <div class="flex-row" style="margin-left: 88px;margin-right: 88px;margin-top: 24px;">

        <div style="width: 343px; display: flex;align-items: center;" class="flex-row">
          <div style="width: 58px;display: flex;align-items: center; padding-left: 16px;">
            <el-checkbox v-model="isAll" @change="seletAll()" :indeterminate="isIndeterminate"></el-checkbox>
          </div>
          <span class="spi_list12">File Name</span>
        </div>
        <div style="width: 285px;margin-left: 16px;">
          <span class="spi_list12">File Type</span>
        </div>
        <div style="width: 285px;margin-left: 16px;">
          <span class="spi_list12">Uploaded At</span>
        </div>
        <div style="width: 285px;margin-left: 16px;">
          <span class="spi_list12">File Size</span>
        </div>

      </div>
      <virtual-list
          class="list-keep"
          :style="{height:700+'px'}"
          :estimate-size="60"
          :data-key="'fileId'"
          :data-sources="GroupDatalist"
          :data-component="itemContactsitem"/>
    </div>
    <Uploading v-if="isPayFile" :projec-data="inputFile" v-on:chickBack="chickBack"/>
    <div class="action-bar justify-between" id="actionbar">
      <div class="flex-row">
        <div class="div_action" style="width: 124px; height: 41px;">
          <span class="span_action">{{ selected }}</span>
        </div>
        <div class="flex-row  iiem_popover" @click="deleList()">
          <img src="@/assets/icon/delete.svg"
               style="width: 15px;height: 15px;margin-right: 8px;margin-left: 16px;"
          >
          <span class="span_item_popover_delete">Delete</span>
        </div>
      </div>
      <div style="width: 153px; height: 41px;justify-content: center;align-items: center;display: flex;cursor: pointer"
           @click="Cancel()">
        <span class="span_tile_cancel">Cancel</span>
        <img style="width: 10px;height: 10px;margin-left: 8px;" :src="require('@/assets/icon/600.svg')">
      </div>
    </div>
    <div style="display: flex;align-items:center;margin: 0; opacity: 0 ">
      <input type="file" ref="uploadBtn" :accept="'audio/mp4, video/mp4, image/*'"
             class="uploadBtnBlock" id="selectcompressed" @change="selectFile"
      />
    </div>
  </div>
</template>
<script>
import VirtualList from "vue-virtual-scroll-list";
import Contactsitem from "@/views/library/itemView.vue";
import Uploading from "@/popover/Uploading.vue";
import {deletemuseaFile, museaFileList} from "@/api/login";
import DeleteFiles from "@/erredpop/DeleteFiles.vue";
import {renderSize} from "../../utils/ruoyi";

export default {
  name: 'My-library',
  components: {
    DeleteFiles,
    Uploading,
    // eslint-disable-next-line vue/no-unused-components
    'virtual-list': VirtualList
  },
  data() {
    return {
      username: '',
      isname: '',
      GroupDatalist: [],
      itemContactsitem: Contactsitem,
      inputFile: null,
      isPayFile: false,
      selected: '',
      seveList: [],
      fullHeight: document.documentElement.clientHeight - 200,
      minGB: 0,
      maxGb: 0,
      progress: 0,
      isAll: false,
      isIndeterminate: false

    }
  },
  created() {
    this.getViewList()
    $bus.on('mylibrary', data => {
      switch (data.code) {
        case 0:
          this.setOnClickd(data.data)
          break
        case 1:
          this.deleFile(data.data)
          break
      }

    })
  },
  methods: {
    renderSize,
    OnlicLisb() {

    },
    chickBack() {
      this.isPayFile = false
      this.getViewList()
    },
    selectFile() {
      this.inputFile = this.$refs.uploadBtn.files[0]
      let mins = this.maxGb - this.minGB
      if (this.inputFile.size < mins){
        if (this.inputFile.size < 1073741824) {
          console.log(this.inputFile)
          let typeList = ['avi', 'm4v', 'mov', 'mp4', 'MOV', 'AVI', 'M4V', 'MP4']
          let typeList1 = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG', 'gif', 'GIF', 'BMP', 'bmp']
          let Filename = this.inputFile.name.split('.')
          let fliterType = this.flitersFilesType(Filename[Filename.length - 1], typeList)
          let fliterType1 = this.flitersFilesType(Filename[Filename.length - 1], typeList1)
          let fileType = ''
          if (fliterType) {
            this.isPayFile = true
          } else {
            if (fliterType1) {
              this.isPayFile = true
            } else {
              this.$showErrorOccured({msg: ['File format not supported', 'please upload again']})
              return
            }
          }

        } else {
          this.$message.error('Please upload files smaller than 1GB')
        }
      }else {
        this.$showErrorOccured({msg:[
            'Please make sure you have enough cloud',
            'storage in your account for these files.']})
      }


      console.log(this.inputFile.size)

      //  this.$refs.uploadBtn.files[0]
    },
    Onclik() {
      if (this.progress < 99) {
        const inpuf = document.querySelector('input[type=\'file\']')
        inpuf.click()
      } else {
        this.$showMsgBox({
          caption: 'Media Library',
          icon: 'failed',
          msg: ['Memory full, please delete excess ', 'files or add more License'],
          button: 'Go back',
          callback: (data) => {
          }
        })
      }
    },
    getViewList() {
      let dataInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      this.GroupDatalist.splice(0, this.GroupDatalist.length)
      var max = dataInfo.licenses.map(itme => itme.licenseType === '2' ? parseInt(itme.licenseContent) : 0).reduce(function (prev, curr, idx, arr) {
        return prev + curr;
      })
      this.maxGb = max * 1073741824
      sessionStorage.removeItem('museaFileVide')
      sessionStorage.removeItem('museaFileImage')
      museaFileList({userId: dataInfo.userId, fileName: this.username}).then(postMessage => {
        let vidoList = []
        let ImageList = []
        if (postMessage.code === 200) {
          let arr = postMessage.rows.sort(this.sortDownDate)
          arr.forEach(itme => {
            if (itme.fileType === '0') {
              vidoList.push(itme)
            } else {
              ImageList.push(itme)
            }
            itme.createTime = this.timeZoneFormat(itme.createTime)
            itme.ischeck = false
          })
          let sum = postMessage.rows.map(it => parseInt(it.fileSize))
          this.minGB = sum.reduce(function (prev, curr, idx, arr) {
            return prev + curr;
          })
          sessionStorage.setItem('museaFileVide', JSON.stringify(vidoList))
          sessionStorage.setItem('museaFileImage', JSON.stringify(ImageList))
          this.GroupDatalist = arr
          this.progress = parseInt(((this.minGB / this.maxGb) * 100).toFixed(2))
        }
      })
    },
    CancelOperation() {

    },
    setOnClickd(obj) {
      const actionBar = document.getElementById('actionbar')
      if (obj.ischeck) {
        this.seveList.push(obj)
        actionBar.classList.add('show')
      } else {
        let Index = this.seveList.findIndex(itme => itme.fileId === obj.fileId)
        this.seveList.splice(Index, 1)
      }
      if (this.seveList.length === 0) {
        this.isAll = false
        this.isIndeterminate = false
        actionBar.classList.remove('show')
      }
      if (this.seveList.length > 0 && this.seveList.length < this.GroupDatalist.length) {
        this.isAll = false
        this.isIndeterminate = true
      }
      if (this.seveList.length === this.GroupDatalist.length) {
        this.isAll = true
        this.isIndeterminate = false
      }

      this.selected = this.seveList.length + ' selected'
    },
    Cancel() {

      this.GroupDatalist.forEach(it => {
        it.ischeck = false
      })
      this.isAll = false
      this.isIndeterminate = false
      this.seveList.splice(0, this.seveList.length)
      document.getElementById('actionbar').classList.remove('show')

    },
    deleList() {
      let listid = this.seveList.map(it => it.fileId)
      console.log(listid.toLocaleString())
      this.$showDeleteFiles({
        caption: '',
        msg: [],
        callback: (data) => {
          if (data === 'yes') {
            deletemuseaFile(listid.toLocaleString()).then(postMessage => {
              if (postMessage.code === 200) {
                this.getViewList()
                document.getElementById('actionbar').classList.remove('show')
              }

            })
          }

        }
      })
    },
    deleFile(obj) {
      this.$showDeleteFiles({
        caption: '',
        msg: [],
        callback: (data) => {
          if (data === 'yes') {
            deletemuseaFile(obj.fileId).then(postMessage => {
              if (postMessage.code === 200) {
                this.getViewList()
              }
            })
          }
        }

      })
    },
    clearInpt() {
      this.getViewList()
    },
    InputString() {
      this.getViewList()
    },
    seletAll() {
      const actionBar = document.getElementById('actionbar')
      if (this.isAll) {
        this.isIndeterminate = false
        this.seveList.splice(0, this.seveList.length)
        this.GroupDatalist.forEach(itme => {
          itme.ischeck = true
          this.seveList.push(itme)
        })
        this.selected = this.seveList.length + ' selected'
        actionBar.classList.add('show')
      } else {
        this.seveList.splice(0, this.seveList.length)
        this.GroupDatalist.forEach(itme => {
          itme.ischeck = false
        })
        actionBar.classList.remove('show')
      }


    }

  }

}
</script>
<style scoped lang="scss">


::v-deep .el-input__inner {
  border: 1px solid #C0BFC0;
  border-radius: 999px;
  height: 44px;
  background-color: #F2F2F2;
  font-family: Inter-Medium;
  font-size: 13px;
  color: #3D2A56;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}


.img_icon1 {
  margin-left: 16px;
  width: 16px;
  height: 16px;
}

.span_tile_name {
  white-space: nowrap;
  font-size: 48px;
  font-family: Inter-Regular, Inter;
  font-weight: 500;
  color: #404756;
  line-height: 48px;
}

.formDiv0 {
  cursor: pointer;
  width: 246px;
  height: 40px;
  text-align: center;
  border-radius: 36px 36px 36px 36px;
  opacity: 1;
  border: 1px solid #AFAFAF;
}

.formDiv2 {
  padding-right: 16px;
  cursor: pointer;
  margin-left: 12px;
  width: fit-content;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  background-color: #9931F8;
  border-radius: 50px;
  opacity: 1;
  border: 1px solid #9931F8;
}

.formLabel {
  display: inline-block;
  width: 30px;
  font-size: 18px;
  color: #889aac;
  text-align: right
}

.ipt {
  display: inline-block;
  width: 80%;
  height: 100%;
  font-size: 15px;
  color: #AFAFAF;
  padding-left: 10px;
  border: none;
  background: #0000;
  outline: none;
}

.ipt::-ms-reveal {
  display: none
}


.spi_list12 {
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #989898;
  line-height: 12px;
}

.spi_list11 {
  white-space: nowrap;
  margin-left: 8px;
  font-size: 13px;
  font-family: Inter-Regular, Inter;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 13px;
}

.list-keep {
  margin-left: 88px;
  margin-right: 88px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 11px;
  padding-right: 20px;

  /*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
  // 滚动条整体部分
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
  &::-webkit-scrollbar-button {
    display: none;
  }

  // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
  &::-webkit-scrollbar-thumb {
    background: rgba(144, 147, 153, 0.3);
    cursor: pointer;
    border-radius: 4px;
  }

  // 边角，即两个滚动条的交汇处
  &::-webkit-scrollbar-corner {
    display: none;
  }

  // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
  &::-webkit-resizer {
    display: none;
  }


}

.span_y_tile {
  margin-top: 5px;
  width: auto;
  height: 13px;
  font-size: 13px;
  font-family: Inter-Regular, Inter;
  font-weight: 500;
  color: #404756;
  line-height: 13px;
  white-space: nowrap;
}

.action-bar {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  width: 100vw;
  height: 44px;
  background-color: #3D2A56;
  z-index: 99;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.2s ease;
  overflow-y: hidden;

  & p {
    color: white;
    font-weight: 700;
  }

  & .button {
    border: 1px solid white;
    margin-left: 20px;
    padding: 10px 20px;
    cursor: pointer;
  }

  &.show {
    transform: translateX(-50%) translateY(0%);
  }
}


.div_action {
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
}

.span_action {
  width: auto;
  height: auto;
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 16px;
}

.iiem_popover {
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: rgba(228, 93, 109, 1);
  height: 41px;
  width: fit-content;
  padding-right: 16px;
}

.span_item_popover_delete {
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 16px;
}

.span_tile_cancel {
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 16px;
}

</style>