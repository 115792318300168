<template>
  <div :key="GeneralViod" :style="{zIndex:option.attribute.zIndex}">
    <video id="example_video_1" controls preload="auto" ref="video" crossorigin="anonymous"
           :width="option.attribute.fontWeight"
           :height="option.attribute.textLineHeight" autoplay="autoplay" muted loop="loop" style="object-fit:fill;"
           :poster="videoImg" >
      <source :src="option.attribute.url" type="video/mp4">
      <source :src="option.attribute.url" type="video/ogg">
    </video>
  </div>
</template>

<script>
import {pollingRefresh} from "@/utils/refreshCptData";

export default {
  name: 'sig-video',
  props: {
    option: Object
  },
  data() {
    return {
      playerOptions: {},
      videoImg: require('@/assets/icon/video.svg'),
      videoUrl: 'https://v-cdn.zjol.com.cn/280443.mp4',
      poster: 'https://cdn.jsdelivr.net/gh/themusecatcher/resources@0.0.3/bao.jpg',
      uuid: null,
      isVideo: false,
      GeneralViod: Date.now()
    }
  },
  created() {
    console.log("------sigvideo----->")
    this.uuid = require('uuid').v1();
    $bus.on('sigvideo', data => {
      console.log("---$bus.on---sigvideo----->")
      console.log(this.option)
      console.log('------------------------>')
      if (data === 1) {
        this.GeneralViod = Date.now()
      }
    })
  },
  methods: {
    refreshCptData() {
      pollingRefresh(this.uuid, this.option, this.loadData)
    },
    loadData() {
      this.cptData = JSON.parse(this.option.attribute)
    },
  },
  watch: {
    option: {
      handler(o, v) {

      },
      deep: true
    }
  },


}
</script>

<style scoped>


</style>