var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({color:_vm.option.cptOption.attribute.textColor,fontSize: _vm.option.cptOption.attribute.textSize+'px',
  fontStyle:_vm.option.cptOption.attribute.fontStyle, fontWeight:_vm.option.cptOption.attribute.fontWeight,lineHeight:_vm.option.cptOption.attribute.textLineHeight+'px',
  backgroundColor:_vm.option.cptOption.attribute.bgColor, zIndex:_vm.option.cptOption.attribute.zIndex,
  fontFamily:_vm.option.cptOption.attribute.textFamily, textDecoration:_vm.option.cptOption.attribute.textDecoration,
      width:'100%',height:'100%',whiteSpace:'pre-wrap',display:'grid',alignItems:'center',
      justifyItems:_vm.option.cptOption.attribute.textAlign,overflow:'hidden'
   }),on:{"click":_vm.redirect}},[_vm._v(" "+_vm._s(_vm.cptData.value)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }