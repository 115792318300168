<template>
  <div v-if="show" class="mask">
    <div class="dlg-msg-box flex flex-col">
      <div class="flex-col div_forgot" style="width: 460px;height: auto;margin: auto;padding-bottom: 24px">
        <span class="title_span">Change password</span>
        <div class="flex-col" style="width: 460px;margin-top: 32px;">
          <span class="span_up_add"> By clicking “Send link now”, A link for resetting and changing</span>
          <span class="span_up_add">  password will be sent to your email address. Current</span>
          <span class="span_up_add">  password will be dropped. You will not be able to sign in this</span>
          <span class="span_up_add"> account until the new password is updated.</span>
        </div>
        <div class="flex-row" style="margin-left: 24px;margin-top: 40px;">
          <el-button class="send_now_div" :loading="loading" type="primary" @click="setView()">Send link now</el-button>
          <button class="button_cancel" style="margin-left: 16px;width: 198px;" @click="cancelClick()">Go back</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {checkSendEmail, resetPassWordBtn} from "@/api/login";

export default {
  name: "ChangePassword",
  props: {
    caption: {},
    show: {},
    msg: {},
    callback: {}
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    init() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = true;
    },
    close() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("close")
    },
    confirmClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("yes")
      //this.$emit('confirm');
    },
    cancelClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
     // this.callback("no")
    },
    setView() {
      let dataInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      if (this.IsEmail(dataInfo.email)) {
        this.loading = true
        resetPassWordBtn({userName: dataInfo.email}).then(postMessage => {
          if (postMessage.code === 200) {
            this.checkEmailState(postMessage.data.redisStr)
          } else {
            this.$message.error(postMessage.msg)
            this.loading = false
          }

        })
      } else {
        this.$message.error('Email format error')
      }

    },
    /**
     * 查看邮件发送状态
     */
    async checkEmailState(str) {

      let time = setInterval(async () => {
        let respons = await checkSendEmail(str)
        if (respons.data.isSned === 1) {
          this.loading = false;
          this.show = false;
          clearInterval(time)
          this.$showRegisterSu(
              {
                msg: ['The email has been sent successfully'],
                button: {
                  isconfirm: true,
                  iscancel: false,
                  confirm: 'confirm'
                },
                callback: (obj) => {
                  if (obj === 'yes') {
                    this.callback({code: 0, data: this.EmailAddress})
                  }
                }


              }
          )
        }
      }, 1000)
    },
  }
}
</script>

<style>
.send_now_div {
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  width: 198px;
  height: 40px;
  background: #9931F8;
  border: 1px solid #9931F8;
  border-radius: 999px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  font-size: 13px;
  color: #FFFFFF;
  line-height: 16px;
  text-align: center;
  font-style: normal;
  text-transform: none;

}
</style>
<style scoped>
@import url(dialogcss.css);
</style>