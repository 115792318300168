<template>
  <div>
    <div class="flex-row" style="margin-top: 20px;">
      <div class="flex-col">
        <span class="span_tile">X</span>
        <el-input v-model="attribute.cptX" autocomplete="off" style="width:156px;margin-top: 8px;" :maxlength="9"
                  @input="OInpi"></el-input>
      </div>
      <div class="flex-col" style="margin-left: 12px">
        <span class="span_tile">Y</span>
        <el-input v-model="attribute.cptY" autocomplete="off" style="width:156px;margin-top: 8px;" :maxlength="9"
                  @input="OInpi"></el-input>
      </div>
    </div>
    <div class="flex-row" style="margin-top: 12px;">
      <div class="flex-col">
        <span class="span_tile">Width</span>
        <el-input v-model="attribute.cptWidth" autocomplete="off" style="width:156px;margin-top: 8px;"
                  @input="OInpi"></el-input>
      </div>
      <div class="flex-col" style="margin-left: 12px">
        <span class="span_tile">Height</span>
        <el-input v-model="attribute.cptHeight" autocomplete="off"
                  style="width:156px;margin-top: 8px;"
                  @input="OInpi"></el-input>
      </div>

    </div>
    <div style="margin-top: 20px;">
      <span class="span_tilesx">Play Interval (in seconds, for images)</span>
      <el-input v-model="attribute.cptOption.attribute.Interval" autocomplete="off"
                style="width:324px;margin-top: 8px;"  :maxlength="8"
      ></el-input>
    </div>
    <div style="margin-top: 20px;" v-if="this.librarylist.length < 0">
      <span class="span_tile">Playlist</span>
      <div class="div_library" @click="onClicklibrary">
        <div class="flex-row" style="margin: auto">
          <img style="width: 16px;height: 16px;" :src="require('@/assets/icon/400.svg')">
          <span class="span_tile_libra">Add From Library</span>
        </div>
      </div>
    </div>
    <div style="margin-top: 20px;" class="flex-col" v-else>
      <div class="flex-row justify-between">
        <span class="span_tilesx">Playlist</span>
        <div class="flex-row" style="cursor: pointer;" @click="onClicklibrary">
          <img style="width: 16px;height: 16px;margin-right: 8px;" :src="require('@/assets/icon/iconplus500.svg')">
          <span class="spin_library">Add From Library</span>
        </div>
      </div>
      <div class="list-keep">
        <div class="flex-row palay_div justify-between" v-for="(item,index) in librarylist">
          <span :title="item.fileName">{{ index + 1 }}.{{ item.fileName }}</span>
          <div class="flex-row">
            <img style="width: 13px;height: 13px;cursor: pointer;" @click="setDown(item.fileId,index)"
                 :src="index === 0 ? require('@/assets/icon/iconarrowup2.svg'): require('@/assets/icon/iconarrowup1.svg')">
            <img style="width: 13px;height: 13px;cursor: pointer;margin-left: 8px;"
                 @click="setUplist(item.fileId,index)"
                 :src="index === librarylist.length-1  ? require('@/assets/icon/iconarrowdown1.svg') : require('@/assets/icon/iconarrowdown.svg')">
            <img style="width: 13px;height: 13px;cursor: pointer;margin-left: 8px;" @click="DeleteList(item.fileId)"
                 :src=" require('@/assets/icon/iconclose400.svg')">
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FreePlaylist',
  props: {
    attribute: Object
  },
  data() {
    return {
      librarylist: []
    }
  },
  created() {
    this.librarylist = this.attribute.cptOption.attribute.urlist
    $bus.on('FreePlaylist', data => {
      this.librarylist = data.list
      this.attribute.cptOption.attribute.urlist = data.list
      this.attribute.cptOption.attribute.url = data.list.map(it => it.filePath).toString()
      $bus.emit("sigPlaylist", 1)
    })
  },
  methods: {
    OInpi() {
      this.attribute.cptOption.attribute.fontWeight = this.attribute.cptWidth
    },
    onClicklibrary() {
      $bus.emit("freeboard", {id: 1, data: this.librarylist})
    },
    setDown(data, Index) {
      if (Index !== 0) {
        //下移
        let index = this.librarylist.findIndex(it => it.fileId === data)
        var tempOption = this.librarylist[index - 1]
        this.$set(this.librarylist, index - 1, this.librarylist[index])
        this.$set(this.librarylist, index, tempOption)
      }
      $bus.emit("sigPlaylist", 1)
    },
    setUplist(data, Index) {
      if (Index !== this.librarylist.length - 1) {
        //上移
        let index = this.librarylist.findIndex(it => it.fileId === data)
        var tempOption = this.librarylist[index + 1]
        this.$set(this.librarylist, index + 1, this.librarylist[index])
        this.$set(this.librarylist, index, tempOption)
      }
      $bus.emit("sigPlaylist", 1)
    },
    DeleteList(data) {
      let Index = this.librarylist.findIndex(itme => itme.fileId === data)
      this.librarylist.splice(Index, 1)
      $bus.emit("sigPlaylist", 1)
    },

  },
  watch: {
    attribute: {
      handler(o, l) {
        this.attribute.cptOption.attribute.fontWeight = this.attribute.cptWidth
        this.attribute.cptOption.attribute.fontHeight = this.attribute.cptHeight
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
.span_tilesx {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  font-family: Inter-SemiBold, Inter;
  font-weight: 400;
  color: #3D2A56;
  line-height: 12px;
}

.span_tile {
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #C0BFC0;
  line-height: 12px;
}

.span_tile_name {
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #1E1E1E;
  line-height: 14px;
}


.span_tile_type {
  height: 13px;
  font-size: 13px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #1E1F21;
  line-height: 13px;
}

.div_library {
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  width: 324px;
  height: 40px;
  background: #9931F8;
  border-radius: 999px;
}

.span_tile_libra {
  margin-left: 8px;
  width: fit-content;
  height: 16px;
  font-size: 13px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #FFFFFF;
}

.spin_library {
  font-size: 13px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #9931F8;
  line-height: 16px;
}

.palay_div {
  padding-top: 16px;
  height: 42px;
  border-bottom: 1px solid #ECECEC;
}

.palay_div span {
  cursor: pointer;
  width: 232px;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #1E1E1E;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.list-keep {
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 11px;
  padding-right: 20px;
  height: 380px;

  /*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
  // 滚动条整体部分
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
  &::-webkit-scrollbar-button {
    display: none;
  }

  // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
  &::-webkit-scrollbar-thumb {
    background: rgba(144, 147, 153, 0.3);
    cursor: pointer;
    border-radius: 4px;
  }

  // 边角，即两个滚动条的交汇处
  &::-webkit-scrollbar-corner {
    display: none;
  }

  // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
  &::-webkit-resizer {
    display: none;
  }


}

</style>