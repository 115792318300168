<template>
  <el-dialog
      :modal-append-to-body="true"
      custom-class="classdois"
      title=""
      :show-close="false"
      width="730px"
      :visible.sync="exist"
      :close-on-click-modal="false"
      :close-on-press-escape="false">
    <div class="flex-col div_forgot">
      <div class="flex-row title_div justify-between">
        <span class="title_span">Select Device</span>
        <img style="cursor: pointer;width: 12px;height: 12px;margin-top: 24px;" @click="Close"
             :src="require('@/assets/icon/code601.svg')">
      </div>
      <div v-loading="loading">
        <div class="flex-row justify-between" style="margin-top: 16px;">
          <input class="elinput_inner" v-model="Deviceinput" placeholder="Device name" @change="getListData"/>
          <el-select style="width: 271px;margin-left: 12px" v-model="value" placeholder="Groups" clearable multiple
                     @change="getListData">
            <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.groupName"
                :value="item.id">
            </el-option>
          </el-select>
          <el-select style="width: 130px;margin-left: 12px;margin-right: 16px;background: #F6F6F6;"
                     @change="getListData"
                     v-model="Statusvalue" placeholder="Status" clearable>
            <el-option
                v-for="item in Statusoptions"
                :key="item.value"
                :label="item.label"
                :value="item.isTrial">
            </el-option>
          </el-select>

        </div>
        <div class="flex-row"
             style="display: flex;align-content: center;align-items: center;margin-top: 16px;">
          <div style="width: 52px;padding-left: 16px">
            <el-checkbox
                :indeterminate="tablePush.indeterminate"
                @change="onClickNames()"
                v-model="tablePush.isSelected"
            >
            </el-checkbox>
          </div>
          <div style="width: 300px;padding-left: 16px;"><p class="span_tile_name">Device Name</p></div>
          <div style="width: 320px;"><p class="span_tile_name">Serial Number</p></div>
          <div style="width: 180px;"><p class="span_tile_name">Status</p></div>
        </div>
        <div style="height: 400px;">
          <virtual-list
              class="list-keep"
              :style="{height:380+'px'}"
              :estimate-size="60"
              :data-key="'id'"
              :data-sources="listData"
              :data-component="itemContactsitem"/>
        </div>
        <div v-bind:class="{selected_div:isOnlick,selected_div_false:!isOnlick}" @click="OnClisk">Push To Selected
          Devices
        </div>
      </div>
    </div>

  </el-dialog>

</template>

<script>
import VirtualList from "vue-virtual-scroll-list";
import Contactsitem from "@/popover/DevIcleItme.vue";
import {getGroups, museaDevicelist, pushMuseaBoard} from "@/api/login";

export default {
  name: 'SelectDevice',
  components: {
    'virtual-list': VirtualList
  },
  props: ['projecData'],
  data() {
    return {
      exist: true,
      listData: [],
      itemContactsitem: Contactsitem,
      seveList: [],
      isOnlick: false,
      loading: false,
      tablePush: {
        indeterminate: false,
        isSelected: false
      },
      Deviceinput: '',
      value: '',
      Statusvalue: '',
      Statusoptions: [
        {value: 'Offline', label: 'Offline', isTrial: '0'},
        {value: 'Online', label: 'Online', isTrial: '1'}
      ],
      options: []

    }
  },
  created() {
    this.oflicense()
    console.log(this.projecData)
    $bus.on('DevIcleItme', data => {
      console.log(data)
      if (data.code === 1) {
        this.selectFile(data.data)
      }
    })
    this.getListData()
    this.getGroupslist()
  },
  methods: {
    Close() {
      this.$emit('chickBack')
    },
    OnClisk() {
      if (this.isOnlick) {
        this.loading = true
        console.log(this.projecData)
        var regesterIds = this.seveList.map(itme => itme.regesterId)
        let data = {}
        if (this.projecData.code === 0) {
          data = {
            boardId: this.projecData.id,
            pushIds: regesterIds.toLocaleString()
          }
        } else {
          data = {
            stackId: this.projecData.id,
            pushIds: regesterIds.toLocaleString()
          }
        }
        console.log(data)
        pushMuseaBoard(data).then(pushMuseaBoard => {
          if (pushMuseaBoard.code === 200) {
            this.loading = false
            this.$emit('chickBack')
          }
        })
      }

    },
    selectFile(obj) {
      let numb = []
      if (obj.isSelected) {
        this.seveList.push(obj)
      } else {
        let Index = this.seveList.findIndex(itme => itme.id === obj.id)
        this.seveList.splice(Index, 1)
      }
      this.listData.forEach(it => {
        if (it.isSelected) {
          numb.push(it)
        }
      })
      if (numb.length > 0) {
        this.tablePush.indeterminate = true
      } else {
        this.tablePush.indeterminate = false
      }
      if (numb.length === this.listData.length) {
        this.tablePush.indeterminate = false
        this.tablePush.isSelected = true
      } else {
        this.tablePush.isSelected = false
      }

    },
    getListData() {
      let dataInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      let data = {
        userId: dataInfo.userId,
        deviceName: this.Deviceinput,
        groupIds: this.value.toString(),
        status: this.Statusvalue
      }
      this.tablePush = {indeterminate: false, isSelected: false}
      this.listData.splice(0, this.listData.length)
      this.seveList.splice(0, this.listData.length)
      museaDevicelist(data).then(pomsitme => {
        if (pomsitme.code === 200) {
          pomsitme.rows.forEach(item => {
            if (item.status === '0' && item.licenseId !== -2) {
              item.statusMessage = 'Offline'

            } else if (item.status === '1' && item.licenseId !== -2) {
              item.statusMessage = 'Online'
            } else if (item.status === '2') {
              item.statusMessage = 'Deregistered'
            }
            if (item.licenseId === -2) {
              item.status = '0'
              item.statusMessage = 'Expired'
            }
            item.isSelected = false
            this.listData.push(item)
          })
        }


      })
    },
    onClickNames() {
      let flise = true
      for (const it of this.listData) {
        if (it.status === '1') {
          it.isSelected = this.tablePush.isSelected
          if (it.isSelected) {
            if (this.seveList.findIndex(iteta => (iteta.id === it.id)) === -1) {
              this.seveList.push(it)
            }
          } else {
            this.seveList.splice(this.seveList.findIndex(iteta => (iteta.id === it.id)), 1)
          }
        } else {
          flise = false
          break
        }
      }
      if (flise) {
        if (!this.tablePush.isSelected) {
          // this.selectingItems.splice(0, this.tablePush.tableData.length)
          if (this.seveList.length === 0) {
            $bus.emit('actionbarvie', true)
          }

          this.isbootmenu = true
        } else {
          if (this.seveList.length > 0) {

            this.tablePush.indeterminate = false
          }
        }
        this.seveList.forEach(it => {
          if (!it.isSelected) {
            this.seveList.splice(this.seveList.findIndex(iteta => (iteta.id === it.id)), 1)
          }
        })
      } else {
        this.tablePush = {indeterminate: false, isSelected: false}
        this.$message.error("There are offline devices in the list, so we cannot select all of them")
      }


    },
    getGroupslist() {
      getGroups().then(postMessage => {
        if (postMessage.code === 200) {
          this.options = postMessage.data
        }
      })
    },
    oflicense() {
      let licenes = JSON.parse(sessionStorage.getItem('Licenselist'))
      let omse = licenes.filter(it => it.dataleft > 0 && it.licenseType === '4')
      console.log(licenes)
      if (omse.length < 1) {
        this.Close()
        this.$showMsgBox({
          caption: 'License expired',
          icon: 'failed',
          msg: ['Your license for MUSEA has been expired.', 'Please consider to renew your license to','enjoy MUSEA features.'],
          button: 'Go Back',
          callback: (data) => {
            this.getCode()
          }
        })
      }
    }


  },
  watch: {
    seveList: {
      handler(o, v) {
        if (o.length > 0) {
          this.isOnlick = true
        } else {
          this.isOnlick = false
        }
      },
      deep: true
    }
  }

}
</script>

<style scoped>
/deep/ .el-dialog {
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 0 0 rgba(255, 255, 255, 0);
}

/deep/ .el-dialog__wrapper {
  background-color: rgba(242, 242, 242, 1);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  transition: all 0.2s ease-out;

}

.content /deep/ .el-dialog__header {
  display: none;
}

.content /deep/ .el-dialog__body {
  padding: 0;
}

/deep/ .el-dialog.classdois {
  border-radius: 5px;
}

::v-deep .el-input__inner {
  border: 1px solid #F6F6F6;
  height: 36px;
  border-radius: 5px;
  background-color: #F6F6F6;
  font-family: Inter-Regular;
  font-size: 13px;
  color: #3D2A56;
}
</style>
<style lang="scss" scoped>
::v-deep .el-checkbox {
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #303030;

  .el-checkbox__inner {
    width: 26px;
    height: 26px;
    border-radius: 50%;

    border: 1px solid rgba(149, 149, 149, 1);
    //修改选中框中的对勾的大小和位置
    &::after {
      top: 5px;
      height: 12px;
      left: 10px;
    }
  }

  /* 自定义indeterminate状态下复选框的样式 */
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: rgba(149, 149, 149, 1); /* 自定义颜色 */
    border-color: rgba(149, 149, 149, 1); /* 自定义边框颜色 */
  }

  .el-checkbox__input.is-indeterminate .el-checkbox__inner::after {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(1); /* 自定义对勾的大小和位置 */
    opacity: 1; /* 自定义对勾的不透明度 */
    background-color: #9931F8; /* 自定义对勾的颜色 */
  }


  .el-checkbox__label {
    display: inline-grid;
    color: #FFFFFF;
    width: 200px;

  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    border: 1px solid #9931F8;
    background-color: #9931F8;
  }


  .el-checkbox__label {
    display: inline-grid;
    color: #FFFFFF;
    width: 200px;
    background-color: #9931F8;

  }

  //修改点击文字颜色不变
  .el-checkbox__input.is-checked + .el-checkbox__label {
    border: 1px solid rgba(149, 149, 149, 1);
    background: rgba(149, 149, 149, 1);
    color: #303030;

  }


}


.elinput_inner {
  padding-left: 26px;
  margin-left: 16px;
  width: 276px;
  background: url('../assets/icon/search.svg') no-repeat center center;
  background-color: #F6F6F6;
  background-size: 12px 12px;
  background-repeat: no-repeat;
  background-position: 3% 13px;
  border: 1px solid #F6F6F6;
  border-radius: 5px;
  height: 36px;
  font-family: Inter-Regular;
  outline-color: #F6F6F6;
  font-size: 13px;
  color: #C0BFC0;
}

.title_div {
  padding-left: 16px;
  border-bottom: 1px solid #FFFFFF;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding-right: 16px;
  background-color: #FFFFFF;
  width: auto;
  height: 44px;
}


.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title_span {
  width: 100%;
  height: auto;
  font-size: 16px;
  font-family: Inter-SemiBold, Inter;
  color: #3D2A56;
  line-height: 20px;
}

.button_gotit {
  margin: auto;
  cursor: pointer;
  width: 120px;
  height: 40px;
  background: #FFFFFF;
  color: #C0BFC0;
  font-family: Inter-SemiBold, Radikal;
  font-size: 13px;
  border: 1px solid #C0BFC0;
  border-radius: 999px;
}


.div_forgot {
  width: auto;
  height: fit-content;
  background-color: #FFFFFF;
  border-radius: 5px;
  padding-bottom: 20px;
  box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.1);

}

.icon2 {
  cursor: pointer;
  width: 12px;
  height: 12px;
}

.span_up_add {
  text-align: center;
  width: 100%;
  font-size: 14px;
  font-family: Radikal-Regular, Radikal;
  font-weight: 400;
  color: #959595;
  line-height: 18px;
}

.span_for_mag {
  display: flex;
  margin: auto;
  font-size: 13px;
  text-align: center;
  font-weight: 400;
  color: #959595;
  line-height: 17px;
  font-family: Radikal-Regular, Radikal;
}


//旋转动画
@-webkit-keyframes mymove {
  from {
    transform: rotate(10deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes mymove {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icon4 {
  width: 130px;
  height: 130px;
  animation: mymove 1ms infinite;
  -webkit-animation: mymove 500ms infinite;
}

.soan_file {
  margin: auto;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #1E1E1E;
  line-height: 19px;
}

.icon5 {
  width: 130px;
  height: 130px;
}


.selected_div {
  cursor: pointer;
  margin: auto;
  text-align: center;
  width: 195px;
  height: 40px;
  background: #9931F8;
  border-radius: 999px;
  font-size: 13px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 40px;
}

.selected_div_false {
  cursor: pointer;
  margin: auto;
  text-align: center;
  width: 195px;
  height: 40px;
  background: #C0BFC0;
  border-radius: 999px;
  font-size: 13px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 40px;
}

.span_tile_name {
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #C0BFC0;
  line-height: 12px;
}

.list-keep {
  height: 350px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 11px;


  /*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
  // 滚动条整体部分
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
  &::-webkit-scrollbar-button {
    display: none;
  }

  // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
  &::-webkit-scrollbar-thumb {
    background: rgba(144, 147, 153, 0.3);
    cursor: pointer;
    border-radius: 4px;
  }

  // 边角，即两个滚动条的交汇处
  &::-webkit-scrollbar-corner {
    display: none;
  }

  // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
  &::-webkit-resizer {
    display: none;
  }


}

.select {
  border-radius: 5px;
  height: 36px;
  left: 10px;
  top: 10px;
}

/* select框 */
.select .el-input__inner {
  border-radius: 5px;
  background-color: #001334;
  border: 1px solid #3882c8;
}

/* 列表 */
.el-select-dropdown__list {
  background-color: #FFFFFF;
}

/* 下拉列表外部盒子 */
.el-select-dropdown {
  border: 1px solid #FFFFFF;
  border-radius: 0px;
}

/* 占位符样式 */
.el-input__inner::placeholder {
  color: #6A84AE;
}

/* 下拉列表每一项样式  */
.el-select-dropdown__item {
  background-color: #FFFFFF !important;
  color: rgba(56, 56, 56, 1);
}

/* 鼠标滑过样式 */
.el-select-dropdown__item:hover {
  background-color: #f6f6f6 !important;
}

/* 选中样式 */
.elpopperbox .el-select-dropdown__item.selected {
  color: #3882c8 !important;
}

/* 下拉列表的小三角 */
.el-popper .popper__arrow::after {
  border-bottom: 5px solid #3882c8 !important;
}


</style>