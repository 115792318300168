<template>
  <div class="flex-row outer_data align-between">
    <div class="flex-row bd5_tile">
      <div class="div_key flex-row">
        <p class="p">{{ source.licenseKey }}</p>

      </div>
      <div class="div_type">
        <p class="p">{{ source.Type }}</p>
      </div>
      <div class="div_content">
        <p class="p">{{ source.expiryTime }}</p>

      </div>
      <div class="div_content">
        <p class="p">{{ source.renewTime }}</p>

      </div>
      <div class="div_content">
        <p class="p">{{ source.dataleft }}</p>

      </div>
      <div class="div_content">
        <p class="p">{{ source.validityta }}</p>

      </div>
      <div class="icon" style="width: 105px;display: flex;align-items: center;">
        <img style="width: 18px;height: 18px;cursor: pointer;" src="@/assets/icon/ic_open.svg"
             @click="onclickOnfin(source)"/>
      </div>
    </div>

  </div>
</template>

<script>


export default {
  name: "LicensesItme",
  props: {
    index: { // 每一行的索引
      type: Number
    },
    source: { // 每一行的内容
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    onclickOnfin(e) {
      this.$showDetails({
        button: this.source,
        callback: (data) =>{

        }
      })
    }

  }

}
</script>


<style scoped lang="scss">
.justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

::v-deep .el-checkbox {
  margin-left: 16px;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #303030;

  .el-checkbox__inner {
    width: 26px;
    height: 26px;
    border-radius: 50%;

    border: 1px solid rgba(149, 149, 149, 1);
    //修改选中框中的对勾的大小和位置
    &::after {
      top: 5px;
      height: 12px;
      left: 10px;
    }
  }

  .el-checkbox__label {
    display: inline-grid;
    color: #FFFFFF;
    width: 200px;

  }

  //修改点击文字颜色不变
  .el-checkbox__input.is-checked + .el-checkbox__label {
    border: 1px solid rgba(0, 145, 255, 1);
    background: rgba(0, 145, 255, 1);
    color: #303030;

  }


}

.align-between {
  align-content: center;
  display: flex;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.outer_data {
  min-width: 1440px;
  width: 100%;
  height: 52px;
  margin-bottom: 10px;
  border-radius: 12px;
  border: 1px solid #FFFFFF;
  background-color: #FFFFFF;
  transition: 0.2s ease;

  & .icon {
    opacity: 0;
  }


  &:not(.selecting):hover {
    transform: translateY(-1px);
    filter: drop-shadow(0px 4px 4px rgba(48, 48, 48, 0.15));

    & .icon {
      opacity: 1;

    }

  }


}

.span_name_tile_name {
  margin: auto 0;
  cursor: pointer;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #404756;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.span_name {
  margin: auto 0;
  cursor: pointer;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #404756;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.bd5_tile {
  height: auto;

  .div_key {
    padding-left: 20px;
    display: flex;
    align-content: center;
    width: 340px;

  }

  .div_type {
    display: flex;
    align-content: center;
    width: 320px;

  }

  .div_content {
    display: flex;
    align-content: center;
    width: 160px;

  }


  .p {
    width: auto;

    font-family: Inter-Regular, Inter;
    font-weight: 400;
    font-size: 14px;
    color: #1E1E1E;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

}

</style>