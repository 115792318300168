<template>
  <div class="login-container flex-col">
    <el-form ref="loginForm" :model="loginForm" size="small" :rules="loginRules" v-if="itme ===0"
             class="login-form" auto-complete="on" label-position="left">
      <div class="flex-col">
        <img style="width:96px;height: 96px;margin: auto;" :src="require('@/assets/logo.svg')">
        <span class="title0" style="margin-top: 14px">MUSEA</span>
        <span class="title1" style="margin-top: 6px">By GloryStar</span>
      </div>
      <el-form-item prop="username" style="margin-top: 64px">
        <div class="formDiv" style="height: 46px;">
          <input class="ipt" v-model="loginForm.username" placeholder="Email address" readonly="readonly"/>
        </div>
      </el-form-item>
      <el-form-item prop="password">
        <div class="formDiv" style="height: 46px;">
          <input class="ipt" v-model="loginForm.password" autocomplete="new-password" :max="20" :maxlength="20"
                 :type="passwordType" placeholder="Password"/>
          <span class="show-pwd" @click="showPwd">
          </span>
        </div>
      </el-form-item>
      <el-form-item prop="password">
        <div class="formDiv" style="height: 46px;">
          <input class="ipt" v-model="loginForm.newPassWord" autocomplete="new-password" :maxlength="20"
                 :type="passwordType" placeholder="Confirm New Password"/>
          <span class="show-pwd" @click="showPwd">

          </span>
        </div>
      </el-form-item>
      <el-row :gutter="12">
        <el-col :span="12">
          <el-form-item prop="username">
            <div class="formDiv" style="height: 56px;">
              <input class="ipt" v-model="loginForm.code" placeholder="CAPTCHA" @keyup.enter="handleLogin"/>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <img :src="baseUrl" @click="getCode()" style="width: 100%;height:56px;"/>
          <!-- time刷新作用-->
        </el-col>
      </el-row>
      <el-button type="primary" class="button_logo" @click.native.prevent="submitNewPassWord">Reset Password</el-button>
    </el-form>

    <span class="text_box_masn">© 2024 Glory Star. All rights reserved</span>
  </div>
</template>

<script>
import {captchaImage, querSetNewPassWordUser, resetPassWord, setNewPassword} from "@/api/login";

export default {
  name: "setPassWord",

  data() {
    const validateUsername = (rule, value, callback) => {
      callback()
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 0) {
        callback(new Error('Password illegal'))
      } else {
        callback()
      }
    }
    return {
      loginForm: {username: '', password: '', newPassWord: '', code: '', uuid: ''},
      loginRules: {
        username: [{required: true, trigger: 'blur', validator: validateUsername}],
        password: [{required: true, trigger: 'blur', validator: validatePassword}]
      },
      loginForms: {
        username: '',
        password: '',
        passwordAgain: '',
        code: '',
        uuid: '',
        redisCode: ''
      },
      checked: '',
      loading: false,
      baseUrl: '',
      passwordType: 'password',
      redirect: undefined,
      time: '',
      uuid: '',
      itme: 0,
      registerForm: {username: '', password: '', code: '', uuid: ''},
      registerRules: {
        username: [{required: true, trigger: 'blur', validator: validateUsername}],
        password: [{required: true, trigger: 'blur', validator: validatePassword}]
      },
      setType: '',
      isUpdateStr: '',
      tile: '',
      mag: ''
    }
  },
  created() {
    this.getCode()
    this.queryUserEmail()
  },

  methods: {
    handleLogin() {

    },
    seveRegister() {

    },
    showPwd() {

    },
    getCode() {
      captchaImage().then(item => {
        this.baseUrl = 'data:image/jpg;base64,' + item.img
        this.loginForm.uuid = item.uuid
        this.registerForm.uuid = item.uuid

      })
    },
    /**
     * 查询当前用户邮箱
     */
    async queryUserEmail() {
      let id = sessionStorage.getItem('setPassWord')
      let obj = this.queryUrlParams()
      console.log(obj)
      id = id ? id : obj.userId

      if (!id) {
        setTimeout(() => {
          this.$router.push('/login')
        }, 1000)
      }
      let str = sessionStorage.getItem('upStr')

      str = str ? str : obj.upStr
      let data = {
        id: id,
        str: str
      }

      this.isUpdateStr = str
      let respons = await querSetNewPassWordUser(data)
      if (respons.code == 200) {
        this.loginForm.username = respons.data.userName
        this.loginForm.redisCode = respons.data.redisStr
      } else if (respons.code == -1) {
        setTimeout(() => {
          this.$router.push('/login')
        }, 1000)
      } else {
        this.$store.dispatch('app/setPopupClobal', {
          isShow: true,
          text: {
            headerText: 'System error',
            mainText: 'An unknown error'
          }
        })

        setTimeout(() => {
          this.$router.push('/login')
        }, 1000)
      }
    },
    /**
     *  提交重新设置的密码
     */
    async submitNewPassWord() {
      console.log("--submitNewPassWord----")
      let data = this.loginForm
      data.isUpdateStr = this.isUpdateStr
      if (!data.username) {
        this.$message.error({message: 'Mailbox lost. Trying to retrieve mailbox'})


        await this.queryUserEmail()
        return false
      } else if (!data.password && !data.newPassWord) {

        this.$message.error({message: 'No new password entered'})
        return false
      } else if (!data.code) {
        this.$message.error({message: 'No verification code entered'})
        return false
      }
      if (data.password !== data.newPassWord) {
        this.$message.error({message: 'The two password inputs are inconsistent'})
        return false
      }
      if (data.password.length < 3 && data.newPassWord.length < 3) {
        this.$message.error({message: 'The number of password digits cannot be less than 4 digits'})
        return false
      }


      console.log("--submitNewPassWord---11-")
      let setType = sessionStorage.getItem('setType')
      let obj = this.queryUrlParams()
      setType = setType ? setType : obj.setType
      console.log("--submitNewPassWord-setType--11---" + setType)
      if (setType === '0' || setType === '2') {
        let respons = await setNewPassword(data)
        if (respons.code === 200) {
          this.itme = 1
          this.$showRegisterSu(
              {
                msg: [
                  'Activation process completed. You can now sign in with your email',
                  'address and password.'
                ],
                button: {
                  isconfirm: true,
                  iscancel: false,
                  confirm: 'Go To Login'
                },
                callback: (obj) => {
                  this.$router.push('/login')
                }
              }
          )

          /*        this.tile = 'Account activated'
                  this.mag = 'Activation process completed. You could now sign in with your email address and password.'
        */
          return false
        } else if (respons.code == -1 || respons.code == -2) {
          this.$message.error({message: respons.msg})

          this.getCode()
          return false
        } else {
          this.$message.error({message: respons.msg})
          setTimeout(() => {
            this.$router.push('/login')
          }, 1000)
        }
      } else if (setType == '1') {
        let respons = await resetPassWord(data)
        if (respons.code == 200) {
          this.itme = 1
          this.$showRegisterSu(
              {
                msg: [
                  'Activation process completed. You can now sign in with your email',
                  'address and password.'
                ],
                button: {
                  isconfirm: true,
                  iscancel: false,
                  confirm: 'Go To Login'
                },
                callback: (obj) => {
                  this.$router.push('/login')
                }
              }
          )
          return false
        } else if (respons.code == -1 || respons.code == -2) {
          this.$message.error({message: respons.msg})
          this.getCode()
          return false
        } else if (respons.code == -3) {
          this.$message.error({message: respons.msg})
          setTimeout(() => {
            this.$router.push('/login')
          }, 1000)
        }
      }
    },
  }

}
</script>

<style scoped>


.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.login-container {
  display: flex;
  background: url("@/assets/Loginbg.svg");
  min-height: 100%;
  width: 100%;
  overflow: hidden;
}

.login-form {

  border-radius: 24px 24px 24px 24px;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.08);
  background: #FFFFFF;
  position: relative;
  width: 460px;
  max-width: 100%;
  padding: 36px 35px 0 35px;
  margin: auto;
  overflow: hidden;
}

.title0 {
  font-family: Inter-SemiBold, Inter;
  font-size: 26px;
  color: #9931F8;
  margin: auto;
  text-align: center;
  font-weight: bold;
}

.title1 {
  font-size: 12px;
  font-family: Inter, Inter;
  margin: auto;
  font-weight: 400;
  color: #969DAC;
  line-height: 12px;
}

.show-pwd {
  margin: auto;
  position: absolute;
  right: 30px;
  top: 10px;
  font-size: 16px;
  color: #889aa4;
  cursor: pointer;
  user-select: none;
}

.formDiv {
  margin: auto;
  text-align: center;
  background: #E0E1DD;
  border-radius: 36px 36px 36px 36px;
  opacity: 1;
  border: 1px solid #E0E1DD;

}

.formLabel {
  display: inline-block;
  width: 30px;
  font-size: 18px;
  color: #889aac;
  text-align: right
}

.ipt {
  text-align: center;
  display: inline-block;
  width: 80%;
  height: 100%;
  font-size: 15px;
  color: #404756;
  padding-left: 10px;
  border: none;
  background: #E0E1DD;
  outline: none
}

.ipt::-ms-reveal {
  display: none
}

.span_tile {
  cursor: pointer;
  width: 100%;
  height: 14px;
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #AFAFAF;
  line-height: 14px;
}


.span_tile1 {
  cursor: pointer;
  width: 100%;
  height: 14px;
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: rgba(153, 49, 248, 1);
  line-height: 14px;
}

.button_logo {
  width: 100%;
  margin-bottom: 16px;
  background: #9931F8;
  border-radius: 999px;
  margin-top: 23px;
  border: #9931F8;
  height: 42px;
  font-family: Inter-SemiBold, Inter;
  font-size: 14px;
}

.go_login {
  cursor: pointer;
  text-align: right;
  width: 100%;
  height: 42px;
  color: #aaaaaa;
  font-family: Inter-SemiBold, Inter;
  font-size: 14px;
}

.text_box_masn {
  position: fixed;
  bottom: 24px;
  width: 100%;
  text-align: center;
  color: #A4ABBD;
  font-size: 12px;
  font-family: Inter-Regular, Inter, serif;
}


</style>