<template>
  <div style="width: 100%; height: 100%" >
    <img style="width: 100%; height: 100%;position: relative; display: inline-block;overflow: hidden;" :id="option.cptOption.id" crossOrigin="anonymous" alt="img"
         :preview-src-list="option.cptOption.attribute.preview ? [option.cptOption.attribute.url]:[]"
         :style="{zIndex:option.cptOption.attribute.zIndex}"
         :src="option.cptOption.attribute.url? option.cptOption.attribute.url: require('@/assets/imageins.svg')"
         :fit="option.cptOption.attribute.fit"/>
  </div>
</template>
<script>
import {fileUrl} from "/env";

export default {
  name: "tem-img",
  props: {option: Object},

  data() {
    return {
      fileUrl: fileUrl,
    }
  },
  created() {
    console.log('---sig-img--->')
    console.log(this.option)
  }

}
</script>
<style scoped>

</style>