import Vue from 'vue'

import cpt_text_option from '@/element/options/cpt-text-option'


let cptOptionsList =[
    cpt_text_option
]
cptOptionsList.forEach(ele => {
    Vue.component(ele.name, ele);
})
