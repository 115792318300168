<template>
  <div style="overflow-y: hidden;" class="flex-col">
    <div class="flex-row justify-between" style="display: flex;align-items: center;margin-top: 20px"
         v-if="attribute.Contvalue!==''">
      <div class="flex-col">
        <span class="span_tile">Content type</span>
        <span class="span_tile_name">{{ typename }}</span>
      </div>
      <div class="div_change" @click="OnlicLisb">
        <span class="span_reset">Reset & Change</span>
      </div>
    </div>
    <div class="flex-col" v-if="attribute.Contvalue===''" style="margin-top: 20px">
      <span class="span_tile">Content type</span>
      <el-select v-model="attribute.Contvalue" placeholder="Select a content type" style="margin-top: 8px"
                 @change="onChangeView">
        <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div style="margin-top: 20px;">
      <component :is="attribute.Contvalue" :attribute='attribute'/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contenttype',
  props: {
    attribute: Object
  },
  data() {
    return {
      value: '',
      typename: '',
      options: [
        {value: 'left-video', label: 'Video', id: 0},
        {value: 'left-image', label: 'Image', id: 1},
        {value: 'left-text', label: 'Text', id: 2},
        {value: 'Left-Rssfeed', label: 'RSS Feed', id: 3},
        {value: 'left-playlist', label: 'Playlist', id: 4}
      ]
    }
  },
  created() {
    switch (this.attribute.cptKey) {
      case 'tem-vido':
        this.typename = 'Video'
        break
      case 'tem-img':
        this.typename = 'Image'
        break
      case 'tem-text':
        this.typename = 'Text'
        break
      case 'temsubtitles':
        this.typename = 'RSS Feed'
        break
      case  'templaylist':
        this.typename = 'Playlist'
        break
    }

  },
  methods: {
    onChangeView(e) {
      console.log(this.attribute)
      switch (e) {
        case  'left-video':
          this.typename = 'Video'
          this.attribute.cptKey = 'tem-vido'
          this.attribute.cptOption = {
            attribute: {
              url: '',
              fontWeight: this.attribute.cptWidth,
              textLineHeight: this.attribute.cptHeight,
              type: 0,
              zIndex: 100
            }
          }

          break
        case 'left-image':
          this.typename = 'Image'
          this.attribute.cptKey = 'tem-img'
          this.attribute.cptOption = {
            attribute: {
              url: require('@/assets/imageins.svg'),
              fit: 'fill',
              preview: false,
              type: 0,
              zIndex: 100
            }
          }
          break
        case 'left-text':
          this.typename = 'Text'
          this.attribute.cptKey = 'tem-text'
          this.attribute.cptOption = {
            cptDataForm: {dataText: '{"value":"Plain text"}', dataSource: 1, pollTime: 0},
            attribute: {
              url: '', textColor: '#000000', textSize: 25,
              fontWeight: 'normal', textLineHeight: 'normal',
              textFamily: 'Microsoft YaHei', textAlign: 'left',
              fontStyle: 'oblique', textDecoration: 'none', bgColor: 'rgba(255, 255, 255, 0)',
              zIndex: 100
            }
          }
          break
        case  'Left-Rssfeed':
          this.typename = 'RSS Feed'
          this.attribute.cptKey = 'temsubtitles'
          this.attribute.cptOption = {
            cptDataForm: {dataText: '{"value":"Plain text"}', dataSource: 1, pollTime: 0},
            attribute: {
              url: '',
              textColor: '#000000',
              textSize: 25,
              fontWeight: 'normal',
              textLineHeight: 'normal',
              textFamily: 'Microsoft YaHei',
              textAlign: 'left',
              fontStyle: 'normal',
              textDecoration: 'none',
              bgColor: 'rgba(255, 255, 255, 0)',
              zIndex: 100,
              direction: 'marquees'
            }
          }

          break
        case  'left-playlist':
          this.typename = 'Playlist'
          this.attribute.cptKey = 'templaylist'
          this.attribute.cptOption = {
            attribute: {
              url: '',
              urlist: [], fontWeight: 200,
              fontHeight: 200, type: 1, Interval: 10
            }
          }
          break
      }
    },
    OnlicLisb() {
      this.attribute.Contvalue = ''
      this.attribute.cptKey = 'empty-sig'

    }
  }
}

</script>

<style scoped>

.flex-col {
  display: flex;
  flex-direction: column;
}

.span_tile {
  font-size: 13px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #1E1F21;
  line-height: 13px;
}

.div_change {
  cursor: pointer;
  display: flex;
  width: 154px;
  height: 40px;
  border-radius: 999px;
  border: 1px solid #0091FF;
}

.span_reset {
  margin: auto;
  width: 122px;
  height: 16px;
  font-size: 13px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #0091FF;
  line-height: 16px;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.align-between {
  display: flex;
  align-items: center;
}

.span_tile_name {
  margin-top: 12px;
  width: 158px;
  height: 14px;
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #1E1F21;
  line-height: 14px;
}

</style>