<template>
  <div :style="{color:option.attribute.textColor,fontSize: option.attribute.textSize+'px',
    fontStyle:option.attribute.fontStyle, fontWeight:option.attribute.fontWeight,lineHeight:option.attribute.textLineHeight+'px',
    backgroundColor:option.attribute.bgColor, zIndex:option.attribute.zIndex,
    fontFamily:option.attribute.textFamily, textDecoration:option.attribute.textDecoration,
    width:'100%',height:'100%',whiteSpace:'pre-wrap',display:'grid',alignItems:'center',justifyItems:option.attribute.textAlign,
    overflow:'hidden',
    }" @click="redirect">
    {{ cptData.value }}
  </div>
</template>

<script>
import {pollingRefresh} from "@/utils/refreshCptData";

export default {
  //textAlign:option.attribute.textAlign,
  name: "sip-text",
  props: {
    option: Object
  },
  data() {
    return {
      cptData: {},
      uuid: null
    }
  },
  created() {
    this.uuid = require('uuid').v1();
    this.refreshCptData();
    $bus.on('sigvideo', data => {
      this.refreshCptData()
    })
  },
  methods: {
    refreshCptData() {
      pollingRefresh(this.uuid, this.option.cptDataForm, this.loadData)
    },
    loadData() {
      this.cptData = JSON.parse(this.option.cptDataForm.dataText)
    },
    redirect() {
      if (this.option.attribute.url) {
        if (this.option.attribute.url.startsWith("view")) {
          this.$router.push(this.option.attribute.url)
          this.$emit("reload")
        } else {
          window.open(this.option.attribute.url)
        }
      }
    }
  },

}
</script>


<style scoped>
.tile_div {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: grid;
  align-items: center;

: left;

}
</style>