<template>
  <div>
    <el-row class="top">
      <el-col :span="24" @click.self.native="outBlur">
        <el-button size="mini" style="margin: 10px 10px;
            background: #49586e;color: #fff;float: right">preview
        </el-button>
        <el-button size="mini" style="margin: 10px 5px;background: #d5d9e2;float: right">preserve</el-button>
        <div style="float: right;margin: 1px 10px;" class="configBtn">
          <i style="font-size: 22px;" class="el-icon-delete"/>
        </div>

        <!--        <div style="float: right;margin: 0 10px;">
                  <el-dropdown >
                    <span class="el-dropdown-link">
                      导出<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="img">图片</el-dropdown-item>
                      <el-dropdown-item command="json">设计文件</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div style="float: right;margin: 0 10px;" >
                  <span style="font-size: 14px;cursor: pointer">导入</span>
                </div>-->
      </el-col>
    </el-row>
    <div :style="{height: (windowHeight-45)+'px'}" @click.self="outBlur">
      <div style="float: left;height: 100%;overflow: hidden;" :style="{width:cptBarWidth+'px'}">
        <component-bar @dragStart="dragStart" :selectedComponents="cacheComponents" :currentCptIndex="currentCptIndex"
                       @copyCpt="copyCpt" @showConfigBar="showConfigBar" @delCpt="delCpt"/>
      </div>

      <div style="float: left;position: relative;overflow: hidden;height: 100%;background: #5E708D"
           :style="{width:(windowWidth-cptBarWidth-configBarWidth)+'px'}" @click.self="outBlur">

        <div style="height: 10px;margin-left: 10px" :style="{width:1920*containerScale+'px'}">
          <ScaleMarkX/><!--顶部刻度线-->
        </div>
        <div style="position: absolute;width: 10px;"
             :style="{height:1920*containerScale / designData.scaleX * designData.scaleY+'px'}">
          <ScaleMarkY/><!--左侧刻度线-->
        </div>

        <div class="webContainer" :style="{width:designData.scaleX+'px',height: designData.scaleY+'px', backgroundColor: designData.bgColor,
             backgroundImage: designData.bgImg ? 'url('+fileUrl+designData.bgImg+')':'none',transform: 'scale('+containerScale+')' }"
             @dragover="allowDrop" @drop="drop" ref="webContainer" @click.self="outBlur">

          <div v-for="(item,index) in cacheComponents" :key="item.id"
               class="cptDiv" :style="{width:Math.round(item.cptWidth)+'px', height:Math.round(item.cptHeight)+'px',
                  top:Math.round(item.cptY)+'px',left:Math.round(item.cptX)+'px',
                  zIndex: currentCptIndex === index ? 1800 : item.cptZ}" :ref="'div'+item.cptKey+index"
               @mousedown="showConfigBar($event,item,index)" tabindex="0">

            <div v-show="currentCptIndex === index"
                 style="position: fixed;border-top: 1px dashed #8A8A8A;width: 100%;left:0"/><!--顶部辅助线-->
            <div v-show="currentCptIndex === index"
                 style="position: fixed;border-right: 1px dashed #8A8A8A;height:100%;top:0"/><!--左侧辅助线-->
            <!-- 2021-12-28新增iframe组件，防止焦点聚焦在iframe内部，添加此蒙版 -->
            <div v-resize="'move'" class="activeMask" :style="cacheChoices[item.id] ? {border:'1px solid #B6BFCE'}:{}"/>
            <div style="width: 100%;height: 100%;">
              <component :is="item.cptKey" :ref="item.cptKey+index" :width="Math.round(item.cptWidth)"
                         :height="Math.round(item.cptHeight)" :option="item.cptOption"/>
            </div>
            <div class="delTag">
              <i class="el-icon-copy-document" @click.stop="copyCpt(item)"/>
              <i style="margin-left: 4px" class="el-icon-delete" @click.stop="delCpt(item,index)"/>
            </div>

            <div v-show="currentCptIndex === index"
                 style="top: 0;left: 0;cursor: se-resize;transform: translate(-50%, -50%)"
                 class="resizeTag" v-resize="'lt'"/>
            <div v-show="currentCptIndex === index"
                 style="top: 0;left: 50%;cursor: s-resize;transform: translate(-50%, -50%)"
                 class="resizeTag" v-resize="'t'"/>
            <div v-show="currentCptIndex === index"
                 style="top: 0;right: 0;cursor: ne-resize;transform: translate(50%, -50%)"
                 class="resizeTag" v-resize="'rt'"/>
            <div v-show="currentCptIndex === index"
                 style="top: 50%;right: 0;cursor: w-resize;transform: translate(50%, -50%)"
                 class="resizeTag" v-resize="'r'"/>
            <div v-show="currentCptIndex === index"
                 style="bottom: 0;right: 0;cursor: se-resize;transform: translate(50%, 50%)"
                 class="resizeTag" v-resize="'rb'"/>
            <div v-show="currentCptIndex === index"
                 style="bottom: 0;left: 50%;cursor: s-resize;transform: translate(-50%, 50%)"
                 class="resizeTag" v-resize="'b'"/>
            <div v-show="currentCptIndex === index"
                 style="bottom: 0;left: 0;cursor: ne-resize;transform: translate(-50%, 50%)"
                 class="resizeTag" v-resize="'lb'"/>
            <div v-show="currentCptIndex === index"
                 style="top: 50%;left: 0;cursor: w-resize;transform: translate(-50%, -50%)"
                 class="resizeTag" v-resize="'l'"/>

          </div>
        </div>


        <div style="position: absolute;width: 120px;height: 30px;bottom: 10px;left: 10px;">
          <el-slider v-model="containerScale" :min="0.3" :max="2" :step="0.01"/>

        </div>

      </div>

      <div style="float: right;height: 100%;overflow: hidden;background: aqua" :style="{width:configBarWidth-6+'px'}">
        <config-bar ref="configBar" :currentCpt.sync="currentCpt" :designData.sync="designData"
                    @refreshCptData="refreshCptData" :height="windowHeight"/><!--右侧属性栏-->
      </div>

    </div>
    <input v-show="false" type="file" id="files" ref="refFile" @change="fileLoad" accept=".cd">
  </div>

</template>
<script>
import ScaleMarkY from "@/views/designer/scaleMark/ScaleMarkY.vue";
import ScaleMarkX from "@/views/designer/scaleMark/ScaleMarkX.vue";
import env from "/env";
import ComponentBar from "@/views/designer/componentBar.vue";
import ConfigBar from "@/views/designer/configBar";
import html2canvas from 'html2canvas';

export default {
  data() {
    return {
      windowWidth: 0,
      windowHeight: 0,
      fileUrl: env.fileUrl,
      cptBarWidth: 200,
      configBarWidth: 300,
      cacheComponents: [],
      designData: {
        id: '', title: '我的大屏', scaleX: 1280, scaleY: 800, version: '',
        bgColor: '#FFF', simpleDesc: '', bgImg: '', viewCode: '', components: []
      },
      currentCptIndex: -1,
      currentCpt: {cptOption: undefined},
      containerScale: 0.5,
      cacheChoices: {},
      cacheChoicesFixed: {}//记录移动前选中组件的位置 自定义事件内部无法处理，放在了外面。
    }
  },
  components: {ComponentBar, ScaleMarkY, ScaleMarkX, ConfigBar},
  created() {
    this.initContainerSize();

  },


  mounted() {
    const that = this;
    window.addEventListener("keydown", (event) => {
      if (that.currentCptIndex !== -1) {
        let key = event.key
        switch (key) {//方向键移动当前组件
          case 'ArrowDown':
            that.currentCpt.cptY += 1;
            break;
          case 'ArrowUp':
            that.currentCpt.cptY -= 1;
            break;
          case 'ArrowLeft':
            that.currentCpt.cptX -= 1
            break;
          case 'ArrowRight':
            that.currentCpt.cptX += 1
            break;
        }
      }
    })
    window.onresize = () => {
      return (() => {
        that.initContainerSize()
      })();
    };
  },
  methods: {
    initContainerSize() {
      this.windowWidth = document.documentElement.clientWidth
      this.windowHeight = document.documentElement.clientHeight
      let tempWidth = this.windowWidth - this.cptBarWidth - this.configBarWidth;
      this.containerScale = 1
    },
    outBlur() {//取消聚焦组件
      this.currentCptIndex = -1;
      this.currentCpt = {};
      this.cacheChoices = {}
    },
    fileLoad() {
      const that = this;
      const selectedFile = this.$refs.refFile.files[0];
      const reader = new FileReader();
      reader.readAsText(selectedFile);
      reader.onload = function () {
        const fileJson = JSON.parse(this.result);//文件大小、合法性待校验
        if (!fileJson.version || fileJson.version !== env.version) {
          that.$message.error('导入失败，与当前版本不一致');
        } else {

          fileJson.id = that.designData.id
          that.designData = fileJson;
          that.cacheComponents = fileJson.components;
          that.designData.components = [];
          that.$message.success('导入成功');
        }
      }
      this.$refs.refFile.value = ''
    },
    showConfigBar(e, item, index) {//刷新属性栏数据，页面上拖动的组件执行click事件来更新组件的属性栏
      this.currentCpt = item;
      this.currentCptIndex = index;
      if (this.$refs['div' + item.cptKey + index]) {
        this.$refs['div' + item.cptKey + index][0].focus();//聚焦 用于多选移动
      }
      if (!e.ctrlKey) {//未按住ctrl键
        this.cacheChoices = {}
      }
      this.$refs['configBar'].showCptConfig(item);
      this.cacheChoices[item.id] = item
      this.cacheChoicesFixed[item.id] = JSON.parse(JSON.stringify(item))
    },
    copyCpt(item) {
      let copyItem = JSON.parse(JSON.stringify(item))
      copyItem.cptX = item.cptX + 30//复制的组件向右下偏移
      copyItem.cptY = item.cptY + 30
      copyItem.id = require('uuid').v1();
      this.cacheComponents.push(copyItem);
      this.currentCptIndex = this.cacheComponents.length - 1//聚焦到复制的组件
    },
    delCpt(cpt, index) {
      this.$confirm('delete ' + cpt.cptTitle + ' assembly?', 'prompt', {
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel',
        type: 'warning'
      }).then(() => {
        //记录一个bug，v-for key值重复导致页面渲染数据错乱。在丢下组件时实用uuid作为key解决。
        this.currentCpt = {};
        this.cacheComponents.splice(index, 1);
        // const childId = this.$refs[cpt.cptKey+index][0].uuid
        //  clearCptInterval(childId);
      }).catch(() => {
      });
    },
    refreshCptData() {
      console.log('-------refreshCptData------->')
      const refName = this.currentCpt.cptKey + this.currentCptIndex;
      if (!this.$refs[refName][0].refreshCptData) {
        this.$message.warning('当前图层还未实现refreshCptData方法')
      } else {
        this.$refs[refName][0].refreshCptData();//刷新子组件数据，refs为组建名加index
      }
    },

    allowDrop(e) {
      e.preventDefault()
    },
    dragStart(copyDom) {//从组件栏拿起组件
      console.log('---------->')
      console.log(copyDom)
      console.log('---------->')
      this.copyDom = copyDom;
      copyDom.draggable = false;
    },
    drop(e) {//从组件栏丢下组件
      let config = JSON.parse(this.copyDom.getAttribute('config'));
      if (config.option.cptDataForm) {//2022-01-24：将静态数据、api、sql用三个字段存储，配置项未填写apiUrl字段m和sql字段在此处赋默认值
        if (!config.option.cptDataForm.apiUrl) {
          config.option.cptDataForm.apiUrl = '/design/test'
        }
        if (!config.option.cptDataForm.sql) {
          config.option.cptDataForm.sql = 'select username from sys_user limit 1'
        }
      }
      let cpt = {
        cptTitle: config.name,
        icon: config.icon,
        cptKey: config.cptKey,
        cptOptionKey: config.cptOptionKey ? config.cptOptionKey : config.cptKey + '-option',
        cptOption: config.option,
        cptX: Math.round(e.offsetX),
        cptY: Math.round(e.offsetY),
        cptZ: 100,
        cptWidth: config.width ? config.width : 400,
        cptHeight: config.height ? config.height : 300,
        id: require('uuid').v1(),
      }
      this.cacheComponents.push(cpt);
      this.cacheChoices = {}//多选清空
      this.showConfigBar({}, cpt, this.cacheComponents.length - 1)//丢下组件后刷新组件属性栏
      this.$refs['configBar'].showCptConfig();
    },
    exportCommand() {
      html2canvas(this.$refs.webContainer, {backgroundColor: '#49586e'}).then(canvas => {
        const canvasData = canvas.toDataURL("image/jpeg");
        fileDownload(canvasData, this.designData.title + '.png');
      })
    }
  },
  directives: {
    resize(el, binding, vNode) {//组件拉伸，移动位置
      el.onmousedown = function (e) {
        const that = vNode.context;
        const scaleClientX = e.clientX / that.containerScale;
        const scaleClientY = e.clientY / that.containerScale;
        const rbX = scaleClientX - el.parentNode.offsetWidth;
        const rbY = scaleClientY - el.parentNode.offsetHeight;
        const ltX = scaleClientX + el.parentNode.offsetWidth;
        const ltY = scaleClientY + el.parentNode.offsetHeight;
        const disX = scaleClientX - el.parentNode.offsetLeft;
        const disY = scaleClientY - el.parentNode.offsetTop;
        let cptWidth, cptHeight, cptX, cptY;

        document.onmousemove = function (me) {
          const meScaleClientX = me.clientX / that.containerScale
          const meScaleClientY = me.clientY / that.containerScale
          if (binding.value === 'move') {
            cptX = meScaleClientX - disX;
            cptY = meScaleClientY - disY;
            Object.keys(that.cacheChoices).forEach((key) => {
              that.cacheChoices[key].cptX = that.cacheChoicesFixed[key].cptX + Math.round(meScaleClientX - scaleClientX)
              that.cacheChoices[key].cptY = that.cacheChoicesFixed[key].cptY + Math.round(meScaleClientY - scaleClientY)
            })
          } else {
            switch (binding.value) {
              case 'lt':
                cptWidth = ltX - meScaleClientX;
                cptHeight = ltY - meScaleClientY;
                cptX = meScaleClientX - disX;
                cptY = meScaleClientY - disY;
                that.currentCpt.cptX = Math.round(cptX);
                that.currentCpt.cptY = Math.round(cptY);
                break;
              case 't':
                cptHeight = ltY - meScaleClientY;
                cptY = meScaleClientY - disY;
                that.currentCpt.cptY = Math.round(cptY);
                break;
              case 'rt':
                cptWidth = meScaleClientX - rbX;
                cptHeight = ltY - meScaleClientY;
                cptY = meScaleClientY - disY;
                that.currentCpt.cptY = Math.round(cptY);
                break;
              case 'r':
                cptWidth = meScaleClientX - rbX;
                break;
              case 'rb':
                cptWidth = meScaleClientX - rbX;
                cptHeight = meScaleClientY - rbY;
                break;
              case 'b':
                cptHeight = meScaleClientY - rbY;
                break;
              case 'lb':
                cptWidth = ltX - meScaleClientX;
                cptHeight = meScaleClientY - rbY;
                cptX = meScaleClientX - disX;
                that.currentCpt.cptX = Math.round(cptX);
                break;
              case 'l':
                cptWidth = ltX - meScaleClientX;
                cptX = meScaleClientX - disX;
                that.currentCpt.cptX = Math.round(cptX);
                break;
            }
            cptWidth = cptWidth < 40 ? 40 : cptWidth;//限制最小缩放
            cptHeight = cptHeight < 20 ? 20 : cptHeight;
            if (cptWidth) that.currentCpt.cptWidth = Math.round(cptWidth);
            if (cptHeight) that.currentCpt.cptHeight = Math.round(cptHeight);
          }
        }
        document.onmouseup = function () {
          document.onmousemove = document.onmouseup = null;
          that.cacheChoicesFixed = JSON.parse(JSON.stringify(that.cacheChoices));//解决多选移动未松开ctrl键第二次以后拖动定位还原
        }
        return false;
      }
    }
  },
  watch: {
    cacheChoicesFixed: {
      handler(o, v) {
        console.log('-------')
        console.log(o)
        console.log('--------')
        console.log(this.cacheComponents)

      },
      deep: true
    }
  }

}
</script>


<style scoped>
.top {
  height: 45px;
  box-shadow: 0 2px 5px #2b3340 inset;
  color: #fff;
  overflow: hidden;
  margin: 0;
  font-size: 18px;
  line-height: 45px;
  background: #353F50
}

.webContainer {
  position: relative;
  margin: 0 10px;
  background-size: 100% 100%;
  transform-origin: 0 0
}

.delTag {
  width: 45px;
  height: 22px;
  background: rgba(43, 51, 64, 0.8);
  border-radius: 2px;
  color: #ccc;
  z-index: 2000;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  display: none;
  cursor: pointer
}

.activeMask {
  width: 98%;
  height: 98%;
  position: absolute;
  z-index: 1801
}

.cptDiv {
  position: absolute;
  outline: none
}

.cptDiv:hover .delTag {
  display: block
}

.resizeTag {
  width: 8px;
  height: 8px;
  position: absolute;
  background-color: #B6BFCE;
  z-index: 2000;
  border-radius: 50%;
}

.configBtn:hover {
  cursor: pointer;
  color: #B6BFCE
}

.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}
</style>