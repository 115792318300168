<template>
  <el-dialog
      :modal-append-to-body="true"
      custom-class="classdois"
      title=""
      :show-close="false"
      width="390px"
      :visible.sync="exist"
      :close-on-click-modal="false"
      :close-on-press-escape="false">
    <div class="flex-col div_forgot">
      <div class="flex-row title_div justify-between">
        <span class="title_span">Add Board</span>
        <img style="cursor: pointer;width: 12px;height: 12px;margin-top: 24px;" @click="Close"
             :src="require('@/assets/icon/code601.svg')">
      </div>
      <div style="height: 400px;">
        <virtual-list
            class="list-keep"
            :style="{height:380+'px'}"
            :estimate-size="60"
            :data-key="'boardId'"
            :data-sources="listData"
            :data-component="itemContactsitem"/>
      </div>
      <div v-bind:class="{selected_div:isOnlick,selected_div_false:!isOnlick}" @click="OnClisk">Add Selected</div>
    </div>
  </el-dialog>


</template>
<script>
import {museaBoardlist, pushMuseaBoard} from "@/api/login";
import Contactsitem from "@/popover/IcleItme.vue";

export default {
  name: 'AddBoardDia',
  props: {
    options: {}
  },
  data() {
    return {
      exist: true,
      itemContactsitem: Contactsitem,
      listData: [],
      sList: [],
      isOnlick: false,

    }
  },
  created() {
    this.getListData()
    $bus.on('AddBoardDia', data => {
      if (data.code === 1) {
        this.selecsstFile(data.data)
      }
    })
  },
  methods: {
    Close() {
      this.$emit('chickBack')
    },
    getListData() {
      this.loading = true
      this.listData.splice(0, this.listData.length)
      JSON.parse(sessionStorage.getItem('myboards')).forEach(it => {
        it.ischeck = false
        this.options.forEach(item => {
          if (item.boardId === it.boardId) {
            it.ischeck = true
            this.sList.push(it)
          }
        })
        this.listData.push(it)
      })
    },
    OnClisk() {
      let datas = this.listData.filter(item => item.ischeck === true)
      datas.forEach(it => {
        it.cacheComponents = []
      })
  //    $bus.emit("Componentleft", {code: 0, data: datas})
      $bus.emit("createstack", {code: 0, data: datas})
    },
    selecsstFile(obj) {
      if (obj.ischeck) {
        this.sList.push(obj)
      } else {
        let Index = this.sList.findIndex(itme => itme.boardId === obj.boardId)
        this.sList.splice(Index, 1)
      }
    },
  },
  watch: {
    sList: {
      handler(o, v) {
        if (o.length > 0) {
          this.isOnlick = true
        } else {
          this.isOnlick = false
        }
      },
      deep: true
    }
  }
}

</script>

<style  scoped >
/deep/ .el-dialog {
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 0 0 rgba(255, 255, 255, 0);
}

/deep/ .el-dialog__wrapper {
  background-color: rgba(242, 242, 242, 1);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  transition: all 0.2s ease-out;

}

.content /deep/ .el-dialog__header {
  display: none;
}

.content /deep/ .el-dialog__body {
  padding: 0;
}

/deep/ .el-dialog.classdois {
  border-radius: 8px;
}

</style>
<style lang="scss" scoped>


.title_div {
  padding-left: 16px;
  border-bottom: 1px solid #FFFFFF;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding-right: 16px;
  background-color: #FFFFFF;
  width: auto;
  height: 44px;
}


.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title_span {
  width: 100%;
  height: auto;
  font-size: 16px;
  font-family: Inter-SemiBold, Inter;
  color: #3D2A56;
  line-height: 20px;
}

.button_gotit {
  margin: auto;
  cursor: pointer;
  width: 120px;
  height: 40px;
  background: #FFFFFF;
  color: #C0BFC0;
  font-family: Inter-SemiBold, Radikal;
  font-size: 13px;
  border: 1px solid #C0BFC0;
  border-radius: 999px;
}


.div_forgot {
  width: 390px;
  height: fit-content;
  background-color: #FFFFFF;
  border-radius: 5px;
  padding-bottom: 20px;
  box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.1);

}

.icon2 {
  cursor: pointer;
  width: 12px;
  height: 12px;
}

.span_up_add {
  text-align: center;
  width: 100%;
  font-size: 14px;
  font-family: Radikal-Regular, Radikal;
  font-weight: 400;
  color: #959595;
  line-height: 18px;
}

.span_for_mag {
  display: flex;
  margin: auto;
  font-size: 13px;
  text-align: center;
  font-weight: 400;
  color: #959595;
  line-height: 17px;
  font-family: Radikal-Regular, Radikal;
}


.icon4 {
  width: 130px;
  height: 130px;
  animation: mymove 1ms infinite;
  -webkit-animation: mymove 500ms infinite;
}

.soan_file {
  margin: auto;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #1E1E1E;
  line-height: 19px;
}

.icon5 {
  width: 130px;
  height: 130px;
}


.selected_div {
  cursor: pointer;
  margin: auto;
  text-align: center;
  width: 120px;
  height: 40px;
  background: #9931F8;
  border-radius: 999px;
  font-size: 13px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 40px;
}

.selected_div_false {
  cursor: pointer;
  margin: auto;
  text-align: center;
  width: 120px;
  height: 40px;
  background: #C0BFC0;
  border-radius: 999px;
  font-size: 13px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 40px;
}

.span_tile_name {
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #C0BFC0;
  line-height: 12px;
}

.list-keep {
  height: 350px;
  width: 390px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 11px;


  /*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
  // 滚动条整体部分
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
  &::-webkit-scrollbar-button {
    display: none;
  }

  // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
  &::-webkit-scrollbar-thumb {
    background: rgba(144, 147, 153, 0.3);
    cursor: pointer;
    border-radius: 4px;
  }

  // 边角，即两个滚动条的交汇处
  &::-webkit-scrollbar-corner {
    display: none;
  }

  // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
  &::-webkit-resizer {
    display: none;
  }


}

</style>