<template>
  <div v-if="show" class="mask">
    <div class="dlg-msg-box flex flex-col">
      <div class="flex-col"
           style="width: 344px;height: auto;margin: auto; background: #FFFFFF;border-radius: 8px;padding-bottom:16px;">
        <div class="flex-row justify-between" style="margin-top: 20px;">
          <p class="tile_mase">Delete Touch Area</p>
        </div>
        <div style="margin-top: 36px;">
          <p class="p_tile_text">Confirm to delete this touch area?</p>
        </div>
        <div class="flex-row" style="margin-left: 24px;margin-top: 36px;">
          <div class="div_delete" @click="confirmClick">Delete Now</div>
          <div class="div_cancel" @click="close">Cancel</div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DeleteTouchArea",
  props: {
    caption: {},
    show: {},
    msg: [],
    button: {},
    callback: {}
  },
  methods: {
    init() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = true;
    },
    close() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("close")
    },
    confirmClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("yes")
      //this.$emit('confirm');
    },
    cancelClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("no")
    }
  }
}
</script>

<style scoped>

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tile_mase {
  width: 100%;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  font-size: 16px;
  color: #383838;
  line-height: 20px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}

.settings_div {
  cursor: pointer;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 139px;
  height: 40px;
  background: #9931F8;
  border-radius: 999px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  font-size: 13px;
  color: #FFFFFF;
  line-height: 16px;
  text-align: center;
  font-style: normal;
  text-transform: none;

}

.p_tile_text {
  width: 100%;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  font-size: 13px;
  color: #1E1E1E;
  line-height: 13px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}

.div_delete {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 40px;
  background: #E44356;
  border-radius: 999px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  font-size: 13px;
  color: #FFFFFF;
  line-height: 16px;
  text-align: center;
  font-style: normal;
  text-transform: none;
  cursor: pointer;
}

.div_cancel {
  cursor: pointer;
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 40px;
  border-radius: 999px;
  border: 1px solid #C0BFC0;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  font-size: 13px;
  color: #C0BFC0;
  line-height: 16px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}

</style>