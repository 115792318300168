<template>
  <div>

  </div>
</template>

<script>
export default {

  props: {
    msg: String
  },
  created() {
    this.$router.push('/home')
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
