<template>
  <div>
    <div class="flex-row justify-between" style="margin-top: 20px;">
      <span class="span_tile_name">Dimension</span>
      <div class="flex-row">
        <img @click="Oncliklayerdown" style="margin-right: 16px;cursor: pointer;"
             :src="require('@/assets/icon/iconlayerdown.svg')">
        <img @click="Oncliklayerup" style="cursor: pointer;" :src="require('@/assets/icon/iconlayerup.svg')">
      </div>
    </div>
    <div class="flex-row" style="margin-top: 12px;">
      <div class="flex-col">
        <span class="span_tile">X</span>
        <el-input v-model="attribute.cptX" autocomplete="off" style="width:156px;margin-top: 8px;"
                  @input="OInpi"></el-input>
      </div>
      <div class="flex-col" style="margin-left: 12px">
        <span class="span_tile">Y</span>
        <el-input v-model="attribute.cptY" autocomplete="off" style="width:156px;margin-top: 8px;"
                  @input="OInpi"></el-input>
      </div>
    </div>
    <div class="flex-row" style="margin-top: 12px;">
      <div class="flex-col">
        <span class="span_tile">Width</span>
        <el-input v-model="attribute.cptWidth" autocomplete="off" style="width:156px;margin-top: 8px;"
                  @input="OInpi"></el-input>
      </div>
      <div class="flex-col" style=" margin-left: 12px">
        <span class="span_tile">Height</span>
        <el-input v-model="attribute.cptHeight" autocomplete="off"
                  style="width:156px;margin-top: 8px;"
                  @input="OInpi"></el-input>
      </div>

    </div>
    <div class="flex-col" style="margin-top: 12px;">
      <p class="span_tile_type">Source type</p>
      <el-select v-model="value" placeholder="Select a content type" popper-class="my-select"
                 style="width: 324px;margin-top: 8px;"
                 @change="onChangevalue">
        <el-option
            v-for="item in sourType"
            :key="item.id"
            :label="item.label"
            :value="item.id">
        </el-option>
      </el-select>
    </div>
    <div class="flex-col" style="margin-top: 12px" v-if="value === 0">
      <span class="span_tile">URL</span>
      <el-input v-model="attribute.cptOption.attribute.url" autocomplete="off"
                style="width:324px;margin-top: 8px;"
                @input="OInpi"></el-input>
    </div>
    <div class="flex-col" style="margin-top: 12px" v-if="value === 1">
      <span class="span_tile">File</span>
      <el-select v-model="filevalue" placeholder="Select a content type"
                 style="width: 324px;margin-top: 8px;overflow-x: hidden;"
                 @change="onChangeView">
        <el-option
            v-for="item in listData"
            :key="item.fileId"
            :label="item.fileName"
            :value="item.filePath">
        </el-option>
      </el-select>

    </div>
<!--    <div style="background-color: #E0E1DD;height: 1px;margin-top: 20px;margin-bottom: 20px;"></div>-->

    <!--    <span class="span_tile_name" style="margin-top: 20px">Touch Interaction</span>
        <div class="flex-row" style="margin-top: 25px;display: flex;align-items: center;">
          <span class="span_tile_y">Enable touch</span>
          <el-checkbox style="margin-left: 200px;" v-model="attribute.cptOption.attribute.ischeck"
                       @change="handleCheckAllChange"></el-checkbox>
        </div>
        <div v-if="attribute.cptOption.attribute.ischeck">
          <p>Touch interaction</p>
          <el-select style="width: 100%" v-model="attribute.cptOption.attribute.Touchvalue">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <div class="flex-col">
            <div v-if="attribute.cptOption.attribute.Touchvalue ===0">
              <p>Image source</p>
              <el-select v-model="attribute.cptOption.attribute.ImageValue" placeholder="Select a content type"
                         style="width: 324px;margin-top: 8px;overflow-x: hidden;"
              >
                <el-option
                    v-for="item in listData"
                    :key="item.fileId"
                    :label="item.fileName"
                    :value="item.filePath">
                </el-option>
              </el-select>
            </div>
            <div v-if="attribute.cptOption.attribute.Touchvalue ===1">
              <p>Video source</p>
              <el-select v-model="filevalue" placeholder="Select a content type"
                         style="width: 324px;margin-top: 8px;overflow-x: hidden;"
                         @change="onChangeVideo">
                <el-option
                    v-for="item in videoData"
                    :key="item.fileId"
                    :label="item.fileName"
                    :value="item.filePath">
                </el-option>
              </el-select>
            </div>
            <div v-if="attribute.cptOption.attribute.Touchvalue ===2">
              <p>Website URL</p>
              <el-input v-model="attribute.cptOption.attribute.TouchUrl" autocomplete="off"
                        style="width:324px;margin-top: 8px;"
                        @input="OInpi"></el-input>
            </div>

          </div>

        </div>-->

  </div>
</template>

<script>

export default {
  name: 'FreeImage',
  props: {
    attribute: Object
  },
  data() {
    return {
      listData: [],
      videoData: [],
      value: '',
      filevalue: '',
      sourType: [
        {value: 'URL', label: ' URL', id: 0},
        {value: 'File', label: 'File', id: 1},
      ],
      options: [{
        value: 0,
        label: 'Show image'
      }, {
        value: 1,
        label: 'Play video'
      }, {
        value: 2,
        label: 'Open website'
      }],
      Touchvalue: '',
      ischeck: false,
      ImageValue: '',
      VideoValue: '',
      WebsiteValue: ''

    }
  },
  created() {
    this.setVideo()
    this.value = this.attribute.cptOption.attribute.type
    const urls = this.attribute.cptOption.attribute.url.split('/')
    if (this.attribute.cptOption.attribute.url) {
      this.filevalue = urls[urls.length - 1]
    }

  },
  methods: {
    OInpi() {
      this.attribute.cptOption.attribute.fontWeight = this.attribute.cptWidth
    },
    setVideo() {
      this.listData.splice(0, this.listData.length)
      this.videoData.splice(0, this.videoData.length)
      let Images = JSON.parse(sessionStorage.getItem('museaFileImage'))
      let FileVide = JSON.parse(sessionStorage.getItem('museaFileVide'))
      this.listData = Images.sort(this.sortDownDate)
      this.videoData = FileVide.sort(this.sortDownDate)
    },


    onChangeView(e) {
      this.attribute.cptOption.attribute.url = e
    },
    onChangeVideo(e) {
      this.attribute.cptOption.attribute.ImageValue = e
    },
    onChangevalue(e) {
      this.attribute.cptOption.attribute.type = e
    },
    Oncliklayerdown() {
      //  $bus.on('freeboard
      this.attribute.cptZ = this.attribute.cptZ - 1
      $bus.emit('freeboard', {id: 3})
    },
    Oncliklayerup() {
      this.attribute.cptZ = this.attribute.cptZ + 1
      $bus.emit('freeboard', {id: 3})
    },
    handleCheckAllChange() {

    }
  },
}

</script>


<style scoped lang="scss">

::v-deep .el-checkbox {
  margin-left: 16px;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #303030;

  .el-checkbox__inner {
    width: 24px;
    height: 24px;
    border: 1px solid rgba(149, 149, 149, 1);
    //修改选中框中的对勾的大小和位置
    &::after {
      top: 5px;
      height: 12px;
      left: 10px;
    }
  }

  .el-checkbox__label {
    display: inline-grid;
    color: #FFFFFF;
    width: 200px;

  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    border: 1px solid #9931F8;
    background-color: #9931F8;
  }

  .el-checkbox__label {
    display: inline-grid;
    color: #FFFFFF;
    width: 200px;
    background-color: #9931F8;

  }

  //修改点击文字颜色不变
  .el-checkbox__input.is-checked + .el-checkbox__label {
    border: 1px solid rgba(0, 145, 255, 1);
    background: rgba(0, 145, 255, 1);
    color: #303030;
  }

}

.span_tile {
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #C0BFC0;
  line-height: 12px;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.span_tile_type {
  height: 13px;
  font-size: 13px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #1E1F21;
  line-height: 13px;
}

.span_tile_name {
  height: 14px;
  font-size: 14px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #404756;
  line-height: 14px;
}

.span_tile_y {
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #1E1F21;
  line-height: 14px;
}

.text_align_div_true {
  cursor: pointer;
  width: 81px;
  height: 32px;
  display: flex;
  background-color: #F2F2F2;
}

.text_align_div_false {
  cursor: pointer;
  width: 81px;
  height: 32px;
  display: flex;
  background-color: #FFFFFF;
}

.tile_span_touch {
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  font-size: 13px;
  color: #8F8F8F;
  line-height: 13px;
  text-align: left;
  font-style: normal;
  text-transform: none;

}


</style>