import { render, staticRenderFns } from "./Component-left.vue?vue&type=template&id=0635c4a6&scoped=true"
import script from "./Component-left.vue?vue&type=script&lang=js"
export * from "./Component-left.vue?vue&type=script&lang=js"
import style0 from "./Component-left.vue?vue&type=style&index=0&id=0635c4a6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0635c4a6",
  null
  
)

export default component.exports