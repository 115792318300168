<template>
  <div style="width: 100%;height: 100%;display: flex;">
    <div :style="{width:windowWidth+'px',height:windowHeight+'px',backgroundColor: designCache.bgColor,margin:'auto'}">
      <div style="position:absolute;z-index: 999;"
           :style="{width:windowWidth+'px',height:conHeight+'px'}">
        <div v-for="(item,index) in cacheComponents" :key="item.id"
             class="cptDiv" :style="{width:Math.round(item.cptWidth)+'px', height:Math.round(item.cptHeight)+'px',
                  top:Math.round(item.cptY)+'px',left:Math.round(item.cptX)+'px' }" :ref="'div'+item.cptKey+index"
             tabindex="0">
          <div style="width: 100%;height: 100%;">
            <component :is="item.cptKey" :ref="item.cptKey+index" :width="Math.round(item.cptWidth)"
                       :height="Math.round(item.cptHeight)" :option="item.cptOption"/>
          </div>

        </div>
      </div>
      <div style="position:absolute;z-index: 998;"
           :style="{width:windowWidth+'px',height:conHeight+'px',background:designCache.bgColor}">
        <div v-for="item in designCache.components" :key="item.id"
             style="position: absolute;"
             :style="{width:Math.round(item.cptWidth)+'px',
                  height:Math.round(item.cptHeight)+'px',
                  top:Math.round(item.cptY)+'px',
                  left:Math.round(item.cptX)+'px',
                  zIndex: item.cptZ }">
          <component :is="item.cptKey" :width="Math.round(item.cptWidth)"
                     :height="Math.round(item.cptHeight)" @reload="loadCacheData"
                     :option="designCache.viewCode ==='0' ?   item :item.cptOption"/>
        </div>

      </div>
      <iframe frameborder="0" height="100%" width="100%"/>
    </div>
  </div>
</template>

<script>


import {fileUrl} from "../../../env";

export default {
  name: "index",
  data() {
    return {
      fileUrl: fileUrl,
      designCache: {},
      windowWidth: 0,
      windowHeight: 0,
      conHeight: 0,
      containerScale: 1,
      Itmedata: 0,
      authCodeDialogVisible: false,
      viewCode: '',
      designData: JSON.parse(localStorage.getItem('designCache')).currentCpt,
      cacheComponents: [],
      upIndex: {},
      pageData: []

    }
  },
  created() {
    const that = this;
    $bus.on('touchsig', data => {
      var index = that.designData.findIndex(it => it.htmlPath === data.attribute.url)
      switch (data.attribute.type) {
        case 0:
          if (that.pageData.length>0){
            that.upIndex = that.pageData[that.pageData.length - 1]
            var indexs = that.designData.findIndex(it => it.boardId === that.upIndex.id)
            that.pageData.splice(indexs, 1)
            that.loadCacheData(indexs);
          }

          break
        case 1:
          that.upIndex = this.designCache
          that.pageData.push(this.designCache)
          that.loadCacheData(index);
          break
        case 2:
          that.digUrl(data.attribute.url)
          break
      }
    })
    that.startTimer()
  },
  mounted() {
    const that = this;
    console.log('currentCpt-->', JSON.parse(localStorage.getItem('designCache')).currentCpt)
    that.loadCacheData(that.Itmedata);

  },
  methods: {
    loadCacheData(e) {
      var data = this.designData[e]
      console.log(data)
      let scale = data.screenSize.split('X')

      let designData = {
        id: data.boardId,
        title: data.boardName,
        scaleX: scale[0],
        scaleY: scale[1],
        version: '',
        bgColor: data.boardBackground,
        simpleDesc: '',
        bgImg: '',
        viewCode: data.boardType,
        components: JSON.parse(data.sourceInfo).sources
      }
      data.cacheComponents.forEach(it => {
            it.cptOption.attribute.typeView = 1
            console.log('cacheComponents==>', it)
          }
      )
      this.cacheComponents = data.cacheComponents
      this.designCache = designData;

      this.loadDesign(designData, false);

    },
    loadDesign(design, componentPares) {
      if (componentPares) {
        design.components = JSON.parse(design.components);
      }
      this.loadSize(design);
    },
    loadSize(designCache) {
      //   let designCache = JSON.parse(localStorage.getItem('designCache'));
      this.windowWidth = designCache.scaleX;
      this.windowHeight = designCache.scaleY;
      this.containerScale = Math.round(this.windowWidth / designCache.scaleX * 100) / 100
      this.conHeight = designCache.scaleY

    },
    digUrl(e) {
      window.open(e,
          "wWindow", "width=" + this.windowWidth + " ,height=" + this.windowHeight +
          ",top=0, left=0, titlebar=no, menubar=no, scrollbars=no, resizable=no, status=no, toolbar=no, location=no");
    },
    startTimer() {
      // this.StackData.checkAll ? '1' : '0'
      const design = JSON.parse(localStorage.getItem('designCache'))

      const autoPlay = design.autoPlay === "1"
      const playInterval = parseInt(design.playInterval)
      console.log('--------->',design)
      if (autoPlay) {
        setInterval(() => {
          if (this.Itmedata > this.designData.length - 1) {
            this.Itmedata = 0
          }
          this.loadCacheData(this.Itmedata)
          ++this.Itmedata
        }, playInterval * 1000)
      }else {
        this.loadCacheData(0)
      }
    }


  }


}
</script>


<style scoped>
.bounce-enter-active {
  transition: all 1s;
  /*animation: bounce-in 1s;*/
}

.bounce-enter {
  opacity: 0;
  transform: scale(.5);
}


.cptDiv {
  display: block;
  position: absolute;
  outline: none
}


@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>