<template>
  <div v-if="show" class="mask">
    <div class="dlg-msg-box flex flex-col">
      <div class="flex-col" style="width: 344px;height: auto;margin: auto; background: #FFFFFF;border-radius: 8px;padding-bottom:16px;">
        <div class="flex-row justify-between" style="margin-top: 20px;">
          <p class="tile_mase">Open Browser</p>
          <img style="cursor: pointer;margin-right: 14px;" :width="12" :height="12"
               :src="require('@/assets/icon/code601.svg')"
               @click="close()">
        </div>
        <div style="margin-left: 24px;">
          <p class="p_tile_text">URL</p>
          <div style="width: 296px;margin-top: 8px;margin-bottom: 32px;">
            <el-input v-model="value" placeholder="Enter a URL"></el-input>
          </div>

          <div v-bind:class="{settings_div:isDiv ,settings_div_fale:!isDiv}" @click="confirmClick()">Confirm Settings</div>
        </div>

      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    caption: {},
    show: {},
    msg: [],
    button: {},
    callback: {}
  },
  data() {
    return {
      value: '',
      isDiv: false
    }
  },
  methods: {
    init() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = true;
      this.value=  this.caption
    },
    close() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("close")
    },
    confirmClick() {
      // eslint-disable-next-line vue/no-mutating-props
      if (this.isDiv) {
        this.show = false;
        this.callback(JSON.stringify({coed: 0, Board: this.value, Transition: this.value}))
      }
      //this.$emit('confirm');
    },
    cancelClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("no")
    }
  },
  watch: {
    value: {
      handler(v, o) {
        if (v.toString() !== '') {
          this.isDiv = true
        }
      }, deep: true
    }
  }

}
</script>

<style scoped>


.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tile_mase {
  width: 314px;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  font-size: 16px;
  color: #383838;
  line-height: 20px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}

.settings_div {
  cursor: pointer;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 139px;
  height: 40px;
  background: #9931F8;
  border-radius: 999px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  font-size: 13px;
  color: #FFFFFF;
  line-height: 16px;
  text-align: center;
  font-style: normal;
  text-transform: none;

}
.settings_div_fale {
  cursor: pointer;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 139px;
  height: 40px;
  background: #F2F2F2;
  border-radius: 999px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  font-size: 13px;
  color: #FFFFFF;
  line-height: 16px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}
.p_tile_text {
  margin-top: 36px;
  width: 61px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  font-size: 13px;
  color: #1E1E1E;
  line-height: 13px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

</style>