<template>
  <div id="app" style="overflow-x: hidden;overflow-y: hidden;">
    <router-view keep-alive/>
  </div>
</template>

<script>


import {getInfo} from "@/api/login";
import axios from "axios";
import {fileUrl} from "../env";
import {setToken} from "@/utils/auth";

export default {
  name: 'App',
  created() {
    this.checkBtnHide()

    if (sessionStorage.getItem('store')) {

    }
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {


    })

  },
  methods: {
    checkBtnHide() {
      const urlParams = new URLSearchParams(window.location.search);
      const uuid = urlParams.get('starToken')
      if (uuid !== null) {
      }else {
        getInfo()
      }

    },

  },

  mounted() {
    document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    }

  }
}
</script>

<style>
html, body, #app {
  min-width: 1480px;
  min-height: 900px;
  height: 100%;

  background-color: #F2F2F2;
}

* {
  margin: 0;
  padding: 0;
}

.formItemDiv {
  width: 100%;
  height: 30px;
  border-radius: 4px;
  background-color: #fff;
  text-indent: 1em;
  font-size: 13px;
  cursor: pointer;
  color: #666
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #5E708D;
}

*::-webkit-scrollbar-track {
  border-radius: 4px;
  background: #ddd;
}

.slide-fade-enter-active {
  transition: all 500ms ease;
}

.slide-fade-leave-active {
  transition: all 200ms cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
