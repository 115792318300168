<template>
  <div v-if="show" class="mask">
    <div class="dlg-msg-box flex flex-col">
      <div class="flex-col" style="width: 496px;height: auto;margin: auto; background: #FFFFFF;border-radius: 24px;padding-bottom: 32px;">
        <div style="width: 496px;height:auto;display: flex; border-radius: 24px; flex-direction: column;background-color: #FFFFFF">
          <div style="margin: auto">
            <img style="width: 96px;height: 96px;margin-top: 36px;margin-bottom: 12px;"
                 :src="require('@/assets/logo.svg')">
            <p class="tile_pa" style="margin: auto">MUSEA</p>
            <p class="tile_name_p" style="margin: 6px auto">By GloryStar</p>
          </div>
          <div style="margin-top: 24px;margin-bottom: 48px;">
            <p class="tile_msag" v-for="(itme,index) in msg">{{ itme }}</p>
          </div>
          <div class="flex-row" style="padding-right: 24px;padding-left: 24px;">
            <div class="button_div_continue" @click="confirmClick()" v-if="button.isconfirm">{{ button.confirm }}</div>
            <div class="button_div_device" @click="cancelClick()" v-if="button.iscancel">{{ button.cancel }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    caption: {},
    show: {},
    msg: [],
    button: {},
    callback: {}
  },
  methods: {
    init() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = true;
    },
    close() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("close")
    },
    confirmClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("yes")
      //this.$emit('confirm');
    },
    cancelClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("no")
    }
  }
}

</script>
<style>


.tile_pa {
  text-align: center;
  width: 96px;
  font-size: 22px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #9931F8;
  line-height: 22px;
}

.tile_name_p {
  width: 96px;
  height: 12px;
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #C0BFC0;
  line-height: 12px;
  text-align: center;
}

.tile_msag {
  text-align: center;
  margin: auto;
  width: 448px;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #383838;
  line-height: 19px;
}

.button_div_continue {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 13px;
  height: 40px;
  background: #9931F8;
  border-radius: 999px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #FFFFFF;

}

.button_div_device {
  cursor: pointer;
  margin-left: 16px;
  display: flex;
  font-size: 13px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 999px;
  border: 1px solid #C0BFC0;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #C0BFC0;
}

</style>
<style scoped>
@import url(dialogcss.css);
</style>