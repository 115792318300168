import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'

import router from './router';
import '@/utils/register-cpt'
import '@/utils/register-option'
import '@/components/register-option'
import '@/assets/styles/stylesheet.css'
import {
    addDateRange,
    ajaxSetCapacity,
    blockToUpload,
    calculateMD5,
    calTime,
    emptyObject,
    flitersFilesType,
    getQueryObject,
    handleTree,
    IsEmail,
    machineStatus,
    parseTime,
    renderSize,
    resetForm,
    selectDictLabel,
    sortDownDate,
    stringJoining,
    timeZoneFormat,
    splitIntoEqualShares,
    getDiffDay,
    getCurrentTime
} from "@/utils/ruoyi";


import download from '@/utils/download'

import ShowMsgBox from '@/erredpop/index'
import PubSub from 'pubsub-js'
import showLogout from "@/erredpop/logout";
import showDeleteFiles from '@/erredpop/deletefiles'
import showRegisterSu from '@/erredpop/registerindex'
import showPassFollow from '@/erredpop/passfollow'
import showForgotPassword from '@/erredpop/ForgotPassword'
import showPreViousBoard from "@/erredpop/GoPreViousBoard";
import showGoToBoard from "@/erredpop/GoToBoard";
import showOpenBrowser from "@/erredpop/OpenBrowser";
import showDeleteTouchArea from "@/erredpop/DeleteTouchArea";
import showExportError from "@/erredpop/ExportError"
import showDetails from "@/erredpop/Details";
import showfileUplade from "@/erredpop/FileUplade";
import showErrorOccured from "@/erredpop/ErrorOccured";
import showcloseAccount from "@/erredpop/closeAccount";
import showChangePassword from "@/erredpop/ChangePassword";
import showInfoGo from "@/erredpop/InfoGo";
import 'video.js/dist/video-js.css';


Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.handleTree = handleTree
Vue.prototype.IsEmail = IsEmail
Vue.prototype.blockToUpload = blockToUpload
Vue.prototype.calculateMD5 = calculateMD5
Vue.prototype.renderSize = renderSize
Vue.prototype.emptyObject = emptyObject
Vue.prototype.stringJoining = stringJoining

Vue.prototype.calTime = calTime
Vue.prototype.queryUrlParams = getQueryObject
Vue.prototype.ajaxSetCapacity = ajaxSetCapacity
Vue.prototype.timeZoneFormat = timeZoneFormat
Vue.prototype.machineStatus = machineStatus
Vue.prototype.flitersFilesType = flitersFilesType
Vue.prototype.sortDownDate = sortDownDate
Vue.prototype.splitIntoEqualShares = splitIntoEqualShares
Vue.prototype.getDiffDay = getDiffDay
Vue.prototype.getCurrentTime = getCurrentTime

Vue.use(ShowMsgBox)
Vue.use(showLogout)
Vue.use(showDeleteFiles)
Vue.use(showRegisterSu)
Vue.use(showPassFollow)
Vue.use(showForgotPassword)
Vue.use(showPreViousBoard)
Vue.use(showGoToBoard)
Vue.use(showOpenBrowser)
Vue.use(showDeleteTouchArea)
Vue.use(showExportError)
Vue.use(showDetails)
Vue.use(showfileUplade)
Vue.use(showErrorOccured)
Vue.use(showcloseAccount)
Vue.use(showChangePassword)
Vue.use(showInfoGo)




Vue.prototype.$download = download
Vue.prototype.flitersFilesType = flitersFilesType
Vue.prototype.renderSize = renderSize


//全局组件通讯
let Plugin = {
    install() {
        //挂载到window上面
        window.$bus = {
            emit: function (eventId, data) {
                //消息同步发布
                PubSub.publishSync('global-bus' + "-" + eventId, data)
            },

            emitAsync: function (eventId, data) {
                //消息异步发布
                PubSub.publish('global-bus' + "-" + eventId, data)
            },

            on: function (eventId, cb) {
                PubSub.subscribe('global-bus' + "-" + eventId, function (msg, data) {
                    cb(data)
                })
            },

            off: function (...eventIds) {
                eventIds.forEach(_ => {
                    PubSub.unsubscribe('global-bus' + "-" + _)
                })
            }
        }
    }
}
Vue.use(Plugin)


Vue.config.productionTip = false
Vue.use(ElementUI, {locale});

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
