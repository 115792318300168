import ExportError from "@/erredpop/ExportError.vue";
let ConfirmConstructor, instance

const showExportError = {
    install(Vue) {
        ConfirmConstructor = Vue.extend(ExportError)
        instance = new ConfirmConstructor().$mount()
        document.body.appendChild(instance.$el)
        Vue.prototype.$showExportError = options => {
            Object.assign(instance, options)
            instance.init()
        }
    }
}
export default showExportError