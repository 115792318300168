<template>
  <div class="flex-col">
    <span class="span_tile_type">Media File</span>
    <el-select v-model="value" placeholder="Select a content type" style="margin-top: 8px;"
               @change="onChangeView">
      <el-option
          v-for="item in sourType"
          :key="item.id"
          :label="item.label"
          :value="item.id">
      </el-option>
    </el-select>
    <div class="flex-col" style="margin-top: 12px" v-if="value === 0">
      <span class="span_tile">URL</span>
      <el-input v-model="attribute.cptOption.attribute.url" autocomplete="off"
                style="width:324px;margin-top: 8px;"
                @input="OInpi"></el-input>
    </div>
    <div class="flex-col" style="margin-top: 12px" v-if="value === 1">
      <span class="span_tile">File</span>
      <el-select v-model="filevalue" placeholder="Select a content type"
                 style="width: 324px;margin-top: 8px;overflow-x: hidden;"
                 @change="onChangeViews">
        <el-option
            v-for="item in listData"
            :key="item.fileId"
            :label="item.fileName"
            :value="item.filePath">
        </el-option>
      </el-select>

    </div>


<!--    <div style="height: 54px;display: flex;margin-top: 24px;border-top: 1px solid #F2F2F2;">
      <span class="span_tile_name">Touch Interaction</span>
    </div>
    <div class="flex-row justify-between" style="width: 300px;margin-top: 8px;">
      <span class="span_tile_y">Enable touch</span>
      <el-checkbox v-model="ischeck" @change="handleCheckAllChange"></el-checkbox>
    </div>-->


  </div>
</template>

<script>
export default {
  name: 'left-image',
  props: {
    attribute: Object
  },
  data() {
    return {
      listData: [],
      value: '',
      filevalue: '',
      ischeck: false,
      sourType: [
        {value: 'URL', label: ' URL', id: 0},
        {value: 'File', label: 'File', id: 1}]

    }
  },
  created() {
    this.setVideo()
    this.value = this.attribute.cptOption.attribute.type
    const urls = this.attribute.cptOption.attribute.url.split('/')
    if (this.attribute.cptOption.attribute.url) {
      this.filevalue = urls[urls.length - 1]
    }
  },

  methods: {
    OInpi() {

    },
    handleCheckAllChange() {

    },
    onChangeViews(e){
      this.attribute.cptOption.attribute.url = e
    },
    onChangeView(e) {
      this.attribute.cptOption.attribute.type = e
    },
    setVideo() {
      this.listData.splice(0, this.listData.length)
      let dataInfo = JSON.parse(sessionStorage.getItem('museaFileImage'))
      this.listData = dataInfo.sort(this.sortDownDate)
    },

  }
}
</script>

<style scoped lang="scss">

::v-deep .el-checkbox {
  margin-left: 16px;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #303030;

  .el-checkbox__inner {
    width: 24px;
    height: 24px;
    border: 1px solid rgba(149, 149, 149, 1);
    //修改选中框中的对勾的大小和位置
    &::after {
      top: 5px;
      height: 12px;
      left: 10px;
    }
  }

  .el-checkbox__label {
    display: inline-grid;
    color: #FFFFFF;
    width: 200px;

  }

  //修改点击文字颜色不变
  .el-checkbox__input.is-checked + .el-checkbox__label {
    border: 1px solid rgba(0, 145, 255, 1);
    background: rgba(0, 145, 255, 1);
    color: #303030;
  }

}


.flex-col {
  display: flex;
  flex-direction: column;
}

.span_tile_type {
  width: 75px;
  height: 13px;
  font-size: 13px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #1E1F21;
  line-height: 13px;
}

.span_tile_name {
  margin-top: 20px;
  width: 324px;
  height: 14px;
  font-size: 14px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #404756;
  line-height: 14px;
}

.span_tile_y {
  width: 87px;
  height: 14px;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #1E1F21;
  line-height: 14px;

}

</style>