<template>
  <div v-if="show" class="mask">
    <div class="dlg-msg-boxs flex flex-col">
      <div class="flex-col"
           style="width: 496px;height: auto;margin: auto; background: #FFFFFF;border-radius: 24px;padding-bottom: 32px;">
        <div
            style="width: 496px;height:auto;display: flex; border-radius: 24px; flex-direction: column;background-color: #FFFFFF">
          <div style="margin: auto">
            <img style="width: 96px;height: 96px;margin-top: 36px;margin-bottom: 12px;"
                 :src="require('@/assets/logo.svg')">
            <p class="tile_pa" style="margin: auto">MUSEA</p>
            <p class="tile_name_p" style="margin: 6px auto">By GloryStar</p>
          </div>
          <div style="margin-top: 24px;margin-bottom: 28px;">
            <p class="tile_msag" v-for="(itme,index) in msg">{{ itme }}</p>
          </div>
          <div class="formDiv">
            <input class="ipt" v-model="EmailAddress" v-if="button.isinput" placeholder="Your Email Address"/>
          </div>

          <el-button :loading="loading" type="primary"
                     class="button_logos"  style="  margin-left: 48px; "
                     @click.native.prevent="setView()">{{ button.confirm }}
          </el-button>
          <!--          <div class="formDiv1" v-if="button.isconfirm" @click="setView()"></div>-->

          <p class="span_go" v-if="button.iscancel" @click="cancelClick()">{{ button.cancel }}</p>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {checkSendEmail, resetPassWordBtn} from "@/api/login";

export default {
  data() {
    return {
      EmailAddress: '',
      loading: false
    }
  },
  name: '',
  props: {
    caption: {},
    show: {},
    msg: [],
    button: {},
    callback: {}
  },
  methods: {
    init() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = true;
    },
    close() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("close")
    },
    confirmClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback({code: 0, data: this.EmailAddress})
      //this.$emit('confirm');
    },
    cancelClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback({code: 1, data: this.EmailAddress})
    },
    setView() {
      if (this.IsEmail(this.EmailAddress)){
        this.loading = true
        resetPassWordBtn({userName: this.EmailAddress}).then(postMessage => {
          if (postMessage.code === 200) {
            this.checkEmailState(postMessage.data.redisStr)
          }else {
            this.$message.error(postMessage.msg)
            this.loading = false
          }

        })
      }else {
        this.$message.error('Email format error')
      }

    },
    /**
     * 查看邮件发送状态
     */
    async checkEmailState(str) {
      let time = setInterval(async () => {
        let respons = await checkSendEmail(str)
        if (respons.data.isSned === 1) {
          this.loading = false;
          this.show = false;
          clearInterval(time)
          this.$showRegisterSu(
              {
                msg: ['The email has been sent successfully'],
                button: {
                  isconfirm: true,
                  iscancel: false,
                  confirm: 'confirm'
                },
                callback: (obj) => {
                  if (obj === 'yes') {
                    this.callback({code: 0, data: this.EmailAddress})
                  }
                }


              }
          )
        }
      }, 1000)
    },


  }
}
</script>

<style lang="scss">

::v-deep.el-button--whiteBackground {
  color: #9931F8;
  border-color: #9931F8;
}
.dlg-msg-boxs {
  border-radius: 5px;

 // background-color: rgba(9, 10, 14, 0.6);
  padding: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.formDiv {
  height: 46px;
  margin-left: 48px;
  margin-right: 48px;
  text-align: center;
  background: #E0E1DD;
  border-radius: 36px 36px 36px 36px;
  opacity: 1;
  border: 1px solid #E0E1DD;

}

.formDiv1 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 24px;
  margin-left: 48px;
  margin-right: 48px;
  height: 42px;
  background: #9931F8;
  border-radius: 999px ;
  opacity: 1;
  font-size: 14px;
  font-family: Inter-SemiBold;
  color: #ECECEC;

  &:hover{
    background-color: #9931F8;
    color: #FFFFFF;
  }
}


.span_go {
  cursor: pointer;
  margin-top: 48px;
  text-align: center;
  height: 14px;
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #C0BFC0;
  line-height: 14px;
}

.ipt {
  text-align: center;
  display: inline-block;
  width: 80%;
  height: 100%;
  color: #404756;
  padding-left: 10px;
  border: none;
  background: #E0E1DD;
  outline: none;
  font-family: Inter-Regular;
  font-size: 14px;
}

.button_logos {

  width: 80%;
  margin-bottom: 16px;
  background: #9931F8;
  border-radius: 999px;
  margin-top: 23px;
  border: #9931F8;
  height: 42px;
  font-family: Inter-SemiBold, Inter;
  font-size: 14px;
}


.ipt::-ms-reveal {
  display: none
}
</style>
<style scoped>
@import url(dialogcss.css);
</style>