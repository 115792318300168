import Vue from 'vue'
import VueRouter from 'vue-router'
import Designer from '@/views/designer/index'
import Login from '@/views/login/index.vue'
import home from '@/views/home/index.vue'
import Choose from '@/views/choosetemplate/index.vue'
import Template from '@/views/template/index.vue'
import preview from '@/views/preview/index.vue'
import freeboard from '@/views/freeboard/index.vue'
import TemIndex from "@/views/preview/temIndex.vue";
import createstack from "@/views/createstack/index.vue"
import stacksPreview from "@/views/stacksPreview/index.vue";
import setPassWord from "@/views/setPassWord/index.vue";
import HelloWorld from "@/components/HelloWorld.vue"

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/', // 如果路由为/
            //path: '*', // 如果路由为*
            redirect: {
                name: 'HelloWorld'
            } //重定向到登录组件
        },
        {
            path: '/design',
            name: 'design',
            component: Designer,
            hidden: true
        },
        {
            path: '/HelloWorld',
            name: 'HelloWorld',
            component: HelloWorld,
            hidden: true
        },

        {
            path: '/login',
            name: 'login',
            component: Login,
            hidden: true
        },
        {
            path: '/home',
            name: 'home',
            component: home,
            hidden: true,
            meta: {
                keepAlive: true // 需要被缓存
            }
        },
        {
            path: '/Choose',
            name: 'Choose',
            component: Choose,
            hidden: true

        },
        {
            path: '/preview',
            name: 'preview',
            component: preview,
            hidden: true

        },
        {
            path: '/temindex',
            name: 'temindex',
            component: TemIndex,
            hidden: true
        },
        {
            path: '/freeboard',
            name: 'freeboard',
            component: freeboard,
            hidden: true
        },
        {
            path: '/template',
            name: 'template',
            component: Template,
            meta: {
                keepAlive: false // 需要被缓存
            }
        },
        {
            path: '/createstack',
            name: 'createstack',
            component: createstack,
            hidden: true,
            meta: {
                keepAlive: false // 需要被缓存
            }
        },
        {
            path:'/stacksPreview',
            name:'stacksPreview',
            component:stacksPreview,
            hidden: true,
        },
        {
            path:'/setPassWord',
            name:'setPassWord',
            component: setPassWord,
            hidden: true,
        }
    ]
})
export default router

// 添加全局路由守卫
router.beforeEach((to, from, next) => {
    next()
})