<template>
  <div>
    <el-form label-width="90px" size="mini">
      <el-form-item label="font">
        <el-select v-model="attribute.textFamily">
          <el-option v-for="(item,index) in textFamilyList" :key="index+item" :label="item" :value="item"/>
        </el-select>
      </el-form-item>
      <el-form-item label="colour">
        <el-color-picker v-model="attribute.textColor" show-alpha/>
      </el-form-item>
      <el-form-item label="Bold">
        <el-select v-model="attribute.fontWeight">
          <el-option label="Finer" value="lighter"/>
          <el-option label="normal" value="normal"/>
          <el-option label="bold" value="bold"/>

        </el-select>
      </el-form-item>
      <el-form-item label="tilt">
        <el-select v-model="attribute.fontStyle">
          <el-option label="normal" value="normal"/>
          <el-option label="italic" value="italic"/>
          <el-option label="oblique" value="oblique"/>
        </el-select>
      </el-form-item>
      <el-form-item label="Background color">
        <el-color-picker v-model="attribute.bgColor" show-alpha/>
      </el-form-item>
      <el-form-item label="Text size">
        <el-input-number :min="13" :max="200" v-model="attribute.textSize" style="width: 100%"/>
      </el-form-item>
      <el-form-item label="Row height">
        <el-input-number :min="13" :max="250" v-model="attribute.textLineHeight" style="width: 100%"/>
      </el-form-item>
      <el-form-item label="Alignment method">
        <el-select v-model="attribute.textAlign">
          <el-option v-for="(item,index) in textAlignList" :key="index+item" :label="item" :value="item"/>
        </el-select>
      </el-form-item>
      <el-form-item label="Underline">
        <el-select v-model="attribute.textDecoration">
          <el-option v-for="(item,index) in textDecorationList" :key="index+item.label" :label="item.label" :value="item.value"/>
        </el-select>
      </el-form-item>
<!--      <el-form-item>
        <span slot="label">
          点击跳转
          <el-tooltip effect="light" content="跳转外链请写全路径如：https://www.baidu.com，大屏间跳转请写view?id=大屏ID，如：view?id=6580439c70e1d2c2ff2b98" placement="bottom-end">
            <i class="el-icon-warning" />
          </el-tooltip>
        </span>
        <el-input type="textarea" :rows="3" v-model="attribute.url"></el-input>
      </el-form-item>-->
    </el-form>
  </div>
</template>

<script >
export default {
  name: "sip-text-option",
  props: {
    attribute: Object
  },
  data() {
    return {
      textFamilyList: ['Microsoft YaHei', 'Blackbody', 'Song typeface', 'Imitation of Song Dynasty','Kaiti','Chinese Song typeface'],
      textAlignList: ['left', 'center', 'right'],
      textDecorationList: [{label:'none',value:'none'},{label:'underline',value:'underline'},{label:'overline',value:'overline'},
        {label:'line-through',value:'line-through'},]
    }
  }
}
</script>

<style scoped>

</style>