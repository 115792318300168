<template>
  <div class="flex-col">
    <div class="flex-row"
         style="padding-left: 88px;padding-top: 64px;padding-right: 88px; display: flex;justify-content: space-between;">
      <span class="span_tile_names">My Profile</span>
    </div>
    <div class="flex-row" style="padding-left: 88px;padding-right: 88px;margin-top: 24px;">
      <div class="div_letn">
        <p class="tile_pa">Profile picture</p>
        <div class="flex-row" style="margin-top: 16px;">
          <div class="imgsx">
            <img :src="addAccountFromData.avatar " alt="" style="width: 131px;height: 131px"/>
          </div>
          <div class="flex-col" style="margin-left: 24px;margin-top: 56px">
            <uploadActive
                v-on:uploadImg="uploadImg"
                :image="addAccountFromData.avatar"
                :tilename="tiledname"
            />
            <!--            <div class="flex-row button_fil" @click="Cropper()">
                          <img style="width: 15px;height: 15px;margin-right: 8px; " src="@/assets/icon/icon_reset.svg" alt=""/>
                          <p class="p-tile_clear">Clear</p>
                        </div>-->
          </div>
        </div>

        <div class="flex-row justify-between" style="margin-top: 40px;">
          <div class="flex-col" style="width: 324px">
            <p class="tile_P">Display name</p>
            <el-input v-model="userprtfile.Displayname" autocomplete="off"></el-input>
          </div>
          <div class="flex-col" style="width: 324px">
            <p class="tile_P">Email address</p>
            <div class="accountcr_div_userprt">
              {{ userprtfile.Emailaddress }}
            </div>
            <!--            <el-input v-model="userprtfile.Emailaddress" autocomplete="off" readonly="readonly"></el-input>-->
          </div>
        </div>
        <div class="flex-row justify-between" style="margin-top: 16px;">
          <div class="flex-col" style="width: 324px">
            <p class="tile_P">Contact name</p>
            <el-input v-model="userprtfile.Contactname" autocomplete="off"></el-input>
          </div>
          <div class="flex-col" style="width: 324px">
            <el-popover
                placement="bottom"
                title=""
                v-model="licenseGender"
            >
              <div class="popover_view flex-col" style="width: 300px;">
                <div v-for="(item ,index ) in genderOptions" :key="index">
                  <span class="popover_view_span_gender" @click="clickGender(item)">{{ item.label }}</span>
                </div>
              </div>

              <div slot="reference">
                <p class="tile_P">Gender</p>
                <el-input v-model="Gender" autocomplete="off" readonly="readonly"
                          suffix-icon="el-icon-arrow-down"></el-input>
              </div>
            </el-popover>
            <!--            <p class="tile_P">Gender</p>
                        <el-input v-model="userprtfile.Gender" autocomplete="off"></el-input>-->
          </div>
        </div>
        <div class="flex-row justify-between" style="margin-top: 16px;">
          <div class="flex-col" style="width: 324px">
            <p class="tile_P">Area code</p>
            <el-input v-model="userprtfile.Areacode" autocomplete="off"></el-input>
          </div>
          <div class="flex-col" style="width: 324px">
            <p class="tile_P">Phone number</p>
            <el-input v-model="userprtfile.Phonenumber" autocomplete="off"></el-input>
          </div>
        </div>
        <div class="flex-row justify-between" style="margin-top: 16px;">
          <div class="flex-col" style=" width: 324px">
            <p class="tile_P">System language</p>
            <el-popover
                placement="bottom"
                title=""
                width="324px"
                v-model="licenseLanguage"
            >
              <div class="popover_view_language flex-col">
                <div v-for="(item, index) in languageOptions" :key="index">
                  <span class="popover_view_span_gender" @click="clickLanguag(item)">{{ item.label }}</span>
                </div>

              </div>
              <div class="group2_span_language flex-col" slot="reference">
                <el-input v-model="userprtfile.Systemlanguage" autocomplete="off" readonly="readonly"></el-input>
              </div>
            </el-popover>
          </div>
          <div class="flex-col" style="width: 324px">
            <p class="tile_P">Time zone</p>
            <el-popover
                trigger="click"
                v-model="licenseTimezone"
                placement="bottom"
            >
              <div class="popover_view1 flex-col">
                <div v-for="(item, index) in timeZoneOptions" :key="index">
                  <span class="popover_view_span_zone" @click="cliczoneTime(item,index)">{{ item.label }}</span>
                </div>

              </div>
              <div class="div_slider flex-row justify-between" style="cursor: pointer; " slot="reference">
                <span class="info2_sian" @click="licenseTimezone=true">{{ userprtfile.Timezone }}</span>
                <i class="el-icon-arrow-down"></i>
              </div>
            </el-popover>
          </div>
        </div>
        <div class="flex-row justify-between" style="margin-top: 16px;">
          <div class="flex-col" style="width: 324px;max-width: 324px;">
            <p class="tile_P">Last login time</p>
            <div class="accountcr_div_userprt">
              {{ userprtfile.Lastlogintime }}
            </div>
          </div>
          <div class="flex-col" style="width: 324px">
            <p class="tile_P">Account created at</p>
            <div class="accountcr_div_userprt">
              {{ userprtfile.Accountcreatedat }}
            </div>
          </div>
        </div>
        <div class="flex-row" style="margin-top: 36px">
          <div class="div_inpt" @click="submitAddAccount()">Save</div>
          <!--          <div class="div_inpt2">Discard all changes</div>-->
        </div>

      </div>
      <div class="flex-col" style="margin-left: 24px;">
        <div class="password_div">
          <p class="tile_P2" style="margin-bottom: 10px">Change password</p>
          <span class="update_div">Update your password regularly to keep your account safe</span>
          <div class="flex-row" style="margin-top: 36px">
            <div class="div_inpt3" @click="Updateclik()">Update my password</div>
          </div>
        </div>
        <div class="account_div_close" style="margin-top: 24px;">
          <p class="p_tile_close">Close account</p>
          <div style="margin-top: 16px">
            <p class="p_close">Submit a request to permanently close this account. It may take some </p>
            <p class="p_close"> time for us to handle your request.</p>
          </div>
          <div class="account_div" @click="setcompany">I wish to close my account</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import uploadActive from '@/views/MyProfile/userAvatar.vue'
import {getInfo, submitEdit} from "@/api/login";
import {fileUrl} from "../../../env";

export default {
  name: "MyProfile",
  components: {
    uploadActive
  },
  data() {
    return {
      addAccountFromData: {
        avatar: require('@/assets/icon/active-ring.svg')
      },
      tiledname: 'Upload profile image',
      userprtfile: {
        Displayname: '',
        Emailaddress: '',
        Contactname: '',
        Gender: '',
        Areacode: '',
        Phonenumber: '',
        Systemlanguage: '',
        Timezone: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong',
        Lastlogintime: '',
        Accountcreatedat: ''
      },
      changePass: {
        current: '',
        password: '',
        RePassword: ''

      },
      // Time zone选择列表
      timeZoneOptions: [
        // {
        //   label: "GMT + 8.00, Hong Kong (SAR)",
        //   value: "8",
        // },
        {label: '(GMT -12:00) Eniwetok, Kwajalein', value: '-720'},
        {label: '(GMT -11:00) Midway Island, Samoa', value: '-660'},
        {label: '(GMT -10:00) Hawaii', value: '-600'},
        {label: '(GMT -9:00) Alaska', value: '-540'},
        {label: '(GMT -8:00) Pacific Time (US + Canada)', value: '-480'},
        {label: '(GMT -7:00) Mountain Time (US + Canada)', value: '-420'},
        {
          label: '(GMT -6:00) Central Time (US + Canada), Mexico City',
          value: '-360'
        },
        {
          label: '(GMT -5:00) Eastern Time (US + Canada), Bogota, Lima',
          value: '-300'
        },
        {
          label: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz',
          value: '-240'
        },
        {label: '(GMT -3:30) Newfoundland', value: '-210'},
        {
          label: '(GMT -3:00) Brazil, Buenos Aires, Georgetown',
          value: '-180'
        },
        {label: '(GMT -2:00) Mid-Atlantic', value: '-120'},
        {label: '(GMT -1:00) Azores, Cape Verde Islands', value: '-60'},
        {
          label: '(GMT -0:00) London, Western Europe, Lisbon, Casablanca',
          value: '0'
        },
        {
          label: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris',
          value: '60'
        },
        {label: '(GMT +2:00) Kaliningrad, South Africa', value: '120'},
        {
          label: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
          value: '180'
        },
        {label: '(GMT +3:30) Tehran', value: '210'},
        {label: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi', value: '240'},
        {label: '(GMT +4:30) Kabul', value: '270'},
        {
          label: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
          value: '300'
        },
        {
          label: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi',
          value: '330'
        },
        {label: '(GMT +5:45) Kathmandu', value: '345'},
        {label: '(GMT +6:00) Almaty, Dhaka, Colombo', value: '360'},
        {label: '(GMT +7:00) Bangkok, Hanoi, Jakarta', value: '420'},
        {
          label: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong',
          value: '480'
        },
        {
          label: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
          value: '540'
        },
        {label: '(GMT +9:30) Adelaide, Darwin', value: '570'},
        {
          label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
          value: '600'
        },
        {
          label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia',
          value: '660'
        },
        {
          label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
          value: '720'
        }
      ],
      // Gender选择列表
      genderOptions: [
        {
          label: 'Female',
          value: '0'
        }, {
          label: 'Male',
          value: '1'
        }, {
          label: 'Unknown',
          value: '2'
        }
      ],
      // Language选择列表
      languageOptions: [
        {
          label: 'English',
          value: '1'
        }
      ],
      licenseLanguage: false,
      licenseTimezone: false,
      Language: '',
      Discard: {},
      licenseGender: false,
      Gender: ''
    }
  },
  created() {
    this.checkBtnHide()
  },
  methods: {
    /**
     * 上传图片返回路径
     */
    uploadImg(event) {
      // 上传子组件返回参数 uploadActive
      console.log('--->', event)
      this.addAccountFromData.avatar = fileUrl + event.url
      this.checkBtnHide()
      $bus.emit('homeIndex', {})
    },
    //取消图片
    Cropper() {
      this.addAccountFromData.avatar = fileUrl + this.Discard.user.avatar
    },
    checkBtnHide() {
      getInfo().then(itme => {
        if (itme.code === 200) {
          this.Discard = itme
          this.addAccountFromData.avatar = fileUrl + itme.user.avatar
          this.userprtfile.Displayname = itme.user.nickName
          this.userprtfile.Emailaddress = itme.user.email
          let phonenumberdata = itme.user.phonenumber.split('-')

          if (phonenumberdata.length > 1) {
            this.userprtfile.Areacode = phonenumberdata[0]
            this.userprtfile.Phonenumber = phonenumberdata[1]
          } else {
            this.userprtfile.Phonenumber = itme.user.phonenumber
          }
          this.addAccountFromData.avatar = fileUrl + itme.user.avatar
          this.userprtfile.Systemlanguage = this.languageOptions[0].label
          this.userprtfile.Contactname = itme.user.contactName
          this.userprtfile.Gender = this.genderOptions[this.genderOptions.findIndex(it => it.value === itme.user.sex)].value
          this.userprtfile.Accountcreatedat = this.timeZoneFormat(itme.user.createTime)
          this.userprtfile.Timezone = this.timeZoneOptions[this.timeZoneOptions.findIndex(item => item.value === (itme.user.timeZone).toString())].label
          sessionStorage.setItem('timeZone', itme.user.timeZone)
          sessionStorage.setItem('servicetimeZone', itme.user.timeZone)
          this.userprtfile.Lastlogintime = this.timeZoneFormat(itme.user.loginDate)
          this.userprtfile.Gende = this.genderOptions[this.genderOptions.findIndex(it => it.value === itme.user.sex)].value
          this.Gender = this.genderOptions[this.genderOptions.findIndex(it => it.value === itme.user.sex)].label
        }
      })
    },
    cliczoneTime(data) {
      this.licenseTimezone = false
      this.userprtfile.Timezone = data.label
      //    this.userprtfile.timeZone = data.value
    },
    setInfo() {
      let data = {
        teamName: this.addAccountFromData.teamName, // 团队名称
        nickName: this.userprtfile.nickName, // 显示名称
        phonenumber: this.userprtfile.Areacode + '-' + this.userprtfile.phonenumber1, // 联系号码
        sex: this.genderOptions[this.genderOptions.findIndex(it => it.label === this.Gender)].value, // 0：男; 1:女; 2:未知
        avatar: this.addAccountFromData.avatar, // 头像路径
        email: this.addAccountFromData.email, // 邮箱
        userName: this.addAccountFromData.userName, // 登录邮箱
        timeZone: this.userprtfile.timeZone.toString(), // 时区
        // 0:已激活；1:未激活；2:已停用
        userId: this.Receivedata.userId,
        contactName: this.signUpForm1.contactName1 + '-' + this.signUpForm1.contactName2
      }
    },
    setcompany() {
      this.$showcloseAccount({
        callback: (e) => {

        }
      })
    },
    clickLanguag(e) {
      this.licenseLanguage = false
    },
    submitAddAccount() {
      console.log('================')
      /*
      *  teamName: this.addAccountFromData.teamName, // 团队名称
      *  nickName: this.addAccountFromData.nickName, // 显示名称
      *  sex: this.genderOptions[this.genderOptions.findIndex(it => it.label === this.userprtfile.Gender)].value, // 0：男; 1:女; 2:未知
      * */
      let dataInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      let FromData = {
        userName: this.userprtfile.Emailaddress,
        phonenumber: this.userprtfile.Areacode + '-' + this.userprtfile.Phonenumber, // 联系号码
        //    avatar: this.addAccountFromData.avatar, // 头像路径
        timeZone: this.timeZoneOptions[this.timeZoneOptions.findIndex(item => item.label === (this.userprtfile.Timezone))].value, // 时区
        // 0:已激活；1:未激活；2:已停用
        userId: dataInfo.userId,
        sex: this.userprtfile.Gende,
        contactName: this.userprtfile.Contactname,
        nickName: this.userprtfile.Displayname
      }

      let data = FromData
      let timeZone = data.timeZone
      delete data.language

      // 必填验证
      switch ('') {
        case data.nickName:
          this.$message.error('The display name is required!')
          return false
          break
          break
        case data.timeZone:
          this.$message.error('Time zone is required!')
          return false
          break
      }

      /*      // 邮箱验证
            if (this.IsEmail(data.email)) {
              data.userName = data.email
            } else {
              this.$message.warning('The email format is incorrect')
              return false
            }*/

      submitEdit(data)
          .then(respons => {
            if (respons.code === 200) {
              this.$message.success('User information saved successfully')
              this.checkBtnHide()
            } else {

            }
          })
          .catch(error => {
          })
    },
    Updateclik() {
      this.$showChangePassword({
        callback: (e) => {

        }
      })
    },
    clickGender(e) {
      console.log(e)
      this.userprtfile.Gende = e.value
      this.Gender = e.label
    }

  }

}
</script>

<style scoped lang="scss">
::v-deep .el-input__inner {
  border: 1px solid #E0E1DD;
  border-radius: 8px;
  width: 100%;
  height: 38px;
  margin-top: 8px;
  background-color: #FFFFFF;
  font-family: Inter-Regular, Inter;
  font-size: 14px;
  color: #1E1E1E;
}

.accountcr_div_userprt {
  display: flex;
  padding-left: 12px;
  align-items: center;
  border: 1px solid #C0BFC0;
  border-radius: 4px;
  height: 38px;
  margin-top: 8px;
  background-color: #FFFFFF;
  font-family: Inter-Regular, Inter;
  font-size: 14px;
  color: #C0BFC0;
}

.info2_sian {
  width: 272px;
  height: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  font-size: 14px;
  color: #1E1E1E;
  line-height: 14px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.span_tile_names {
  white-space: nowrap;
  font-size: 48px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #404756;
  line-height: 48px;
}


.imgsx {
  width: 132px;
  height: 132px;
  min-width: 132px;
  min-height: 132px;
  max-width: 132px;
  max-height: 132px;
  background-color: #F4F4F5;
  border-radius: 50%;
  overflow: hidden;
}

.button_fil {
  margin-top: 13px;
  cursor: pointer;
}

.p-tile_clear {
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #A4ABBD;
  line-height: 16px;
}

.tile_pa {
  width: 93px;
  height: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  font-size: 14px;
  color: #8F8F8F;
  line-height: 14px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.div_inpt {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  text-align: center;
  width: 120px;
  height: 40px;
  background: #9931F8;
  border-radius: 999px;
  font-size: 13px;
  color: #FFFFFF;
  font-family: Inter-SemiBold, Inter;
}

.div_inpt2 {
  cursor: pointer;
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-items: center;
  height: 40px;
  text-align: center;
  background: #FFFFFF;
  border-radius: 999px;
  font-size: 13px;
  color: #8F8F8F;
  font-family: Inter-SemiBold, Inter;
}

.div_inpt3 {
  width: 198px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  text-align: center;
  height: 40px;
  background: #FFFFFF;
  border-radius: 999px;
  font-size: 13px;
  color: #9931F8;
  border: 1px solid #9931F8;
  font-family: Inter-SemiBold, Inter;
}

.tile_P {
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  font-size: 13px;
  color: #8F8F8F;
  line-height: 13px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.tile_P2 {
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  font-size: 14px;
  color: #1E1E1E;
  line-height: 14px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.account_div {
  cursor: pointer;
  margin-top: 24px;
  width: 198px;
  height: 40px;
  border-radius: 999px;
  border: 1px solid #E44356;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  font-size: 13px;
  color: #E44356;
  line-height: 16px;
  text-align: center;
  font-style: normal;
  text-transform: none;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  text-align: center;
}

.p_close {
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  font-size: 14px;
  color: #1E1E1E;
  line-height: 19px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.p_tile_close {
  font-family: Inter-Medium, Inter;
  font-weight: 400;
  font-size: 14px;
  color: #1E1E1E;
  line-height: 19px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.div_slider {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin-top: 8px;
  border-radius: 5px;
  border: 1px solid #D1D4DD;
  width: 324px;
  height: 38px;
}

.popover_view_span_zone {
  width: 272px;
  cursor: pointer;
  margin-top: 15px;
  font-size: 14px;
  font-family: Inter-Regular, Inter, serif;
  color: #404756;
  font-weight: 400;
}

.div_letn {
  width: 712px;
  height: auto;
  background-color: #FFFFFF;
  border-radius: 12px;
  min-width: 712px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 20px;
}

.password_div {
  width: 528px;
  height: auto;
  background: #FFFFFF;
  border-radius: 12px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 20px;
}

.account_div_close {
  width: 528px;
  height: auto;
  background: #FFFFFF;
  border-radius: 12px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 20px;
}

.update_div {

  width: 480px;
  height: 19px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  font-size: 14px;
  color: #8F8F8F;
  line-height: 19px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.popover_view_span_gender {
  cursor: pointer;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  font-size: 14px;
  color: #1E1E1E;
  line-height: 14px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

</style>