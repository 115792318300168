import Details from "@/erredpop/Details.vue";

let ConfirmConstructor, instance

const showDetails = {
    install(Vue) {
        ConfirmConstructor = Vue.extend(Details)
        instance = new ConfirmConstructor().$mount()
        document.body.appendChild(instance.$el)
        Vue.prototype.$showDetails = options => {
            Object.assign(instance, options)
            instance.init()
        }
    }
}

export default showDetails