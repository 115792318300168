<template>
  <div style="width: 100%;height:90vh;background: #FFFFFF;overflow: hidden">
    <el-row style="background: #FFFFFF;">
      <!--      <el-tabs v-show="configBarShow" v-model="configTab" :stretch="true">
              <el-tab-pane label="Coordinate" name="basic">
                <div style="width: 300px; margin: 0 auto">
                  <el-row style="padding: 10px 6px 0 6px">
                    Width：
                    <el-input-number style="width: 95%" :min="20" :max="2000" v-model="currentCpt.cptWidth" size="small"/>
                  </el-row>
                  <el-row style="padding: 10px 6px 0 6px">
                    Height：
                    <el-input-number style="width: 95%" :min="20" :max="1500" v-model="currentCpt.cptHeight" size="small"/>
                  </el-row>
                  <el-row style="padding: 10px 6px 0 6px">
                    X Axis：
                    <el-input-number style="width: 95%" :min="-500" :max="2500" v-model="currentCpt.cptX" size="small"/>
                  </el-row>
                  <el-row style="padding: 10px 6px 0 6px">
                    Y Axis：
                    <el-input-number style="width: 95%" :min="-500" v-model="currentCpt.cptY" size="small"/>
                  </el-row>
                  <el-row style="padding: 10px 6px 0 6px">
                    Z Axis：
                    <el-input-number style="width: 95%" :min="1" :max="1800" v-model="currentCpt.cptZ" size="small"/>
                  </el-row>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Attribute" name="custom">
                <div class="customForm" :style="{height:(height-140)+'px'}" v-if="currentCpt && currentCpt.cptOption">
                  <component :is="currentCpt.cptOptionKey?currentCpt.cptOptionKey: + currentCpt.cptKey+'-option'"
                             :attribute="currentCpt.cptOption.attribute"/>
                </div>
              </el-tab-pane>
            </el-tabs>-->
      <div style="margin-top: 10px;">
        <div class="flex-row" style="height: 48px;width: 100%">
          <div :class="itmeDiv===0 ? 'tile_span_true':'tile_span_false'" @click="OnClinseItme(0)">
            <span :class="itmeDiv ===0 ?'span_tile_true':'span_tile_false'">Basic Settings</span>
          </div>
          <div :class="itmeDiv===1 ? 'tile_span_true':'tile_span_false'" @click="OnClinseItme(1)">
            <span :class="itmeDiv ===1 ?'span_tile_true':'span_tile_false'">Component</span>
          </div>
        </div>
        <div v-if="itmeDiv===0" class="flex-col" style="padding-left: 18px;padding-right: 18px">
          <span class="span_name_tile">Name of the board</span>
          <div>
            <el-input v-model="designData.title" autocomplete="off" style="width: 100%;margin-top: 8px;"></el-input>
          </div>
<!--          <span class="span_name_tile">Aspect ratio</span>
          <div class="div_tope">
            <span style=" text-align: left;height: 38px;line-height: 38px;padding-left: 12px;"> 16 : 9</span>
          </div>-->
          <span class="span_name_tile">Screen Size</span>
          <div class="flex-row" style="margin-top: 12px;">
            <div class="flex-col">
              <span class="span_width">Width</span>
              <el-input v-model="designData.scaleX" autocomplete="off" style="width: 100%;margin-top: 8px;"
                        @input="setWidthX()"></el-input>
            </div>
            <div class="flex-col" style="margin-left: 12px">
              <span class="span_width">Height</span>
              <el-input v-model="designData.scaleY" autocomplete="off" style="width: 100%;margin-top: 8px;"
                        @input="setHeightY()"></el-input>

            </div>
          </div>
          <span class="span_name_tile">Background fill</span>
          <div class="flex-row" style="margin-top: 9px;">
            <el-color-picker v-model="designData.bgColor"></el-color-picker>
            <div
                style="border-radius: 8px;border: 1px solid #D9D9D9;width: 284px;height: 38px;margin-left: 8px;display: flex;align-items: center;padding-left: 12px;">
              <span class="span_tile_y">{{ designData.bgColor }}</span></div>
          </div>

        </div>
        <div v-if="itmeDiv===1" class="flex-col" style="padding-left: 18px;padding-right: 18px">
          <div class="customForm">
            <component :is="itemCom.cptOptionKey" :attribute="itemCom" :key="General"/>
          </div>
        </div>

        <!--
                <el-form :model="designData" label-width="100px" size="small" :label-position="'top'" >
                  <el-form-item label="Name of the board">
                    <el-input v-model="designData.title" autocomplete="off" style="width: 90%;margin-left: 16px;"></el-input>
                  </el-form-item>
                  <el-form-item label="Width">
                    <el-input-number v-model="designData.scaleX" :min="640" :max="10240" style="width: 90%;margin-left: 16px;"/>
                  </el-form-item>
                  <el-form-item label="Height">
                    <el-input-number v-model="designData.scaleY" :min="320" :max="10240" style="width: 90%;margin-left: 16px;"/>
                  </el-form-item>
                  <el-form-item label="background color">
                    <el-color-picker v-model="designData.bgColor" show-alpha style="margin-left: 16px;"/>
                  </el-form-item>
                  <el-form-item label="Background image" >
                    <div v-if="designData.bgImg" style="width: 100%;height: 100%;position: relative;margin-left: 16px;">
                      <img :src="fileUrl+designData.bgImg" style="width: 100%;height: 100%;margin-left: 16px;"/>
                      <i style="position: absolute;z-index: 6;right: 0;font-size: 20px;color: #FFCCCC"
                         class="el-icon-delete" @click.stop="handleRemove"></i>
                    </div>
                    <div v-else class="uploadItem" @click="showGallery" style="margin-left: 16px;">
                      <i style="font-size: 40px;color: #aaa;" class="el-icon-plus"></i>
                    </div>
                  </el-form-item>
                </el-form>
        -->


      </div>
    </el-row>
  </div>
</template>

<script>
import {fileUrl} from "../../../env";

export default {
  name: 'componentleft',
  props: {
    currentCpt: [],
    designData: Object,
    height: Number
  },
  data() {
    return {
      itmeDiv: 0,
      configBarShow: false,
      configTab: 'custom',
      dataLabels: ['数据', '接口地址'],
      hasJsonFlag: true,
      cmOptions: {
        tabSize: 4, // tab的空格个数
        theme: 'dracula', //主题样式
        lineNumbers: true, //是否显示行数
        lineWrapping: true, //是否自动换行
        styleActiveLine: true, //line选择是是否加亮
        matchBrackets: true, //括号匹配
        mode: 'text/x-sparksql', //实现javascript代码高亮
        readOnly: false, //只读

        keyMap: 'default',
        extraKeys: {tab: 'autocomplete'},
        foldGutter: true,
        gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
        hintOptions: {
          completeSingle: false,
          tables: {},
        },
        AspectRatio: '16 : 9'

      },
      itemCom: 0,
      keyMap: 'default',
      General: Date.now(),

    }
  },
  mounted() {

  },
  methods: {
    confirmCheck() {
      this.designData.bgImg = fileUrl;
    },
    refreshCptData() {
      this.$emit('refreshCptData');
    },
    showGallery() {
      this.$refs.gallery.opened();
    },
    handleRemove() {
      this.designData.bgImg = ''
    },
    showCptConfig(e) {
      this.General = Date.now()
      this.itmeDiv = 1
      this.itemCom = e
    },
    OnClinseItme(data) {
      this.itmeDiv = data
      this.itemCom = 0
    },
    setWidthX() {
      $bus.emit('freeboard', {id: 3, data: this.designData.scaleX})
    },
    setHeightY() {
      $bus.emit('freeboard', {id: 4, data: this.designData.scaleY})
    }


  }
}

</script>

<style scoped>

/deep/ .el-form-item__label {
  padding-left: 16px;
  white-space: nowrap;
  text-align: left;
  font-size: 18px;
}

.cptTitle {
  line-height: 35px;
  text-align: center;
  background: #3f4b5f;
  color: #fff;
}

.closeItem:hover {
  cursor: pointer;
  background: #2b3340;
}

.customForm {
  overflow-y: hidden;
}

.uploadItem {
  width: 120px;
  height: 120px;
  text-align: center;
  line-height: 120px;
  border: 1px solid #ddd;
  cursor: pointer
}

.tile_span_true {
  cursor: pointer;
  align-content: center;
  align-items: center;
  display: flex;
  width: 180px;
  height: 48px;
  background: #FFFFFF;
  border-bottom: 2px solid #1E1F21;
}

.tile_span_false {
  cursor: pointer;
  align-content: center;
  display: flex;
  width: 180px;
  height: 48px;
  background: #FFFFFF;
  border-bottom: 2px solid #AFAFAF;
}

.span_tile_true {
  margin: auto;
  height: 14px;
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #1E1F21;
}

.span_tile_false {
  margin: auto;
  height: 14px;
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 400;
  color: #AFAFAF;
}

.div_tope {
  margin-top: 8px;
  display: flex;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  height: 38px;
}

.span_name_tile {
  margin-top: 20px;
  font-size: 13px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #1E1F21;
  line-height: 13px;
}

.span_width {
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #AFAFAF;
  line-height: 12px;
}

</style>