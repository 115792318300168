<template>
  <div>
    <div class="flex-row " style="margin-top: 20px;">
      <span class="span_tile_name">Dimension</span>
      <div class="flex-row" style="margin-left: 200px">
        <img @click="Oncliklayerdown" style="margin-right: 16px;cursor: pointer;"
             :src="require('@/assets/icon/iconlayerdown.svg')">
        <img @click="Oncliklayerup" style="cursor: pointer;" :src="require('@/assets/icon/iconlayerup.svg')">
      </div>
    </div>
    <div class="flex-row" style="margin-top: 12px;">
      <div class="flex-col">
        <span class="span_tile">X</span>
        <el-input v-model="attribute.cptX" autocomplete="off" style="width:156px;margin-top: 8px;"
                  @input="OInpi"></el-input>
      </div>
      <div class="flex-col" style="margin-left: 12px">
        <span class="span_tile">Y</span>
        <el-input v-model="attribute.cptY" autocomplete="off" style="width:156px;margin-top: 8px;"
                  @input="OInpi"></el-input>
      </div>
    </div>
    <div class="flex-row" style="margin-top: 12px;">
      <div class="flex-col">
        <span class="span_tile">Width</span>
        <el-input v-model="attribute.cptWidth" autocomplete="off" style="width:156px;margin-top: 8px;"
                  @input="OInpi"></el-input>
      </div>
      <div class="flex-col" style="margin-left: 12px">
        <span class="span_tile">Height</span>
        <el-input v-model="attribute.cptHeight" autocomplete="off"
                  style="width:156px;margin-top: 8px;"
                  @input="OInpi"></el-input>
      </div>

    </div>
    <div class="flex-col" style="margin-top: 12px;">
      <p class="span_tile_type">Source type</p>
      <el-select v-model="value" placeholder="Select a content type"
                 style="width: 324px;margin-top: 8px;overflow-y: hidden;"
                 @change="onChangevalue">
        <el-option
            v-for="item in sourType"
            :key="item.id"
            :label="item.label"
            :value="item.id">
        </el-option>
      </el-select>
    </div>
    <div class="flex-col" style="margin-top: 12px" v-if="value === 0">
      <span class="span_tile">URL</span>
      <el-input v-model="attribute.cptOption.attribute.url" autocomplete="off"
                style="width:324px;margin-top: 8px;"
                @input="OInpi"></el-input>
    </div>
    <div class="flex-col" style="margin-top: 12px" v-if="value === 1">
      <span class="span_tile">File</span>
      <el-select v-model="filevalue" placeholder="Select a content type"
                 style="width: 324px;margin-top: 8px;overflow-x: hidden;"
                 @change="onChangeView">
        <el-option
            v-for="item in listData"
            :key="item.fileId"
            :label="item.fileName"
            :value="item.filePath">
        </el-option>
      </el-select>

    </div>

    <!--    cptHeight: 365
        cptKey: "sig-video"
        cptOption: Object { attribute: Getter & Setter, … }
        cptOptionKey: "sig-video-option"
        cptTitle: "Video"
        cptWidth: 640
        cptX: 81
        cptY: 24
        cptZ: 100
        icon: "videoleft"
        id: "771f9860-b37c-11ee-9e60-4174a64b50f7"
        fontWeight: 640
       textLineHeight: 365
      typ: 0
     url: "https://v-cdn.zjol.com.cn/280443.mp4"
        -->

  </div>
</template>
<script>
import {museaFileList} from "@/api/login";

export default {
  name: 'FreeVideo',
  props: {
    attribute: Object
  },
  data() {
    return {
      Weight: '',
      listData: [],
      value: '',
      filevalue: '',
      sourType: [
        {value: 'URL', label: ' URL', id: 0},
        {value: 'File', label: 'File', id: 1},
      ]
    }
  },
  created() {
    this.setVideo()
    this.value = this.attribute.cptOption.attribute.type
    this.filevalue = this.attribute.cptOption.attribute.url

  },
  methods: {
    OInpi() {
      this.attribute.cptOption.attribute.fontWeight = this.attribute.cptWidth
    },
    setVideo() {
      this.listData.splice(0, this.listData.length)
      let dataInfo = JSON.parse(sessionStorage.getItem('museaFileVide'))
      this.listData = dataInfo.sort(this.sortDownDate)
    },


    onChangeView(e) {
      console.log('----sigvideo----onChangeView------->')
      this.attribute.cptOption.attribute.url = e
      $bus.emit('sigvideo', 1)
    },
    onChangevalue(e) {
      this.attribute.cptOption.attribute.type = e
    },
    Oncliklayerdown() {
      this.attribute.cptZ = this.attribute.cptZ - 1
    },
    Oncliklayerup() {
      this.attribute.cptZ = this.attribute.cptZ + 1
    }

  },
  watch: {
    attribute: {
      handler(o, l) {
        this.attribute.cptOption.attribute.fontWeight = this.attribute.cptWidth
        this.attribute.cptOption.attribute.textLineHeight = this.attribute.cptHeight
      },
      deep: true
    }
  }
}
</script>


<style scoped>
.span_tile {
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #C0BFC0;
  line-height: 12px;
}

.span_tile_name {
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #1E1E1E;
  line-height: 14px;
}


.span_tile_type {
  height: 13px;
  font-size: 13px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #1E1F21;
  line-height: 13px;
}

</style>