import ChangePassword from "@/erredpop/ChangePassword.vue";

let ConfirmConstructor, instance

const showChangePassword = {
    install(Vue) {
        ConfirmConstructor = Vue.extend(ChangePassword)
        instance = new ConfirmConstructor().$mount()
        document.body.appendChild(instance.$el)

        Vue.prototype.$showChangePassword = options => {
            Object.assign(instance, options)
            instance.init()
        }

    }
}

export default showChangePassword