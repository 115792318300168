import Vue from 'vue'

import contenttype from '@/element/options/ContentType.vue'
import LeftImage from '@/element/leftview/Left-Image.vue'
import Leftplaylist from '@/element/leftview/Left-playlist.vue'

import LeftText from '@/element/leftview/Left-Text.vue'
import LeftVideo from '@/element/leftview/leftVideo.vue'

import freeText from "@/element/leftfree/FreeText.vue";
import freeVideo from "@/element/leftfree/FreeVideo.vue";
import freeImage from "@/element/leftfree/FreeImage.vue";
import freeRssfeed from "@/element/leftfree/FreeRssfeed.vue";
import freePlaylist from "@/element/leftfree/FreePlaylist.vue";
import FreePlaylist from "@/element/leftfree/FreePlaylist.vue";
import FreeSubtitles from '@/element/leftfree/FreeSubtitles.vue'

import LeftRssfeed from "@/element/leftview/Left-Rssfeed.vue";


let cptOptionsList = [
    contenttype, LeftImage, Leftplaylist, LeftText, LeftVideo, freeRssfeed, freePlaylist, freeVideo, freeImage, freeText, FreeSubtitles, FreePlaylist, LeftRssfeed
]

cptOptionsList.forEach(ele => {
    Vue.component(ele.name, ele);
})
