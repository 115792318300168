<template>
  <div v-if="show" class="mask">
    <div class="dlg-msg-box flex flex-col">
      <div class="flex-col"
           style="width: 390px;height: auto;margin: auto; background: #FFFFFF;border-radius: 8px;padding-bottom:16px;">
        <div class="flex-row justify-between" style="margin-top: 20px;">
          <p class="tile_mase">Details</p>
          <img style="cursor: pointer;margin-right: 14px;" :width="12" :height="12"
               :src="require('@/assets/icon/code601.svg')"
               @click="close()">
        </div>
        <div class="flex-col" style="padding-left: 24px;margin-top: 32px;">
          <span class="span_tle">License key</span>
          <span class="span_masg" style="margin-top: 8px;">{{ button.licenseKey  }}</span>

          <span class="span_tle" style="margin-top: 20px;">License type</span>
          <span class="span_masg" style="margin-top: 8px;">{{ button.Type }}</span>

          <span class="span_tle" style="margin-top: 20px;">Expiry date</span>
          <span class="span_masg" style="margin-top: 8px;">{{ button.expiryTime }}</span>

          <span class="span_tle" style="margin-top: 20px;">Last renew date</span>
          <span class="span_masg" style="margin-top: 8px;">{{button.renewTime }}</span>

          <span class="span_tle" style="margin-top: 20px;">Days left</span>
          <span class="span_masg" style="margin-top: 8px;">{{ button.dataleft }}</span>

          <span class="span_tle" style="margin-top: 20px;">Validity</span>
          <span class="span_masg" style="margin-top: 8px;">{{ button.validityta }}</span>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Details",
  props: {
    caption: {},
    show: {},
    msg: [],
    button: {},
    callback: {}
  },
  methods: {
    init() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = true;
    },
    close() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("close")
    },
    confirmClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("yes")
      //this.$emit('confirm');
    },
    cancelClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("no")
    }
  }
}
</script>

<style scoped>

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tile_mase {
  width: 100%;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  font-size: 16px;
  color: #383838;
  line-height: 20px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}

.span_tle {
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  font-size: 14px;
  color: #8F8F8F;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.span_masg {
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  font-size: 14px;
  color: #1E1E1E;
  line-height: 14px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

</style>