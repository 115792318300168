<template>
  <div class="body flex-col">
    <el-row class="top">
      <el-col :span="12">
        <div class="flex-row">
          <div style="width: 64px;height: 54px;background: #9931F8; display: flex;align-items: center;">
            <img class="img_icon" :src="require('@/assets/logo_fff.svg')">

          </div>
          <div class="my_boards" @click="OnlicLisb(0)">
            <div class="my_boards_imgs">
              <div class="svgicon">
                <img v-bind:class="{img_svg1:Library.Boards ,img_svg0:!Library.Boards}"
                     :src="require('@/assets/icon/board.svg')">
              </div>
              <span v-bind:class="{tile_span1:Library.Boards ,tile_span2:!Library.Boards}">My Boards</span>
            </div>
            <div v-if="Library.Boards" class="line_div"></div>
          </div>
          <div class="my_boards" @click="OnlicLisb(1)">
            <div class="my_boards_imgs">
              <div class="svgicon">
                <img v-bind:class="{img_svg1:Library.Stacks ,img_svg0:!Library.Stacks}"
                     :src="require('@/assets/icon/stack.svg')">
              </div>
              <span v-bind:class="{tile_span1:Library.Stacks ,tile_span2:!Library.Stacks}">My Stacks</span>
            </div>
            <div v-if="Library.Stacks" class="line_div"></div>

          </div>
          <div class="my_boards" @click="OnlicLisb(2)">
            <div class="my_boards_imgs">
              <div class="svgicon">
                <img v-bind:class="{img_svg1:Library.Library ,img_svg0:!Library.Library}"
                     :src="require('@/assets/icon/library.svg')">
              </div>
              <span v-bind:class="{tile_span1:Library.Library ,tile_span2:!Library.Library}">Media Library</span>
            </div>
            <div v-if="Library.Library" class="line_div"></div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="my_boards" style="position: absolute; right: 0">
          <el-popover
              class="my_boards_imgs"
              placement="top"
              :width="200"
              trigger="hover">
            <div class="flex-col" style="display: flex;align-content: center;">
              <img style="border-radius: 50%;margin: auto;width: 50px;height: 50px;"
                   :src="UelImag">
              <span style="margin: 12px auto;">{{ user.nickName }}</span>
              <span style="margin:  auto;font-size: 12px;color: #AFAFAF;">{{ user.email }}</span>
              <i style="height: 24px;"></i>
              <div class="div_profile" @click="OnlicLisb(4)">
                <div class="flex-row" style="margin: auto" >
                  <img style="width: 15px;height: 15px;margin: auto" :src="require('@/assets/icon/edit1.svg')">
                  <span style="color: #9931F8;margin-left: 14px;font-family: Inter-SemiBold;font-size: 13px">Edit profile</span>
                </div>
              </div>
              <i style="height: 8px;"></i>
              <div class="div_profile" @click="OnlicLisb(3)">
                <div class="flex-row" style="margin: auto">
                  <img style="width: 15px;height: 15px;margin: auto" :src="require('@/assets/icon/License.svg')">
                  <span
                      style="color: #9931F8;margin-left: 14px;font-family: Inter-SemiBold;font-size: 13px">My licenses</span>
                </div>
              </div>
              <i style="height: 8px;"></i>
              <div class="div_logout" @click="Logout()">
                <div class="flex-row" style="margin: auto">
                  <img style="width: 15px;height: 15px;margin: auto" :src="require('@/assets/icon/logout.svg')">
                  <span
                      style="color: #E44356;margin-left: 14px;font-family: Inter-SemiBold;font-size: 13px;">Log out</span>
                </div>
              </div>
            </div>
            <div slot="reference">
              <img class="imag_right_uesr" style="border-radius: 50%" :src="UelImag">
              <img class="imag_right_icon" :src="require('@/assets/icon/iconarrow-expand.svg')">
            </div>

          </el-popover>
        </div>
      </el-col>
    </el-row>
    <div>
      <MyBoards v-if="Library.Boards"/>
      <MyStacks v-if="Library.Stacks"/>
      <MyLibrary v-if="Library.Library"/>
      <Licenses v-if="Library.licenses"/>
      <MyProfile v-if="Library.Profile"/>
    </div>

  </div>
</template>

<script>
import MyBoards from "@/views/boards/index.vue";
import MyLibrary from "@/views/library/index.vue";
import MyStacks from "@/views/stacks/index.vue";
import {removeToken, setToken} from "@/utils/auth";
import {getInfo, Licenselist, museaFileList} from "@/api/login";
import {fileUrl} from "../../../env";
import Licenses from "@/views/licenses/index.vue";
import MyProfile from "@/views/MyProfile/index.vue";
import axios from "axios";

export default {
  components: {MyProfile, Licenses, MyStacks, MyLibrary, MyBoards},
  data() {
    return {
      Library: {
        Boards: false,
        Stacks: false,
        Library: false,
        licenses: false,
        Profile: false
      },
      nameStar: 'My Boards',
      user: {},
      UelImag: require('@/assets/icon/image.svg')

    }
  },
  created() {
    this.checkBtnHide()
    $bus.on('homeIndex', data => {
      this.checkBtnHide()
    })

  },
  mounted() {
    /*    var itme = sessionStorage.getItem('homeItme')
        if (itme !== null) {
          this.Library = {Boards: false, Stacks: false, Library: false, licenses: false}
          switch (parseInt(itme)) {
            case 0:
              this.Library.Boards = true
              this.nameStar = 'My Boards'
              break
            case 1:
              this.Library.Stacks = true
              this.nameStar = 'My Stacks'
              break
            case 2:
              this.Library.Library = true
              this.nameStar = 'Media Library'
              break

          }
        }*/
  },
  methods: {
    OnlicLisb(obj) {
      this.Library = {Boards: false, Stacks: false, Library: false, Profile: false, licenses: false,}
      switch (obj) {
        case 0:
          sessionStorage.setItem('homeItme', JSON.stringify(obj))
          this.Library.Boards = true
          this.nameStar = 'My Boards'
          break
        case 1:
          sessionStorage.setItem('homeItme', JSON.stringify(obj))
          this.Library.Stacks = true
          this.nameStar = 'My Stacks'
          break
        case 2:
          sessionStorage.setItem('homeItme', JSON.stringify(obj))
          this.Library.Library = true
          this.nameStar = 'Media Library'
          break
        case 3:
          this.Library.licenses = true
          this.nameStar = 'My Licenses'
          break
        case 4:
          this.Library.Profile = true
          this.nameStar = 'My Profile'
          break
      }

    },
    refreshCptData() {

    },
    CancelOperation() {
      const actionBar = document.getElementById('actionbar')
      actionBar.classList.remove('show')
      // eslint-disable-next-line no-undef
      $bus.emit('actionbarvie', true)
    },
    Logout() {
      this.$showLogout({
        caption: '',
        msg: ['Please make sure you have enough cloud', 'storage in your account for these files.'],
        // eslint-disable-next-line no-unused-vars
        callback: (data) => {
          // eslint-disable-next-line no-empty
          if (data === 'yes') {
            this.FedLogOut()
          }
        }
      })
    },
    FedLogOut() {
      return new Promise(resolve => {
        removeToken()
        resolve()
        this.$router.replace('/login').catch(err => err)
      })
    },
    checkBtnHide() {
      const urlParams = new URLSearchParams(window.location.search);
      const uuid = urlParams.get('starToken')
      if (uuid !== null) {
        console.log('===uuid=>',uuid)
        this.selectFile(uuid)
      } else {
        console.log('-------else------>',uuid)
        getInfo().then(itme => {
          if (itme.code === 200) {
            sessionStorage.setItem('userInfo', JSON.stringify(itme.user))
            this.user = itme.user
            this.UelImag = fileUrl + itme.user.avatar
            sessionStorage.setItem('timeZone', itme.user.timeZone)
            sessionStorage.setItem('servicetimeZone', itme.user.timeZone)
            this.Library.Boards = true
            this.getLicenseData(itme)
            this.getViewList(itme)
          }else {

          }
        })
      }


    },
    async selectFile(data) {
      removeToken()
      let fn = new FormData()
      fn.append('token', data)
      await axios({
        method: 'POST',
        url: `${fileUrl}/prod-api/loginSso`,
        data: fn,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
        },

      }).then(respons => {
        console.log(respons.data.code)
        if (respons.data.code === 200) {
          console.log('----->',respons.data.token)
          setToken(respons.data.token)
          getInfo().then(itme => {
            if (itme.code === 200) {
              sessionStorage.setItem('userInfo', JSON.stringify(itme.user))
              this.user = itme.user
              this.UelImag = fileUrl + itme.user.avatar
              sessionStorage.setItem('timeZone', itme.user.timeZone)
              sessionStorage.setItem('servicetimeZone', itme.user.timeZone)

              window.history.replaceState(null,null,this.getnewurl())
              this.Library.Boards = true
              this.getLicenseData(itme)
              this.getViewList(itme)
            }
          })

        } else {

        }
      }).catch(reason => {
      })

    },
    getViewList(e) {
      let dataInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      var max = dataInfo.licenses.map(itme => itme.licenseType === '2' ? parseInt(itme.licenseContent) : 0).reduce(function (prev, curr, idx, arr) {
        return prev + curr;
      })
      sessionStorage.removeItem('museaFileVide')
      sessionStorage.removeItem('museaFileImage')
      museaFileList({userId: dataInfo.userId, fileName: this.username}).then(postMessage => {
        let vidoList = []
        let ImageList = []
        if (postMessage.code === 200) {
          let arr = postMessage.rows.sort(this.sortDownDate)
          arr.forEach(itme => {
            if (itme.fileType === '0') {
              vidoList.push(itme)
            } else {
              ImageList.push(itme)
            }
            itme.createTime = this.timeZoneFormat(itme.createTime)
            itme.ischeck = false
          })
          sessionStorage.setItem('museaFileVide', JSON.stringify(vidoList))
          sessionStorage.setItem('museaFileImage', JSON.stringify(ImageList))
        }
      })
    },
    getLicenseData(e) {
      let dataInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      let data = {
        userId: dataInfo.userId,
        licenseKey: '',
        licenseType: '',
        expiryTime: '',
        extendExpiryTime: '',
        validity: ''
      }
      sessionStorage.removeItem('Licenselist')
      Licenselist(data).then(reset => {
        if (reset.code === 200) {
          this.Library.Boards = true
          reset.rows.forEach(item => {
            if (item.extendExpiryTime && item.extendExpiryTime !== '') {
              item.dataleft = this.getDiffDay(item.extendExpiryTime, this.getCurrentTime()) > 0 ? this.getDiffDay(item.extendExpiryTime, this.getCurrentTime()) : 0
              item.expiryTimesdsquna = item.extendExpiryTime
            } else {
              item.dataleft = this.getDiffDay(item.expiryTime, this.getCurrentTime()) > 0 ? this.getDiffDay(item.expiryTime, this.getCurrentTime()) : 0
              item.expiryTimesdsquna = item.expiryTime
            }
            if (item.extendExpiryTime && item.extendExpiryTime !== '') {
              const sedataleftime = this.getDiffDay(item.expiryTime, this.getCurrentTime()) > 0 ? this.getDiffDay(item.expiryTime, this.getCurrentTime()) : 0
              if (sedataleftime > 0) {
                item.renewTime = '-'
              } else {
                item.renewTime = item.expiryTime
              }
            } else {
              item.renewTime = '-'
            }
            if (item.dataleft > 0) {

              item.validityta = 'Valid'
            } else {

              item.validityta = 'Expired'
            }

          })
          sessionStorage.setItem('Licenselist', JSON.stringify(reset.rows))
        }
      })
    },
    getnewurl () {
      let url = document.location.href;
      let reg = /[^\w](starToken)=?([^&|^#]*)/g;
      url = url.replace(reg, "");
      reg = /&&/g;
      url = url.replace(reg, "");
      reg = /&#/g;
      url = url.replace(reg, "#");
      reg = /\?#/g;
      url = url.replace(reg, "#");
      // url = url.replaceAll(document.domain,"");
      // url = url.replaceAll("http://","");
      // url = url.replaceAll("https://","");
      reg = /\?#/g;
      url = url.replace(reg, "#");
      return url;
    }
  }
}
</script>

<style scoped lang="scss">

::v-deep .el-checkbox {
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #303030;

  .el-checkbox__inner {
    width: 26px;
    height: 26px;
    border-radius: 50%;

    border: 1px solid rgba(149, 149, 149, 1);
    //修改选中框中的对勾的大小和位置
    &::after {
      top: 5px;
      height: 12px;
      left: 10px;
    }
  }

  .el-checkbox__label {
    display: inline-grid;
    color: #FFFFFF;
    width: 200px;

  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    border: 1px solid #9931F8;
    background-color: #9931F8;
  }

  .el-checkbox__label {
    display: inline-grid;
    color: #FFFFFF;
    width: 200px;
    background-color: #9931F8;

  }


  /* 自定义indeterminate状态下复选框的样式 */
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: rgba(149, 149, 149, 1); /* 自定义颜色 */
    border-color: rgba(149, 149, 149, 1); /* 自定义边框颜色 */
  }


  //修改点击文字颜色不变
  .el-checkbox__input.is-checked + .el-checkbox__label {
    border: 1px solid rgba(0, 145, 255, 1);
    background: rgba(0, 145, 255, 1);
    color: #303030;

  }


}



.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.body {
  min-width: 1440px;
  background-color: #F2F2F2;
}

.tile_span {
  margin-left: 8px;
  font-size: 14px;
  font-family: Inter, Inter;
  font-weight: normal;
  color: #0091FF;
  line-height: 14px;
}

.tile_span1 {
  margin-left: 8px;
  font-size: 14px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: rgba(153, 49, 248, 1);
  line-height: 14px;
  white-space: nowrap;

}

.tile_span2 {
  white-space: nowrap;
  margin-left: 8px;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: normal;
  color: #383838;
  line-height: 14px;
  font-style: normal;
  text-transform: none;
  opacity: 0.5;
}


.imag_right_uesr {
  margin: auto;
  width: 30px;
  height: 30px;
}

.imag_right_icon {
  margin: auto 8px;
  width: 12px;
  height: 12px;
  opacity: 0.3;
}

.top {
  min-width: 1440px;
  height: 54px;
  box-shadow: 0 2px 5px rgba(255, 255, 255, 1) inset;
  color: #fff;
  overflow: hidden;
  margin: 0;
  font-size: 18px;
  line-height: 45px;
  background: rgba(255, 255, 255, 1);
}

.my_boards {
  cursor: pointer;
  padding-right: 14px;
  padding-left: 14px;
  width: auto;
  height: 54px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 16px;
}

.my_boards_imgs {
  flex-direction: row;
  display: flex;
  align-items: center;
  margin: auto;
}

.line_div {
  width: 120%;
  height: 4px;
  background-color: rgba(153, 49, 248, 1);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px
}


.div_logout {
  cursor: pointer;
  width: 184px;
  height: 40px;
  border-radius: 999px;
  border: 1px solid #E44356;
  margin: auto;
  display: flex;
  justify-items: auto;
  align-items: center;
}

.div_profile {
  cursor: pointer;
  width: 184px;
  height: 40px;
  border-radius: 999px;
  margin: auto;
  border: 1px solid #9931F8;
  display: flex;
  justify-items: auto;
  align-items: center;
}


.img_icon {
  margin: auto;
  width: 22px;
  height: 22px;

}

.img_icon_felse {
  width: 22px;
  height: 22px;
  margin-left: 16px;
  opacity: 0.3;
}


.svgicon {
  margin-top: -10px;
  width: 30px;
  height: 30px;
  overflow: hidden;

  .img_svg0 {
    position: relative;
    transform: translateX(-80px);
    filter: drop-shadow(80px 0 #383838);
    border-left: 0px solid transparent; //防止drop-shadow主体超出视线隐藏后阴影消失
    border-right: 0px solid transparent;
    opacity: 0.5;
  }

  .img_svg1 {
    position: relative;
    transform: translateX(-80px);
    filter: drop-shadow(80px 0 #9931F8);
    border-left: 0px solid transparent; //防止drop-shadow主体超出视线隐藏后阴影消失
    border-right: 0px solid transparent;
  }

}


</style>