import { render, staticRenderFns } from "./InfoGo.vue?vue&type=template&id=77232d34&scoped=true"
import script from "./InfoGo.vue?vue&type=script&lang=js"
export * from "./InfoGo.vue?vue&type=script&lang=js"
import style0 from "./InfoGo.vue?vue&type=style&index=0&id=77232d34&prod&lang=css"
import style1 from "./InfoGo.vue?vue&type=style&index=1&id=77232d34&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77232d34",
  null
  
)

export default component.exports