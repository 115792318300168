// import html2canvas from 'html2canvas'
import html2canvas from './html2canvas.min'

/**
 * 获取随机字符串
 * @returns {String}
 */
export function getRandomStr() {
    return Math.random().toString(32).substr(2)
}

// style属性片段
const styleFragment = {
    button: {
        height: '36px',
        boxSizing: 'border-box',
        padding: '0 8px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        border: 'none'
    }, noWrap: {
        overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', paddingTop: '1px'
    }, _defaultStyle: {
        boxSizing: 'border-box'
    }
}

/**
 * 根据nodeObj生成dom结构
 * @param {ComponentDomNode} obj
 * @returns {HTMLDivElement}
 */
export function createDom(obj) {
    let dom = null
    try {
        let {style = {}} = obj
        const {tag = 'div', styleFragment: domStyleFragment, attrs = {}, children, text} = obj
        dom = document.createElement(tag)
        if (domStyleFragment && domStyleFragment.length > 0) {
            const _style = {}
            domStyleFragment.forEach(item => {
                Object.assign(_style, styleFragment[item] || null)
            })
            style = Object.assign({}, _style, style)
        }
        Object.keys(style).forEach(key => {
            dom.style[key] = style[key]
        })
        Object.keys(styleFragment._defaultStyle).forEach(key => {
            dom.style[key] = styleFragment._defaultStyle[key]
        })
        Object.keys(attrs).forEach(key => {
            dom.setAttribute(key, attrs[key])
        })
        if (text) {
            dom.innerText = text
        }
        if (children && children.length > 0) {
            children.forEach(item => {
                dom.appendChild(createDom(item))
            })
        }
    } catch (e) {
        console.error(e)
    }
    return dom
}

/**
 * 生成html字符串
 * @param {ComponentDomNode} nodeObj
 * @returns {ComponentHtml}
 */
export function createHtmlStr(nodeObj) {
    const result = document.createElement('div')
    result.appendChild(createDom(nodeObj))
    return result.innerHTML
}

/**
 * 把style字符串转换为style对象
 * @param {String} styleStr style字符串
 * @returns {Object} styleObj
 */
export function parseStyle(styleStr) {
    if (!styleStr) {
        return {}
    }
    const styleArr = styleStr.split(';') || []
    const styleObj = {}
    styleArr.forEach(item => {
        const [attr, val] = item.split(':')
        if (attr && val && val !== 'undefined') { // 判断"undefined"是因为某些style字符串解析出来的属性为"undefined"字符串，所以要去除这种情况
            styleObj[toCamel(attr)] = val || ''
        }
    })
    return styleObj
}

/**
 * 把style对象转换为字符串
 * @param {Object} styleObj
 * @returns {String} styleStr style字符串
 */
export function stringifyStyle(styleObj) {
    let styleStr = ''
    const styleArr = []
    Object.keys(styleObj).forEach(key => {
        styleArr.push(`${toLine(key)}:${styleObj[key] || ''}`)
    })
    styleStr = styleArr.join(';')
    return styleStr
}

/**
 * 驼峰转横线
 * @param {String} str
 */
export function toLine(str) {
    let temp = str.replace(/[A-Z]/g, function (match) {
        return '-' + match.toLowerCase()
    })
    if (temp.slice(0, 1) === '-') { // 如果首字母是大写，执行replace时会多一个_，这里需要去掉
        temp = temp.slice(1)
    }
    return temp
}

/**
 *
 * @param {String} str 横线转驼峰
 */
export function toCamel(str) {
    const [fir, ...arr] = str.split('-')
    const result = fir + arr.map(item => {
        return item.substr(0, 1).toUpperCase() + item.substr(1)
    }).join('')
    return result
}

export function domToImg(dom, options = {}) {
    return new Promise((resolve, reject) => {
        const baseOptions = {
            width: 0, height: 0
        }
        options = Object.assign({}, baseOptions, options)
        const {width, height} = options
        try {
            document.body.appendChild(dom)
            html2canvas(dom, {
                width: width, height: height, // dpi: 192,
                scale: 2
            })
                .then(canvas => {
                    const url = canvas.toDataURL()
                    console.log(url)
                    const _img = document.createElement('img')
                    _img.width = width
                    _img.height = height
                    _img.src = url
                    document.body.removeChild(dom)
                    resolve(_img)
                })
                .catch(err => {
                    console.log(err)
                    document.body.removeChild(dom)
                    reject()
                })
        } catch (e) {
            console.log(e)
            reject()
        }
    })
}

/**
 * 将数组中某元素移动到0位置
 * @param {Array} arr
 * @param {Number} index
 * @returns {Array}
 */
export function arrMoveTop(arr, index) {
    if (index !== 0) {
        const _arr = [...arr]
        const moveItem = _arr.splice(index, 1)
        _arr.unshift(...moveItem)
        return _arr
    } else {
        return arr
    }
}

/**
 * @param {Array} arr
 * @param {Number} index
 * @returns {Array}
 */
export function arrMoveUpper(arr, index) {
    if (index !== 0) {
        const _arr = [...arr]
        const curIdx = index
        const preIdx = index - 1;
        [_arr[curIdx], _arr[preIdx]] = [_arr[preIdx], _arr[curIdx]]
        return _arr
    } else {
        return arr
    }
}

/**
 * 将数组中某元素向下移动一个位置
 * @param {Array} arr
 * @param {Number} index
 * @returns {Array}
 */
export function arrMoveLower(arr, index) {
    if (index !== arr.length - 1) {
        const _arr = [...arr]
        const curIdx = index
        const nextIdx = index + 1;
        [_arr[curIdx], _arr[nextIdx]] = [_arr[nextIdx], _arr[curIdx]]
        return _arr
    } else {
        return arr
    }
}

/**
 * 将数组中某元素移动到末尾
 * @param {Array} arr
 * @param {Number} index
 * @returns {Array}
 */
export function arrMoveBottom(arr, index) {
    const len = arr.length - 1
    if (index !== len) {
        const _arr = [...arr]
        const moveItem = _arr.splice(index, 1)
        _arr.push(...moveItem)
        return _arr
    } else {
        return arr
    }
}

export const HoC = (WrappedComponent, options) => ({
    inheritAttrs: false, ...options,
    props: typeof WrappedComponent === 'function' ? WrappedComponent.options.props : WrappedComponent.props,
    created() {
        this._isHoc = true
    },
    render(h) {
        const slots = this.$slots
        const scopedSlots = {}
        Object.keys(slots).map(key => (scopedSlots[key] = () => slots[key]))
        const props = options.props
        return h(WrappedComponent, {
            attrs: this.$attrs, props: Object.assign({}, this.$props, props), on: this.$listeners, scopedSlots
        })
    }
})

export function base64ToBlob(base64Str) {
    var arr = base64Str.split(',')
    var mime = arr[0].match(/:(.*?);/)[1]
    var bstr = atob(arr[1])
    var n = bstr.length
    var u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], {type: mime})
}

export function setDatel(key, data) {
    switch (key) {
        case 'sig-img':
            let filename0 = data.cptOption.attribute.url.split('/')
            const url = data.cptOption.attribute.type === 0 ? data.cptOption.attribute.url : './' + filename0[filename0.length - 1]
            const tile = '<div style="position: absolute; width:' + data.cptWidth + 'px;height: ' + data.cptHeight + 'px;top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';">'
            const divimg = '<div  width="' + data.cptWidth + '" height="' + data.cptWidth + '" style="width: 100%; height: 100%;">'
            const img = '<img style="width: 100%; height: 100%; position: relative; display: inline-block; overflow: hidden;" src="' + url + '" fit="fill"/>'
            return tile + divimg + img + '</div>' + '</div>'
            break
        case 'sig-video':
            let filename = data.cptOption.attribute.url.split('/')
            const urlvdeo = data.cptOption.attribute.type === 0 ? data.cptOption.attribute.url : './' + filename[filename.length - 1]
            const tlie1 = '<div style="position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';">'
            const tile2 = '<div  width="' + data.cptWidth + '" height="' + data.cptHeight + '">'
            const tile3 = '<video  id="example_video_1"    width="' + data.cptWidth + '" height="' + data.cptHeight + '" preload autoplay controls loop style="object-fit:fill;">'
            const tile4 = '<source src="' + urlvdeo + '" type="video/mp4">'
            const tile5 = '</video></div></div>'
            return tlie1 + tile2 + tile3 + tile4 + tile5
            break
        case 'sip-text':
            const texttile1 = '<div  style="position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';">'
            const texttile2 = '<div style="width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; text-align: ' + data.cptOption.attribute.textAlign + ';font-family: ' + data.cptOption.attribute.textFamily + '; color: ' + data.cptOption.attribute.textColor + '; font-size: ' + data.cptOption.attribute.textSize + 'px; font-style: ' + data.cptOption.attribute.fontStyle + '; font-weight: ' + data.cptOption.attribute.fontWeight + '; background-color: ' + data.cptOption.attribute.bgColor + ';  text-decoration: ' + data.cptOption.attribute.textDecoration + ';">'
            const texttile3 = '</div></div>'
            return texttile1 + texttile2 + JSON.parse(data.cptOption.cptDataForm.dataText).value + texttile3
            break
        case 'sigsubtitles':

            const csstext = '<style >\n ' +
                '       .scrolling-text' + data.id + ' { overflow: hidden;  white-space: nowrap; width: 100%;}\n' +
                '       .scrolling-text' + data.id + ' span {display: inline-block;padding-left: 100%; animation:  ' + data.cptOption.attribute.direction + '  20s linear infinite; /* 调整动画时长和效果 */}\n' +
                '        @keyframes marquee { 0% { transform: translateX(0); } 100% { transform: translateX(-100%); }}\n' +
                '        @keyframes marquees {0% { transform: translateX(-100%); }100% {  transform: translateX(0); }}\n' +
                '    </style>'
            const texttile6 = '<div  class="scrolling-text' + data.id + '"  style="position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';background-color: ' + data.cptOption.attribute.bgColor + ';">'

            const texttile7 = '<span width="' + data.cptWidth + '" height="' + data.cptHeight + '" style="font-family: ' + data.cptOption.attribute.textFamily + ';text-align: ' + data.cptOption.attribute.textAlign + '; color: ' + data.cptOption.attribute.textColor + '; font-size: ' + data.cptOption.attribute.textSize + 'px; font-style: ' + data.cptOption.attribute.fontStyle + '; font-weight: ' + data.cptOption.attribute.fontWeight + ';   text-decoration: ' + data.cptOption.attribute.textDecoration + ';">'

            const texttile9 = '</span></div>'
            return csstext + texttile6 + texttile7 + JSON.parse(data.cptOption.cptDataForm.dataText).value + texttile9
            break
        case 'sigPlaylist':
            const tile11 = '<div id="app"  style="position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';">'
            const tile12 = '<div :key="GeneralViod">\n' +
                '    <div v-if="isVideo" style="width: 100%; height: 100%">\n' +
                '      <img :width="' + data.cptWidth + '"\n' +
                '           :height="' + data.cptHeight + '"\n' +
                '           style="position: relative; display: inline-block;overflow: hidden;"\n' +
                '           alt="img"\n' +
                '           :src="url"\n' +
                '           />' +
                '    </div>\n' +
                '    <video v-else id="example_video_1"  preload autoplay controls\n' +
                '           @ended="onVideoEnded"\n' +
                '           :width="' + data.cptWidth + '"\n' +
                '           :height="' + data.cptHeight + '" autoplay="autoplay"  \n' +
                '            style="object-fit:fill;" \n' +
                '           :src="url">\n' +
                '    </video>\n' +
                '  </div>' +
                '  </div>'
            let listfile = []
            data.cptOption.attribute.urlist.forEach(itme => {
                const imepath = itme.filePath.split('/')
                listfile.push(
                    {
                        Interval: data.cptOption.attribute.Interval,
                        fileType: itme.fileType,
                        filePath: './' + imepath[imepath.length - 1]
                    }
                )
            })

            const jsc = '<script>\n' +
                '    new Vue({\n' +
                '        el: \'#app\',\n' +
                '        data: function(){\n' +
                '            return{\n' +
                '                url: \'\',\n' +
                '                GeneralViod: Date.now(),\n' +
                '                uuid: null,\n' +
                '                isVideo: true,\n' +
                '                curr: 0,\n' +
                '                option: ' + JSON.stringify(listfile).toString() +
                '            }\n' +
                '        },\n' +
                '        mounted() {\n' +
                '            this.setOneOf()\n' +
                '        },\n' +
                '        methods: {\n' +
                '            onVideoEnded() {\n' +
                '                // 在这里执行你想要的操作\n' +
                '                if (this.curr < JSON.parse(JSON.stringify(this.option)).length ) {\n' +
                '                    this.isVideo = JSON.parse(JSON.stringify(this.option))[this.curr].fileType === \'1\'\n' +
                '                    if (this.isVideo) {\n' +
                '                       this.url  = JSON.parse(JSON.stringify(this.option))[this.curr].filePath \n' +
                '                       ++this.curr \n' +
                '                        this.setViewImag()\n' +
                '                    } else {\n' +
                '                        this.url  =JSON.parse(JSON.stringify(this.option))[this.curr].filePath; \n' +
                '                        ++this.curr \n' +
                '                        this.GeneralViod = Date.now()\n' +
                '                    }\n' +
                '                } else {\n' +
                '                    this.curr = 0\n' +
                '                   this.url = JSON.parse(JSON.stringify(this.option))[this.curr].filePath\n' +
                '                    this.isVideo = JSON.parse(JSON.stringify(this.option))[this.curr].fileType === \'1\'\n' +
                '                    this.setViewImag()\n' +
                '                }\n' +
                '            },\n' +
                '            setOneOf() {\n' +
                '                if (this.curr < JSON.parse(JSON.stringify(this.option)).length ) {\n' +
                '                    this.isVideo = JSON.parse(JSON.stringify(this.option))[this.curr].fileType === \'1\'\n' +
                '                    if (this.isVideo) {\n' +
                '                       this.url = JSON.parse(JSON.stringify(this.option))[this.curr].filePath; \n  ' +
                '                        ++this.curr \n' +
                '                        this.GeneralViod = Date.now()\n' +
                '                        this.setViewImag()\n' +
                '                    } else {\n' +
                '                     this.url= JSON.parse(JSON.stringify(this.option))[this.curr].filePath; \n' +
                '                     ++this.curr \n' +
                '                        this.GeneralViod = Date.now()\n' +
                '                    }\n' +
                '                } else {\n' +
                '                    this.curr = 0\n' +
                '                    this.url =JSON.parse(JSON.stringify(this.option))[this.curr].filePath; \n' +
                '                    this.isVideo = JSON.parse(JSON.stringify(this.option))[this.curr].fileType === \'1\'\n' +
                '                    this.setViewImag()\n' +
                '                }\n' +
                '            },\n' +
                '            setViewImag() {\n' +
                '                setTimeout(this.setOneOf, JSON.parse(JSON.stringify(this.option))[0].Interval * 1000)\n' +
                '            }\n' +
                '        }\n' +
                '    })\n' +
                '</script>'


            return tile11 + tile12 + jsc
            break
    }
}

export function setDateTem(key, data) {
    switch (key) {
        case 'tem-img':
            let filename0 = data.cptOption.attribute.url.split('/')
            const url = data.cptOption.attribute.type === 0 ? data.cptOption.attribute.url : './' + filename0[filename0.length - 1]
            const tile = '<div style="position: absolute; width:' + data.cptWidth + 'px;height: ' + data.cptHeight + 'px;top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';">'
            const divimg = '<div  width="' + data.cptWidth + '" height="' + data.cptHeight + '" style="width: 100%; height: 100%;">'
            const img = '<img style="width: 100%; height: 100%; position: relative; display: inline-block; overflow: hidden;" src="' + url + '" fit="fill"/>'
            return tile + divimg + img + '</div>' + '</div>'
            break
        case 'tem-vido':
            let filename = data.cptOption.attribute.url.split('/')
            const urlvdeo = data.cptOption.attribute.type === 0 ? data.cptOption.attribute.url : './' + filename[filename.length - 1]
            const tlie1 = '<div style="position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';">'
            const tile2 = '<div  width="' + data.cptWidth + '" height="' + data.cptHeight + '">'
            const tile3 = '<video  id="example_video_1"    width="' + data.cptWidth + '" height="' + data.cptHeight + '" preload autoplay controls loop style="object-fit:fill;">'
            const tile4 = '<source src="' + urlvdeo + '" type="video/mp4">'
            const tile5 = '</video></div></div>'
            return tlie1 + tile2 + tile3 + tile4 + tile5
            break
        case 'tem-text':
            const texttile1 = '<div  style="position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';">'
            const texttile2 = '<div style="width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px;display: grid;white-space: nowrap;align-items: center;font-family: ' + data.cptOption.attribute.textFamily + ';justify-items: ' + data.cptOption.attribute.textAlign + '; color: ' + data.cptOption.attribute.textColor + '; font-size: ' + data.cptOption.attribute.textSize + 'px; font-style: ' + data.cptOption.attribute.fontStyle + '; font-weight: ' + data.cptOption.attribute.fontWeight + '; background-color: ' + data.cptOption.attribute.bgColor + ';  text-decoration: ' + data.cptOption.attribute.textDecoration + ';">'
            const texttile3 = '</div></div>'
            return texttile1 + texttile2 + JSON.parse(data.cptOption.cptDataForm.dataText).value + texttile3
            break
        case 'temsubtitles':

            const csstext = '<style >\n ' +
                '       .scrolling-text' + data.id + ' { overflow: hidden;  white-space: nowrap; width: 100%;}\n' +
                '       .scrolling-text' + data.id + ' span {display: inline-block;padding-left: 100%; animation:  ' + data.cptOption.attribute.direction + '  20s linear infinite; /* 调整动画时长和效果 */}\n' +
                '        @keyframes marquee { 0% { transform: translateX(0); } 100% { transform: translateX(-100%); }}\n' +
                '        @keyframes marquees {0% { transform: translateX(-100%); }100% {  transform: translateX(0); }}\n' +
                '    </style>'
            const texttile6 = '<div  class="scrolling-text' + data.id + '"  style="position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';background-color: ' + data.cptOption.attribute.bgColor + ';">'

            const texttile7 = '<span style=" width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px;align-items: center;display: grid;font-family: ' + data.cptOption.attribute.textFamily + ';text-align: ' + data.cptOption.attribute.textAlign + '; color: ' + data.cptOption.attribute.textColor + '; font-size: ' + data.cptOption.attribute.textSize + 'px; font-style: ' + data.cptOption.attribute.fontStyle + '; font-weight: ' + data.cptOption.attribute.fontWeight + ';   text-decoration: ' + data.cptOption.attribute.textDecoration + ';">'

            const texttile9 = '</span></div>'
            return csstext + texttile6 + texttile7 + JSON.parse(data.cptOption.cptDataForm.dataText).value + texttile9
            break
        case 'templaylist':
            const tile11 = '<div id="app"  style="position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';">'
            const tile12 = '<div :key="GeneralViod">\n' +
                '    <div v-if="isVideo" style="width: 100%; height: 100%">\n' +
                '      <img :width="' + data.cptWidth + '"\n' +
                '           :height="' + data.cptHeight + '"\n' +
                '           style="position: relative; display: inline-block;overflow: hidden;"\n' +
                '           alt="img"\n' +
                '           :src="url"\n' +
                '           />' +
                '    </div>\n' +
                '    <video v-else id="example_video_1"  preload autoplay controls\n' +
                '           @ended="onVideoEnded"\n' +
                '           :width="' + data.cptWidth + '"\n' +
                '           :height="' + data.cptHeight + '" autoplay="autoplay"  \n' +
                '            style="object-fit:fill;" \n' +
                '           :src="url">\n' +
                '    </video>\n' +
                '  </div>' +
                '  </div>'
            let listfile = []
            data.cptOption.attribute.urlist.forEach(itme => {
                const imepath = itme.filePath.split('/')
                listfile.push(
                    {
                        Interval: data.cptOption.attribute.Interval,
                        fileType: itme.fileType,
                        filePath: './' + imepath[imepath.length - 1]
                    }
                )
            })

            const jsc = '<script>\n' +
                '    new Vue({\n' +
                '        el: \'#app\',\n' +
                '        data: function(){\n' +
                '            return{\n' +
                '                url: \'\',\n' +
                '                GeneralViod: Date.now(),\n' +
                '                uuid: null,\n' +
                '                isVideo: true,\n' +
                '                curr: 0,\n' +
                '                option: ' + JSON.stringify(listfile).toString() +
                '            }\n' +
                '        },\n' +
                '        mounted() {\n' +
                '            this.setOneOf()\n' +
                '        },\n' +
                '        methods: {\n' +
                '            onVideoEnded() {\n' +
                '                // 在这里执行你想要的操作\n' +
                '                if (this.curr < JSON.parse(JSON.stringify(this.option)).length ) {\n' +
                '                    this.isVideo = JSON.parse(JSON.stringify(this.option))[this.curr].fileType === \'1\'\n' +
                '                    if (this.isVideo) {\n' +
                '                       this.url  = JSON.parse(JSON.stringify(this.option))[this.curr].filePath \n' +
                '                       ++this.curr \n' +
                '                        this.setViewImag()\n' +
                '                    } else {\n' +
                '                        this.url  =JSON.parse(JSON.stringify(this.option))[this.curr].filePath; \n' +
                '                        ++this.curr \n' +
                '                        this.GeneralViod = Date.now()\n' +
                '                    }\n' +
                '                } else {\n' +
                '                    this.curr = 0\n' +
                '                   this.url = JSON.parse(JSON.stringify(this.option))[this.curr].filePath\n' +
                '                    this.isVideo = JSON.parse(JSON.stringify(this.option))[this.curr].fileType === \'1\'\n' +
                '                    this.setViewImag()\n' +
                '                }\n' +
                '            },\n' +
                '            setOneOf() {\n' +
                '                if (this.curr < JSON.parse(JSON.stringify(this.option)).length ) {\n' +
                '                    this.isVideo = JSON.parse(JSON.stringify(this.option))[this.curr].fileType === \'1\'\n' +
                '                    if (this.isVideo) {\n' +
                '                       this.url = JSON.parse(JSON.stringify(this.option))[this.curr].filePath; \n  ' +
                '                        ++this.curr \n' +
                '                        this.GeneralViod = Date.now()\n' +
                '                        this.setViewImag()\n' +
                '                    } else {\n' +
                '                     this.url= JSON.parse(JSON.stringify(this.option))[this.curr].filePath; \n' +
                '                     ++this.curr \n' +
                '                        this.GeneralViod = Date.now()\n' +
                '                    }\n' +
                '                } else {\n' +
                '                    this.curr = 0\n' +
                '                    this.url =JSON.parse(JSON.stringify(this.option))[this.curr].filePath; \n' +
                '                    this.isVideo = JSON.parse(JSON.stringify(this.option))[this.curr].fileType === \'1\'\n' +
                '                    this.setViewImag()\n' +
                '                }\n' +
                '            },\n' +
                '            setViewImag() {\n' +
                '                setTimeout(this.setOneOf, JSON.parse(JSON.stringify(this.option))[0].Interval * 1000)\n' +
                '            }\n' +
                '        }\n' +
                '    })\n' +
                '</script>'


            return tile11 + tile12 + jsc
            break
    }
}

export function getHtml(that, data) {
    const bgtile1 = '<div id="app" style="width:' + that.scaleX + 'px;height:' + that.scaleY + 'px;background-color: rgb(255, 255, 255);max-width:' + that.scaleX + 'px;max-height: ' + that.scaleY + 'px;">'
    const tile2 = '<div style="position: relative;width: ' + that.scaleX + 'px;height: ' + that.scaleY + 'px;">'
    const tile3 = '</div></div>'
    const jsv0 = '<script src="'
    const jsv1 = './vue.min.js">'
    const jsv2 = "</scr"
    const jsv3 = "ipt>"
    const jsv4 = '<script src="'
    const jsv5 = './layui.js">'
    const jsv6 = '</sc'
    const jsv7 = 'ript>'
    const div1 = "  <div id=\"clickView\" style=\"position:absolute;z-index: 999;pointer-events: none;\"\n" +
        "         :style=\"{width:windowWidth+'px',height:conHeight+'px'}\">\n" +
        "\n" +
        "        <div v-for=\"(item,index) in cacheComponents\" :key=\"item.id\"\n" +
        "             class=\"cptDiv\" :style=\"{width:Math.round(item.cptWidth)+'px', height:Math.round(item.cptHeight)+'px',\n" +
        "                  top:Math.round(item.cptY)+'px',left:Math.round(item.cptX)+'px' }\" :ref=\"'div'+item.cptKey+index\"\n" +
        "             tabindex=\"0\">\n" +
        "            <div style=\"width: 100%;height: 100%; pointer-events: auto;\" v-on:click=\"onclickVue(item,0)\">\n" +
        "\n" +
        "            </div>\n" +
        "\n" +
        "\n" +
        "        </div>\n" +
        "    </div>"
    const div2 = "  <div id=\"viewHt\" ></div>"
    return `<!DOCTYPE html>
                <html>
                <head>
                <meta charset="utf-8">
                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                <meta name="viewport" content="width=device-width,initial-scale=1.0,user-scalable=no">
                 ${jsv0 + jsv1 + jsv2 + jsv3}
                 ${jsv4 + jsv5 + jsv6 + jsv7}
                <meta name="referrer" content="never"><style> *{ margin: 0;  padding: 0;}
                 body{   overflow: hidden;}
                 .cptDiv { position: absolute;  outline: none }
                    @keyframes fadeIn {
        to {
            opacity: 1; /* 结束状态，透明度为1 */
            transform: translateX(0); /* 结束状态，位置复原 */
        }
    }
                    @keyframes scaleOut {
        0% {
            opacity: 0;
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1500px, 0);
            animation-timing-function: linear;
        }
        50% {
            opacity: .5;
            transform: scale3d(0.75, 0.75, 0.75) translate3d(0, -60px, 0);
            animation-timing-function: linear;
        }
        100% {
            opacity: 1;
            transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
            animation-timing-function: linear;
        }
    }
                    @keyframes translateOut {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-100px);
        }

    }
                    @keyframes rotateOut {
        0% {
            transform: rotate(-180deg);
        }

        100% {
            transform: rotateY(-360deg);
        }
    }
                    .setscale {
        animation: 2s linear 0s normal scaleOut;
    }
                    .setrotate {
        animation: 2s linear 0s normal rotateOut;
    }
                    .settranslate   {
        opacity: 0.5;
        transform: translateX(-1280px); /* 添加过渡效果 */
        animation: fadeIn 1s ease forwards;
    }
                    .setnone{

    }
                </style>
                 <link rel="stylesheet" href="./stylesheet.css">
                  <link rel="stylesheet" href="./layui.css">
                <title></title>
                </head>
                <body>
                ${bgtile1 + tile2 + div1 + div2 + '<script>' + data + jsv6 + jsv7 + tile3}
              </body>
         </html>`


}

export function getOnlickHtml(data) {
    const tile = '<div style="position: absolute; width:' + data.cptWidth + 'px;height: ' + data.cptHeight + 'px;top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';">'
    const divimg = '<div  width="' + data.cptWidth + '" height="' + data.cptWidth + '" style="width: 100%; height: 100%;">'
    return tile + divimg + '</div>' + '</div>'
}

export function getsetDatel() {
    return "function setDatel(key, data) {\n" +
        "    switch (key) {\n" +
        "        case 'sig-img':\n" +
        "            let filename0 = data.cptOption.attribute.url.split('/')\n" +
        "            const url = data.cptOption.attribute.type === 0 ? data.cptOption.attribute.url : './' + filename0[filename0.length - 1]\n" +
        "            const tile = '<div style=\"position: absolute; width:' + data.cptWidth + 'px;height: ' + data.cptHeight + 'px;top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';\">'\n" +
        "            const divimg = '<div  width=\"' + data.cptWidth + '\" height=\"' + data.cptWidth + '\" style=\"width: 100%; height: 100%;\">'\n" +
        "            const img = '<img style=\"width: 100%; height: 100%; position: relative; display: inline-block; overflow: hidden;\" src=\"' + url + '\" fit=\"fill\"/>'\n" +
        "            return tile + divimg + img + '</div>' + '</div>'\n" +
        "            break\n" +
        "        case 'sig-video':\n" +
        "            let filename = data.cptOption.attribute.url.split('/')\n" +
        "            const urlvdeo = data.cptOption.attribute.type === 0 ? data.cptOption.attribute.url : './' + filename[filename.length - 1]\n" +
        "            const tlie1 = '<div style=\"position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';\">'\n" +
        "            const tile2 = '<div  width=\"' + data.cptWidth + '\" height=\"' + data.cptHeight + '\">'\n" +
        "            const tile3 = '<video  id=\"example_video_1\"    width=\"' + data.cptWidth + '\" height=\"' + data.cptHeight + '\" preload autoplay controls loop style=\"object-fit:fill;\">'\n" +
        "            const tile4 = '<source src=\"' + urlvdeo + '\" type=\"video/mp4\">'\n" +
        "            const tile5 = '</video></div></div>'\n" +
        "            return tlie1 + tile2 + tile3 + tile4 + tile5\n" +
        "            break\n" +
        "        case 'sip-text':\n" +
        "            const texttile1 = '<div  style=\"position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';\">'\n" +
        "            const texttile2 = '<div width=\"' + data.cptWidth + '\" height=\"' + data.cptHeight + '\" style=\"text-align: ' + data.cptOption.attribute.textAlign + ';font-family: ' + data.cptOption.attribute.textFamily + '; color: ' + data.cptOption.attribute.textColor + '; font-size: ' + data.cptOption.attribute.textSize + 'px; font-style: ' + data.cptOption.attribute.fontStyle + '; font-weight: ' + data.cptOption.attribute.fontWeight + '; background-color: ' + data.cptOption.attribute.bgColor + ';  text-decoration: ' + data.cptOption.attribute.textDecoration + ';\">'\n" +
        "            const texttile3 = '</div></div>'\n" +
        "            return texttile1 + texttile2 + JSON.parse(data.cptOption.cptDataForm.dataText).value + texttile3\n" +
        "            break\n" +
        "        case 'sigsubtitles':\n" +
        "\n" +
        "            const csstext = '<style >\\n ' +\n" +
        "                '       .scrolling-text' + data.id + ' { overflow: hidden;  white-space: nowrap; width: 100%;}\\n' +\n" +
        "                '       .scrolling-text' + data.id + ' span {display: inline-block;padding-left: 100%; animation:  ' + data.cptOption.attribute.direction + '  20s linear infinite; /* 调整动画时长和效果 */}\\n' +\n" +
        "                '        @keyframes marquee { 0% { transform: translateX(0); } 100% { transform: translateX(-100%); }}\\n' +\n" +
        "                '        @keyframes marquees {0% { transform: translateX(-100%); }100% {  transform: translateX(0); }}\\n' +\n" +
        "                '    </style>'\n" +
        "            const texttile6 = '<div  class=\"scrolling-text' + data.id + '\"  style=\"position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';background-color: ' + data.cptOption.attribute.bgColor + ';\">'\n" +
        "\n" +
        "            const texttile7 = '<span width=\"' + data.cptWidth + '\" height=\"' + data.cptHeight + '\" style=\"font-family: ' + data.cptOption.attribute.textFamily + ';text-align: ' + data.cptOption.attribute.textAlign + '; color: ' + data.cptOption.attribute.textColor + '; font-size: ' + data.cptOption.attribute.textSize + 'px; font-style: ' + data.cptOption.attribute.fontStyle + '; font-weight: ' + data.cptOption.attribute.fontWeight + ';   text-decoration: ' + data.cptOption.attribute.textDecoration + ';\">'\n" +
        "\n" +
        "            const texttile9 = '</span></div>'\n" +
        "            return csstext + texttile6 + texttile7 + JSON.parse(data.cptOption.cptDataForm.dataText).value + texttile9\n" +
        "            break\n" +
        "        case 'sigPlaylist':\n" +
        "            const tile11 = '<div id=\"app\"  style=\"position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';\">'\n" +
        "            const tile12 = '<div :key=\"GeneralViod\">\\n' +\n" +
        "                '    <div v-if=\"isVideo\" style=\"width: 100%; height: 100%\">\\n' +\n" +
        "                '      <img :width=\"' + data.cptWidth + '\"\\n' +\n" +
        "                '           :height=\"' + data.cptHeight + '\"\\n' +\n" +
        "                '           style=\"position: relative; display: inline-block;overflow: hidden;\"\\n' +\n" +
        "                '           alt=\"img\"\\n' +\n" +
        "                '           :src=\"url\"\\n' +\n" +
        "                '           />' +\n" +
        "                '    </div>\\n' +\n" +
        "                '    <video v-else id=\"example_video_1\"  preload autoplay controls\\n' +\n" +
        "                '           @ended=\"onVideoEnded\"\\n' +\n" +
        "                '           :width=\"' + data.cptWidth + '\"\\n' +\n" +
        "                '           :height=\"' + data.cptHeight + '\" autoplay=\"autoplay\"  \\n' +\n" +
        "                '            style=\"object-fit:fill;\" \\n' +\n" +
        "                '           :src=\"url\">\\n' +\n" +
        "                '    </video>\\n' +\n" +
        "                '  </div>' +\n" +
        "                '  </div>'\n" +
        "            let listfile = []\n" +
        "            data.cptOption.attribute.urlist.forEach(itme => {\n" +
        "                const imepath = itme.filePath.split('/')\n" +
        "                listfile.push(\n" +
        "                    {\n" +
        "                        Interval: data.cptOption.attribute.Interval,\n" +
        "                        fileType: itme.fileType,\n" +
        "                        filePath: './' + imepath[imepath.length - 1]\n" +
        "                    }\n" +
        "                )\n" +
        "            })\n" +
        "\n" +
        "            const jsc = '<script>' +\n" +
        "                '    new Vue({\\n' +\n" +
        "                '        el: \\'#app\\',\\n' +\n" +
        "                '        data: function(){\\n' +\n" +
        "                '            return{\\n' +\n" +
        "                '                url: \\'\\',\\n' +\n" +
        "                '                GeneralViod: Date.now(),\\n' +\n" +
        "                '                uuid: null,\\n' +\n" +
        "                '                isVideo: true,\\n' +\n" +
        "                '                curr: 0,\\n' +\n" +
        "                '                option: ' + JSON.stringify(listfile).toString() +\n" +
        "                '            }\\n' +\n" +
        "                '        },\\n' +\n" +
        "                '        mounted() {\\n' +\n" +
        "                '            this.setOneOf()\\n' +\n" +
        "                '        },\\n' +\n" +
        "                '        methods: {\\n' +\n" +
        "                '            onVideoEnded() {\\n' +\n" +
        "                '                // 在这里执行你想要的操作\\n' +\n" +
        "                '                if (this.curr < JSON.parse(JSON.stringify(this.option)).length ) {\\n' +\n" +
        "                '                    this.isVideo = JSON.parse(JSON.stringify(this.option))[this.curr].fileType === \\'1\\'\\n' +\n" +
        "                '                    if (this.isVideo) {\\n' +\n" +
        "                '                       this.url  = JSON.parse(JSON.stringify(this.option))[this.curr].filePath \\n' +\n" +
        "                '                       ++this.curr \\n' +\n" +
        "                '                        this.setViewImag()\\n' +\n" +
        "                '                    } else {\\n' +\n" +
        "                '                        this.url  =JSON.parse(JSON.stringify(this.option))[this.curr].filePath; \\n' +\n" +
        "                '                        ++this.curr \\n' +\n" +
        "                '                        this.GeneralViod = Date.now()\\n' +\n" +
        "                '                    }\\n' +\n" +
        "                '                } else {\\n' +\n" +
        "                '                    this.curr = 0\\n' +\n" +
        "                '                   this.url = JSON.parse(JSON.stringify(this.option))[this.curr].filePath\\n' +\n" +
        "                '                    this.isVideo = JSON.parse(JSON.stringify(this.option))[this.curr].fileType === \\'1\\'\\n' +\n" +
        "                '                    this.setViewImag()\\n' +\n" +
        "                '                }\\n' +\n" +
        "                '            },\\n' +\n" +
        "                '            setOneOf() {\\n' +\n" +
        "                '                if (this.curr < JSON.parse(JSON.stringify(this.option)).length ) {\\n' +\n" +
        "                '                    this.isVideo = JSON.parse(JSON.stringify(this.option))[this.curr].fileType === \\'1\\'\\n' +\n" +
        "                '                    if (this.isVideo) {\\n' +\n" +
        "                '                       this.url = JSON.parse(JSON.stringify(this.option))[this.curr].filePath; \\n  ' +\n" +
        "                '                        ++this.curr \\n' +\n" +
        "                '                        this.GeneralViod = Date.now()\\n' +\n" +
        "                '                        this.setViewImag()\\n' +\n" +
        "                '                    } else {\\n' +\n" +
        "                '                     this.url= JSON.parse(JSON.stringify(this.option))[this.curr].filePath; \\n' +\n" +
        "                '                     ++this.curr \\n' +\n" +
        "                '                        this.GeneralViod = Date.now()\\n' +\n" +
        "                '                    }\\n' +\n" +
        "                '                } else {\\n' +\n" +
        "                '                    this.curr = 0\\n' +\n" +
        "                '                    this.url =JSON.parse(JSON.stringify(this.option))[this.curr].filePath; \\n' +\n" +
        "                '                    this.isVideo = JSON.parse(JSON.stringify(this.option))[this.curr].fileType === \\'1\\'\\n' +\n" +
        "                '                    this.setViewImag()\\n' +\n" +
        "                '                }\\n' +\n" +
        "                '            },\\n' +\n" +
        "                '            setViewImag() {\\n' +\n" +
        "                '                setTimeout(this.setOneOf, JSON.parse(JSON.stringify(this.option))[0].Interval * 1000)\\n' +\n" +
        "                '            }\\n' +\n" +
        "                '        }\\n' +\n" +
        "                '    })\\n' +\n" +
        "                '</script>'\n" +
        "\n" +
        "\n" +
        "            return tile11 + tile12 + jsc\n" +
        "            break\n" +
        "    }\n" +
        "}"
}

export function getsetDateTem() {
    return " setDateTem(key, data) {\n" +
        "    switch (key) {\n" +
        "        case 'tem-img':\n" +
        "            let filename0 = data.cptOption.attribute.url.split('/')\n" +
        "            const url = data.cptOption.attribute.type === 0 ? data.cptOption.attribute.url : './' + filename0[filename0.length - 1]\n" +
        "            const tile = '<div style=\"position: absolute; width:' + data.cptWidth + 'px;height: ' + data.cptHeight + 'px;top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';\">'\n" +
        "            const divimg = '<div  width=\"' + data.cptWidth + '\" height=\"' + data.cptWidth + '\" style=\"width: 100%; height: 100%;\">'\n" +
        "            const img = '<img style=\"width: 100%; height: 100%; position: relative; display: inline-block; overflow: hidden;\" src=\"' + url + '\" fit=\"fill\"/>'\n" +
        "            return tile + divimg + img + '</div>' + '</div>'\n" +
        "            break\n" +
        "        case 'tem-vido':\n" +
        "            let filename = data.cptOption.attribute.url.split('/')\n" +
        "            const urlvdeo = data.cptOption.attribute.type === 0 ? data.cptOption.attribute.url : './' + filename[filename.length - 1]\n" +
        "            const tlie1 = '<div style=\"position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';\">'\n" +
        "            const tile2 = '<div  width=\"' + data.cptWidth + '\" height=\"' + data.cptHeight + '\">'\n" +
        "            const tile3 = '<video  id=\"example_video_1\"    width=\"' + data.cptWidth + '\" height=\"' + data.cptHeight + '\" preload autoplay controls loop style=\"object-fit:fill;\">'\n" +
        "            const tile4 = '<source src=\"' + urlvdeo + '\" type=\"video/mp4\">'\n" +
        "            const tile5 = '</video></div></div>'\n" +
        "            return tlie1 + tile2 + tile3 + tile4 + tile5\n" +
        "            break\n" +
        "        case 'tem-text':\n" +
        "            const texttile1 = '<div  style=\"position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';\">'\n" +
        "            const texttile2 = '<div width=\"' + data.cptWidth + '\" height=\"' + data.cptHeight + '\" style=\"font-family: ' + data.cptOption.attribute.textFamily + ';text-align: ' + data.cptOption.attribute.textAlign + '; color: ' + data.cptOption.attribute.textColor + '; font-size: ' + data.cptOption.attribute.textSize + 'px; font-style: ' + data.cptOption.attribute.fontStyle + '; font-weight: ' + data.cptOption.attribute.fontWeight + '; background-color: ' + data.cptOption.attribute.bgColor + ';  text-decoration: ' + data.cptOption.attribute.textDecoration + ';\">'\n" +
        "            const texttile3 = '</div></div>'\n" +
        "            return texttile1 + texttile2 + JSON.parse(data.cptOption.cptDataForm.dataText).value + texttile3\n" +
        "            break\n" +
        "        case 'temsubtitles':\n" +
        "\n" +
        "            const csstext = '<style >\\n ' +\n" +
        "                '       .scrolling-text' + data.id + ' { overflow: hidden;  white-space: nowrap; width: 100%;}\\n' +\n" +
        "                '       .scrolling-text' + data.id + ' span {display: inline-block;padding-left: 100%; animation:  ' + data.cptOption.attribute.direction + '  20s linear infinite; /* 调整动画时长和效果 */}\\n' +\n" +
        "                '        @keyframes marquee { 0% { transform: translateX(0); } 100% { transform: translateX(-100%); }}\\n' +\n" +
        "                '        @keyframes marquees {0% { transform: translateX(-100%); }100% {  transform: translateX(0); }}\\n' +\n" +
        "                '    </style>'\n" +
        "            const texttile6 = '<div  class=\"scrolling-text' + data.id + '\"  style=\"position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';background-color: ' + data.cptOption.attribute.bgColor + ';\">'\n" +
        "\n" +
        "            const texttile7 = '<span width=\"' + data.cptWidth + '\" height=\"' + data.cptHeight + '\" style=\"font-family: ' + data.cptOption.attribute.textFamily + ';text-align: ' + data.cptOption.attribute.textAlign + '; color: ' + data.cptOption.attribute.textColor + '; font-size: ' + data.cptOption.attribute.textSize + 'px; font-style: ' + data.cptOption.attribute.fontStyle + '; font-weight: ' + data.cptOption.attribute.fontWeight + ';   text-decoration: ' + data.cptOption.attribute.textDecoration + ';\">'\n" +
        "\n" +
        "            const texttile9 = '</span></div>'\n" +
        "            return csstext + texttile6 + texttile7 + JSON.parse(data.cptOption.cptDataForm.dataText).value + texttile9\n" +
        "            break\n" +
        "        case 'templaylist':\n" +
        "            const tile11 = '<div id=\"app\"  style=\"position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';\">'\n" +
        "            const tile12 = '<div :key=\"GeneralViod\">\\n' +\n" +
        "                '    <div v-if=\"isVideo\" style=\"width: 100%; height: 100%\">\\n' +\n" +
        "                '      <img :width=\"' + data.cptWidth + '\"\\n' +\n" +
        "                '           :height=\"' + data.cptHeight + '\"\\n' +\n" +
        "                '           style=\"position: relative; display: inline-block;overflow: hidden;\"\\n' +\n" +
        "                '           alt=\"img\"\\n' +\n" +
        "                '           :src=\"url\"\\n' +\n" +
        "                '           />' +\n" +
        "                '    </div>\\n' +\n" +
        "                '    <video v-else id=\"example_video_1\"  preload autoplay controls\\n' +\n" +
        "                '           @ended=\"onVideoEnded\"\\n' +\n" +
        "                '           :width=\"' + data.cptWidth + '\"\\n' +\n" +
        "                '           :height=\"' + data.cptHeight + '\" autoplay=\"autoplay\"  \\n' +\n" +
        "                '            style=\"object-fit:fill;\" \\n' +\n" +
        "                '           :src=\"url\">\\n' +\n" +
        "                '    </video>\\n' +\n" +
        "                '  </div>' +\n" +
        "                '  </div>'\n" +
        "            let listfile = []\n" +
        "            data.cptOption.attribute.urlist.forEach(itme => {\n" +
        "                const imepath = itme.filePath.split('/')\n" +
        "                listfile.push(\n" +
        "                    {\n" +
        "                        Interval: data.cptOption.attribute.Interval,\n" +
        "                        fileType: itme.fileType,\n" +
        "                        filePath: './' + imepath[imepath.length - 1]\n" +
        "                    }\n" +
        "                )\n" +
        "            })\n" +
        "\n" +
        "            const jsc = '<script>\\n' +\n" +
        "                '    new Vue({\\n' +\n" +
        "                '        el: \\'#app\\',\\n' +\n" +
        "                '        data: function(){\\n' +\n" +
        "                '            return{\\n' +\n" +
        "                '                url: \\'\\',\\n' +\n" +
        "                '                GeneralViod: Date.now(),\\n' +\n" +
        "                '                uuid: null,\\n' +\n" +
        "                '                isVideo: true,\\n' +\n" +
        "                '                curr: 0,\\n' +\n" +
        "                '                option: ' + JSON.stringify(listfile).toString() +\n" +
        "                '            }\\n' +\n" +
        "                '        },\\n' +\n" +
        "                '        mounted() {\\n' +\n" +
        "                '            this.setOneOf()\\n' +\n" +
        "                '        },\\n' +\n" +
        "                '        methods: {\\n' +\n" +
        "                '            onVideoEnded() {\\n' +\n" +
        "                '                // 在这里执行你想要的操作\\n' +\n" +
        "                '                if (this.curr < JSON.parse(JSON.stringify(this.option)).length ) {\\n' +\n" +
        "                '                    this.isVideo = JSON.parse(JSON.stringify(this.option))[this.curr].fileType === \\'1\\'\\n' +\n" +
        "                '                    if (this.isVideo) {\\n' +\n" +
        "                '                       this.url  = JSON.parse(JSON.stringify(this.option))[this.curr].filePath \\n' +\n" +
        "                '                       ++this.curr \\n' +\n" +
        "                '                        this.setViewImag()\\n' +\n" +
        "                '                    } else {\\n' +\n" +
        "                '                        this.url  =JSON.parse(JSON.stringify(this.option))[this.curr].filePath; \\n' +\n" +
        "                '                        ++this.curr \\n' +\n" +
        "                '                        this.GeneralViod = Date.now()\\n' +\n" +
        "                '                    }\\n' +\n" +
        "                '                } else {\\n' +\n" +
        "                '                    this.curr = 0\\n' +\n" +
        "                '                   this.url = JSON.parse(JSON.stringify(this.option))[this.curr].filePath\\n' +\n" +
        "                '                    this.isVideo = JSON.parse(JSON.stringify(this.option))[this.curr].fileType === \\'1\\'\\n' +\n" +
        "                '                    this.setViewImag()\\n' +\n" +
        "                '                }\\n' +\n" +
        "                '            },\\n' +\n" +
        "                '            setOneOf() {\\n' +\n" +
        "                '                if (this.curr < JSON.parse(JSON.stringify(this.option)).length ) {\\n' +\n" +
        "                '                    this.isVideo = JSON.parse(JSON.stringify(this.option))[this.curr].fileType === \\'1\\'\\n' +\n" +
        "                '                    if (this.isVideo) {\\n' +\n" +
        "                '                       this.url = JSON.parse(JSON.stringify(this.option))[this.curr].filePath; \\n  ' +\n" +
        "                '                        ++this.curr \\n' +\n" +
        "                '                        this.GeneralViod = Date.now()\\n' +\n" +
        "                '                        this.setViewImag()\\n' +\n" +
        "                '                    } else {\\n' +\n" +
        "                '                     this.url= JSON.parse(JSON.stringify(this.option))[this.curr].filePath; \\n' +\n" +
        "                '                     ++this.curr \\n' +\n" +
        "                '                        this.GeneralViod = Date.now()\\n' +\n" +
        "                '                    }\\n' +\n" +
        "                '                } else {\\n' +\n" +
        "                '                    this.curr = 0\\n' +\n" +
        "                '                    this.url =JSON.parse(JSON.stringify(this.option))[this.curr].filePath; \\n' +\n" +
        "                '                    this.isVideo = JSON.parse(JSON.stringify(this.option))[this.curr].fileType === \\'1\\'\\n' +\n" +
        "                '                    this.setViewImag()\\n' +\n" +
        "                '                }\\n' +\n" +
        "                '            },\\n' +\n" +
        "                '            setViewImag() {\\n' +\n" +
        "                '                setTimeout(this.setOneOf, JSON.parse(JSON.stringify(this.option))[0].Interval * 1000)\\n' +\n" +
        "                '            }\\n' +\n" +
        "                '        }\\n' +\n" +
        "                '    })\\n' +\n" +
        "                '</script>'\n" +
        "\n" +
        "\n" +
        "            return tile11 + tile12 + jsc\n" +
        "            break\n" +
        "    }\n" +
        "}"
}

export function getVUeData(data) {


    return "  new Vue({\n" +
        "        el: '#app',\n" +
        "        data: function () {\n" +
        "            return {\n" +
        "                rawHtml: '',\n" +
        "                currentCpt: " + JSON.stringify(data.currentCpt).toString() + ",\n" +
        "                cacheComponents:[],\n" +
        "                windowWidth: " + data.scaleX + ",\n" +
        "                conHeight: " + data.scaleY + ",\n" +
        "                windowHeight: 800,\n" +
        "                upIndex: {},\n" +
        "                designData: [],\n" +
        "                designCache: {},\n" +
        "                html:'',\n" +
        "                ishtml:false,\n" +
        "                autoPlay:" + data.autoPlay + ",\n" +
        "                playInterval:" + data.playInterval + ",\n" +
        "                Itmedata:0 \n" +
        "            }\n" +
        "        },\n" +
        "        created() {\n" +
        "            this.currentCpt.forEach(item => {\n" +
        "                this.designData.push(JSON.parse(JSON.stringify(item)))\n" +
        "            })\n" +
        "      this.startTimer()\n" +
        "\n" +
        "        },\n" +
        "        methods: {\n" +
        "            getView(e) {\n" +
        "                var template = \"\";\n" +
        "                var currentCpt = this.designData[e]\n" +
        "                var info = currentCpt.sourceInfo\n" +
        "                var sources = JSON.parse(info).sources\n" +
        "                const bgtile1 = '<div style=\"width:' + this.windowWidth + 'px;height:' + this.conHeight + 'px;background-color: ' + currentCpt.boardBackground + ';max-width:' + currentCpt.scaleX + 'px;max-height: ' + currentCpt.scaleY + 'px;overflow: hidden;\">'\n" +
        "                const tile2 = '<div style=\"width: ' +  this.windowWidth  + 'px;height: ' + this.conHeight + 'px;\">'\n" +
        "                const tile3 = '</div></div>'\n   " +
        "                if (currentCpt.boardType === '1') {\n" +
        "                    sources.forEach(it => {\n" +
        "                        template += `${this.setDatel(it.cptKey, it, currentCpt.boardBackground)}`;\n" +
        "                    })\n" +
        "                } else {\n" +
        "                    sources.forEach(it => {\n" +
        "                        template += `${this.setDateTem(it.cptKey, it)}`;\n" +
        "                    })\n" +
        "                }\n" +
        "                this.cacheComponents = currentCpt.cacheComponents\n" +
        "                this.designCache = currentCpt;\n" +
        "                document.getElementById('viewHt').innerHTML = bgtile1 + tile2 + template + tile3;\n" +
        "            },\n" +
        "            setDateTem(key, data,e) {\n" +
        "                switch (key) {\n" +
        "                    case 'tem-img':\n" +
        "                        let filename0 = data.cptOption.attribute.url.split('/')\n" +
        "                        const url = data.cptOption.attribute.type === 0 ? data.cptOption.attribute.url : './' + filename0[filename0.length - 1]\n" +
        "                        const tile = '<div style=\"position: absolute; width:' + data.cptWidth + 'px;height: ' + data.cptHeight + 'px;top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';\">'\n" +
        "                        const divimg = '<div  width=\"' + data.cptWidth + '\" height=\"' + data.cptWidth + '\" style=\"width: 100%; height: 100%;\">'\n" +
        "                        const img = '<img style=\"width: 100%; height: 100%; position: relative; display: inline-block; overflow: hidden;\" src=\"' + url + '\" fit=\"fill\"/>'\n" +
        "                        return tile + divimg + img + '</div>' + '</div>'\n" +
        "                        break\n" +
        "                    case 'tem-vido':\n" +
        "                        let filename = data.cptOption.attribute.url.split('/')\n" +
        "                        const urlvdeo = data.cptOption.attribute.type === 0 ? data.cptOption.attribute.url : './' + filename[filename.length - 1]\n" +
        "                        const tlie1 = '<div style=\"position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';\">'\n" +
        "                        const tile2 = '<div  width=\"' + data.cptWidth + '\" height=\"' + data.cptHeight + '\">'\n" +
        "                        const tile3 = '<video  id=\"example_video_1\"    width=\"' + data.cptWidth + '\" height=\"' + data.cptHeight + '\" preload autoplay controls loop style=\"object-fit:fill;\">'\n" +
        "                        const tile4 = '<source src=\"' + urlvdeo + '\" type=\"video/mp4\">'\n" +
        "                        const tile5 = '</video></div></div>'\n" +
        "                        return tlie1 + tile2 + tile3 + tile4 + tile5\n" +
        "                        break\n" +
        "                    case 'tem-text':\n" +
        "                        const texttile1 = '<div  style=\"position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';\">'\n" +
        "                        const texttile2 = '<div width=\"' + data.cptWidth + '\" height=\"' + data.cptHeight + '\" style=\"font-family: ' + data.cptOption.attribute.textFamily + ';text-align: ' + data.cptOption.attribute.textAlign + '; color: ' + data.cptOption.attribute.textColor + '; font-size: ' + data.cptOption.attribute.textSize + 'px; font-style: ' + data.cptOption.attribute.fontStyle + '; font-weight: ' + data.cptOption.attribute.fontWeight + '; background-color: ' + data.cptOption.attribute.bgColor + ';  text-decoration: ' + data.cptOption.attribute.textDecoration + ';\">'\n" +
        "                        const texttile3 = '</div></div>'\n" +
        "                        return texttile1 + texttile2 + JSON.parse(data.cptOption.cptDataForm.dataText).value + texttile3\n" +
        "                        break\n" +
        "                    case 'temsubtitles':\n" +
        "\n" +
        "                        const csstext = '<style >\\n ' +\n" +
        "                            '       .scrolling-text' + data.id + ' { overflow: hidden;  white-space: nowrap; width: 100%;}\\n' +\n" +
        "                            '       .scrolling-text' + data.id + ' span {display: inline-block;padding-left: 100%; animation:  ' + data.cptOption.attribute.direction + '  20s linear infinite; /* 调整动画时长和效果 */}\\n' +\n" +
        "                            '        @keyframes marquee { 0% { transform: translateX(0); } 100% { transform: translateX(-100%); }}\\n' +\n" +
        "                            '        @keyframes marquees {0% { transform: translateX(-100%); }100% {  transform: translateX(0); }}\\n' +\n" +
        "                            '    </style>'\n" +
        "                        const texttile6 = '<div  class=\"scrolling-text' + data.id + '\"  style=\"position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';background-color: ' + data.cptOption.attribute.bgColor + ';\">'\n" +
        "\n" +
        "                        const texttile7 = '<span width=\"' + data.cptWidth + '\" height=\"' + data.cptHeight + '\" style=\"font-family: ' + data.cptOption.attribute.textFamily + ';text-align: ' + data.cptOption.attribute.textAlign + '; color: ' + data.cptOption.attribute.textColor + '; font-size: ' + data.cptOption.attribute.textSize + 'px; font-style: ' + data.cptOption.attribute.fontStyle + '; font-weight: ' + data.cptOption.attribute.fontWeight + ';   text-decoration: ' + data.cptOption.attribute.textDecoration + ';\">'\n" +
        "\n" +
        "                        const texttile9 = '</span></div>'\n" +
        "                        return csstext + texttile6 + texttile7 + JSON.parse(data.cptOption.cptDataForm.dataText).value + texttile9\n" +
        "                        break\n" +
        "                    case 'templaylist':\n" +
        "                        const tile11 = '<div id=\"app\"  style=\"position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';\">'\n" +
        "                        const tile12 = '<div :key=\"GeneralViod\">\\n' +\n" +
        "                            '    <div v-if=\"isVideo\" style=\"width: 100%; height: 100%\">\\n' +\n" +
        "                            '      <img :width=\"' + data.cptWidth + '\"\\n' +\n" +
        "                            '           :height=\"' + data.cptHeight + '\"\\n' +\n" +
        "                            '           style=\"position: relative; display: inline-block;overflow: hidden;\"\\n' +\n" +
        "                            '           alt=\"img\"\\n' +\n" +
        "                            '           :src=\"url\"\\n' +\n" +
        "                            '           />' +\n" +
        "                            '    </div>\\n' +\n" +
        "                            '    <video v-else id=\"example_video_1\"  preload autoplay controls\\n' +\n" +
        "                            '           @ended=\"onVideoEnded\"\\n' +\n" +
        "                            '           :width=\"' + data.cptWidth + '\"\\n' +\n" +
        "                            '           :height=\"' + data.cptHeight + '\" autoplay=\"autoplay\"  \\n' +\n" +
        "                            '            style=\"object-fit:fill;\" \\n' +\n" +
        "                            '           :src=\"url\">\\n' +\n" +
        "                            '    </video>\\n' +\n" +
        "                            '  </div>' +\n" +
        "                            '  </div>'\n" +
        "                        let listfile = []\n" +
        "                        return tile11 + tile12 \n" +
        "                        break\n" +
        "                }\n" +
        "            },\n" +
        "            setDatel(key, data,e) {\n" +
        "                switch (key) {\n" +
        "                    case 'sig-img':\n" +
        "                        let filename0 = data.cptOption.attribute.url.split('/')\n" +
        "                        const url = data.cptOption.attribute.type === 0 ? data.cptOption.attribute.url : './' + filename0[filename0.length - 1]\n" +
        "                        const tile = '<div style=\"position: absolute; width:' + data.cptWidth + 'px;height: ' + data.cptHeight + 'px;top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';\">'\n" +
        "                        const divimg = '<div  width=\"' + data.cptWidth + '\" height=\"' + data.cptWidth + '\" style=\"width: 100%; height: 100%;\">'\n" +
        "                        const img = '<img style=\"width: 100%; height: 100%; position: relative; display: inline-block; overflow: hidden;\" src=\"' + url + '\" fit=\"fill\"/>'\n" +
        "                        return tile + divimg + img + '</div>' + '</div>'\n" +
        "                        break\n" +
        "                    case 'sig-video':\n" +
        "                        let filename = data.cptOption.attribute.url.split('/')\n" +
        "                        const urlvdeo = data.cptOption.attribute.type === 0 ? data.cptOption.attribute.url : './' + filename[filename.length - 1]\n" +
        "                        const tlie1 = '<div style=\"position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';\">'\n" +
        "                        const tile2 = '<div  width=\"' + data.cptWidth + '\" height=\"' + data.cptHeight + '\">'\n" +
        "                        const tile3 = '<video  id=\"example_video_1\"    width=\"' + data.cptWidth + '\" height=\"' + data.cptHeight + '\" preload autoplay controls loop style=\"object-fit:fill;\">'\n" +
        "                        const tile4 = '<source src=\"' + urlvdeo + '\" type=\"video/mp4\">'\n" +
        "                        const tile5 = '</video></div></div>'\n" +
        "                        return tlie1 + tile2 + tile3 + tile4 + tile5\n" +
        "                        break\n" +
        "                    case 'sip-text':\n" +
        "                        const texttile1 = '<div  style=\"position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';\">'\n" +
        "                        const texttile2 = '<div width=\"' + data.cptWidth + '\" height=\"' + data.cptHeight + '\" style=\"text-align: ' + data.cptOption.attribute.textAlign + ';font-family: ' + data.cptOption.attribute.textFamily + '; color: ' + data.cptOption.attribute.textColor + '; font-size: ' + data.cptOption.attribute.textSize + 'px; font-style: ' + data.cptOption.attribute.fontStyle + '; font-weight: ' + data.cptOption.attribute.fontWeight + '; background-color: ' + data.cptOption.attribute.bgColor + ';  text-decoration: ' + data.cptOption.attribute.textDecoration + ';\">'\n" +
        "                        const texttile3 = '</div></div>'\n" +
        "                        return texttile1 + texttile2 + JSON.parse(data.cptOption.cptDataForm.dataText).value + texttile3\n" +
        "                        break\n" +
        "                    case 'sigsubtitles':\n" +
        "\n" +
        "                        const csstext = '<style >\\n ' +\n" +
        "                            '       .scrolling-text' + data.id + ' { overflow: hidden;  white-space: nowrap; width: 100%;}\\n' +\n" +
        "                            '       .scrolling-text' + data.id + ' span {display: inline-block;padding-left: 100%; animation:  ' + data.cptOption.attribute.direction + '  20s linear infinite; /* 调整动画时长和效果 */}\\n' +\n" +
        "                            '        @keyframes marquee { 0% { transform: translateX(0); } 100% { transform: translateX(-100%); }}\\n' +\n" +
        "                            '        @keyframes marquees {0% { transform: translateX(-100%); }100% {  transform: translateX(0); }}\\n' +\n" +
        "                            '    </style>'\n" +
        "                        const texttile6 = '<div  class=\"scrolling-text' + data.id + '\"  style=\"position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';background-color: ' + data.cptOption.attribute.bgColor + ';\">'\n" +
        "\n" +
        "                        const texttile7 = '<span width=\"' + data.cptWidth + '\" height=\"' + data.cptHeight + '\" style=\"font-family: ' + data.cptOption.attribute.textFamily + ';text-align: ' + data.cptOption.attribute.textAlign + '; color: ' + data.cptOption.attribute.textColor + '; font-size: ' + data.cptOption.attribute.textSize + 'px; font-style: ' + data.cptOption.attribute.fontStyle + '; font-weight: ' + data.cptOption.attribute.fontWeight + ';   text-decoration: ' + data.cptOption.attribute.textDecoration + ';\">'\n" +
        "\n" +
        "                        const texttile9 = '</span></div>'\n" +
        "                        return csstext + texttile6 + texttile7 + JSON.parse(data.cptOption.cptDataForm.dataText).value + texttile9\n" +
        "                        break\n" +
        "                    case 'sigPlaylist':\n" +
        "                        const tile11 = '<div id=\"app\"  style=\"position: absolute; width: ' + data.cptWidth + 'px; height: ' + data.cptHeight + 'px; top: ' + data.cptY + 'px; left: ' + data.cptX + 'px; z-index: ' + data.cptZ + ';\">'\n" +
        "                        const tile12 = '<div :key=\"GeneralViod\">\\n' +\n" +
        "                            '    <div v-if=\"isVideo\" style=\"width: 100%; height: 100%\">\\n' +\n" +
        "                            '      <img :width=\"' + data.cptWidth + '\"\\n' +\n" +
        "                            '           :height=\"' + data.cptHeight + '\"\\n' +\n" +
        "                            '           style=\"position: relative; display: inline-block;overflow: hidden;\"\\n' +\n" +
        "                            '           alt=\"img\"\\n' +\n" +
        "                            '           :src=\"url\"\\n' +\n" +
        "                            '           />' +\n" +
        "                            '    </div>\\n' +\n" +
        "                            '    <video v-else id=\"example_video_1\"  preload autoplay controls\\n' +\n" +
        "                            '           @ended=\"onVideoEnded\"\\n' +\n" +
        "                            '           :width=\"' + data.cptWidth + '\"\\n' +\n" +
        "                            '           :height=\"' + data.cptHeight + '\" autoplay=\"autoplay\"  \\n' +\n" +
        "                            '            style=\"object-fit:fill;\" \\n' +\n" +
        "                            '           :src=\"url\">\\n' +\n" +
        "                            '    </video>\\n' +\n" +
        "                            '  </div>' +\n" +
        "                            '  </div>'\n" +
        "                        let listfile = []\n" +
        "                        data.cptOption.attribute.urlist.forEach(itme => {\n" +
        "                            const imepath = itme.filePath.split('/')\n" +
        "                            listfile.push(\n" +
        "                                {\n" +
        "                                    Interval: data.cptOption.attribute.Interval,\n" +
        "                                    fileType: itme.fileType,\n" +
        "                                    filePath: './' + imepath[imepath.length - 1]\n" +
        "                                }\n" +
        "                            )\n" +
        "                        })\n" +
        "                        re" + "turn tile11 + tile12 \n" +
        "                        br" + "eak\n" +
        "                }\n" +
        "            },\n" +
        "            digUrl(e) {\n" +
        "                layer.open({\n" +
        "                    type: 2,\n" +
        "                    title: e,\n" +
        "                    shadeClose: true,\n" +
        "                    maxmin: true, //开启最大化最小化按钮\n" +
        "                    area: [this.windowWidth+'px', this.windowHeight+'px'],\n" +
        "                    content: e\n" +
        "                });\n" +
        "    /*\n" +
        "                window.open(e,\n" +
        "                    \"wWindow\", \"width=\" + this.windowWidth-150 + \" ,height=\" + this.windowHeight-150 +\n" +
        "                    \",top=0, left=0, titlebar=no, menubar=no, scrollbars=no, resizable=no, status=no, toolbar=no, location=no\");*/\n" +
        "            },\n" +
        "      startTimer() {\n" +
        "                if (this.autoPlay) {\n" +
        "                    setInterval(() => {\n" +
        "                        if (this.Itmedata > this.designData.length - 1) {\n" +
        "                            this.Itmedata = 0\n" +
        "                        }\n" +
        "                        this.getView(this.Itmedata)\n" +
        "                        ++this.Itmedata\n" +
        "                    }, this.playInterval * 1000)\n" +
        "                }else {\n" +
        "                    this.getView(0)\n" +
        "                }\n" +
        "            }," +
        "            onclickVue(e) {\n" +
        "                var index = this.designData.findIndex(it => it.htmlPath === e.cptOption.attribute.url)\n" +
        "                switch (e.cptOption.attribute.type) {\n" +
        "                    case 0:\n" +
        "                        var indexs = this.designData.findIndex(it => it.boardId === this.upIndex.boardId)\n" +
        "                        if (indexs !== -1) {\n" +
        "                            this.getView(indexs);\n" +
        "                        }\n" +
        "                        break\n" +
        "                    case 1:\n" +
        "                        this.upIndex = this.designCache\n" +
        "                        this.getView(index);\n" +
        "                        break\n" +
        "                    case 2:\n" +
        "                        this.digUrl(e.cptOption.attribute.url)\n" +
        "                        break\n" +
        "                }\n" +
        "\n" +
        "            }\n" +
        "        }\n" +
        "\n" +
        "    })"
}





