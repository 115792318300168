<template>
  <div class="flex-col" style="overflow: hidden;">
    <el-row class="top">
      <div class="flex-row justify-between">
        <div style=" display: flex; flex-direction: row;">
          <div style="background: rgba(64, 71, 86, 1);width: 54px;height: 54px;display: flex;cursor: pointer; "
               @click="goBack()">
            <img style="margin: auto;" :src="require('@/assets/icon/600.svg')"/>
          </div>
          <div style="width: 100px;height: 54px;display: flex;">
            <span class="span_tile">Go Back</span>
          </div>
        </div>
        <div class="flex-row" v-if="seveItme === 0">
          <div class="flex-row div_tile_r" @click="handleUndo()">
            <div class="svgicon">
              <img v-bind:class="{img_svg1:historyData.length > 0 ,img_svg0:!historyData.length > 0}"
                   :src="require('@/assets/icon/Undo.svg')">
            </div>
            <p v-bind:class="{tile_span_r:historyData.length > 0,tile_span_r_false:historyData.length<1}">Undo</p>
          </div>
          <div style="height: 17px;width: 1px;background-color: #C0BFC0;margin-left: 8px;margin-right: 8px;"></div>
          <div class="flex-row div_tile_r" @click="handleRedo()">
            <div class="svgicon">
              <img v-bind:class="{img_svg1:RedoData.length > 0 ,img_svg0:!RedoData.length > 0}"
                   :src="require('@/assets/icon/Redo.svg')">
            </div>
            <p v-bind:class="{tile_span_r:RedoData.length > 0,tile_span_r_false:RedoData.length<1}">Redo</p>
          </div>
        </div>
        <div class="flex-row" v-if="seveItme === 0">
          <div class="flex-row" style="width: 66px;height: 54px;cursor: pointer;"
               @click="setLayui">
            <div style="display: flex;align-content: center;margin: auto;">
              <img style="width: 18px;height: 18px;" :src="require('@/assets/icon/Relationaldiagram.svg')">
            </div>
          </div>
          <div class="flex-row" style="width: 66px;height: 54px;cursor: pointer;" @click="preview">
            <div style="display: flex;align-content: center;margin: auto;">
              <img style="width: 18px;height: 18px;" :src="require('@/assets/icon/preview.svg')">
            </div>
          </div>
          <div class="flex-row" style="width: 150px;background: #0FBF19;height: 54px;cursor: pointer;"
               @click="jsonHtml()">
            <div style="display: flex;align-content: center;margin: auto;">
              <img style="width: 18px;height: 18px;" :src="require('@/assets/icon/save.svg')">
              <span class="span_seve">Save Board</span>
            </div>
          </div>
        </div>

      </div>
    </el-row>
    <div v-loading="loading" element-loading-text="Saving....">
      <div class="flex-row" style="overflow-y: hidden;" v-if="seveItme === 0">
        <div style="float: left;height: 100%;overflow: hidden;min-width: 360px;z-index: 2000; "
             :style="{width:cptBarWidth+'px'}">
          <Componentleft ref="configBar" :designData.sync="designData" :currentCpt.sync="cacheComponents"
                         @refreshCptData="refreshCptData" :height="windowHeight"/>
        </div>
        <div class="flex-col" style="hdisplay: flex;align-items: center;  ">
          <div
              :style="{width:(windowWidth-cptBarWidth-configBarWidth)+'px'}" @click.self="outBlur">
            <div class="webContainer " :style="{width:designData.scaleX+'px',height: designData.scaleY+'px', maxWidth:designData.scaleX+'px',maxHeight: designData.scaleY+'px',
            backgroundColor: designData.bgColor,   backgroundImage: designData.bgImg ? 'url('+fileUrl+designData.bgImg+')':'none',
            borderLeft: Leftborder,borderRight: Rightborder,borderTop :Topborder,borderBottom: Bottomborder,
            transform: 'scale('+containerScale+')'}"
                 id="myElement" ref="webContainer"
                 @dragover="allowDrop" @drop="drop" @click.self="outBlur">
              <!--              -->
              <div v-for="(item,index) in cacheComponents" :key="item.id"
                   class="cptDiv" :style="{width:Math.round(item.cptWidth)+'px', height:Math.round(item.cptHeight)+'px',
                  top:Math.round(item.cptY)+'px',left:Math.round(item.cptX)+'px',
              zIndex: currentCptIndex === index ? 1800 : item.cptZ }" :ref="'div'+item.cptKey+index"
                   @mousedown="showConfigBar($event,item,index)" @mouseup="showSeup($event,item,index)" tabindex="0">
                <div v-show="currentCptIndex === index"
                     :style="{position:'fixed', borderTop: topAuxiliary,width: '100%',left:0}"/><!--顶部辅助线-->
                <div v-show="currentCptIndex === index"
                     :style="{position: 'fixed',borderRight: leftAuxiliary,height:'100%',top: 0}"/><!--左侧辅助线-->
                <!-- 2021-12-28新增iframe组件，防止焦点聚焦在iframe内部，添加此蒙版 -->
                <div v-resize="'move'" class="activeMask"
                     :style="cacheChoices[item.id] ? {border:'1px dotted #9931F8'}:{border:'1px dotted #9931F8'}">
                  <div class="flex-row top_div_tile" v-if="cacheChoices[item.id] ">
                    <div class="flex-row" style="margin: auto">
                      <img style="width: 16px;height: 16px;margin-right: 6px;"
                           :src="require('@/assets/icon/' +item.icons +'.svg')">
                      <span class="span_tile_name">{{ item.cptTitle }} </span>
                    </div>
                  </div>
                </div>
                <div style="width: 100%;height: 100%;">
                  <component :is="item.cptKey" :ref="item.cptKey+index" :width="Math.round(item.cptWidth)"
                             :height="Math.round(item.cptHeight)" :option="item.cptOption"/>
                </div>
                <div class="delTag">
                  <i style="margin-left: 4px" class="el-icon-full-screen" :title="'Maximize'"
                     @click.stop="maxCpt(item)"/>
                  <i style="margin-left: 4px" class="el-icon-copy-document" :title="'Copy'"
                     @click.stop="copyCpt(item)"/>
                  <i style="margin-left: 4px" class="el-icon-delete" :title="'Delete'"
                     @click.stop="delCpt(item,index)"/>
                </div>
                <div v-show="currentCptIndex === index"
                     style="top: 0;left: 0;cursor: se-resize;transform: translate(-50%, -50%)"
                     class="resizeTag" v-resize="'lt'"/>
                <div v-show="currentCptIndex === index"
                     style="top: 0;left: 50%;cursor: s-resize;transform: translate(-50%, -50%)"
                     class="resizeTag" v-resize="'t'"/>
                <div v-show="currentCptIndex === index"
                     style="top: 0;right: 0;cursor: ne-resize;transform: translate(50%, -50%)"
                     class="resizeTag" v-resize="'rt'"/>
                <div v-show="currentCptIndex === index"
                     style="top: 50%;right: 0;cursor: w-resize;transform: translate(50%, -50%)"
                     class="resizeTag" v-resize="'r'"/>
                <div v-show="currentCptIndex === index"
                     style="bottom: 0;right: 0;cursor: se-resize;transform: translate(50%, 50%)"
                     class="resizeTag" v-resize="'rb'"/>
                <div v-show="currentCptIndex === index"
                     style="bottom: 0;left: 50%;cursor: s-resize;transform: translate(-50%, 50%)"
                     class="resizeTag" v-resize="'b'"/>
                <div v-show="currentCptIndex === index"
                     style="bottom: 0;left: 0;cursor: ne-resize;transform: translate(-50%, 50%)"
                     class="resizeTag" v-resize="'lb'"/>
                <div v-show="currentCptIndex === index"
                     style="top: 50%;left: 0;cursor: w-resize;transform: translate(-50%, -50%)"
                     class="resizeTag" v-resize="'l'"/>
              </div>
            </div>
          </div>
          <div class="flex-row" style="position: absolute; bottom: 80px;">
            <Leftcombar @dragStart="dragStart" :selectedComponents="cacheComponents" :currentCptIndex="currentCptIndex"
                        @showConfigBar="showConfigBar" @copyCpt="copyCpt" @delCpt="delCpt"/>
          </div>
        </div>
        <div style="width: 20px;margin-top: 200px;position: absolute;right: 20px;">
          <el-slider v-model="containerScale" :min="0.3" :max="0.8" :step="0.01" :vertical="true" height="200px"/>
        </div>
      </div>
      <div style="overflow-y: hidden; display: flex;position: relative;" v-if="seveItme === 1">
        <div class="flex-col" style="margin: auto;">
          <span class="span_tile_board" style="margin-top: 195px;">Board Saved</span>
          <span class="span_boadrd">{{ designData.title }}</span>
          <div style="width: 1px;height: 88px;background-color: #E0E1DD;margin: 64px auto ;"></div>
          <span class="span_tile_board">Next Step</span>
          <div class="flex-row">
            <div class="flex-row" style="cursor: pointer;" @click="isSelect = true">
              <div
                  style="width: 50px;height: 54px;border-radius: 50%;border: 2px solid #9931F8;background-color: #FFFFFF;display: flex;">
                <img style="margin: auto;" :src="require('@/assets/icon/iconpushdevice.svg')">
              </div>
              <span class="span_iles">Push To Device</span>
            </div>
            <div class="flex-row" style="margin-left: 120px;cursor: pointer;" @click="handleGenTable()">
              <div
                  style="width: 50px;height: 54px;border-radius: 50%;border: 2px   solid #9931F8;background-color: #FFFFFF;display: flex;">
                <img style="margin: auto;" :src="require('@/assets/icon/iconcompress.svg')">
              </div>
              <span class="span_iles">Export As .zip</span>
            </div>
          </div>

        </div>

      </div>
    </div>
    <FromLibrary v-if="isFile" :projec-data="Library" v-on:chickBack="chickBack"/>
    <select-device v-if="isSelect" :projec-data="downloadID" v-on:chickBack="chickBack"/>
    <el-drawer
        :title="'layer'"
        :visible.sync="drawer"
        :direction="direction"
        :before-close="handleClose">
      <div>

      </div>
    </el-drawer>

  </div>
</template>

<script>
import env, {fileUrl} from "../../../env";
import portrait from "@/components/portrait";
import options from "@/components/landscape";
import Componentleft from "@/views/freeboard/Component-left.vue";
import Leftcombar from "@/views/freeboard/LeftComBar.vue";
import ComponentBar from "@/views/designer/componentBar.vue";
import {addmuseaBoard, EditMuseaBoard} from "@/api/login";
import {setDatel} from "@/utils";
import html2canvas from "html2canvas";
import axios from "axios";
import {getToken} from "@/utils/auth";
import FreePlaylist from "@/element/leftfree/FreePlaylist.vue";
import FromLibrary from "@/popover/FromLibrary.vue";
import SelectDevice from "@/popover/SelectDevice.vue";

export default {
  name: 'freeboard',
  components: {SelectDevice, FromLibrary, FreePlaylist, ComponentBar, Leftcombar, Componentleft},
  data() {
    return {
      currentCptIndex: -1,
      windowWidth: 0,
      windowHeight: 0,
      configBarWidth: 0,
      fileUrl: env.fileUrl,
      portrait,
      options,
      cacheComponents: [],
      cptBarWidth: 360,
      designData: {
        id: '', title: 'New Board', scaleX: 1280, scaleY: 800, version: '',
        bgColor: '#FFFFFF', simpleDesc: '', bgImg: '', viewCode: 0, components: []
      },
      currentCpt: {cptOption: undefined},
      containerScale: 0.75,
      cacheChoices: {},
      cacheChoicesFixed: {},//记录移动前选中组件的位置 自定义事件内部无法处理，放在了外面。
      tilename: 'Section 1 (Empty)',
      seveItme: 0,
      designCache: {},
      windowWidths: 0,
      windowHeights: 0,
      conHeight: 0,
      downloadID: {},
      EditorID: 0,
      loading: false,
      isFile: false,
      Imagelist: [],
      VidoList: [],
      Library: {},
      isSelect: false,
      canvas: null, // 画布
      ctx: null,
      Leftborder: '',
      Rightborder: '',
      Topborder: '',
      Bottomborder: '',
      topAuxiliary: '1px dashed #8A8A8A',
      leftAuxiliary: '1px dashed #8A8A8A',
      historyData: [],
      RedoData: [],
      drawer: false,
      direction: 'rtl',
    }
  },
  created() {
    this.initContainerSize();
    $bus.on('freeboard', data => {
      switch (data.id) {
        case 1:
          this.Library = data
          this.isFile = true
          break
        case 2:
          this.isFile = false
          break
        case 3:
          this.outBlur()
          break
        case 4:
          for (const item1 of this.cacheComponents) {
            if (item1.id !== data.data.id) {
              let itY = item1.cptY + item1.cptHeight
              if (item1.cptY === data.data.cptY || itY === data.data.cptY) {
                this.topAuxiliary = '2px solid #FC0000'
                break
              } else {
                this.topAuxiliary = '1px dashed #8A8A8A'
              }
            }
          }
          for (const itme2 of this.cacheComponents) {
            if (itme2.id !== data.data.id) {
              let itX = itme2.cptX + itme2.cptWidth
              if (itme2.cptX === data.data.cptx || data.data.cptx === itX) {
                this.leftAuxiliary = '2px solid #FC0000'
                break
              } else {
                this.leftAuxiliary = '1px dashed #8A8A8A'
              }
            }
          }
          if (data.data.cptx === 0) {
            this.Leftborder = '2px solid #FC0000'
          } else {
            this.Leftborder = ''
          }
          if (data.data.cptY === 0) {
            this.Topborder = '2px solid #FC0000'
          } else {
            this.Topborder = ''
          }
          if (data.data.buttoncptX >= this.designData.scaleX) {
            this.Rightborder = '2px solid #FC0000'
          } else {
            this.Rightborder = ''
          }
          if (data.data.buttoncptY >= this.designData.scaleY) {
            this.Bottomborder = '2px solid #FC0000'
          } else {
            this.Bottomborder = ''
          }
          break

      }
    })

  },
  mounted() {
    const that = this;
    window.addEventListener("keydown", this.handleKeyDown)
    window.addEventListener("keyup", this.handlekeyUp)

    window.onresize = () => {
      return (() => {
        that.initContainerSize()
      })();
    };
    let datalist = this.$route.query.data
    if (datalist) {
      const design = JSON.parse(datalist)
      this.EditorID = 1
      this.designData.id = design.boardId
      const screenSize = design.screenSize.split('X')
      this.designData.scaleX = screenSize[0]
      this.designData.scaleY = screenSize[1]
      this.designData.title = design.boardName
      this.designData.bgColor = design.boardBackground
      console.log('mounted===>', this.designData)
      JSON.parse(design.sourceInfo).sources.forEach(it => {
        this.cacheComponents.push(it);
        this.showConfigBar({}, it, this.cacheComponents.length - 1)//丢下组件后刷新组件属性栏
      })
      this.cacheChoices = {}//多选清空
      // this.$refs['configBar'].showCptConfig();
    }
    this.moveNodeDown()
    // this.drop({name: 'Text', icon: 'text', cptKey: 'empty-sig', width: 150, height: 40, option: cpt_text_option} )
    //   this.initData()
  },
  methods: {
    initData() {
      const canvas = (this.canvas = document.getElementById("mycanvas"));
      const ctx = (this.ctx = canvas.getContext("2d"));
      this.drawChessContainer(16, 9)
    },
    drawChessContainer(x, y) {
      const {ctx} = this;
      ctx.rect(0, 0, this.designData.scaleX, this.designData.scaleY);
      for (var index = 1; index < y; index++) {
        // 横线
        ctx.beginPath();
        ctx.moveTo(0, this.designData.scaleY * (index / y));
        ctx.lineTo(this.designData.scaleX, this.designData.scaleY * (index / y));
        // 绘制线条
        ctx.stroke();
      }
      for (var index = 1; index < x; index++) {
        // 竖线
        ctx.beginPath();
        ctx.moveTo(this.designData.scaleX * (index / x), 0);
        ctx.lineTo(this.designData.scaleX * (index / x), this.designData.scaleY);
        // 绘制线条
        ctx.stroke();
      }
    },
    initContainerSize() {
      this.windowWidth = document.documentElement.clientWidth
      this.windowHeight = document.documentElement.clientHeight
      let tempWidth = this.windowWidth - this.cptBarWidth - this.configBarWidth;
      this.containerScale = 0.95
    },
    goBack() {
      if (this.seveItme === 1) {
        this.$showInfoGo({
          callback: (obj) => {
            if(obj === 'yes'){
              this.$router.push({name: 'home'})

            }else {
              this.$router.go(-1); // 返回上一个页面
            }
          }
        })

      } else {
        this.$router.go(-1); // 返回上一个页面
      }

    },
    preview() {
      this.designData.components = this.cacheComponents;
      localStorage.setItem('designCache', JSON.stringify(this.designData));
      let routeUrl = this.$router.resolve({
        path: "/preview"
      });
      window.open(routeUrl.href, '_blank');
    },
    setLayui() {
      this.drawer = !this.drawer
    },
    dragStart(copyDom) {//从组件栏拿起组件
      this.copyDom = copyDom;
      copyDom.draggable = false;
    },
    delCpt(cpt, index) {
      this.$confirm('Confirm whether to delete this component ?', '', {
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel',
        type: 'warning'
      }).then(() => {
        //记录一个bug，v-for key值重复导致页面渲染数据错乱。在丢下组件时实用uuid作
        // 为key解决。
        this.currentCpt = {};
        this.cacheComponents.splice(index, 1);

        //   const childId = this.$refs[cpt.cptKey + index][0].uuid
        //   clearCptInterval(childId);
      }).catch(() => {
      });
    },
    maxCpt(item) {
      let copyItem = JSON.parse(JSON.stringify(item))
      let Index = this.cacheComponents.findIndex(itme => itme.id === copyItem.id)
      this.cacheComponents[Index].cptX = 0
      this.cacheComponents[Index].cptY = 0
      this.cacheComponents[Index].cptWidth = this.designData.scaleX
      this.cacheComponents[Index].cptHeight = this.designData.scaleY
    },
    copyCpt(item) {
      let copyItem = JSON.parse(JSON.stringify(item))
      if (item.cptKey !== 'sigPlaylist') {
        copyItem.cptX = item.cptX + 30//复制的组件向右下偏移
        copyItem.cptY = item.cptY + 30
        copyItem.id = require('uuid').v1();
        this.cacheComponents.push(copyItem);
        this.currentCptIndex = this.cacheComponents.length - 1//聚焦到复制的组件
      } else {
        let Index = this.cacheComponents.findIndex(itme => itme.cptKey === 'sigPlaylist')
        if (Index === -1) {
          copyItem.cptX = item.cptX + 30//复制的组件向右下偏移
          copyItem.cptY = item.cptY + 30
          copyItem.id = require('uuid').v1();
          this.cacheComponents.push(copyItem);
          this.currentCptIndex = this.cacheComponents.length - 1//聚焦到复制的组件
        } else {
          this.$message.warning('Only one can be created PlayList')
        }
      }
    },
    drop(e) {
      console.log('-------drop----')
      let config = JSON.parse(this.copyDom.getAttribute('config'));
      if (config.cptKey !== 'sigPlaylist') {
        let cpt = {
          cptTitle: config.name,
          icon: config.icon,
          icons: config.icons,
          cptKey: config.cptKey,
          cptOptionKey: config.cptKey + '-option',
          cptOption: config.option,
          cptX: Math.round(e.offsetX),
          cptY: Math.round(e.offsetY),
          cptZ: 100,
          cptWidth: config.width ? config.width : 400,
          cptHeight: config.height ? config.height : 300,
          id: require('uuid').v1(),
        }
        this.cacheComponents.push(cpt);
        this.showSeup('', cpt, 0)
        this.cacheChoices = {}//多选清空
        this.showConfigBar({}, cpt, this.cacheComponents.length - 1)//丢下组件后刷新组件属性栏
        this.$refs['configBar'].showCptConfig();

      } else {
        let Index = this.cacheComponents.findIndex(itme => itme.cptKey === 'sigPlaylist')
        if (Index === -1) {
          let cpt = {
            cptTitle: config.name,
            icon: config.icon,
            cptKey: config.cptKey,
            icons: config.icons,
            cptOptionKey: config.cptKey + '-option',
            cptOption: config.option,
            cptX: Math.round(e.offsetX),
            cptY: Math.round(e.offsetY),
            cptZ: 100,
            cptWidth: config.width ? config.width : 400,
            cptHeight: config.height ? config.height : 300,
            id: require('uuid').v1(),
          }
          this.cacheComponents.push(cpt);
          this.cacheChoices = {}//多选清空
          this.showSeup('', cpt, 0)
          this.showConfigBar({}, cpt, this.cacheComponents.length - 1)//丢下组件后刷新组件属性栏
          this.$refs['configBar'].showCptConfig();

        } else {
          this.$message.warning('Only one can be created PlayList')
        }
      }


    },
    allowDrop(e) {
      e.preventDefault()
    },
    showConfigBar(e, item, index) {//刷新属性栏数据，页面上拖动的组件执行click事件来更新组件的属性栏
      this.currentCpt = item;
      console.log('==showConfigBar==>', item)
      this.currentCptIndex = index;
      this.$refs['configBar'].OnClinseItme(1)
      if (this.$refs['div' + item.cptKey + index]) {
        //   this.$refs['div' + item.cptKey + index][0].focus();//聚焦 用于多选移动
      }
      if (!e.ctrlKey) {//未按住ctrl键
        this.cacheChoices = {}
      }
      this.$refs['configBar'].showCptConfig(item);
      this.cacheChoices[item.id] = item
      this.cacheChoicesFixed[item.id] = JSON.parse(JSON.stringify(item))

      // $bus.emit('sigvideo', {id: 1})
    },
    showSeup(e, item, index) {
      console.log(item)
      let dts = []
      dts.push(...this.cacheComponents)
      if (this.historyData.length > 0) {
        let pop = this.historyData[this.historyData.length - 1]
        const dats = {
          id: item.id,
          x: item.cptX,
          y: item.cptY,
          width: item.cptWidth,
          height: item.cptHeight,
          z: item.cptZ,
          Option: item.cptOption,
          data: dts
        }
        if (pop.id === item.id) {
          if (pop.x !== item.cptX) {
            this.historyData.push(dats)
          }
        } else {
          this.historyData.push(dats)
        }
      } else {
        this.historyData.push({
          id: item.id,
          x: item.cptX,
          y: item.cptY,
          width: item.cptWidth,
          height: item.cptHeight,
          z: item.cptZ,
          Option: item.cptOption,
          data: dts
        })
      }

    },
    outBlur() {//取消聚焦组件
      this.Leftborder = ''
      this.Topborder = ''
      this.Rightborder = ''
      this.Bottomborder = ''
      this.topAuxiliary = '1px dashed #8A8A8A'
      this.leftAuxiliary = '1px dashed #8A8A8A'
      this.currentCptIndex = -1;
      this.currentCpt = {};
      this.cacheChoices = {}
      this.$refs['configBar'].OnClinseItme(0)
    },
    refreshCptData() {
      console.log('----refreshCptData-')
      const refName = this.currentCpt.cptKey + this.currentCptIndex;
      if (!this.$refs[refName][0].refreshCptData) {
        this.$message.warning('当前图层还未实现refreshCptData方法')
      } else {
        this.$refs[refName][0].refreshCptData();//刷新子组件数据，refs为组建名加index
      }
    },
    setView(data) {
      let screenSize = this.designData.scaleX.toString() + 'X' + this.designData.scaleY.toString()
      console.log(this.cacheComponents)
      if (this.EditorID === 0) {
        let das = {
          htmlPath: data.htmlUrl,
          boardName: this.designData.title,
          aspectRadio: this.designData.viewCode.toString(),
          screenSize: screenSize,
          boardType: "1",
          boardBackground: this.designData.bgColor,
          boardThumbnail: data.thumbnailUrl,
          sourceInfo: this.cacheComponents

        }
        addmuseaBoard(das).then(postMessage => {
          if (postMessage.code === 200) {
            this.downloadID = {code: 0, id: postMessage.id}
            this.seveItme = 1
            this.loading = false
          }
        }).catch(reason => {
        })
      } else {
        this.downloadID = {code: 0, id: this.designData.id}
        let das = {
          boardId: this.designData.id,
          htmlPath: data.htmlUrl,
          boardName: this.designData.title,
          aspectRadio: this.designData.viewCode.toString(),
          screenSize: screenSize,
          boardType: "1",
          boardBackground: this.designData.bgColor,
          boardThumbnail: data.thumbnailUrl,
          sourceInfo: this.cacheComponents
        }
        EditMuseaBoard(das).then(postMessage => {
          if (postMessage.code === 200) {
            this.seveItme = 1
            this.loading = false
          }
        }).catch(reason => {
        })
      }

    },
    handleGenTable() {
      let licenes = JSON.parse(sessionStorage.getItem('Licenselist'))
      let omse = licenes.filter(it => it.dataleft > 0 && it.licenseType === '4')
      if (omse.length < 1) {
        this.$showMsgBox({
          caption: 'License expired',
          icon: 'failed',
          msg: ['Your license for MUSEA has been expired.', 'Please consider to renew your license to', 'enjoy MUSEA features.'],
          button: 'Go Back',
          callback: (data) => {

          }
        })
      } else {
        this.$download.zip("/prod-api/system/museaBoard/exportBoard/" + this.downloadID.id, "MuSeaBoard.zip");
      }


    },
    async jsonHtml() {
      this.loading = true
      this.outBlur()
      var template = "";
      const that = this
      console.log('that.designData===>', that.designData)
      this.cacheComponents.forEach(it => {
        template += `${setDatel(it.cptKey, it)}`;
      })
      const bgtile1 = '<div style="width:' + that.designData.scaleX + 'px;height:' + that.designData.scaleY + 'px;background-color: ' + that.designData.bgColor + ';max-width:' + that.designData.scaleX + 'px;max-height: ' + that.designData.scaleY + 'px;overflow: hidden;">'
      const tile2 = '<div style="position: relative;width: ' + that.designData.scaleX + 'px;height: ' + that.designData.scaleY + 'px;">'
      const tile3 = '</div></div>'
      const jsv0 = '<script src="'
      const jsv1 = './vue.min.js">'
      const jsv2 = "</scr"
      const jsv3 = "ipt>"
      let html = `<!DOCTYPE html>
                <html>
                <head>
                <meta charset="utf-8">
                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                <meta name="viewport" content="width=device-width,initial-scale=1.0,user-scalable=no" >
                 ${jsv0 + jsv1 + jsv2 + jsv3}
                <meta name="referrer" content="never"><style> *{ margin: 0;  padding: 0;} body{}</style>
                 <link rel="stylesheet" href="./stylesheet.css">
                <title></title>
                </head>
                <body>${bgtile1 + tile2 + template + tile3}</body>
                </html>`
      const options = {
        logging: true,
        useCORS: true,
        allowTaint: false,
      }
      html2canvas(document.getElementById('myElement'), options).then(async canvas => {
        var base64 = canvas.toDataURL("image/jpeg")
        let fn = new FormData()
        fn.append('htmlData', html)
        fn.append('thumbnailData', base64.split('data:image/jpeg;base64,')[1])
        await axios({
          method: 'POST',
          url: `${fileUrl}/prod-api/common/genHtmlAndThumbnail`,
          data: fn,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
            "Authorization": getToken()
          },
        }).then(postMessage => {
          this.setView(postMessage.data)
        })
      })
    },
    chickBack() {
      this.isFile = false
      this.isSelect = false
    },
    Pushdevice(data) {
      this.isSelect = true
    },
    handleUndo() {
      let daHis = this.historyData[this.historyData.length - 2]
      let daHispop = this.historyData[this.historyData.length - 1]
      if (daHis === undefined) {
        const itredo = this.historyData.pop()
        this.RedoData.push(itredo)
        this.cacheComponents.splice(0, 1)
      } else {
        const indexs = daHis.data.findIndex(itmes => itmes.id === daHispop.id)
        if (indexs === -1) {
          const cacts = this.cacheComponents.findIndex(itme => itme.id === daHispop.id)
          this.cacheComponents.splice(cacts, 1)
          const itredo = this.historyData.pop()
          this.RedoData.push(itredo)
        } else {
          const index = this.cacheComponents.findIndex(itme => itme.id === daHis.id)
          if (indexs !== -1) {
            this.cacheComponents[index].cptWidth = daHis.width
            this.cacheComponents[index].cptHeight = daHis.height
            this.cacheComponents[index].cptX = daHis.x
            this.cacheComponents[index].cptY = daHis.y
            this.cacheComponents[index].cptZ = daHis.z
            this.cacheComponents[index].cptOption = daHis.Option
            const itredo = this.historyData.pop()
            this.RedoData.push(itredo)
          } else {

          }
        }
      }
    },
    handleRedo() {
      let daHis = this.RedoData.shift()
      console.log(daHis)
      const index = this.cacheComponents.findIndex(itme => itme.id === daHis.id)
      if (index !== -1) {
        this.cacheComponents[index].cptWidth = daHis.width
        this.cacheComponents[index].cptHeight = daHis.height
        this.cacheComponents[index].cptX = daHis.x
        this.cacheComponents[index].cptY = daHis.y
        this.cacheComponents[index].cptZ = daHis.z
        this.cacheComponents[index].cptOption = daHis.Option
      } else {
        this.cacheComponents.push(...daHis.data)
      }

    },
    handleKeyDown(event) {
      const {key, ctrlKey} = event
      if (key === 'z' && ctrlKey) {
        this.handleUndo()
      }
      if (ctrlKey) {
        document.body.style.cursor = 'move';
      }
      if (this.currentCptIndex !== -1) {
        let key = event.key
        switch (key) {//方向键移动当前组件
          case 'ArrowDown':
            this.currentCpt.cptY += 1;
            break;
          case 'ArrowUp':
            this.currentCpt.cptY -= 1;
            break;
          case 'ArrowLeft':
            this.currentCpt.cptX -= 1
            break;
          case 'ArrowRight':
            this.currentCpt.cptX += 1
            break;

        }
      }
    },
    handlekeyUp(event) {
      const {key, ctrlKey} = event
      document.body.style.cursor = 'default';
    },
    moveNodeDown() {
      const child = document.getElementById('myElement')
      let isDragging = false
      let prevX = 0
      let prevY = 0
      child.addEventListener('mousedown', function (e) {
        isDragging = true
        prevX = e.clientX
        prevY = e.clientY
      })
      child.addEventListener('mouseup', function () {
        isDragging = false
      })
      child.addEventListener('mousemove', function (e) {
        const {key, ctrlKey} = e
        if (isDragging && ctrlKey) {
          const diffX = e.clientX - prevX
          const left = parseInt(window.getComputedStyle(child).left) || 0
          child.style.left = `${left + diffX}px`
          prevX = e.clientX
          const diffY = e.clientY - prevY
          const top = parseInt(window.getComputedStyle(child).top) || 0
          child.style.top = `${top + diffY}px`
          prevY = e.clientY
        }
      })
    },
    handleClose(done) {
      done();
    },

  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  directives: {
    resize(el, binding, vNode) {//组件拉伸，移动位置
      el.onmousedown = function (e) {
        console.log('----<<>>', e)
        const {key, ctrlKey} = e
        if (!ctrlKey) {
          const that = vNode.context;
          const scaleClientX = e.clientX / that.containerScale;
          const scaleClientY = e.clientY / that.containerScale;
          const rbX = scaleClientX - el.parentNode.offsetWidth;
          const rbY = scaleClientY - el.parentNode.offsetHeight;
          const ltX = scaleClientX + el.parentNode.offsetWidth;
          const ltY = scaleClientY + el.parentNode.offsetHeight;
          const disX = scaleClientX - el.parentNode.offsetLeft;
          const disY = scaleClientY - el.parentNode.offsetTop;
          let cptWidth, cptHeight, cptX, cptY;

          document.onmousemove = function (me) {
            const meScaleClientX = me.clientX / that.containerScale
            const meScaleClientY = me.clientY / that.containerScale
            if (binding.value === 'move') {
              cptX = meScaleClientX - disX;
              cptY = meScaleClientY - disY;
              Object.keys(that.cacheChoices).forEach((key) => {
                that.cacheChoices[key].cptX = parseInt(that.cacheChoicesFixed[key].cptX) + Math.round(meScaleClientX - scaleClientX)
                that.cacheChoices[key].cptY = parseInt(that.cacheChoicesFixed[key].cptY) + Math.round(meScaleClientY - scaleClientY)
                $bus.emit('freeboard', {
                  id: 4, data: {
                    id: that.cacheChoicesFixed[key].id,
                    cptx: that.cacheChoices[key].cptX,
                    cptY: that.cacheChoices[key].cptY,
                    buttoncptX: that.cacheChoices[key].cptX + that.cacheChoices[key].cptWidth,
                    buttoncptY: that.cacheChoices[key].cptY + that.cacheChoices[key].cptHeight,
                  }
                })
              })
            } else {
              switch (binding.value) {
                case 'lt':
                  cptWidth = ltX - meScaleClientX;
                  cptHeight = ltY - meScaleClientY;
                  cptX = meScaleClientX - disX;
                  cptY = meScaleClientY - disY;
                  that.currentCpt.cptX = Math.round(cptX);
                  that.currentCpt.cptY = Math.round(cptY);
                  break;
                case 't':
                  cptHeight = ltY - meScaleClientY;
                  cptY = meScaleClientY - disY;
                  that.currentCpt.cptY = Math.round(cptY);
                  break;
                case 'rt':
                  cptWidth = meScaleClientX - rbX;
                  cptHeight = ltY - meScaleClientY;
                  cptY = meScaleClientY - disY;
                  that.currentCpt.cptY = Math.round(cptY);
                  break;
                case 'r':
                  cptWidth = meScaleClientX - rbX;
                  break;
                case 'rb':
                  cptWidth = meScaleClientX - rbX;
                  cptHeight = meScaleClientY - rbY;
                  break;
                case 'b':
                  cptHeight = meScaleClientY - rbY;
                  break;
                case 'lb':
                  cptWidth = ltX - meScaleClientX;
                  cptHeight = meScaleClientY - rbY;
                  cptX = meScaleClientX - disX;
                  that.currentCpt.cptX = Math.round(cptX);
                  break;
                case 'l':
                  cptWidth = ltX - meScaleClientX;
                  cptX = meScaleClientX - disX;
                  that.currentCpt.cptX = Math.round(cptX);
                  break;
              }
              cptWidth = cptWidth < 40 ? 40 : cptWidth;//限制最小缩放
              cptHeight = cptHeight < 20 ? 20 : cptHeight;
              if (cptWidth) that.currentCpt.cptWidth = Math.round(cptWidth);
              if (cptHeight) that.currentCpt.cptHeight = Math.round(cptHeight);
            }
          }
          document.onmouseup = function () {
            document.onmousemove = document.onmouseup = null;
            that.cacheChoicesFixed = JSON.parse(JSON.stringify(that.cacheChoices));//解决多选移动未松开ctrl键第二次以后拖动定位还原
          }
        }


        return false;
      }
    }
  },
  watch: {}
}
</script>

<style scoped lang="scss">
.top {
  z-index: 2000;
  height: 54px;
  color: #fff;
  overflow: hidden;
  margin: 0;
  font-size: 18px;
  line-height: 45px;
  background: rgba(255, 255, 255, 1);
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.span_seve {
  margin-left: 12px;
  font-size: 14px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 14px;
}

.span_preview {
  margin-left: 12px;
  font-size: 14px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #404756;
  line-height: 14px;
}

.span_tile {
  margin: auto;
  font-size: 16px;
  font-family: Inter-SemiBold, Inter;
  font-weight: normal;
  color: #404756;
  line-height: 16px;
}


.webContainer {
  display: block;
  position: absolute;
  margin: 50px;
  background-size: 100% 100%;
  transform-origin: 0 0;

}

.delTag {
  width: auto;
  height: 22px;
  background: rgba(43, 51, 64, 0.8);
  border-radius: 2px;
  color: #ccc;
  z-index: 2000;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  display: none;
  cursor: pointer;

}

.activeMask {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1801
}


.cptDiv {
  display: block;
  position: absolute;
  outline: none;
}

.cptDiv:hover .delTag {
  display: block
}

.resizeTag {
  width: 8px;
  height: 8px;
  position: absolute;
  background-color: #B6BFCE;
  z-index: 2000;
  border-radius: 50%;

}

.configBtn:hover {
  cursor: pointer;
  color: #B6BFCE
}

.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}

.top_div_tile {
  padding: 7px 8px;
  display: flex;
  position: absolute;
  top: -30px;
  background: #9931F8;
  width: fit-content;
  height: fit-content;
  margin-left: 12px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

}

.span_tile_name {
  white-space: nowrap;
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 14px;
}

.span_tile_board {
  margin: 40px auto;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #8F8F8F;
  line-height: 14px;
}


.span_boadrd {
  margin: auto;
  font-size: 48px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #1E1E1E;
  line-height: 48px;
}

.span_iles {
  margin: auto 20px;
  font-size: 16px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #9931F8;
  line-height: 16px;
}

.tile_span_r {
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  font-size: 13px;
  color: #1E1E1E;
  line-height: 13px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.tile_span_r_false {
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  font-size: 13px;
  color: #C0BFC0;
  line-height: 13px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.div_tile_r {
  width: 90px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.svgicon {
  margin-top: -10px;
  width: 30px;
  height: 30px;
  overflow: hidden;

  .img_svg0 {
    position: relative;
    transform: translateX(-80px);
    filter: drop-shadow(80px 0 #C0BFC0);
    border-left: 0 solid transparent; //防止drop-shadow主体超出视线隐藏后阴影消失
    border-right: 0 solid transparent;
    opacity: 0.5;
  }

  .img_svg1 {
    position: relative;
    transform: translateX(-80px);
    filter: drop-shadow(80px 0 #1E1E1E);
    border-left: 0 solid transparent; //防止drop-shadow主体超出视线隐藏后阴影消失
    border-right: 0 solid transparent;
  }

}

</style>