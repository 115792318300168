<template>
  <div v-if="show" class="mask">
    <div class="dlg-msg-box flex flex-col">
      <div class="flex-col div_forgot" style="width: 328px;height: auto;margin: auto;padding-bottom: 24px">
        <span class="title_span">File Uploaded</span>
        <div style="width: 100%;display: flex;align-items: center;align-content: center;justify-content: center;">
          <img :width="142" :height="142" style="margin-top: 32px;" :src="require('@/assets/icon/success.svg')"/>
        </div>

        <div class="flex-col" style="width: 344px;margin-top: 32px;margin-bottom: 32px;">
          <span class="span_up_add">File upload completed.</span>
        </div>
        <button class="button_cancel" @click="close()" style="margin: auto;">Close</button>

      </div>

    </div>
  </div>
</template>
<script>
export default {
  name: "FileUplade",
  props: {
    caption: {},
    show: {},
    msg: {},
    callback: {}
  },
  methods: {
    init() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = true;
    },
    close() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("close")
    },
    confirmClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("yes")
      //this.$emit('confirm');
    },
    cancelClick() {
      // eslint-disable-next-line vue/no-mutating-props
      this.show = false;
      this.callback("no")
    }
  }
}
</script>

<style>

</style>
<style scoped>
@import url(dialogcss.css);
</style>